import { useState } from "react";

function useMarketingListContainer() {
  const [selectedMarketingId, setSelectedMarketingId] = useState(null);

  function onMarketingSelect(id) {
    setSelectedMarketingId(id);
  }

  return {
    models: {
      selectedMarketingId,
    },
    operations: {
      onMarketingSelect,
    },
  };
}

export default useMarketingListContainer;

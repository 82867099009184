import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useReducer, useRef } from "react";
import imageCompression from "browser-image-compression";

import {
  CREATE_ART,
  DELETE_ART_AUDIO,
  DELETE_ART_MEDIA,
  GET_ART,
  GET_ARTS,
  UPDATE_ART,
  UPDATE_ART_AUDIOS,
  UPDATE_ART_MEDIA,
  UPDATE_ART_PRIMARY_MEDIA,
} from "../../../lib/apollo/gql/artGqls";
import { GET_USER_PROFILE } from "../../../lib/apollo/gql/userGqls";
import { COMPRESSION_OPTION } from "../../../utilities/constants";

export const UPDATE_ART_MODAL_ACTION_TYPES = {
  HANDLE_TYPE: "HANDLE_TYPE",
  HANDLE_CATEGORY: "HANDLE_CATEGORY",

  HANDLE_ARTIST_ID: "HANDLE_ARTIST_ID",
  HANDLE_ARTIST_NAME_KOR: "HANDLE_ARTIST_NAME_KOR",
  HANDLE_ARTIST_NAME_ENG: "HANDLE_ARTIST_NAME_ENG",

  HANDLE_SPACE_ID: "HANDLE_SPACE_ID",

  HANDLE_TITLE_KOR: "HANDLE_TITLE_KOR",
  HANDLE_TITLE_ENG: "HANDLE_TITLE_ENG",

  HANDLE_MATERIAL_KOR: "HANDLE_MATERIAL_KOR",
  HANDLE_MATERIAL_ENG: "HANDLE_MATERIAL_ENG",

  HANDLE_WIDTH: "HANDLE_WIDTH",
  HANDLE_HEIGHT: "HANDLE_HEIGHT",
  HANDLE_DEPTH: "HANDLE_DEPTH",

  HANDLE_YEAR: "HANDLE_YEAR",

  HANDLE_TAG: "HANDLE_TAG",

  HANDLE_EDITION: "HANDLE_EDITION",

  HANDLE_ARTIST_NOTE: "HANDLE_ARTIST_NOTE",

  HANDLE_OWNER_ID: "HANDLE_OWNER_ID",

  HANDLE_SEARCH_COLOR_IDS: "HANDLE_SEARCH_COLOR_IDS",

  HANDLE_SEARCH_MATERIAL_IDS: "HANDLE_SEARCH_MATERIAL_IDS",

  HANDLE_SEARCH_CATEGORY_ID: "HANDLE_SEARCH_CATEGORY_ID",

  HANDLE_PRIMARY_MEDIA_STATE: "HANDLE_PRIMARY_MEDIA_STATE",

  HANDLE_MEDIA_STATE: "HANDLE_MEDIA_STATE",

  HANDLE_AUDIOS_STATE: "HANDLE_AUDIOS_STATE",

  SET_ALL: "SET_ALL",
};

/**
 * Update art modal's reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function reducer(state, action) {
  switch (action.type) {
    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_TYPE: {
      return {
        ...state,
        type: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_CATEGORY: {
      return {
        ...state,
        category: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_ARTIST_ID: {
      return {
        ...state,
        artist: action.payload,
        owner: action.payload,
        artistNameKor: "",
        artistNameEng: "",
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_ARTIST_NAME_KOR: {
      return {
        ...state,
        artist: null,
        artistNameKor: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_ARTIST_NAME_ENG: {
      return {
        ...state,
        artist: null,
        artistNameEng: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_SPACE_ID: {
      return {
        ...state,
        space: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_TITLE_KOR: {
      return {
        ...state,
        titleKor: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_TITLE_ENG: {
      return {
        ...state,
        titleEng: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_MATERIAL_KOR: {
      return {
        ...state,
        materialKor: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_MATERIAL_ENG: {
      return {
        ...state,
        materialEng: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_WIDTH: {
      return {
        ...state,
        width: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_HEIGHT: {
      return {
        ...state,
        height: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_DEPTH: {
      return {
        ...state,
        depth: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_YEAR: {
      return {
        ...state,
        year: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_TAG: {
      return {
        ...state,
        tag: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_EDITION: {
      return {
        ...state,
        edition: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_ARTIST_NOTE: {
      return {
        ...state,
        artistNote: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_OWNER_ID: {
      return {
        ...state,
        artist: null,
        owner: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_SEARCH_COLOR_IDS: {
      return {
        ...state,
        searchColorIds: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_SEARCH_MATERIAL_IDS: {
      return {
        ...state,
        searchMaterialIds: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_SEARCH_CATEGORY_ID: {
      return {
        ...state,
        searchCategoryId: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_PRIMARY_MEDIA_STATE: {
      return {
        ...state,
        primaryMediaState: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_MEDIA_STATE: {
      return {
        ...state,
        mediaState: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_AUDIOS_STATE: {
      return {
        ...state,
        audiosState: action.payload,
      };
    }

    case UPDATE_ART_MODAL_ACTION_TYPES.SET_ALL: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

const initialState = {
  id: "",
  type: null,
  category: null,
  artist: null,
  space: null,
  artistNameKor: "",
  artistNameEng: "",
  titleKor: "",
  titleEng: "",
  materialKor: "",
  materialEng: "",
  width: "",
  height: "",
  depth: "",
  year: new Date().getFullYear(),
  tag: "",
  edition: "",
  owner: null,
  searchColorIds: [],
  searchMaterialIds: [],
  searchCategoryId: null,
  artistNote: "",
  primaryMedia: null,
  media: [],
  audios: [],
  primaryMediaState: null,
  mediaState: null,
  audiosState: null,
};

function useUpdateArtModal(selectedArtId, onRequestClose) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const primaryMediaRef = useRef(null);
  const mediaRef = useRef(null);
  const audioRef = useRef(null);

  const [createArt, { loading: createLoading }] = useMutation(CREATE_ART, {
    onCompleted: () => {
      onRequestClose();
    },
    refetchQueries: () => [{ query: GET_ARTS }],
  });

  const [updateArt, { loading: updateLoading }] = useMutation(UPDATE_ART, {
    onCompleted: () => {
      onRequestClose();
    },
  });

  const [updateArtPrimaryMedia] = useMutation(UPDATE_ART_PRIMARY_MEDIA, {
    onCompleted: () => {
      dispatch({
        type: UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_PRIMARY_MEDIA_STATE,
        payload: null,
      });
    },
  });

  const [updateArtMedia] = useMutation(UPDATE_ART_MEDIA, {
    onCompleted: () => {
      dispatch({
        type: UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_MEDIA_STATE,
        payload: [],
      });
    },
  });
  const [deleteArtMedia] = useMutation(DELETE_ART_MEDIA);

  const [updateArtAudios] = useMutation(UPDATE_ART_AUDIOS, {
    onCompleted: () => {
      dispatch({
        type: UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_AUDIOS_STATE,
        payload: [],
      });
    },
  });
  const [deleteArtAudio] = useMutation(DELETE_ART_AUDIO);

  const [getArt, { loading: getArtLoading }] = useLazyQuery(GET_ART, {
    fetchPolicy: "network-only",
    onCompleted: ({ getArt }) => {
      const {
        id,
        type,
        category,
        artist,
        space,
        artistNameKor,
        artistNameEng,
        titleKor,
        titleEng,
        materialKor,
        materialEng,
        width,
        height,
        depth,
        year,
        tag,
        edition,
        searchColors,
        searchMaterials,
        searchCategory,
        artistNote,
        primaryMedia,
        media,
        audios,
      } = getArt;

      const searchColorIds = [...searchColors]?.map((color) => color.id);
      const searchMaterialIds = [...searchMaterials]?.map(
        (material) => material.id
      );

      const payload = {
        id,
        type: { value: type, label: type },
        category: { value: category, label: category },
        artist: artist
          ? {
              value: artist.id,
              label: `${artist?.user?.name}(${artist?.user?.email || ""})`,
            }
          : null,
        space: space
          ? {
              value: space.id,
              label: `${space?.user?.name}`,
            }
          : null,
        artistNameKor: artistNameKor || "",
        artistNameEng: artistNameEng || "",
        titleKor,
        titleEng,
        materialKor,
        materialEng,
        width: width || "",
        height: height || "",
        depth: depth || "",
        year: year,
        tag: tag || "",
        edition: edition || "",
        artistNote,
        searchColorIds,
        searchMaterialIds,
        searchCategoryId: searchCategory?.id,
        primaryMedia,
        media,
        audios,
      };

      dispatch({
        type: UPDATE_ART_MODAL_ACTION_TYPES.SET_ALL,
        payload,
      });
    },
  });

  const [getUserProfile] = useLazyQuery(GET_USER_PROFILE);

  useEffect(() => {
    if (selectedArtId !== "new") {
      getArt({
        variables: {
          id: selectedArtId,
        },
      });
    }
  }, [selectedArtId]);

  /**
   * Handle input values for text input and text area
   * @param {object} e - Keyboard Event
   * @param {object} type - Reducer action type
   */
  function onChange(e, type) {
    const { value } = e.target;

    dispatch({ type, payload: value });
  }

  /**
   * Handler selector value
   * @param {object} value - react-selector value
   * @param {object} type - Reducer action type
   */
  function onSelectorChange(value, type) {
    dispatch({ type, payload: value });
  }

  /**
   * Handle file input for primary media
   * @param {object} e - File input event
   */
  function onPrimaryMediaChange(e) {
    const { files } = e.target;

    const file = files[0];

    dispatch({
      type: UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_PRIMARY_MEDIA_STATE,
      payload: file,
    });
  }

  /**
   * Handle file input for media
   * @param {object} e - File input event
   */
  function onMediaChange(e) {
    const { files } = e.target;

    const payload = [];

    for (let file of files) {
      payload.push(file);
    }

    dispatch({
      type: UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_MEDIA_STATE,
      payload: payload,
    });
  }

  /**
   * Handle file input for audio
   * @param {object} e - File input event
   */
  function onAudioChange(e) {
    const { files } = e.target;

    const payload = [];

    for (let file of files) {
      payload.push(file);
    }

    dispatch({
      type: UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_AUDIOS_STATE,
      payload: payload,
    });
  }

  /**
   * Handle search colors
   * @param {string} colorId
   */
  function onSearchColorChange(colorId) {
    const editedSearchColorIds = [...state.searchColorIds].filter(
      (id) => id !== colorId
    );
    if (editedSearchColorIds?.length === state.searchColorIds.length) {
      editedSearchColorIds.push(colorId);
    }

    dispatch({
      type: UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_SEARCH_COLOR_IDS,
      payload: editedSearchColorIds,
    });
  }

  /**
   * Handle search materials
   * @param {string} materialId
   */
  function onSearchMaterialChange(materialId) {
    const editedSearchMaterialIds = [...state.searchMaterialIds].filter(
      (id) => id !== materialId
    );
    if (editedSearchMaterialIds?.length === state.searchMaterialIds.length) {
      editedSearchMaterialIds.push(materialId);
    }

    dispatch({
      type: UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_SEARCH_MATERIAL_IDS,
      payload: editedSearchMaterialIds,
    });
  }

  /**
   * Handle search category
   * @param {string} categoryId
   */
  function onSearchCategoryChange(categoryId) {
    dispatch({
      type: UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_SEARCH_CATEGORY_ID,
      payload: categoryId,
    });
  }

  /**
   * When submit button is clicked,
   * if there's selected art id, it updates art except media
   * else it creates a new art
   */
  async function onSubmit() {
    const {
      id,
      type,
      category,
      artist,
      space,
      artistNameKor,
      artistNameEng,
      titleKor,
      titleEng,
      materialKor,
      materialEng,
      width,
      height,
      depth,
      year,
      tag,
      edition,
      owner,
      searchColorIds,
      searchMaterialIds,
      searchCategoryId,
      artistNote,
      primaryMediaState,
      mediaState,
      audiosState,
    } = state;

    const artistNameObj = {};

    if (artist) {
      const { data } = await getUserProfile({
        variables: {
          id: artist?.value,
        },
      });
      if (data?.getUserProfile) {
        const { nameEng, nameKor } = data?.getUserProfile;

        artistNameObj.artistNameEng = nameEng;
        artistNameObj.artistNameKor = nameKor;
      }
    }

    if (selectedArtId === "new") {
      const primaryMediaInput = {};
      const mediaInput = [];
      const audiosInput = [];

      if (primaryMediaState) {
        await imageCompression(primaryMediaState, COMPRESSION_OPTION).then(
          (file) => {
            primaryMediaInput.file = file;
          }
        );
      }

      if (mediaState?.length > 0) {
        for await (let file of mediaState) {
          await imageCompression(file, COMPRESSION_OPTION).then((file) => {
            mediaInput.push({ file });
          });
        }
      }

      if (audiosState?.length > 0) {
        for await (let file of audiosState) {
          audiosInput.push({ file });
        }
      }

      const artInput = {
        type: type?.value,
        category: category?.value,
        artistId: artist ? artist?.value : null,
        spaceId: space ? space?.value : null,
        artistNameKor: artist ? artistNameObj.artistNameKor : artistNameKor,
        artistNameEng: artist ? artistNameObj.artistNameEng : artistNameEng,
        titleKor,
        titleEng,
        materialKor,
        materialEng,
        width,
        height,
        depth: depth || null,
        year: year.toString(),
        tag,
        edition: edition || null,
        ownerId: owner?.value,
        searchColorIds,
        searchMaterialIds,
        searchCategoryId,
        artistNote,
        primaryMediaInput: primaryMediaInput,
        mediaInput: mediaInput?.length > 0 ? mediaInput : null,
        audiosInput: audiosInput?.length > 0 ? audiosInput : null,
      };

      createArt({
        variables: {
          artInput,
        },
      });
    } else {
      const updateArtInput = {
        id,
        type: type?.value,
        category: category?.value,
        artistId: artist ? artist?.value : null,
        spaceId: space ? space?.value : null,
        artistNameKor: artist ? artistNameObj.artistNameKor : artistNameKor,
        artistNameEng: artist ? artistNameObj.artistNameEng : artistNameEng,
        titleKor,
        titleEng,
        materialKor,
        materialEng,
        width,
        height,
        depth: depth || null,
        year: year.toString(),
        tag,
        edition: edition || null,
        ownerId: owner?.value,
        searchColorIds,
        searchMaterialIds,
        searchCategoryId,
        artistNote,
      };

      updateArt({
        variables: {
          updateArtInput,
        },
      });
    }
  }

  function isSubmitDisabled() {
    const {
      type,
      category,
      artist,
      space,
      artistNameKor,
      artistNameEng,
      titleKor,
      titleEng,
      materialKor,
      materialEng,
      width,
      height,
      year,
      owner,
      searchColorIds,
      searchMaterialIds,
      searchCategoryId,
      artistNote,
      primaryMedia,
      primaryMediaState,
      mediaState,
      audiosState,
    } = state;

    const typeDisabled = !type;
    const ctegoryDisabled = !category;
    const artistDisabled = !(
      artist ||
      (owner && artistNameKor && artistNameEng)
    );
    const titleDisabled = !(titleKor && titleEng);
    const materialDisabled = !(materialKor && materialEng);
    const yearDisabled = !year;
    const searchColorDisabled = !(searchColorIds?.length > 0);
    const searchMaterialDisabled = !(searchMaterialIds?.length > 0);
    const searchCategoryDisabled = !searchCategoryId;
    const mediaDisabled = !primaryMedia && !primaryMediaState;
    const loadingDisabled = createLoading || updateLoading;

    return (
      typeDisabled ||
      ctegoryDisabled ||
      artistDisabled ||
      titleDisabled ||
      materialDisabled ||
      yearDisabled ||
      searchColorDisabled ||
      searchMaterialDisabled ||
      searchCategoryDisabled ||
      mediaDisabled ||
      loadingDisabled
    );
  }

  /**
   * When primary media edit button is clicked,
   * it shows file selector
   */
  function onPrimaryMediaEditClick() {
    primaryMediaRef?.current?.click();
  }

  /**
   * When register new primary media button is clicked
   * it updates primary media
   */
  async function onPrimaryMediaChangeClick() {
    const { primaryMediaState } = state;

    const primaryMediaInput = {};

    if (primaryMediaState) {
      await imageCompression(primaryMediaState, COMPRESSION_OPTION).then(
        (file) => {
          primaryMediaInput.file = file;
        }
      );

      updateArtPrimaryMedia({
        variables: {
          artId: selectedArtId,
          primaryMediaInput,
        },
      });
    }
  }

  /**
   * When select media button is clicked,
   * it shows file selector
   */
  function onAddMediaClick() {
    mediaRef?.current?.click();
  }

  /**
   * When delete button for a each media file is clicked,
   * delete art media
   * @param {string} mediaId
   */
  function onMediaDeleteClick(mediaId) {
    const confirm = window.confirm(
      "한 번 삭제하면 되돌릴 수 없습니다. 삭제하시겠습니까?"
    );
    if (confirm) {
      deleteArtMedia({
        variables: {
          artId: selectedArtId,
          mediaId,
        },
      });
    }
  }

  /**
   * Register new media for art
   */
  async function onUpdateMediaClick() {
    const { mediaState } = state;
    const mediaInput = [];

    if (mediaState?.length > 0) {
      for await (let file of mediaState) {
        await imageCompression(file, COMPRESSION_OPTION).then((file) => {
          mediaInput.push({ file });
        });
      }

      updateArtMedia({
        variables: {
          artId: selectedArtId,
          mediaInput,
        },
      });
    }
  }

  function onEditAudioClick() {
    audioRef?.current?.click();
  }

  async function onAudioChangeClick() {
    const { audios, audiosState } = state;
    if (audios?.length > 0) {
      for await (let audio of audios) {
        await deleteArtAudio({
          variables: {
            artId: selectedArtId,
            audioId: audio.id,
          },
        });
      }
    }

    const audiosInput = [];

    if (audiosState?.length > 0) {
      for await (let file of audiosState) {
        audiosInput.push({ file });
      }

      await updateArtAudios({
        variables: {
          artId: selectedArtId,
          audiosInput,
        },
      });
    }
  }

  return {
    refs: {
      primaryMediaRef,
      mediaRef,
      audioRef,
    },
    models: {
      state,
      loading: createLoading || updateLoading,
      getArtLoading,
    },
    operations: {
      onChange,
      onSelectorChange,
      onPrimaryMediaChange,
      onMediaChange,
      onAudioChange,
      onSearchColorChange,
      onSearchMaterialChange,
      onSearchCategoryChange,
      onSubmit,
      isSubmitDisabled,
      onPrimaryMediaEditClick,
      onPrimaryMediaChangeClick,
      onAddMediaClick,
      onMediaDeleteClick,
      onUpdateMediaClick,
      onEditAudioClick,
      onAudioChangeClick,
    },
  };
}

export default useUpdateArtModal;

import React from "react";
import { Flex } from "../common/styles/commonStyles";
import CouponContainer from "./coupon/CouponContainer";
import CouponTypeContainer from "./couponType/CouponTypeContainer";

function ManageCouponContainer() {
  return (
    <Flex
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
    >
      <CouponTypeContainer />
      <CouponContainer />
    </Flex>
  );
}

export default ManageCouponContainer;

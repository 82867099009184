import React from "react";
import { REPORT_TYPE } from "../../../utilities/constants";
import Loading from "../../common/components/Loading";
import Pagination from "../../common/components/Pagination";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../common/styles/commonStyles";
import ReportReasonModal from "../reportReason/ReportReasonModal";
import ArtReportList from "./components/ArtReportList";
import NoteReportList from "./components/NoteReportList";
import ReportCard from "./components/ReportCard";
import useReportContainer from "./ReportContainer.hook";

function ReportContainer() {
  const {
    models: { type, showReasonModal },
    operations: { onTypeChange, toggleReasonModal },
  } = useReportContainer();

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
      overflowY="auto"
      padding={12}
    >
      <Flex marginBottom={12} justifyContent="space-between" width="100%">
        <Flex flexDirection="row" alignItems="center" gap={4}>
          <SectionTitle>신고</SectionTitle>
          <TextButton
            backgroundColor={type === REPORT_TYPE.ART ? "blue" : "gray"}
            onClick={() => onTypeChange(REPORT_TYPE.ART)}
          >
            작품
          </TextButton>
          <TextButton
            backgroundColor={type === REPORT_TYPE.NOTE ? "blue" : "gray"}
            onClick={() => onTypeChange(REPORT_TYPE.NOTE)}
          >
            노트
          </TextButton>
        </Flex>
        <TextButton onClick={() => toggleReasonModal(true)}>
          신고 사유 관리
        </TextButton>
      </Flex>
      {type === REPORT_TYPE.NOTE ? <NoteReportList /> : <ArtReportList />}
      {showReasonModal && (
        <ReportReasonModal
          isOpen={showReasonModal}
          onRequestClose={() => toggleReasonModal(false)}
        />
      )}
    </Flex>
  );
}

export default ReportContainer;

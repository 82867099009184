import { useState } from "react";
import { CONTENT_TYPES } from "../../../utilities/constants";

function useContentListContainer() {
  const [selectedContentType, setSelectedContentType] = useState(
    CONTENT_TYPES.ALL
  );
  const [selectedContentId, setSelectedContentId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchedQuery, setSearchedQuery] = useState("");

  function onContentTypeClick(type) {
    if (type !== selectedContentType) {
      setSelectedContentType(type);
    }
  }

  function onSelectContent(contentId) {
    setSelectedContentId(contentId);
  }

  function onSearchChange(e) {
    const { value } = e.target;

    setSearchQuery(value);
    if (value.length === 0 && searchedQuery) {
      setSearchedQuery("");
    }
  }

  function onSearchKeyDown(e) {
    const { key } = e;

    if (key === "Enter") {
      onSearch();
    }
  }

  function onSearch() {
    if (searchQuery !== searchedQuery) {
      setSearchedQuery(searchQuery);
    }
  }

  return {
    models: {
      selectedContentType,
      selectedContentId,
      searchQuery,
      searchedQuery,
    },
    operations: {
      onContentTypeClick,
      onSelectContent,
      onSearchChange,
      onSearchKeyDown,
      onSearch,
    },
  };
}

export default useContentListContainer;

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ADD_PLAYLIST_ITEMS_AS_ADMIN,
  DELETE_PLAYLIST_ITEM_AS_ADMIN,
  GET_PLAYLIST,
  GET_PLAYLISTS,
  GET_PLAYLIST_ITEMS,
  UPDATE_PLAYLIST_AS_ADMIN,
} from "../../../lib/apollo/gql/playlistGqls";
import { useEffect, useRef, useState } from "react";
import imageCompression from "browser-image-compression";
import { COMPRESSION_OPTION } from "../../../utilities/constants";
import { SEARCH_CONTENTS } from "../../../lib/apollo/gql/contentGqls";
import { SEARCH_ARTS } from "../../../lib/apollo/gql/artGqls";

const defaultContentsState = [
  { content: { value: null, label: null }, sort: 0 },
];

const defaultArtsState = [
  {
    art: { value: null, label: null },
    sort: 0,
  },
];

function useUpdatePlaylistContainer(selectedPlaylistId, onRequestClose) {
  const thumbnailInputRef = useRef();
  const audioInputRef = useRef();

  const [state, setState] = useState({
    id: selectedPlaylistId || "",
    theme: "",
    title: "",
    summary: "",
    thumbnailMediaState: null,
    audioState: null,
    backgroundColor: "",
  });

  const [contents, setContents] = useState(defaultContentsState);
  const [arts, setArts] = useState(defaultArtsState);

  const [updatePlaylistAsAdmin, { loading: updateLoading }] = useMutation(
    UPDATE_PLAYLIST_AS_ADMIN,
    {
      onCompleted: ({ updatePlaylistAsAdmin }) => {
        setState((prev) => ({
          ...prev,
          id: updatePlaylistAsAdmin?.id,
        }));

        getPlaylistItems({
          variables: {
            playlistId: updatePlaylistAsAdmin?.id,
          },
        });
      },
      refetchQueries: () => [
        {
          query: GET_PLAYLISTS,
          variables: {
            activeOnly: false,
            first: 10,
          },
        },
      ],
    }
  );

  const [getPlaylist, { data, loading: playlistLoading }] = useLazyQuery(
    GET_PLAYLIST,
    {
      onCompleted: ({ getPlaylist }) => {
        const { id, theme, title, summary, backgroundColor } = getPlaylist;

        setState((prev) => ({
          ...prev,
          id,
          theme,
          title,
          summary,
          backgroundColor,
        }));
      },
    }
  );

  const [
    getPlaylistItems,
    { data: playlistItemsData, loading: playlistItemsLoading },
  ] = useLazyQuery(GET_PLAYLIST_ITEMS);

  const [addPlaylistItemsAsAdmin, { loading: addPlaylistItemLoading }] =
    useMutation(ADD_PLAYLIST_ITEMS_AS_ADMIN, {
      onCompleted: () => {
        setContents(defaultContentsState);
        setArts(defaultArtsState);
      },
      refetchQueries: () => [
        {
          query: GET_PLAYLIST_ITEMS,
          variables: {
            playlistId: state?.id,
          },
        },
      ],
    });

  const [deletePlaylistItemAsAdmin, { loading: deletePlaylistItemLoading }] =
    useMutation(DELETE_PLAYLIST_ITEM_AS_ADMIN, {
      refetchQueries: () => [
        {
          query: GET_PLAYLIST_ITEMS,
          variables: {
            playlistId: state?.id,
          },
        },
      ],
    });

  const [searchContents] = useLazyQuery(SEARCH_CONTENTS);

  const [searchArts] = useLazyQuery(SEARCH_ARTS);

  useEffect(() => {
    if (selectedPlaylistId !== "new") {
      getPlaylist({
        variables: {
          playlistId: selectedPlaylistId,
        },
      });

      getPlaylistItems({
        variables: {
          playlistId: selectedPlaylistId,
        },
      });
    }
  }, [selectedPlaylistId]);

  /**
   * Handle input values like title, highlight, body, category
   * @param {object} e
   * @param {string} key
   */
  function onInputChange(e, key) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  /**
   * Handle media input
   */
  function onMediaChange(e, key) {
    const { files } = e.target;

    const file = files[0];

    setState((prev) => ({
      ...prev,
      [key]: file,
    }));
  }

  function onMediaChangeCancelClick(key) {
    setState((prev) => ({
      ...prev,
      [key]: null,
    }));
  }

  function isSubmitDisabled() {
    const { title, theme, summary, thumbnailMediaState, audioState } = state;

    if (selectedPlaylistId === "new") {
      return !(title && theme && summary && thumbnailMediaState);
    } else {
      return !(title && theme && summary);
    }
  }

  async function onSubmit() {
    const {
      id,
      title,
      theme,
      summary,
      backgroundColor,
      thumbnailMediaState,
      audioState,
    } = state;

    const playlistInput = {
      id: selectedPlaylistId === "new" ? null : id,
      title,
      theme,
      summary,
      backgroundColor,
      audioInput: audioState ? { file: audioState } : null,
    };

    if (thumbnailMediaState) {
      await imageCompression(thumbnailMediaState, COMPRESSION_OPTION).then(
        (file) => {
          playlistInput.thumbnailMediaInput = { file };
        }
      );
    }

    updatePlaylistAsAdmin({
      variables: {
        playlistInput,
      },
    });
  }

  function onContentSelectorChange(value, index) {
    let editedContents = [...contents];
    editedContents[index] = { ...editedContents[index], content: value };

    setContents(editedContents);
  }

  function onContentSortChange(e, index) {
    const { value } = e.target;

    let editedContents = [...contents];
    editedContents[index] = { ...editedContents[index], sort: value };

    setContents(editedContents);
  }

  function onContentStateDelete(selectedIndex) {
    const editedContents = [...contents].filter(
      (content, index) => index !== selectedIndex
    );

    setContents(
      editedContents?.length > 0 ? editedContents : defaultContentsState
    );
  }

  function onAddContentClick() {
    const editedContents = [...contents, defaultContentsState?.[0]];

    setContents(editedContents);
  }

  function onArtSelectorChange(value, index) {
    let editedArts = [...arts];
    editedArts[index] = { ...editedArts[index], art: value };

    setArts(editedArts);
  }

  function onArtSortChange(e, index) {
    const { value } = e.target;

    let editedArts = [...arts];
    editedArts[index] = { ...editedArts[index], sort: value };

    setArts(editedArts);
  }

  function onArtStateDelete(selectedIndex) {
    const editedArts = [...arts].filter(
      (art, index) => index !== selectedIndex
    );

    setArts(editedArts?.length > 0 ? editedArts : defaultArtsState);
  }

  function onAddArtClick() {
    const editedArts = [...arts, defaultArtsState?.[0]];

    setArts(editedArts);
  }

  const loadContentOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(async () => {
        const { data } = await searchContents({
          variables: {
            query: inputValue,
            type: null,
            activeOnly: false,
            offset: 0,
            limit: 100,
          },
        });

        const result = data?.searchContents?.edges;

        const options = result?.map((content) => ({
          value: content?.id,
          label: content?.title,
        }));

        resolve(options);
      }, 1);
    });

  const loadArtOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(async () => {
        const { data } = await searchArts({
          variables: {
            query: inputValue,
            offset: 0,
            limit: 20,
          },
        });

        const result = data?.searchArts?.edges;

        const options = result?.map((art) => ({
          value: art?.id,
          label: `${art?.titleKor}(${art?.titleEng})`,
        }));

        resolve(options);
      }, 1);
    });

  function onAddItemsSubmit() {
    const editedContents = [...contents]?.filter(
      (content) => content?.content?.value !== null
    );
    const editedArts = [...arts]?.filter((art) => art?.art?.value !== null);

    const playlistItemsInput = [];

    if (editedContents?.length > 0) {
      for (let content of editedContents) {
        playlistItemsInput.push({
          playlistId: state?.id,
          contentId: content?.content?.value,
          artId: null,
          sort: Number(content?.sort),
        });
      }
    }

    if (editedArts?.length > 0) {
      for (let art of editedArts) {
        playlistItemsInput.push({
          playlistId: state?.id,
          artId: art?.art?.value,
          contentId: null,
          sort: Number(art?.sort),
        });
      }
    }

    addPlaylistItemsAsAdmin({
      variables: {
        playlistItemsInput,
      },
    });
  }

  function onDeletePlaylistItem(playlistItemId) {
    deletePlaylistItemAsAdmin({
      variables: {
        playlistItemId,
        playlistId: state?.id,
      },
    });
  }

  return {
    refs: {
      thumbnailInputRef,
      audioInputRef,
    },
    models: {
      state,
      data: data?.getPlaylist,
      playlistLoading,
      updateLoading,
      playlistItem: playlistItemsData?.getPlaylistItems,
      playlistItemsLoading,
      contents,
      arts,
      addPlaylistItemLoading,
      deletePlaylistItemLoading,
    },
    operations: {
      onInputChange,
      onMediaChange,
      onMediaChangeCancelClick,
      isSubmitDisabled,
      onSubmit,
      loadContentOptions,
      loadArtOptions,
      onContentSelectorChange,
      onContentSortChange,
      onContentStateDelete,
      onAddContentClick,
      onArtSelectorChange,
      onArtSortChange,
      onArtStateDelete,
      onAddArtClick,
      onAddItemsSubmit,
      onDeletePlaylistItem,
    },
  };
}

export default useUpdatePlaylistContainer;

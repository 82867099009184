import styled from "styled-components";

import searchIcon from "../../../assets/icons/search.svg";

export const Flex = styled.div(
  ({
    flexDirection = "row",
    alignItems = "center",
    justifyContent = "center",
    ...rest
  }) => ({
    display: "flex",
    flexDirection,
    alignItems,
    justifyContent,
    ...rest,
  })
);

export const TextButton = styled.div(
  ({ backgroundColor = "black", color = "white", disabled, ...rest }) => ({
    cursor: "pointer",
    borderRadius: 6,
    textAlign: "center",
    padding: "8px 12px",
    pointerEvents: disabled ? "none" : "auto",
    opacity: disabled ? 0.5 : 1,
    backgroundColor,
    color,
    ...rest,

    "&:hover": {
      opacity: 0.7,
    },
  })
);

export const Table = styled.table(() => ({
  width: "100%",
  borderSpacing: 0,
  alignItems: "center",
  justifyContent: "center",
}));

export const TableHead = styled.thead(() => ({
  backgroundColor: "rgba(0,0,0,0.2)",
}));

export const TableHeadRow = styled.tr(() => ({
  textAlign: "left",
  fontSize: 14,
}));

export const TableHeadCell = styled.th(({ width }) => ({
  padding: "4px 8px",
  width,
}));

export const TableRow = styled.tr(() => ({
  fontSize: 13,
  textAlign: "left",
}));

export const TableCell = styled.td(({ backgroundColor, color }) => ({
  borderBottom: "1px solid rgba(0,0,0,0.4)",
  padding: "4px 8px",
  backgroundColor,
  color,
}));

const SEARCH_INPUT_WIDTH = 250;
const ICON_SIZE = 20;

export const SearchInput = styled.input(() => ({
  flex: 1,
  width: SEARCH_INPUT_WIDTH,
  fontSize: 14,
  borderRadius: 4,
  padding: "4px 8px",
  border: "1px solid rgba(0,0,0,0.1)",

  "&:focus": {
    outline: "none",
    border: "1px solid rgba(0,0,0,0.3)",
  },
}));

export const SearchIcon = styled.img.attrs(() => ({
  src: searchIcon,
}))(() => ({
  width: ICON_SIZE,
  height: ICON_SIZE,
}));

export const SectionTitle = styled.div(() => ({
  fontSize: 14,
  fontWeight: "bold",
}));

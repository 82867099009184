import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_USERS_BY_USER_TYPE_AS_ADMIN } from "../../../../lib/apollo/gql/userGqls";
import { LIMIT, USER_TYPES_PARAMS } from "../../../../utilities/constants";

function useUserList(selectedUserType) {
  const [offset, setOffset] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, fetchMore } = useQuery(
    GET_USERS_BY_USER_TYPE_AS_ADMIN,
    {
      variables: {
        type: USER_TYPES_PARAMS[selectedUserType],
        offset: offset,
        limit: LIMIT,
      },
      onCompleted: ({ getUsersByUserTypeAsAdmin }) => {
        const { pageInfo } = getUsersByUserTypeAsAdmin;

        const { totalPages } = pageInfo;

        setTotalPageCount(totalPages);
        setOffset(pageInfo.offset);
      },
    }
  );

  /**
   *  When page changed from pagination
   *  Fetch data for the offset changed
   */
  async function onPageChange({ selected }) {
    const newOffset = selected;
    if (!fetchingMore) {
      setFetchingMore(true);

      await fetchMore({
        variables: {
          type: USER_TYPES_PARAMS[selectedUserType],
          offset: newOffset,
          limit: LIMIT,
        },
      });

      setOffset(newOffset);
      setFetchingMore(false);
    }
  }

  return {
    models: {
      loading: loading || fetchingMore,
      data: data?.getUsersByUserTypeAsAdmin?.edges,
      offset,
      totalPageCount,
    },
    operations: {
      onPageChange,
    },
  };
}

export default useUserList;

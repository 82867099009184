import { useMutation } from "@apollo/client";
import { TOGGLE_PAYMENT_AS_ADMIN } from "../../../lib/apollo/gql/paymentGqls";

function usePaymentListTable() {
  const [togglePaymentAsAdmin] = useMutation(TOGGLE_PAYMENT_AS_ADMIN);

  function onPaidChange(e, paymentId) {
    const { checked } = e.target;

    togglePaymentAsAdmin({
      variables: {
        paymentId,
        paid: checked,
      },
    });
  }

  return {
    operations: {
      onPaidChange,
    },
  };
}

export default usePaymentListTable;

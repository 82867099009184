import React from "react";
import { CONTENT_TYPES } from "../../../../utilities/constants";
import {
  Flex,
  SearchIcon,
  SearchInput,
  TextButton,
} from "../../../common/styles/commonStyles";

function ContentListNavBar({
  selectedContentType,
  searchQuery,
  onContentTypeClick,
  onSelectContent,
  onSearchChange,
  onSearchKeyDown,
  onSearch,
}) {
  return (
    <Flex
      padding={12}
      justifyContent="space-between"
      width="100%"
      backgroundColor="rgba(0,0,0,0.1)"
    >
      <Flex gap={4}>
        {Object.keys(CONTENT_TYPES).map((type) => {
          const active = selectedContentType === CONTENT_TYPES[type];
          return (
            <TextButton
              key={type}
              fontSize={12}
              backgroundColor={active ? "black" : "white"}
              color={active ? "white" : "black"}
              onClick={() => onContentTypeClick(CONTENT_TYPES[type])}
            >
              {CONTENT_TYPES[type]}
            </TextButton>
          );
        })}
        <TextButton
          marginLeft={24}
          fontSize={12}
          backgroundColor="blue"
          onClick={() => onSelectContent("new")}
        >
          새 컨텐츠 등록
        </TextButton>
      </Flex>
      <Flex alignItems="center" gap={2}>
        <SearchInput
          placeholder="제목, 하일라이트, 컨텐츠 바디"
          value={searchQuery}
          onChange={onSearchChange}
          onKeyDown={onSearchKeyDown}
        />
        <TextButton
          backgroundColor="white"
          width={24}
          height={24}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={onSearch}
        >
          <SearchIcon />
        </TextButton>
      </Flex>
    </Flex>
  );
}

export default ContentListNavBar;

import React from "react";
import useUserList from "./UserList.hook";
import UserTable from "./UserTable";

function UserList({ selectedUserType, onUserSelect }) {
  const {
    models: { data, loading, offset, totalPageCount },
    operations: { onPageChange },
  } = useUserList(selectedUserType);

  return (
    <UserTable
      data={data}
      loading={loading}
      offset={offset}
      totalPageCount={totalPageCount}
      onPageChange={onPageChange}
      onUserSelect={onUserSelect}
    />
  );
}

export default UserList;

import styled from "styled-components";

export const LabeledInputWrapper = styled.div(
  ({ width = "100%", marginBottom }) => ({
    width,
    marginBottom,
    textAlign: "left",
  })
);

export const LabeledInputLabel = styled.div(() => ({
  fontSize: 12,
  marginBottom: 8,
  fontWeight: "bold",
}));

export const LabeledInputInput = styled.input(() => ({
  width: "100%",
  fontSize: 14,
  borderRadius: 4,
  padding: "8px 12px",
  border: "1px solid rgba(0,0,0,0.1)",

  "&:focus": {
    outline: "none",
    border: "1px solid rgba(0,0,0,0.3)",
  },
}));

export const LabeledInputError = styled.div(() => ({
  color: "red",
  marginTop: 8,
  fontSize: 10,
}));

import ArtHistoryListContainer from "../../modules/artHistory/artHistoryList/ArtHistoryListContainer";
import ArtsListContainer from "../../modules/arts/artsList/ArtsListContainer";
import BannerContainer from "../../modules/banner/BannerContainer";
import ContentListContainer from "../../modules/content/contentList/ContentListContainer";
import ManageCouponContainer from "../../modules/coupon/ManageCouponContainer";
import ExhibitionListContainer from "../../modules/exhibition/exhibitionList/ExhibitionListContainer";
import FAQContainer from "../../modules/faq/FAQContainer";
import MainContainer from "../../modules/main/MainContainer";
import AdminPhraseContainer from "../../modules/manage/adminPhrase/AdminPhraseContainer";
import FooterInformationContainer from "../../modules/manage/footerInformation/FooterInformationContainer";
import MarketingListContainer from "../../modules/marketing/marketingList/MarketingListContainer";
import NoticeContainer from "../../modules/notice/NoticeContainer";
import OrderListContainer from "../../modules/order/orderList/OrderListContainer";
import PaymentContainer from "../../modules/payment/PaymentContainer";
import PlaylistsContainer from "../../modules/playlist/playlists/PlaylistsContainer";
import PolicyContainer from "../../modules/policy/PolicyContainer";
import QuestionContainer from "../../modules/question/QuestionContainer";
import ReportContainer from "../../modules/report/report/ReportContainer";
import SaleListContainer from "../../modules/sale/saleList/SaleListContainer";
import SignInContainer from "../../modules/signIn/SignInContainer";
import SubscriptionListContainer from "../../modules/subscription/subscriptionList/SubscriptionListContainer";
import SubscriptionPlanContainer from "../../modules/subscriptionPlan/SubscriptionPlanContainer";
import UserListContainer from "../../modules/user/userList/UserListContainer";
import WeeklyListContainer from "../../modules/weekly/weeklyList/WeeklyListContainer";

const ROUTE_NAMES = {
  SIGN_IN: {
    path: "/signIn",
    element: <SignInContainer />,
  },
  MAIN: {
    path: "/",
    element: <MainContainer />,
  },
  USER: {
    title: "유저목록",
    path: "/user",
    element: <UserListContainer />,
  },
  ARTS: {
    title: "작품목록",
    path: "/arts",
    element: <ArtsListContainer />,
  },
  SALE: {
    title: "판매작품관리",
    path: "/sale",
    element: <SaleListContainer />,
  },
  CONTENTS: {
    title: "컨텐츠",
    path: "/contents",
    element: <ContentListContainer />,
  },
  ART_HISTORY: {
    title: "미술사",
    path: "/artHistories",
    element: <ArtHistoryListContainer />,
  },
  WEEKLY: {
    title: "위클리",
    path: "/weeklies",
    element: <WeeklyListContainer />,
  },

  EXHIBITION: {
    title: "전시",
    path: "/exhibitions",
    element: <ExhibitionListContainer />,
  },
  PLAYLIST: {
    title: "플레이리스트",
    path: "/playlists",
    element: <PlaylistsContainer />,
  },
  ADMIN_PHRASE: {
    title: "에디토리얼 문구",
    path: "/admin_phrase",
    element: <AdminPhraseContainer />,
  },
  FOOTER_INFORMATION: {
    title: "풋터 정보",
    path: "/footer_information",
    element: <FooterInformationContainer />,
  },
  MARKETING: {
    title: "마케팅",
    path: "/marketing",
    element: <MarketingListContainer />,
  },
  NOTICE: {
    title: "팝업",
    path: "/notice",
    element: <NoticeContainer />,
  },
  BANNER: {
    title: "배너",
    path: "/banner",
    element: <BannerContainer />,
  },
  SUBSCRIPTION: {
    title: "구독",
    path: "/subscription",
    element: <SubscriptionListContainer />,
  },
  SUBSCRIPTION_PLAN: {
    title: "구독 플랜",
    path: "/subscription_plan",
    element: <SubscriptionPlanContainer />,
  },
  PROMOTION_CODE: {
    title: "프로모션코드",
    path: "/promotion_code",
    element: <ManageCouponContainer />,
  },
  ORDER: {
    title: "주문/배송",
    path: "/order",
    element: <OrderListContainer />,
  },
  PAYMENT: {
    title: "판매정산",
    path: "/payment",
    element: <PaymentContainer />,
  },
  REPORT: {
    title: "신고",
    path: "/report",
    element: <ReportContainer />,
  },
  QUESTION: {
    title: "문의사항",
    path: "/question",
    element: <QuestionContainer />,
  },
  POLICY: {
    title: "약관/방침",
    path: "/policy",
    element: <PolicyContainer />,
  },
  FAQ: {
    title: "FAQ",
    path: "/faq",
    element: <FAQContainer />,
  },
  NO_ENTRY: {
    path: "*",
    element: <div>NOT FOUND</div>,
  },
};

export default ROUTE_NAMES;

import React from "react";
import { Flex, SectionTitle } from "../../common/styles/commonStyles";

function MainStatCard({ title, value }) {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      border="1px solid black"
      padding={12}
      flexBasis="32%"
      borderRadius={4}
    >
      <SectionTitle>{title}</SectionTitle>
      <div>{value}</div>
    </Flex>
  );
}

export default MainStatCard;

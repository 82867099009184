import React from "react";

import ReactModal from "react-modal";
import { ModalCloseButton, ModalCloseIcon, ModalTitle } from "./Modal.styles";

/**
 * Modal component using react-modal library
 * check the props here
 * http://reactcommunity.org/react-modal/
 */
function Modal({ children, isOpen, onRequestClose, title, ...rest }) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          top: "2.5%",
          left: "2.5%",
          right: "2.5%",
          bottom: "2.5%",
        },
      }}
      {...rest}
    >
      <ModalCloseButton onClick={onRequestClose}>
        <ModalCloseIcon />
      </ModalCloseButton>
      {!!title && <ModalTitle>{title}</ModalTitle>}
      {children}
    </ReactModal>
  );
}

export default Modal;

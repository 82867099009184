import { InMemoryCache } from "@apollo/client";
import customOffsetLimitPagination from "./customOffsetLimitPagination";
import customRelayStylePagination from "./customRelayPagination";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getUsersByUserTypeAsAdmin: customOffsetLimitPagination(["type"]),
        searchUser: customOffsetLimitPagination(["query", "type"]),
        getArts: customOffsetLimitPagination(),
        searchArts: customOffsetLimitPagination(["query"]),
        getContentsByType: customOffsetLimitPagination(["type"]),
        searchContents: customOffsetLimitPagination(["query", "type"]),
        getArtHistories: customOffsetLimitPagination(),
        searchArtHistories: customOffsetLimitPagination(["query"]),
        getWeeklies: customOffsetLimitPagination(["activeOnly"]),
        searchWeelies: customOffsetLimitPagination(["query", "activeOnly"]),
        getMarketings: customOffsetLimitPagination(),
        getSubscriptionsAsAdmin: customOffsetLimitPagination(["status"]),
        getAllOrdersAsAdmin: customOffsetLimitPagination(["activeOnly"]),
        getPaymentsAsAdmin: customOffsetLimitPagination([
          "from",
          "to",
          "unpaidOnly",
        ]),
        getQuestionsAsAdmin: customOffsetLimitPagination(),
        getReportsAsAdmin: customOffsetLimitPagination(),
        getSaleItems: customOffsetLimitPagination(["saleType", "onSaleOnly"]),
        getBannersAsAdmin: customOffsetLimitPagination(),

        // cursor based
        getPlaylists: customRelayStylePagination(["activeOnly"]),
      },
    },
  },
});

export default cache;

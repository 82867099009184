import React from "react";
import { SUBSCRIPTION_STATUS } from "../../../../utilities/constants";
import { Flex, TextButton } from "../../../common/styles/commonStyles";

function SubscriptionListNavBar({
  selectedSubscriptionStatus,
  onSubscriptionStatusSelect,
}) {
  return (
    <Flex
      padding={12}
      justifyContent="space-between"
      width="100%"
      backgroundColor="rgba(0,0,0,0.1)"
    >
      <Flex gap={4}>
        <TextButton
          fontSize={12}
          backgroundColor={!selectedSubscriptionStatus ? "black" : "white"}
          color={!selectedSubscriptionStatus ? "white" : "black"}
          onClick={() => onSubscriptionStatusSelect(null)}
        >
          ALL
        </TextButton>
        {Object.keys(SUBSCRIPTION_STATUS).map((status) => {
          const active =
            selectedSubscriptionStatus === SUBSCRIPTION_STATUS[status];
          return (
            <TextButton
              key={status}
              fontSize={12}
              backgroundColor={active ? "black" : "white"}
              color={active ? "white" : "black"}
              onClick={() => onSubscriptionStatusSelect(status)}
            >
              {SUBSCRIPTION_STATUS[status]}
            </TextButton>
          );
        })}
      </Flex>
    </Flex>
  );
}

export default SubscriptionListNavBar;

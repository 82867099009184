import { setContext } from "@apollo/client/link/context";
import { getFirebaseIdToken } from "../../firebase";

const authLink = setContext(async (_, { headers }) => {
  try {
    const token = await getFirebaseIdToken();
    // console.log(token);
    return { headers: { ...headers, authorization: token } };
  } catch (err) {
    console.error(err);
  }
});

export default authLink;

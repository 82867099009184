import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

/**
 * web firebaseAuth를 위한 코드입니다.
 * 1. firebase app을 initialize하고, firebase 함수들을 정의합니다.
 */
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export async function firebaseSignUp(email, password) {
  return await createUserWithEmailAndPassword(auth, email, password);
}

export async function firebaseSignIn(email, password) {
  await signInWithEmailAndPassword(auth, email, password);
}

export async function firebaseSignOut() {
  await signOut(auth);
}

export async function getFirebaseIdToken() {
  return await auth.currentUser?.getIdToken();
}

import { useState } from "react";

function useBannerContainer() {
  const [selectedBanner, setSelectedBanner] = useState(null);

  function onBannerSelect(banner) {
    setSelectedBanner(banner);
  }

  return {
    models: {
      selectedBanner,
    },
    operations: {
      onBannerSelect,
    },
  };
}

export default useBannerContainer;

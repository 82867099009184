import React from "react";
import HTMLEditor from "../../common/components/HTMLEditor";
import LabeledInput from "../../common/components/LabeledInput";
import Loading from "../../common/components/Loading";
import Modal from "../../common/components/Modal";
import Thumbnail from "../../common/components/Thumbnail";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../common/styles/commonStyles";
import useUpdateMarketingContainer, {
  UPDATE_MARKETING_ACTION_TYPES,
} from "./UpdateMarketingContainer.hook";

function UpdateMarketingContainer({
  isOpen,
  selectedMarketingId,
  onRequestClose,
}) {
  const isNew = selectedMarketingId === "new";

  const {
    refs: { primaryMediaRef },
    models: { state, updateLoading, marketingLoading },
    operations: {
      onMediaChange,
      onMediaEditClick,
      onMediaChangeCancelClick,
      onInputChange,
      onBodyChange,
      isSubmitDisabled,
      onSubmit,
    },
  } = useUpdateMarketingContainer(selectedMarketingId, onRequestClose);

  const {
    id,
    title,
    category,
    body,
    highlight,
    primaryMedia,
    primaryMediaState,
  } = state;

  return (
    <Modal
      isOpen={isOpen}
      title={isNew ? "마케팅 등록" : "마케팅 수정"}
      onRequestClose={onRequestClose}
    >
      {marketingLoading && <Loading />}
      {!marketingLoading && (
        <>
          {!isNew && (
            <Flex>
              <LabeledInput label="ID" value={selectedMarketingId} disabled />
            </Flex>
          )}

          <LabeledInput
            inputRef={primaryMediaRef}
            label="대표 이미지"
            type="file"
            accept="image/*"
            style={{
              visibility:
                primaryMedia || primaryMediaState ? "hidden" : "visible",
            }}
            onChange={onMediaChange}
          />
          {primaryMedia && !primaryMediaState && (
            <Flex alignItems="center" justifyContent="flex-start" gap={4}>
              <Thumbnail media={primaryMedia}>
                <TextButton onClick={onMediaEditClick}>수정</TextButton>
              </Thumbnail>
            </Flex>
          )}
          {primaryMediaState && (
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              gap={4}
              marginBottom={12}
            >
              <Thumbnail
                media={{ uri: URL.createObjectURL(primaryMediaState) }}
              >
                <Flex gap={4}>
                  <TextButton onClick={onMediaEditClick}>파일변경</TextButton>
                  {primaryMedia && (
                    <TextButton
                      backgroundColor="red"
                      onClick={onMediaChangeCancelClick}
                    >
                      수정 취소
                    </TextButton>
                  )}
                </Flex>
              </Thumbnail>
            </Flex>
          )}

          <LabeledInput
            label="마케팅 카테고리"
            placeholder="마케팅 카테고리"
            value={category}
            onChange={(e) =>
              onInputChange(e, UPDATE_MARKETING_ACTION_TYPES.HANDLE_CATEGORY)
            }
          />

          <LabeledInput
            label="마케팅 제목"
            placeholder="마케팅 제목"
            value={title}
            onChange={(e) =>
              onInputChange(e, UPDATE_MARKETING_ACTION_TYPES.HANDLE_TITLE)
            }
          />

          <LabeledInput
            label="하일라이트"
            placeholder="하일라이트"
            value={highlight}
            onChange={(e) =>
              onInputChange(e, UPDATE_MARKETING_ACTION_TYPES.HANDLE_HIGHLIGHT)
            }
          />

          <Flex width="100%" flexDirection="column" alignItems="flex-start">
            <SectionTitle>컨텐츠 본문</SectionTitle>
            <HTMLEditor value={body} onChange={onBodyChange} />
          </Flex>

          <Flex marginTop={80}>
            <TextButton
              width={300}
              onClick={async () => await onSubmit()}
              disabled={isSubmitDisabled()}
            >
              {updateLoading ? "등록중" : "등록하기"}
            </TextButton>
          </Flex>
        </>
      )}
    </Modal>
  );
}

export default UpdateMarketingContainer;

import React from "react";
import { Flex } from "../../common/styles/commonStyles";
import UpdateMarketingContainer from "../updateMarketing/UpdateMarketingContainer";
import MarketingListNavBar from "./components/MarketingListNavBar";
import MarketingListTable from "./components/MarketingListTable";
import useMarketingListContainer from "./MarketingListContainer.hook";

function MarketingListContainer() {
  const { models, operations } = useMarketingListContainer();

  const { selectedMarketingId } = models;

  const { onMarketingSelect } = operations;
  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
    >
      <MarketingListNavBar onMarketingSelect={onMarketingSelect} />

      <MarketingListTable onMarketingSelect={onMarketingSelect} />
      {!!selectedMarketingId && (
        <UpdateMarketingContainer
          isOpen={!!selectedMarketingId}
          selectedMarketingId={selectedMarketingId}
          onRequestClose={() => onMarketingSelect(null)}
        />
      )}
    </Flex>
  );
}

export default MarketingListContainer;

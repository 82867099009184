import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  GET_SALE_BY_ID_AS_ADMIN,
  UPDATE_SALE_AS_ADMIN,
} from "../../../lib/apollo/gql/saleGqls";
import { GET_USER_PROFILE } from "../../../lib/apollo/gql/userGqls";
import {
  SALE_STATUS,
  SELLER_DELIVERY_OPTIONS,
} from "../../../utilities/constants";

function useUpdateSaleModal(selectedSaleId, onRequestClose) {
  const client = useApolloClient();

  const [state, setState] = useState({
    buyer: null,
    buyerState: { value: "", label: "" },
    price: 0,
    note: "",
    hasWarranty: true,
    status: SALE_STATUS.NOT_FOR_SALE,
    deliveryOption: SELLER_DELIVERY_OPTIONS.DELIVERABLE,
    saleMedia: [],
    saleMediaState: null,
    accountName: "",
    bankName: "",
    accountNumber: "",
  });

  const { data, loading, error } = useQuery(GET_SALE_BY_ID_AS_ADMIN, {
    fetchPolicy: "network-only",
    variables: {
      saleId: selectedSaleId,
    },
    onCompleted: ({ getSaleByIdAsAdmin }) => {
      const {
        buyer,
        price,
        note,
        hasWarranty,
        status,
        deliveryOption,
        bankAccount,
        saleMedia,
      } = getSaleByIdAsAdmin;

      const { accountName, bankName, accountNumber } = bankAccount;

      setState((prev) => ({
        ...prev,
        buyer,
        buyerState: { value: buyer?.id || "", label: buyer?.name || "" },
        price: price || 0,
        note: note || "",
        hasWarranty,
        status,
        deliveryOption,
        bankName: bankName || "",
        accountName: accountName || "",
        accountNumber: accountNumber || "",
        saleMedia,
      }));
    },
  });

  const [updateSaleAsAdmin] = useMutation(UPDATE_SALE_AS_ADMIN, {
    onCompleted: () => {
      onRequestClose();
    },
  });

  function onTextChange(e, key) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onWarrantyChange(e) {
    const { checked } = e.target;

    setState((prev) => ({
      ...prev,
      hasWarranty: checked,
    }));
  }

  function onStatusChange(status) {
    setState((prev) => ({
      ...prev,
      status,
    }));
  }

  async function onBuyerChange(value) {
    const {
      data: { getUserProfile },
    } = await client.query({
      query: GET_USER_PROFILE,
      variables: {
        id: value?.value,
      },
    });

    setState((prev) => ({
      ...prev,
      buyerState: value,
      buyer: getUserProfile,
      status: SALE_STATUS.SOLDOUT,
    }));
  }

  function onDeliveryOptionChange(option) {
    setState((prev) => ({
      ...prev,
      deliveryOption: option,
    }));
  }

  function onSubmit() {
    let confirm = state.status === SALE_STATUS.SOLDOUT ? false : true;

    if (state.status === SALE_STATUS.SOLDOUT) {
      confirm = window.confirm(
        "정말로 판매 작품 수정을 하시겠습니까? 구매자가 지정된 경우 다시는 되돌릴 수 없습니다. 구매자를 정확히 확인하셨습니까?"
      );
    }

    if (confirm) {
      updateSaleAsAdmin({
        variables: {
          saleInput: {
            id: data?.getSaleByIdAsAdmin?.id,
            artId: data?.getSaleByIdAsAdmin?.art?.id,
            buyerId: state?.buyer?.id || null,
            sellerId: data?.getSaleByIdAsAdmin?.seller?.id,
            price: Number(state?.price),
            note: state?.note,
            deliveryOption: state.deliveryOption,
            hasWarranty: state.hasWarranty,
            status: state.status,
            bankAccountInput: {
              id: data?.getSaleByIdAsAdmin?.bankAccount?.id,
              accountName: state?.accountName,
              bankName: state?.bankName,
              accountNumber: state?.accountNumber,
            },
          },
        },
      });
    }
  }

  return {
    models: {
      loading,
      data: data?.getSaleByIdAsAdmin,
      state,
    },
    operations: {
      onTextChange,
      onBuyerChange,
      onStatusChange,
      onDeliveryOptionChange,
      onWarrantyChange,
      onSubmit,
    },
  };
}

export default useUpdateSaleModal;

import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useReducer } from "react";
import {
  GET_CONTENT,
  GET_CONTENTS_BY_TYPE,
  UPDATE_CONTENT_AS_ADMIN,
} from "../../../lib/apollo/gql/contentGqls";

export const UPDATE_CONTENT_MODAL_ACTION_TYPES = {
  HANDLE_CONTENT_TYPE: "HANDLE_CONTENT_TYPE",

  HANDLE_WRITER: "HANDLE_WRITER",
  HANDLE_ARTIST: "HANDLE_ARTIST",
  HANDLE_ART: "HANDLE_ART",

  HANDLE_TITLE: "HANDLE_TITLE",
  HANDLE_HIGHLIGHT: "HANDLE_HIGHLIGHT",
  HANDLE_BODY: "HANDLE_BODY",

  SET_ALL: "SET_ALL",
};

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_CONTENT_MODAL_ACTION_TYPES.HANDLE_CONTENT_TYPE: {
      return {
        ...state,
        contentType: action.payload,
        artist: action.payload !== "INTERVIEW" ? null : state.artist,
      };
    }

    case UPDATE_CONTENT_MODAL_ACTION_TYPES.HANDLE_WRITER: {
      return {
        ...state,
        writer: action.payload,
      };
    }

    case UPDATE_CONTENT_MODAL_ACTION_TYPES.HANDLE_ARTIST: {
      return {
        ...state,
        artist: action.payload,
      };
    }

    case UPDATE_CONTENT_MODAL_ACTION_TYPES.HANDLE_ART: {
      return {
        ...state,
        art: action.payload,
      };
    }

    case UPDATE_CONTENT_MODAL_ACTION_TYPES.HANDLE_TITLE: {
      return {
        ...state,
        title: action.payload,
      };
    }

    case UPDATE_CONTENT_MODAL_ACTION_TYPES.HANDLE_HIGHLIGHT: {
      return {
        ...state,
        highlight: action.payload,
      };
    }

    case UPDATE_CONTENT_MODAL_ACTION_TYPES.HANDLE_BODY: {
      return {
        ...state,
        body: action.payload,
      };
    }

    case UPDATE_CONTENT_MODAL_ACTION_TYPES.SET_ALL: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

const initialState = {
  id: "",
  contentType: null,
  writer: null,
  artist: null,
  art: null,
  title: "",
  highlight: "",
  body: "",
};

function useUpdateContentModal(selectedContentId, onRequestClose) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [updateContentAsAdmin, { loading: updateLoading }] = useMutation(
    UPDATE_CONTENT_AS_ADMIN,
    {
      onCompleted: () => {
        onRequestClose();
      },
      refetchQueries: () => [
        {
          query: GET_CONTENTS_BY_TYPE,
          variables: {
            type: null,
            activeOnly: false,
            sortOption: "CREATED",
            offset: 0,
            limit: 10,
          },
        },
        {
          query: GET_CONTENTS_BY_TYPE,
          variables: {
            type: "DOCENT",
            activeOnly: false,
            sortOption: "CREATED",
            offset: 0,
            limit: 10,
          },
        },
        {
          query: GET_CONTENTS_BY_TYPE,
          variables: {
            type: "INTERVIEW",
            activeOnly: false,
            sortOption: "CREATED",
            offset: 0,
            limit: 10,
          },
        },
        {
          query: GET_CONTENTS_BY_TYPE,
          variables: {
            type: "ESSAY",
            activeOnly: false,
            sortOption: "CREATED",
            offset: 0,
            limit: 10,
          },
        },
      ],
    }
  );

  const [getContent, { loading: contentLoading }] = useLazyQuery(GET_CONTENT, {
    fetchPolicy: "network-only",
    onCompleted: ({ getContent }) => {
      const { id, type, writer, artist, art, title, body, highlight } =
        getContent;
      const payload = {
        id,
        contentType: type,
        writer: {
          value: writer.id,
          label: `${writer?.user?.name}(${writer?.user?.email || ""})`,
        },
        artist: artist
          ? {
              value: artist.id,
              label: `${artist?.user?.name}(${artist?.user?.email || ""})`,
            }
          : null,
        art: { value: art.id, label: `${art?.titleKor}(${art?.titleEng})` },
        title,
        body,
        highlight,
      };

      dispatch({
        type: UPDATE_CONTENT_MODAL_ACTION_TYPES.SET_ALL,
        payload,
      });
    },
  });

  useEffect(() => {
    if (selectedContentId && selectedContentId !== "new") {
      getContent({
        variables: {
          contentId: selectedContentId,
        },
      });
    }
  }, [selectedContentId]);

  /**
   * Handle input values like title and highlight
   * @param {object} e
   * @param {string} type
   */
  function onInputChange(e, type) {
    const { value } = e.target;

    dispatch({
      type,
      payload: value,
    });
  }

  /**
   * Handle content type value
   * @param {string} contentType
   */
  function onContentTypeChange(contentType) {
    dispatch({
      type: UPDATE_CONTENT_MODAL_ACTION_TYPES.HANDLE_CONTENT_TYPE,
      payload: contentType,
    });
  }

  /**
   * Handle writer
   * @param {object} value
   */
  function onWriterSelect(value) {
    dispatch({
      type: UPDATE_CONTENT_MODAL_ACTION_TYPES.HANDLE_WRITER,
      payload: value,
    });
  }

  /**
   * Handle artist
   * @param {object} value
   */
  function onArtistSelect(value) {
    dispatch({
      type: UPDATE_CONTENT_MODAL_ACTION_TYPES.HANDLE_ARTIST,
      payload: value,
    });
  }

  /**
   * Handle art
   * @param {object} value
   */
  function onArtSelect(value) {
    dispatch({
      type: UPDATE_CONTENT_MODAL_ACTION_TYPES.HANDLE_ART,
      payload: value,
    });
  }

  /**
   * Handle content body change
   * @param {string} value
   */
  function onBodyChange(value) {
    dispatch({
      type: UPDATE_CONTENT_MODAL_ACTION_TYPES.HANDLE_BODY,
      payload: value,
    });
  }

  function isSubmitDisabled() {
    const { id, contentType, writer, artist, art, title, highlight, body } =
      state;
    const isTypeDisabled = !contentType;
    const isWriterDisabled = !writer;
    const isArtistDisabled = contentType === "INTERVIEW" && !artist;
    const isArtDisabled = !art;
    const isTitleDisabled = !title;
    const isHighlightDisabled = !highlight;
    const isBodyDisabled = !body;

    return (
      isTypeDisabled ||
      isWriterDisabled ||
      isArtDisabled ||
      isArtistDisabled ||
      isTitleDisabled ||
      isHighlightDisabled ||
      isBodyDisabled ||
      updateLoading
    );
  }

  async function onSubmit() {
    const { id, contentType, writer, artist, art, title, highlight, body } =
      state;

    const contentInput = {
      id: selectedContentId !== "new" ? selectedContentId : null,
      type: contentType,
      writerId: writer?.value,
      artistId: artist ? artist?.value : null,
      artId: art?.value,
      title,
      body,
      highlight,
    };

    await updateContentAsAdmin({
      variables: {
        contentInput,
      },
    });
  }

  return {
    models: {
      state,
      updateLoading,
      contentLoading,
    },
    operations: {
      onInputChange,
      onContentTypeChange,
      onWriterSelect,
      onArtistSelect,
      onArtSelect,
      onBodyChange,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useUpdateContentModal;

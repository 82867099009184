import moment from "moment";
import React from "react";
import Loading from "../../../common/components/Loading";
import Pagination from "../../../common/components/Pagination";
import Thumbnail from "../../../common/components/Thumbnail";
import {
  Flex,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TextButton,
} from "../../../common/styles/commonStyles";
import useWeeklyListTable from "./WeeklyListTable.hook";

function WeeklyListTable({ searchedQuery, onSelectWeekly }) {
  const { models, operations } = useWeeklyListTable(searchedQuery);

  const { data, loading, offset, totalPageCount } = models;

  const { onPageChange, onDeleteClick } = operations;

  return (
    <Flex
      flex={9}
      overflowY="auto"
      flexDirection="column"
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      padding={12}
    >
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell width="8%">ID</TableHeadCell>
            <TableHeadCell width="8%">작가</TableHeadCell>
            <TableHeadCell width="10%">대표 이미지</TableHeadCell>
            <TableHeadCell width="10%">타이틀</TableHeadCell>
            <TableHeadCell width="15%">하일라이트</TableHeadCell>
            <TableHeadCell width="10%">컨텐츠</TableHeadCell>
            <TableHeadCell width="5%">Vol.</TableHeadCell>
            <TableHeadCell width="5%">박수</TableHeadCell>
            <TableHeadCell width="5%">조회수</TableHeadCell>
            <TableHeadCell width="8%">게시일</TableHeadCell>
            <TableHeadCell width="8%">등록일</TableHeadCell>
            <TableHeadCell width="5%">수정</TableHeadCell>
            <TableHeadCell width="5%">삭제</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan="100%">
                <Loading />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data?.map((weekly) => {
              const {
                id,
                writer,
                primaryMedia,
                title,
                highlight,
                contents,
                claps,
                viewCount,
                fontColor,
                volume,
                backgroundColor,
                createdAt,
                releaseAt,
              } = weekly;
              return (
                <TableRow key={id}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{writer?.user?.name}</TableCell>
                  <TableCell>
                    <Thumbnail width={100} height={100} media={primaryMedia} />
                  </TableCell>
                  <TableCell
                    backgroundColor={backgroundColor}
                    color={fontColor}
                  >
                    {title}
                    <br />
                    font color:{fontColor} <br />
                    background:{backgroundColor}
                  </TableCell>
                  <TableCell>{highlight}</TableCell>
                  <TableCell>
                    {contents?.map((content, index) => (
                      <div key={content.id + id + index}>{content.title}</div>
                    ))}
                  </TableCell>
                  <TableCell>{volume}</TableCell>
                  <TableCell>{claps}</TableCell>
                  <TableCell>{viewCount}</TableCell>
                  <TableCell>
                    {moment(releaseAt).format("YYYY-MM-DD HH:mm A")}
                  </TableCell>
                  <TableCell>
                    {moment(createdAt).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell>
                    <TextButton padding={4} onClick={() => onSelectWeekly(id)}>
                      수정
                    </TextButton>
                  </TableCell>
                  <TableCell>
                    <TextButton
                      padding={4}
                      backgroundColor="red"
                      onClick={async () => await onDeleteClick(id)}
                    >
                      삭제
                    </TextButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </tbody>
        )}
      </Table>
      <Pagination
        forcePage={offset}
        pageCount={totalPageCount}
        pageRangeDisplayed={5}
        previousLabel="Prev"
        nextLabel="Next"
        onPageChange={onPageChange}
      />
    </Flex>
  );
}

export default WeeklyListTable;

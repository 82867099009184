import React from "react";
import Loading from "../../../common/components/Loading";
import { Flex, TextButton } from "../../../common/styles/commonStyles";
import useArtColorSelector from "./ArtColorSelector.hook";

function ArtColorSelector({ searchColorIds, onSearchColorChange }) {
  const { models } = useArtColorSelector();

  const { data, loading } = models;

  if (loading) return <Loading />;

  return (
    <Flex
      flexWrap="wrap"
      gap={8}
      justifyContent="flex-start"
      alignItems="center"
      marginBottom={12}
    >
      {data?.map((color) => {
        const isSelected = searchColorIds.includes(color.id);
        return (
          <TextButton
            key={color.id}
            color="black"
            backgroundColor="rgba(0,0,0,0.05)"
            padding="0"
            onClick={() => onSearchColorChange(color.id)}
          >
            <Flex
              flexBasis={100}
              padding="8px 12px"
              alignItems="center"
              justifyContent="center"
            >
              <input
                type="checkbox"
                checked={isSelected}
                onChange={() => onSearchColorChange(color.id)}
              />
              <Flex
                width={20}
                height={20}
                marginRight={4}
                borderRadius={10}
                backgroundColor={color.id}
              />
              {color.id}
            </Flex>
          </TextButton>
        );
      })}
    </Flex>
  );
}

export default ArtColorSelector;

import React from "react";
import Modal from "../../common/components/Modal";
import useUpdatePlaylistContainer from "./UpdatePlaylistContainer.hook";
import Loading from "../../common/components/Loading";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../common/styles/commonStyles";
import LabeledInput from "../../common/components/LabeledInput";
import Thumbnail from "../../common/components/Thumbnail";
import LabeledTextarea from "../../common/components/LabeledTextarea";
import AsyncSelector from "react-select/async";

function UpdatePlaylistContainer({
  isOpen,
  selectedPlaylistId,
  onRequestClose,
}) {
  const {
    refs: { thumbnailInputRef, audioInputRef },
    models: {
      state,
      data,
      playlistLoading,
      updateLoading,

      playlistItem,
      playlistItemsLoading,
      contents,
      arts,
      addPlaylistItemLoading,
      deletePlaylistItemLoading,
    },
    operations: {
      onInputChange,
      onMediaChange,
      onMediaChangeCancelClick,
      isSubmitDisabled,
      onSubmit,
      loadContentOptions,
      loadArtOptions,
      onContentSelectorChange,
      onContentSortChange,
      onContentStateDelete,
      onAddContentClick,
      onArtSelectorChange,
      onArtSortChange,
      onArtStateDelete,
      onAddArtClick,
      onAddItemsSubmit,
      onDeletePlaylistItem,
    },
  } = useUpdatePlaylistContainer(selectedPlaylistId, onRequestClose);

  const {
    id,
    title,
    theme,
    backgroundColor,
    summary,
    thumbnailMediaState,
    audioState,
  } = state;

  const isNew = id === "new";

  return (
    <Modal
      isOpen={isOpen}
      title={isNew ? "플레이리스트 등록" : "플레이리스트 수정"}
      onRequestClose={onRequestClose}
    >
      {playlistLoading && <Loading />}
      {!playlistLoading && (
        <>
          {!isNew && (
            <Flex>
              <LabeledInput label="ID" value={id} disabled />
            </Flex>
          )}

          <LabeledInput
            inputRef={thumbnailInputRef}
            label="대표 이미지"
            type="file"
            accept="image/*"
            style={{
              visibility:
                data?.thumbnailMedia || thumbnailMediaState
                  ? "hidden"
                  : "visible",
            }}
            onChange={(e) => onMediaChange(e, "thumbnailMediaState")}
          />
          {data?.thumbnailMedia && !thumbnailMediaState && (
            <Flex alignItems="center" justifyContent="flex-start" gap={4}>
              <Thumbnail media={data?.thumbnailMedia}>
                <TextButton onClick={() => thumbnailInputRef?.current?.click()}>
                  수정
                </TextButton>
              </Thumbnail>
            </Flex>
          )}
          {thumbnailMediaState && (
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              gap={4}
              marginBottom={12}
            >
              <Thumbnail
                media={{ uri: URL.createObjectURL(thumbnailMediaState) }}
              >
                <Flex gap={4}>
                  <TextButton
                    onClick={() => thumbnailInputRef?.current?.click()}
                  >
                    파일변경
                  </TextButton>
                  {data?.thumbnailMedia && (
                    <TextButton
                      backgroundColor="red"
                      onClick={() =>
                        onMediaChangeCancelClick("thumbnailMediaState")
                      }
                    >
                      수정 취소
                    </TextButton>
                  )}
                </Flex>
              </Thumbnail>
            </Flex>
          )}

          <LabeledInput
            inputRef={audioInputRef}
            label="오디오"
            type="file"
            accept="audio/*"
            style={{ visibility: !isNew ? "hidden" : "visible" }}
            onChange={(e) => onMediaChange(e, "audioState")}
          />
          {!isNew && (
            <TextButton
              onClick={() => {
                audioInputRef?.current?.click();
              }}
            >
              오디오파일 변경 하기
            </TextButton>
          )}
          {data?.audio && !audioState && (
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              gap={4}
              width="100%"
              height={100}
            >
              <audio controls preload="auto" controlsList="nodownload">
                <source src={data?.audio?.uri} />
              </audio>
            </Flex>
          )}
          {audioState && (
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              gap={4}
              width="100%"
              height={100}
            >
              <audio
                key={audioState?.name}
                controls
                preload="auto"
                controlsList="nodownload"
              >
                <source src={URL.createObjectURL(audioState)} />
              </audio>
              <Flex gap={4}>
                {data?.audio && (
                  <TextButton
                    backgroundColor="red"
                    onClick={() => onMediaChangeCancelClick("audioState")}
                  >
                    수정 취소
                  </TextButton>
                )}
              </Flex>
            </Flex>
          )}

          <LabeledInput
            label="제목"
            placeholder="제목"
            value={title}
            onChange={(e) => onInputChange(e, "title")}
          />

          <LabeledInput
            label="주제"
            placeholder="ex) calm, in winter, etc.."
            value={theme}
            onChange={(e) => onInputChange(e, "theme")}
          />

          <LabeledTextarea
            label="설명"
            placeholder="설명글"
            value={summary}
            onChange={(e) => onInputChange(e, "summary")}
          />

          <Flex alignItems="center">
            <Flex width={25} height={25} backgroundColor={backgroundColor} />
            <LabeledInput
              label="배경 색상"
              placeholder="#FFFFFF or rgb(0,0,0) or rgba(0,0,0,0.5)"
              value={backgroundColor}
              onChange={(e) => onInputChange(e, "backgroundColor")}
            />
          </Flex>

          <Flex marginTop={80}>
            <TextButton
              width={300}
              onClick={async () => await onSubmit()}
              disabled={isSubmitDisabled()}
            >
              {updateLoading ? "등록중" : "등록하기"}
            </TextButton>
          </Flex>
        </>
      )}
      {!playlistItemsLoading && id !== "new" && (
        <Flex
          width="100%"
          flexDirection="column"
          gap={8}
          marginTop={20}
          alignItems="flex-start"
        >
          <Flex
            width="100%"
            flexDirection="column"
            gap={8}
            marginBottom={20}
            alignItems="flex-start"
          >
            {playlistItem?.map((item, index) => (
              <Flex key={index} gap={8}>
                <div>
                  type: <strong>{item?.content ? "컨텐츠" : "작품"}</strong>
                </div>
                <div>
                  제목:{" "}
                  <strong>
                    {item?.content
                      ? item?.content?.title
                      : item?.art?.titleKor + "/" + item?.art?.titleEng}
                  </strong>
                </div>
                <div>
                  순서: <strong>{item?.sort}</strong>
                </div>
                <TextButton
                  backgroundColor="red"
                  onClick={() => onDeletePlaylistItem(item?.id)}
                >
                  삭제
                </TextButton>
              </Flex>
            ))}
          </Flex>
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            gap={8}
            width="100%"
            marginBottom={12}
          >
            <SectionTitle>컨텐츠 추가</SectionTitle>

            {contents?.map((content, index) => (
              <Flex
                key={"content" + index}
                alignItems="center"
                gap={8}
                width="100%"
              >
                <AsyncSelector
                  placeholder="컨텐츠 제목, 하일라이트, 바디"
                  value={content?.content}
                  loadOptions={loadContentOptions}
                  onChange={(value) => onContentSelectorChange(value, index)}
                  styles={{
                    container: (style) => ({ ...style, flex: 1 }),
                  }}
                />
                <Flex flex={1} alignItems="center" gap={4}>
                  <Flex flex={1}>
                    <LabeledInput
                      type="number"
                      label="순서"
                      value={content.sort}
                      onChange={(e) => onContentSortChange(e, index)}
                    />
                  </Flex>
                </Flex>
                <TextButton
                  backgroundColor="red"
                  onClick={() => onContentStateDelete(index)}
                >
                  Delete
                </TextButton>
              </Flex>
            ))}
            <TextButton onClick={onAddContentClick}>추가</TextButton>
          </Flex>

          <Flex
            flexDirection="column"
            alignItems="flex-start"
            gap={8}
            width="100%"
            marginBottom={12}
          >
            <SectionTitle>작품 추가</SectionTitle>

            {arts?.map((art, index) => (
              <Flex
                key={"art" + index}
                alignItems="center"
                gap={8}
                width="100%"
              >
                <AsyncSelector
                  placeholder="작품명, 재료, 작가명"
                  value={art?.art}
                  loadOptions={loadArtOptions}
                  onChange={(value) => onArtSelectorChange(value, index)}
                  styles={{
                    container: (style) => ({ ...style, flex: 1 }),
                  }}
                />
                <Flex flex={1} alignItems="center" gap={4}>
                  <Flex flex={1}>
                    <LabeledInput
                      type="number"
                      label="순서"
                      value={art.sort}
                      onChange={(e) => onArtSortChange(e, index)}
                    />
                  </Flex>
                </Flex>
                <TextButton
                  backgroundColor="red"
                  onClick={() => onArtStateDelete(index)}
                >
                  Delete
                </TextButton>
              </Flex>
            ))}
            <TextButton onClick={onAddArtClick}>추가</TextButton>
          </Flex>

          <Flex marginTop={80}>
            <TextButton
              width={300}
              onClick={() => onAddItemsSubmit()}
              disabled={addPlaylistItemLoading}
            >
              {addPlaylistItemLoading ? "등록중" : "등록하기"}
            </TextButton>
          </Flex>
        </Flex>
      )}
    </Modal>
  );
}

export default UpdatePlaylistContainer;

import { gql } from "@apollo/client";
import { ADMIN_PHRASE_FIELD, FOOTER_INFORMATION_FIELD } from "./fragments";

export const UPDATE_ADMIN_PHRASE_AS_ADMIN = gql`
  ${ADMIN_PHRASE_FIELD}
  mutation UpdateAdminPhraseAsAdmin($adminPhraseInput: AdminPhraseInput!) {
    updateAdminPhraseAsAdmin(adminPhraseInput: $adminPhraseInput) {
      ...AdminPhraseField
    }
  }
`;

export const GET_ADMIN_PHRASE = gql`
  ${ADMIN_PHRASE_FIELD}
  query GetAdminPhrase {
    getAdminPhrase {
      ...AdminPhraseField
    }
  }
`;

export const UPDATE_FOOTER_INFORMATION_AS_ADMIN = gql`
  ${FOOTER_INFORMATION_FIELD}
  mutation UpdateFooterInformationAsAdmin(
    $footerInformationInput: FooterInformationInput!
  ) {
    updateFooterInformationAsAdmin(
      footerInformationInput: $footerInformationInput
    ) {
      ...FooterInformationField
    }
  }
`;

export const GET_FOOTER_INFORMATION = gql`
  ${FOOTER_INFORMATION_FIELD}
  query GetFooterInformation {
    getFooterInformation {
      ...FooterInformationField
    }
  }
`;

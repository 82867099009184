import React from "react";
import AsyncSelector from "react-select/async";

import { USER_TYPES } from "../../../../utilities/constants";
import LabeledInput from "../../../common/components/LabeledInput";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../../common/styles/commonStyles";
import useArtSearchArtist from "./ArtSearchArtist.hook";

function ArtSearchArtist({
  isNew,
  artist,
  owner,
  artistNameKor,
  artistNameEng,
  onSelectorChange,
  onNameKorChange,
  onNameEngChange,
}) {
  const { models, operations } = useArtSearchArtist(isNew, artist);

  const { isNotOurArtist } = models;

  const { toggleIsNotOurArtist, loadOptions } = operations;

  return (
    <Flex flexDirection="column" alignItems="flex-start" gap={8} width="100%">
      <SectionTitle>아티스트(콜렉션)</SectionTitle>
      <Flex width="100%" justifyContent="flex-start" gap={8}>
        <TextButton
          backgroundColor={isNotOurArtist ? "red" : "white"}
          color={isNotOurArtist ? "white" : "black"}
          border="1px solid black"
          onClick={toggleIsNotOurArtist}
        >
          외부아티스트
        </TextButton>

        <AsyncSelector
          placeholder={isNotOurArtist ? "콜렉션" : "아티스트"}
          value={isNotOurArtist ? owner : artist}
          loadOptions={loadOptions}
          onChange={(value) =>
            onSelectorChange(
              value,
              isNotOurArtist ? USER_TYPES.ALL : USER_TYPES.ARTIST
            )
          }
          styles={{
            container: (style) => ({ ...style, flex: 1 }),
          }}
        />
      </Flex>
      {isNotOurArtist && (
        <Flex width="100%" gap={4}>
          <Flex flex={1}>
            <LabeledInput
              label="작가명(한글)"
              placeholder="작가명(한글)"
              value={artistNameKor}
              onChange={onNameKorChange}
            />
          </Flex>
          <Flex flex={1}>
            <LabeledInput
              label="작가명(영문)"
              placeholder="작가명(영문)"
              value={artistNameEng}
              onChange={onNameEngChange}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default ArtSearchArtist;

import React from "react";
import { Flex } from "../../common/styles/commonStyles";
import UpdateWeeklyModal from "../updateWeekly/UpdateWeeklyModal";
import WeeklyListNavBar from "./components/WeeklyListNavBar";
import WeeklyListTable from "./components/WeeklyListTable";
import useWeeklyListContainer from "./WeeklyListContainer.hook";

function WeeklyListContainer() {
  const { models, operations } = useWeeklyListContainer();

  const { selectedWeeklyId, searchQuery, searchedQuery } = models;

  const { onSelectWeekly, onSearchChange, onSearchKeyDown, onSearch } =
    operations;

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
    >
      <WeeklyListNavBar
        searchQuery={searchQuery}
        onSearchChange={onSearchChange}
        onSearchKeyDown={onSearchKeyDown}
        onSearch={onSearch}
        onSelectWeekly={onSelectWeekly}
      />

      <WeeklyListTable
        searchedQuery={searchedQuery}
        onSelectWeekly={onSelectWeekly}
      />

      {!!selectedWeeklyId && (
        <UpdateWeeklyModal
          isOpen={!!selectedWeeklyId}
          selectedWeeklyId={selectedWeeklyId}
          onRequestClose={() => onSelectWeekly(null)}
        />
      )}
    </Flex>
  );
}

export default WeeklyListContainer;

import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  DELETE_ART_HISTORY_AS_ADMIN,
  GET_ART_HISTORIES,
  SEARCH_ART_HISTORIES,
} from "../../../../lib/apollo/gql/artHistoryGqls";

const LIMIT = 10;

function useArtHistoryTable(searchedQuery) {
  const [offset, setOffset] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);

  const [
    getArtHistories,
    {
      data: artHistoriesData,
      loading: artHistoriesLoading,
      fetchMore: artHistoriesFetchMore,
    },
  ] = useLazyQuery(GET_ART_HISTORIES, {
    onCompleted: ({ getArtHistories }) => {
      const { pageInfo } = getArtHistories;

      const { totalPages } = pageInfo;

      setTotalPageCount(totalPages);
      setOffset(pageInfo.offset);
    },
  });

  const [
    searchArtHistories,
    { data: searchData, loading: searchLoading, fetchMore: searchFetchMore },
  ] = useLazyQuery(SEARCH_ART_HISTORIES, {
    onCompleted: ({ searchArtHistories }) => {
      const { pageInfo } = searchArtHistories;

      const { totalPages } = pageInfo;

      setTotalPageCount(totalPages);
      setOffset(pageInfo.offset);
    },
  });

  const [deleteArtHistoryAsAdmin] = useMutation(DELETE_ART_HISTORY_AS_ADMIN, {
    refetchQueries: () => [
      {
        query: GET_ART_HISTORIES,
        variables: {
          offset,
          limit: LIMIT,
        },
      },
    ],
  });

  useEffect(() => {
    if (searchedQuery) {
      searchArtHistories({
        variables: {
          query: searchedQuery,

          offset: 0,
          limit: LIMIT,
        },
      });
    } else {
      getArtHistories({
        variables: {
          offset: 0,
          limit: LIMIT,
        },
      });
    }
  }, [searchedQuery]);

  /**
   *  When page changed from pagination
   *  Fetch data for the offset changed
   */
  async function onPageChange({ selected }) {
    const newOffset = selected;
    if (!fetchingMore) {
      setFetchingMore(true);
      if (searchedQuery) {
        await searchFetchMore({
          query: searchedQuery,
          offset: newOffset,
          limit: LIMIT,
        });
      } else {
        await artHistoriesFetchMore({
          variables: {
            offset: newOffset,
            limit: LIMIT,
          },
        });
      }
      setOffset(newOffset);
      setFetchingMore(false);
    }
  }

  async function onDeleteClick(id) {
    await deleteArtHistoryAsAdmin({
      variables: {
        artHistoryId: id,
      },
    });
  }

  return {
    models: {
      data: searchedQuery
        ? searchData?.searchArtHistories?.edges
        : artHistoriesData?.getArtHistories?.edges,
      loading: artHistoriesLoading || searchLoading || fetchingMore,
      offset,
      totalPageCount,
    },
    operations: {
      onPageChange,
      onDeleteClick,
    },
  };
}

export default useArtHistoryTable;

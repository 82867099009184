import { useState } from "react";

function useCouponContainer() {
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  function onCouponSelect(coupon) {
    setSelectedCoupon(coupon);
  }

  return {
    models: {
      selectedCoupon,
    },
    operations: {
      onCouponSelect,
    },
  };
}

export default useCouponContainer;

import React from "react";
import Select from "react-select";
import Loading from "../../../common/components/Loading";
import { Flex, SectionTitle } from "../../../common/styles/commonStyles";
import useArtCategorySelector from "./ArtCategorySelector.hook";

function ArtCategorySelector({ category, onSelectorChange }) {
  const { models } = useArtCategorySelector();

  const { data, loading } = models;

  if (loading) return <Loading />;

  const options = data?.map((category) => ({
    value: category,
    label: category,
  }));

  return (
    <Flex flexDirection="column" width="100%" alignItems="flex-start" gap={8}>
      <SectionTitle>카테고리</SectionTitle>
      <Select
        placeholder="작품 카테고리"
        options={options}
        value={category}
        onChange={(value) => onSelectorChange(value)}
        styles={{
          container: (style) => ({ ...style, width: "100%" }),
        }}
      />
    </Flex>
  );
}

export default ArtCategorySelector;

import { gql } from "@apollo/client";
import {
  ART_FIELD,
  PAGE_INFO_FIELD,
  SEARCH_CATEGORY_FIELD,
  SEARCH_MATERIAL_FIELD,
} from "./fragments";

export const GET_ART_TYPES = gql`
  query GetArtTypes {
    getArtTypes
  }
`;

export const GET_ART_CATEGORIES = gql`
  query GetArtCategories {
    getArtCategories
  }
`;

export const GET_COLORS = gql`
  query GetColors {
    getColors {
      id
    }
  }
`;

export const GET_SEARCH_MATERIALS = gql`
  ${SEARCH_MATERIAL_FIELD}
  query GetSearchMaterials {
    getSearchMaterials {
      ...SearchMaterialField
    }
  }
`;

export const GET_SEARCH_CATEGORIES = gql`
  ${SEARCH_CATEGORY_FIELD}
  query GetSearchCategories {
    getSearchCategories {
      ...SearchCategoryField
    }
  }
`;

export const CREATE_ART = gql`
  ${ART_FIELD}
  mutation CreateArt($artInput: ArtInput!) {
    createArt(artInput: $artInput) {
      ...ArtField
    }
  }
`;

export const GET_ARTS = gql`
  ${ART_FIELD}
  ${PAGE_INFO_FIELD}
  query GetArts($type: ArtTypes, $offset: Int, $limit: Int) {
    getArts(type: $type, offset: $offset, limit: $limit) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...ArtField
      }
    }
  }
`;

export const SEARCH_ARTS = gql`
  ${ART_FIELD}
  ${PAGE_INFO_FIELD}
  query SearchArts($query: String!, $offset: Int, $limit: Int) {
    searchArts(query: $query, offset: $offset, limit: $limit) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...ArtField
      }
    }
  }
`;

export const GET_ART = gql`
  ${ART_FIELD}
  query GetArt($id: ID!) {
    getArt(id: $id) {
      ...ArtField
    }
  }
`;

export const DELETE_ART = gql`
  mutation DeleteArt($artId: ID!) {
    deleteArt(artId: $artId)
  }
`;

export const UPDATE_ART = gql`
  ${ART_FIELD}
  mutation UpdateArt($updateArtInput: UpdateArtInput!) {
    updateArt(updateArtInput: $updateArtInput) {
      ...ArtField
    }
  }
`;

export const UPDATE_ART_PRIMARY_MEDIA = gql`
  ${ART_FIELD}
  mutation UpdateArtPrimaryMedia($artId: ID!, $primaryMediaInput: MediaInput!) {
    updateArtPrimaryMedia(
      artId: $artId
      primaryMediaInput: $primaryMediaInput
    ) {
      ...ArtField
    }
  }
`;

export const UPDATE_ART_MEDIA = gql`
  ${ART_FIELD}
  mutation UpdateArtMedia($artId: ID!, $mediaInput: [MediaInput]!) {
    updateArtMedia(artId: $artId, mediaInput: $mediaInput) {
      ...ArtField
    }
  }
`;

export const DELETE_ART_MEDIA = gql`
  ${ART_FIELD}
  mutation DeleteArtMedia($artId: ID!, $mediaId: ID!) {
    deleteArtMedia(artId: $artId, mediaId: $mediaId) {
      ...ArtField
    }
  }
`;

export const UPDATE_ART_AUDIOS = gql`
  ${ART_FIELD}
  mutation UpdateArtAudios($artId: ID!, $audiosInput: [MediaInput]!) {
    updateArtAudios(artId: $artId, audiosInput: $audiosInput) {
      ...ArtField
    }
  }
`;

export const DELETE_ART_AUDIO = gql`
  ${ART_FIELD}
  mutation DeleteArtAudio($artId: ID!, $audioId: ID!) {
    deleteArtAudio(artId: $artId, audioId: $audioId) {
      ...ArtField
    }
  }
`;

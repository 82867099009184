import { gql } from "@apollo/client";
import { PAGE_INFO_FIELD, QUESTION_FIELD } from "./fragments";

export const GET_QUESTIONS_AS_ADMIN = gql`
  ${PAGE_INFO_FIELD}
  ${QUESTION_FIELD}
  query GetQuestionsAsAdmin($offset: Int, $limit: Int) {
    getQuestionsAsAdmin(offset: $offset, limit: $limit) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...QuestionField
      }
    }
  }
`;

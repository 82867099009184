import React from "react";
import Loading from "../../../common/components/Loading";
import {
  Flex,
  SectionTitle,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TextButton,
} from "../../../common/styles/commonStyles";
import copyText from "../../../common/utils/copyText";
import useCouponTypeList from "./CouponTypeList.hook";

function CouponTypeList({ onCouponTypeSelect }) {
  const {
    models: { loading, data },
  } = useCouponTypeList();

  if (loading) return <Loading />;

  return (
    <Flex
      flex={1.5}
      borderBottom="1px solid black"
      width="100%"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      padding={12}
      overflowY="auto"
    >
      <SectionTitle>쿠폰 타입</SectionTitle>
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell width="5%">ID</TableHeadCell>
            <TableHeadCell width="10%">이름</TableHeadCell>
            <TableHeadCell width="10%">할인타입</TableHeadCell>
            <TableHeadCell width="10%">할인액</TableHeadCell>
            <TableHeadCell width="10%">최대할인액</TableHeadCell>
            <TableHeadCell width="10%">최소가격</TableHeadCell>
            <TableHeadCell width="8%">수정</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <tbody>
          {data?.map((type) => {
            const {
              id,
              name,
              discountType,
              discount,
              maximumDiscountAmount,
              minimumPrice,
              createdAt,
            } = type;
            return (
              <TableRow key={id}>
                <TableCell onClick={async () => copyText(id)}>{id}</TableCell>
                <TableCell>{name}</TableCell>
                <TableCell>{discountType}</TableCell>
                <TableCell>{discount}</TableCell>
                <TableCell>{maximumDiscountAmount}</TableCell>
                <TableCell>{minimumPrice}</TableCell>
                <TableCell>
                  <TextButton onClick={() => onCouponTypeSelect(type)}>
                    수정
                  </TextButton>
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </Flex>
  );
}

export default CouponTypeList;

import React from "react";
import { Flex } from "../../common/styles/commonStyles";
import ContentListNavBar from "./components/ContentListNavBar";
import UpdateContentModal from "../updateContent/UpdateContentModal";
import useContentListContainer from "./ContentListContainer.hook";
import ContentListTable from "./components/ContentListTable";

function ContentListContainer() {
  const { models, operations } = useContentListContainer();

  const { selectedContentType, selectedContentId, searchQuery, searchedQuery } =
    models;

  const {
    onContentTypeClick,
    onSelectContent,
    onSearchChange,
    onSearchKeyDown,
    onSearch,
  } = operations;

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
    >
      <ContentListNavBar
        selectedContentType={selectedContentType}
        searchQuery={searchQuery}
        onSelectContent={onSelectContent}
        onSearchChange={onSearchChange}
        onSearchKeyDown={onSearchKeyDown}
        onSearch={onSearch}
        onContentTypeClick={onContentTypeClick}
      />
      <ContentListTable
        selectedContentType={selectedContentType}
        searchedQuery={searchedQuery}
        onSelectContent={onSelectContent}
      />
      {!!selectedContentId && (
        <UpdateContentModal
          isOpen={!!selectedContentId}
          selectedContentId={selectedContentId}
          onRequestClose={() => onSelectContent(null)}
        />
      )}
    </Flex>
  );
}

export default ContentListContainer;

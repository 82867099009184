import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  GET_REPORT_REASONS,
  UPDATE_REPORT_REASON_AS_ADMIN,
} from "../../../lib/apollo/gql/report";
import { REPORT_TYPE } from "../../../utilities/constants";

function useReportReasonModal() {
  const [noteReason, setNoteReason] = useState("");
  const [artReason, setArtReason] = useState("");

  const {
    data: noteData,
    loading: noteLoading,
    error,
  } = useQuery(GET_REPORT_REASONS, {
    variables: {
      type: REPORT_TYPE.NOTE,
    },
  });

  const { data: artData, loading: artLoading } = useQuery(GET_REPORT_REASONS, {
    variables: {
      type: REPORT_TYPE.ART,
    },
  });

  const [updateReportReasonAsAdmin, { loading: updateLoading }] = useMutation(
    UPDATE_REPORT_REASON_AS_ADMIN,
    {
      onCompleted: () => {
        setNoteReason("");
        setArtReason("");
      },
      refetchQueries: () => [
        {
          query: GET_REPORT_REASONS,
          variables: {
            type: REPORT_TYPE.NOTE,
          },
        },

        {
          query: GET_REPORT_REASONS,
          variables: {
            type: REPORT_TYPE.ART,
          },
        },
      ],
    }
  );

  function onNoteReasonChange(e) {
    const { value } = e.target;

    setNoteReason(value);
  }

  function onArtReasonChange(e) {
    const { value } = e.target;

    setArtReason(value);
  }

  function onNoteReasonSubmit() {
    updateReportReasonAsAdmin({
      variables: {
        reportReasonInput: {
          reason: noteReason,
          type: REPORT_TYPE.NOTE,
        },
      },
    });
  }

  function onArtReasonSubmit() {
    updateReportReasonAsAdmin({
      variables: {
        reportReasonInput: {
          reason: artReason,
          type: REPORT_TYPE.ART,
        },
      },
    });
  }

  return {
    models: {
      noteReason,
      artReason,
      noteData: noteData?.getReportReasons,
      artData: artData?.getReportReasons,
      loading: noteLoading || artLoading,
      updateLoading,
    },
    operations: {
      onNoteReasonChange,
      onArtReasonChange,
      onNoteReasonSubmit,
      onArtReasonSubmit,
    },
  };
}

export default useReportReasonModal;

import moment from "moment";
import React from "react";
import {
  ORDER_DELIVERY_OPTIONS_TEXT,
  ORDER_STATUS,
  ORDER_STATUS_TEXT,
  SALE_DELIVERY_STATUS_TEXT,
  SHIPPING_STATUS,
  SHIPPING_STATUS_TEXT,
} from "../../../../utilities/constants";
import Loading from "../../../common/components/Loading";
import Modal from "../../../common/components/Modal";
import Thumbnail from "../../../common/components/Thumbnail";
import {
  Flex,
  SectionTitle,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TextButton,
} from "../../../common/styles/commonStyles";
import SaleListTable from "../../../sale/saleList/components/SaleListTable";
import useOrderDetailModal from "./OrderDetailModal.hook";

function OrderDetailModal({ isOpen, selectedOrderId, onRequestClose }) {
  const {
    models: {
      data,
      loading,
      isShippingEditMode,
      updateShippingLoading,
      editingShippingState,
    },
    operations: {
      changeSaleDeliveryStatus,
      toggleShippingEditMode,
      onShippingChange,
      onShippingSubmit,
      confirmCancel,
    },
  } = useOrderDetailModal(selectedOrderId);

  if (loading) return <Loading />;

  const {
    id,
    user,
    coupon,
    point,
    status,
    initialAmount,
    finalAmount,
    payMethod,
    paidAt,
    cancelledAt,
    cancelReason,
    deliveryOption,
    saleItems,
    shipping,
    createdAt,
  } = data;

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="flex-start"
        gap={4}
        marginBottom={12}
      >
        <SectionTitle>주문 상세보기</SectionTitle>
        <Table>
          <TableHead>
            <TableHeadRow>
              <TableHeadCell width="8%">ID</TableHeadCell>
              <TableHeadCell width="8%">구매자</TableHeadCell>
              <TableHeadCell width="8%">주문상태</TableHeadCell>
              <TableHeadCell width="8%">결제금액</TableHeadCell>
              <TableHeadCell width="8%">할인금액</TableHeadCell>
              <TableHeadCell width="8%">쿠폰사용유무</TableHeadCell>
              <TableHeadCell width="8%">결제수단</TableHeadCell>
              <TableHeadCell width="8%">결제시각</TableHeadCell>
              <TableHeadCell width="8%">배송옵션</TableHeadCell>
              <TableHeadCell width="8%">배송주소</TableHeadCell>
              <TableHeadCell width="8%">연락처</TableHeadCell>
              <TableHeadCell width="8%">배송상태</TableHeadCell>
              <TableHeadCell width="8%">주문시각</TableHeadCell>
              <TableHeadCell width="8%">취소하기</TableHeadCell>
            </TableHeadRow>
          </TableHead>
          <tbody>
            <TableRow>
              <TableCell>{id}</TableCell>
              <TableCell>{user?.name}</TableCell>

              <TableCell>{ORDER_STATUS_TEXT[status]}</TableCell>
              <TableCell>{finalAmount}</TableCell>
              <TableCell>{initialAmount - finalAmount}</TableCell>
              <TableCell>{coupon ? coupon?.id : "N"}</TableCell>
              <TableCell>{payMethod}</TableCell>
              <TableCell>
                {moment(paidAt).format("YYYY-MM-DD HH:mm:ss")}
              </TableCell>
              <TableCell>
                {ORDER_DELIVERY_OPTIONS_TEXT[deliveryOption]}
              </TableCell>
              <TableCell>{shipping?.address || "-"}</TableCell>
              <TableCell>{shipping?.phone || "-"}</TableCell>

              <TableCell>{shipping?.status}</TableCell>
              <TableCell>
                {moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}
              </TableCell>
              <TableCell>
                <TextButton width={100} onClick={() => confirmCancel(id)}>
                  주문 취소
                </TextButton>
              </TableCell>
            </TableRow>
          </tbody>
        </Table>
      </Flex>

      {/* ------------------------------------------------------------- */}
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="flex-start"
        gap={4}
        marginBottom={12}
      >
        <SectionTitle>작품</SectionTitle>
        <SaleListTable
          data={saleItems}
          showPagination={false}
          changeSaleDeliveryStatus={changeSaleDeliveryStatus}
        />
      </Flex>

      {/* ------------------------------------------------------------- */}
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="flex-start"
        gap={4}
        marginBottom={12}
      >
        <SectionTitle>배송현황</SectionTitle>
        <Table>
          <TableHead>
            <TableHeadRow>
              <TableHeadCell>ID</TableHeadCell>
              <TableHeadCell>주문ID</TableHeadCell>
              <TableHeadCell>배송상태</TableHeadCell>
              <TableHeadCell>택배사</TableHeadCell>
              <TableHeadCell>운송장번호</TableHeadCell>
              <TableHeadCell>업데이트시각</TableHeadCell>
              <TableHeadCell>수정</TableHeadCell>
            </TableHeadRow>
          </TableHead>
          <tbody>
            <TableRow>
              <TableCell>{shipping?.id}</TableCell>
              <TableCell>{shipping?.order?.id}</TableCell>
              {isShippingEditMode ? (
                <>
                  <TableCell>
                    <select
                      defaultValue={editingShippingState?.status}
                      onChange={(e) => onShippingChange(e, "status")}
                    >
                      {Object.keys(SHIPPING_STATUS).map((key) => (
                        <option key={key} value={key}>
                          {SHIPPING_STATUS_TEXT[key]}
                        </option>
                      ))}
                    </select>
                  </TableCell>
                  <TableCell>
                    <input
                      value={editingShippingState?.deliveryCompany}
                      onChange={(e) => onShippingChange(e, "deliveryCompany")}
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      value={editingShippingState?.deliveryCode}
                      onChange={(e) => onShippingChange(e, "deliveryCode")}
                    />
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell>
                    {SHIPPING_STATUS_TEXT[shipping?.status]}
                  </TableCell>
                  <TableCell>{shipping?.deliveryCompany}</TableCell>
                  <TableCell>{shipping?.deliveryCode}</TableCell>
                </>
              )}

              <TableCell>
                {moment(shipping?.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
              </TableCell>
              <TableCell>
                {isShippingEditMode ? (
                  <TextButton width={50} onClick={() => onShippingSubmit()}>
                    저장
                  </TextButton>
                ) : (
                  <TextButton
                    width={50}
                    onClick={() => toggleShippingEditMode()}
                  >
                    수정
                  </TextButton>
                )}
              </TableCell>
            </TableRow>
          </tbody>
        </Table>
      </Flex>

      {/* ------------------------------------------------------------- */}
      {cancelledAt && (
        <Flex
          width="100%"
          flexDirection="column"
          alignItems="flex-start"
          gap={4}
          marginBottom={12}
        >
          <SectionTitle>취소 이유</SectionTitle>
          <Table>
            <TableHead>
              <TableHeadRow>
                <TableHeadCell>취소 사유</TableHeadCell>
                <TableHeadCell>취소 시각</TableHeadCell>
              </TableHeadRow>
            </TableHead>
            <tbody>
              <TableRow>
                <TableCell>{cancelReason}</TableCell>
                <TableCell>
                  {moment(cancelledAt).format("YYYY-MM-DD HH:mm:ss")}
                </TableCell>
              </TableRow>
            </tbody>
          </Table>
        </Flex>
      )}
    </Modal>
  );
}

export default OrderDetailModal;

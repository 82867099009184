import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  GET_BANNERS_AS_ADMIN,
  UPDATE_BANNERS_AS_ADMIN,
} from "../../../lib/apollo/gql/bannerGqls";

function useBannerTable() {
  const [offset, setOffset] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);

  const { loading, data, fetchMore } = useQuery(GET_BANNERS_AS_ADMIN, {
    variables: {
      offset,
      limit: 10,
    },
    onCompleted: ({ getBannersAsAdmin }) => {
      const { pageInfo } = getBannersAsAdmin;

      const { totalPages } = pageInfo;

      setTotalPageCount(totalPages);
      setOffset(pageInfo.offset);
    },
  });

  /**
   *  When page changed from pagination
   *  Fetch data for the offset changed
   */
  async function onPageChange({ selected }) {
    const newOffset = selected;
    if (!fetchingMore) {
      setFetchingMore(true);
      fetchMore({
        variables: {
          offset: newOffset,
          limit: 10,
        },
      });
      setOffset(newOffset);
      setFetchingMore(false);
    }
  }

  return {
    models: {
      loading,
      data: data?.getBannersAsAdmin?.edges,
      offset,
      totalPageCount,
    },
    operations: {
      onPageChange,
    },
  };
}

export default useBannerTable;

import React from "react";
import { Flex, TextButton } from "../../common/styles/commonStyles";
import useAddNotice from "./AddNotice.hook";
import UpdateNotice from "./UpdateNotice";

function AddNotice() {
  const {
    models: { state, showInputs },
    operations: { toggleShowInputs, onChange, onSubmit, isSubmitDisabled },
  } = useAddNotice();

  return (
    <Flex
      alignItems="flex-start"
      gap={12}
      width="100%"
      justifyContent="flex-start"
    >
      <TextButton onClick={toggleShowInputs}>
        팝업 추가 {showInputs && "취소"}
      </TextButton>
      {showInputs && (
        <UpdateNotice
          state={state}
          isUpdate={false}
          onChange={onChange}
          onSubmit={onSubmit}
          isSubmitDisabled={isSubmitDisabled}
        />
      )}
    </Flex>
  );
}

export default AddNotice;

import React from "react";
import {
  SearchIcon,
  Flex,
  SearchInput,
  TextButton,
} from "../../../common/styles/commonStyles";

function ArtHistoryListNavBar({
  searchQuery,
  onSelectArtHistory,
  onSearchChange,
  onSearchKeyDown,
  onSearch,
}) {
  return (
    <Flex
      padding={12}
      justifyContent="space-between"
      width="100%"
      backgroundColor="rgba(0,0,0,0.1)"
    >
      <Flex alignItems="center" gap={4}>
        <TextButton
          backgroundColor="#df65dd"
          onClick={() => onSelectArtHistory("new")}
        >
          등록
        </TextButton>
      </Flex>
      <Flex alignItems="center" gap={2}>
        <SearchInput
          placeholder="미술사 제목, 하일라이트, 본문"
          value={searchQuery}
          onChange={onSearchChange}
          onKeyDown={onSearchKeyDown}
        />
        <TextButton
          backgroundColor="white"
          width={24}
          height={24}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={onSearch}
        >
          <SearchIcon />
        </TextButton>
      </Flex>
    </Flex>
  );
}

export default ArtHistoryListNavBar;

import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_REPORTS_AS_ADMIN } from "../../../lib/apollo/gql/report";
import { LIMIT, REPORT_TYPE } from "../../../utilities/constants";

function useReportContainer() {
  const [showReasonModal, setShowReasonModal] = useState(false);

  const [type, setType] = useState(REPORT_TYPE.ART);

  function onTypeChange(type) {
    setType(type);
  }

  function toggleReasonModal(value) {
    setShowReasonModal(value);
  }

  return {
    models: {
      type,
      showReasonModal,
    },
    operations: {
      onTypeChange,
      toggleReasonModal,
    },
  };
}

export default useReportContainer;

import { gql } from "@apollo/client";
import { ART_HISTORY_FIELD, PAGE_INFO_FIELD } from "./fragments";

export const UPDATE_ART_HISTORY_AS_ADMIN = gql`
  ${ART_HISTORY_FIELD}
  mutation UpdateArtHistoryAsAdmin($artHistoryInput: ArtHistoryInput!) {
    updateArtHistoryAsAdmin(artHistoryInput: $artHistoryInput) {
      ...ArtHistoryField
    }
  }
`;

export const GET_ART_HISTORIES_LENGTH = gql`
  query GetArtHistoriesLength {
    getArtHistoriesLength
  }
`;

export const GET_ART_HISTORY = gql`
  ${ART_HISTORY_FIELD}
  query GetArtHistory($artHistoryId: ID!) {
    getArtHistory(artHistoryId: $artHistoryId) {
      ...ArtHistoryField
    }
  }
`;

export const GET_ART_HISTORIES = gql`
  ${PAGE_INFO_FIELD}
  ${ART_HISTORY_FIELD}
  query GetArtHistories($offset: Int, $limit: Int) {
    getArtHistories(offset: $offset, limit: $limit) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...ArtHistoryField
      }
    }
  }
`;

export const SEARCH_ART_HISTORIES = gql`
  ${PAGE_INFO_FIELD}
  ${ART_HISTORY_FIELD}
  query SearchArtHistories($query: String!, $offset: Int, $limit: Int) {
    searchArtHistories(query: $query, offset: $offset, limit: $limit) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...ArtHistoryField
      }
    }
  }
`;

export const DELETE_ART_HISTORY_AS_ADMIN = gql`
  mutation DeleteArtHistoryAsAdmin($artHistoryId: ID!) {
    deleteArtHistoryAsAdmin(artHistoryId: $artHistoryId)
  }
`;

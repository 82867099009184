import styled from "styled-components";

const MIN_HEIGHT = 50;

export const LabeledTextareaTextarea = styled.textarea(() => ({
  width: "100%",
  fontSize: 14,
  borderRadius: 4,
  padding: "8px 12px",
  border: "1px solid rgba(0,0,0,0.1)",
  minHeight: MIN_HEIGHT,

  "&:focus": {
    outline: "none",
    border: "1px solid rgba(0,0,0,0.3)",
  },
}));

import moment from "moment";
import React from "react";
import { SUBSCRIPTION_STATUS } from "../../../../utilities/constants";
import Loading from "../../../common/components/Loading";
import Pagination from "../../../common/components/Pagination";
import {
  Flex,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TextButton,
} from "../../../common/styles/commonStyles";
import useSubscriptionListTable from "./SubscriptionListTable.hook";

function SubscriptionListTable({
  selectedSubscriptionStatus,
  onSubscriptionSelect,
}) {
  const {
    models: { loading, data, offset, totalPageCount },
    operations: { onPageChange },
  } = useSubscriptionListTable(selectedSubscriptionStatus);

  return (
    <Flex
      flex={9}
      overflowY="auto"
      flexDirection="column"
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      padding={12}
    >
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell width="4%">ID</TableHeadCell>
            <TableHeadCell width="5%">User Name</TableHeadCell>
            <TableHeadCell width="8%">User Email</TableHeadCell>
            <TableHeadCell width="8%">Plan</TableHeadCell>
            <TableHeadCell width="5%">시작일</TableHeadCell>
            <TableHeadCell width="5%">종료일</TableHeadCell>
            <TableHeadCell width="5%">다음 결제일</TableHeadCell>
            <TableHeadCell width="8%">상태</TableHeadCell>
            <TableHeadCell width="6%">결제내역보기</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan="100%">
                <Loading />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data?.map((subscription) => {
              const {
                id,
                user,
                subscriptionPlan,
                from,
                to,
                nextPayAt,
                status,
                productId,
                subscriptionPayments,
                createdAt,
                updatedAt,
              } = subscription;

              return (
                <TableRow key={id}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{user?.name}</TableCell>
                  <TableCell>{user?.email}</TableCell>
                  <TableCell>
                    {subscriptionPlan
                      ? subscriptionPlan?.name
                      : productId
                      ? productId
                      : to
                      ? "1년 상품"
                      : "무료"}
                  </TableCell>
                  <TableCell>{moment(from).format("YYYY-MM-DD")}</TableCell>
                  <TableCell>
                    {status === SUBSCRIPTION_STATUS.CANCELLED ||
                    (!subscriptionPlan?.name && to)
                      ? moment(to).format("YYYY-MM-DD")
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {nextPayAt
                      ? moment(nextPayAt).format("YYYY-MM-DD")
                      : "없음"}
                  </TableCell>
                  <TableCell>{status}</TableCell>
                  <TableCell>
                    <TextButton
                      width={50}
                      onClick={() => onSubscriptionSelect(id)}
                    >
                      보기
                    </TextButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </tbody>
        )}
      </Table>
      <Pagination
        forcePage={offset}
        pageCount={totalPageCount}
        pageRangeDisplayed={5}
        previousLabel="Prev"
        nextLabel="Next"
        onPageChange={onPageChange}
      />
    </Flex>
  );
}

export default SubscriptionListTable;

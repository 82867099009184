import { gql } from "@apollo/client";
import { PAGE_INFO_FIELD, USER_FIELD } from "./fragments";

export const GET_USER_PROFILE = gql`
  ${USER_FIELD}
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      ...UserField
    }
  }
`;

export const GET_USERS_BY_USER_TYPE_AS_ADMIN = gql`
  ${USER_FIELD}
  ${PAGE_INFO_FIELD}
  query GetUsersByUserTypeAsAdmin(
    $type: UserTypes!
    $offset: Int
    $limit: Int
  ) {
    getUsersByUserTypeAsAdmin(type: $type, offset: $offset, limit: $limit) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...UserField
      }
    }
  }
`;

export const SEARCH_USER = gql`
  ${USER_FIELD}
  ${PAGE_INFO_FIELD}
  query SearchUser(
    $query: String!
    $type: UserTypes!
    $offset: Int
    $limit: Int
  ) {
    searchUser(query: $query, type: $type, offset: $offset, limit: $limit) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...UserField
      }
    }
  }
`;

export const CREATE_USER_PROFILE_AS_ADMIN = gql`
  ${USER_FIELD}
  mutation CreateUserProfileAsAdmin(
    $userInput: UserInput
    $writerInput: WriterInput
    $artistInput: ArtistInput
    $spaceInput: SpaceInput
  ) {
    createUserProfileAsAdmin(
      userInput: $userInput
      writerInput: $writerInput
      artistInput: $artistInput
      spaceInput: $spaceInput
    ) {
      ...UserField
    }
  }
`;

export const UPDATE_USER_PROFILE_AS_ADMIN = gql`
  ${USER_FIELD}
  mutation UpdateUserProfileAsAdmin(
    $userId: ID!
    $userInput: UserInput
    $writerInput: WriterInput
    $artistInput: ArtistInput
    $spaceInput: SpaceInput
  ) {
    updateUserProfileAsAdmin(
      userId: $userId
      userInput: $userInput
      writerInput: $writerInput
      artistInput: $artistInput
      spaceInput: $spaceInput
    ) {
      ...UserField
    }
  }
`;

export const CHECK_ARTIST_REGISTER_ENABLE_AS_ADMIN = gql`
  query CheckArtistRegisterEnableAsAdmin($userId: ID!) {
    checkArtistRegisterEnableAsAdmin(userId: $userId)
  }
`;

export const CHECK_SPACE_REGISTER_ENABLE_AS_ADMIN = gql`
  query CheckSpaceRegisterEnableAsAdmin($userId: ID!) {
    checkSpaceRegisterEnableAsAdmin(userId: $userId)
  }
`;

export const UPDATE_USER_BILLING = gql`
  ${USER_FIELD}
  mutation UpdateUserBilling($billingInput: BillingInput!) {
    updateUserBilling(billingInput: $billingInput) {
      ...UserField
    }
  }
`;

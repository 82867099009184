import React from "react";
import LabeledInput from "../../../common/components/LabeledInput";
import { Flex, TextButton } from "../../../common/styles/commonStyles";
import useReportReasonCard from "./ReportReasonCard.hook";

function ReportReasonCard({ reportReason }) {
  const {
    models: { reason, editMode, updateLoading },
    operations: { toggleEditMode, onChange, onSubmit },
  } = useReportReasonCard(reportReason);
  return (
    <Flex
      flexDirection="row"
      justifyContent="flex-start"
      width="100%"
      margin="8px 0px"
    >
      {editMode ? (
        <Flex
          flexDirection="row"
          justifyContent="flex-start"
          width="100%"
          gap={8}
        >
          <LabeledInput
            placeholder="신고 사유"
            value={reason}
            onChange={onChange}
          />
          <TextButton width={100} disabled={updateLoading} onClick={onSubmit}>
            {updateLoading ? "수정중" : "수정하기"}
          </TextButton>
          <TextButton
            width={100}
            backgroundColor="red"
            disabled={updateLoading}
            onClick={() => toggleEditMode()}
          >
            취소하기
          </TextButton>
        </Flex>
      ) : (
        <div onClick={() => toggleEditMode()}>{reason}</div>
      )}
    </Flex>
  );
}

export default ReportReasonCard;

import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { useEffect, useReducer, useRef } from "react";

import imageCompression from "browser-image-compression";

import { GET_ART } from "../../../lib/apollo/gql/artGqls";
import {
  GET_EXHIBITION,
  GET_EXHIBITIONS_AS_ADMIN,
  UPDATE_EXHIBITION_AS_ADMIN,
} from "../../../lib/apollo/gql/exhibitionGqls";
import { COMPRESSION_OPTION, LIMIT } from "../../../utilities/constants";

export const UPDATE_EXHIBITION_MODAL_ACTION_TYPES = {
  HANDLE_TITLE: "HANDLE_TITLE",
  HANDLE_INTRODUCTION: "HANDLE_INTRODUCTION",
  HANDLE_FROM: "HANDLE_FROM",
  HANDLE_TO: "HANDLE_TO",
  HANDLE_DIRECTOR: "HANDLE_DIRECTOR",
  HANDLE_HOST: "HANDLE_HOST",
  HANDLE_SPONSOR: "HANDLE_SPONSOR",
  HANDLE_FONT_COLOR: "HANDLE_FONT_COLOR",
  HANDLE_PRIMARY_MEDIA_STATE: "HANDLE_PRIMARY_MEDIA_STATE",
  HANDLE_BACKGROUND_MEDIA_STATE: "HANDLE_BACKGROUND_MEDIA_STATE",
  HANDLE_ARTS: "HANDLE_ARTS",

  SET_ALL: "SET_ALL",
};

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_TITLE: {
      return {
        ...state,
        title: action.payload,
      };
    }

    case UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_INTRODUCTION: {
      return {
        ...state,
        introduction: action.payload,
      };
    }

    case UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_FROM: {
      return {
        ...state,
        from: action.payload,
      };
    }

    case UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_TO: {
      return {
        ...state,
        to: action.payload,
      };
    }

    case UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_DIRECTOR: {
      return {
        ...state,
        director: action.payload,
      };
    }

    case UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_HOST: {
      return {
        ...state,
        host: action.payload,
      };
    }

    case UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_SPONSOR: {
      return {
        ...state,
        sponsor: action.payload,
      };
    }

    case UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_FONT_COLOR: {
      return {
        ...state,
        fontColor: action.payload,
      };
    }

    case UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_PRIMARY_MEDIA_STATE: {
      return {
        ...state,
        primaryMediaState: action.payload,
      };
    }

    case UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_BACKGROUND_MEDIA_STATE: {
      return {
        ...state,
        backgroundMediaState: action.payload,
      };
    }

    case UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_ARTS: {
      return {
        ...state,
        arts: action.payload.arts,
        artists: action.payload.artists,
      };
    }

    case UPDATE_EXHIBITION_MODAL_ACTION_TYPES.SET_ALL: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

const initialState = {
  id: "",
  title: "",
  introduction: "",
  from: moment().format("YYYY-MM-DD"),
  to: moment().format("YYYY-MM-DD"),
  director: "",
  host: "",
  sponsor: "",
  fontColor: "#000000",
  primaryMediaState: null,
  backgroundMediaState: null,
  primaryMedia: null,
  backgroundMedia: null,
  arts: [null],
  artists: [],
};

function useUpdateExhibitionModal(selectedExhibitionId, onRequestClose) {
  const primaryMediaRef = useRef(null);
  const backgroundMediaRef = useRef(null);

  const client = useApolloClient();

  const [state, dispatch] = useReducer(reducer, initialState);

  const [updateExhibitionAsAdmin, { loading: updateLoading }] = useMutation(
    UPDATE_EXHIBITION_AS_ADMIN,
    {
      onCompleted: () => {
        onRequestClose();
      },
      refetchQueries: () =>
        selectedExhibitionId === "new"
          ? [
              {
                query: GET_EXHIBITIONS_AS_ADMIN,
                variables: {
                  offset: 0,
                  limit: LIMIT,
                },
              },
            ]
          : [],
    }
  );

  const [getExhibition, { loading: exhibitionLoading }] = useLazyQuery(
    GET_EXHIBITION,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ getExhibition }) => {
        if (state?.id !== selectedExhibitionId) {
          const {
            id,
            title,
            introduction,
            from,
            to,
            director,
            host,
            sponsor,
            fontColor,
            primaryMedia,
            backgroundMedia,
            arts,
          } = getExhibition;
          const payload = {
            id,
            title,
            introduction,
            from: moment(from).format("YYYY-MM-DD"),
            to: moment(to).format("YYYY-MM-DD"),
            director,
            host,
            sponsor,
            fontColor,
            primaryMedia,
            backgroundMedia,
            arts: arts?.map((art) => ({
              value: art.id,
              label: `${art?.titleKor}(${art?.titleEng})`,
            })),
            artists: arts?.map((art) => art.artist),
          };

          dispatch({
            type: UPDATE_EXHIBITION_MODAL_ACTION_TYPES.SET_ALL,
            payload,
          });
        }
      },
    }
  );

  useEffect(() => {
    if (selectedExhibitionId && selectedExhibitionId !== "new") {
      getExhibition({
        variables: {
          exhibitionId: selectedExhibitionId,
        },
      });
    }
  }, [selectedExhibitionId]);

  /**
   * Handle input values (title, introduction, from, to director, host, sponsor, fontColor)
   * @param {object} e
   * @param {string} type
   */
  function onInputChange(e, type) {
    const { value } = e.target;

    dispatch({
      type,
      payload: value,
    });
  }

  /**
   * Handle primary media input
   */
  function onPrimaryMediaChange(e) {
    const { files } = e.target;

    const file = files[0];

    dispatch({
      type: UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_PRIMARY_MEDIA_STATE,
      payload: file,
    });
  }

  function onPrimaryMediaEditClick() {
    primaryMediaRef?.current?.click();
  }

  function onPrimaryMediaChangeCancelClick() {
    dispatch({
      type: UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_PRIMARY_MEDIA_STATE,

      payload: null,
    });
  }

  /**
   * Handle background media input
   */
  function onBackgroundMediaChange(e) {
    const { files } = e.target;

    const file = files[0];

    dispatch({
      type: UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_BACKGROUND_MEDIA_STATE,
      payload: file,
    });
  }

  function onBackgroundMediaEditClick() {
    backgroundMediaRef?.current?.click();
  }

  function onBackgroundMediaChangeCancelClick() {
    dispatch({
      type: UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_BACKGROUND_MEDIA_STATE,
      payload: null,
    });
  }

  async function onArtSelect(value, index) {
    const {
      data: { getArt },
    } = await client.query({
      query: GET_ART,
      variables: {
        id: value.value,
      },
    });

    if (!getArt) return;

    const editedArts = [...state.arts];
    editedArts[index] = value;

    const { artist } = getArt;

    const editedArtists = [...state.artists];
    editedArtists[index] = artist;

    dispatch({
      type: UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_ARTS,
      payload: {
        arts: editedArts,
        artists: editedArtists,
      },
    });
  }

  function onAddArtClick() {
    const editedArts = [...state.arts, null];
    const editedArtists = [...state.artists, null];

    dispatch({
      type: UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_ARTS,
      payload: {
        arts: editedArts,
        artists: editedArtists,
      },
    });
  }

  async function onDeleteArt(index) {
    const editedArts = [...state.arts];
    editedArts.splice(index, 1);
    const editedArtists = [...state.artists];
    editedArtists.splice(index, 1);

    dispatch({
      type: UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_ARTS,
      payload: {
        arts: editedArts,
        artists: editedArtists,
      },
    });
  }

  function isSubmitDisabled() {
    const isTitleDisabled = !state.title;
    const isIntroductionDisabled = !state.introduction;
    const isPrimaryMediaDisabled =
      !state.primaryMedia && !state.primaryMediaState;
    const isDurationDisabled = !state.from || !state.to;
    const isDirectorDisabled = !state.director;
    const isFontColorDisabled = !state.fontColor;
    const isArtDisabled = !(state.arts?.length > 0);

    return (
      isTitleDisabled ||
      isIntroductionDisabled ||
      isPrimaryMediaDisabled ||
      isDurationDisabled ||
      isDirectorDisabled ||
      isFontColorDisabled ||
      isArtDisabled ||
      updateLoading
    );
  }

  async function onSubmit() {
    const {
      title,
      introduction,
      from,
      to,
      director,
      host,
      sponsor,
      fontColor,
      primaryMediaState,
      backgroundMediaState,
      arts,
      artists,
    } = state;

    let primaryMediaInput = null;
    let backgroundMediaInput = null;

    if (primaryMediaState) {
      await imageCompression(primaryMediaState, COMPRESSION_OPTION).then(
        (file) => {
          primaryMediaInput = { file };
        }
      );
    }

    if (backgroundMediaState) {
      await imageCompression(backgroundMediaState, COMPRESSION_OPTION).then(
        (file) => {
          backgroundMediaInput = { file };
        }
      );
    }

    const artIds = [...arts].map((art) => art.value);
    const artistIds = [...artists].map((artist) => artist.id);

    updateExhibitionAsAdmin({
      variables: {
        exhibitionInput: {
          id: selectedExhibitionId === "new" ? null : selectedExhibitionId,
          title,
          introduction,
          from,
          to,
          director,
          host,
          sponsor,
          fontColor,
          primaryMediaInput,
          backgroundMediaInput,
          artIds: [...new Set(artIds)],
          artistIds: [...new Set(artistIds)],
        },
      },
    });
  }

  return {
    refs: {
      primaryMediaRef,
      backgroundMediaRef,
    },
    models: {
      state,
      updateLoading,
      exhibitionLoading,
    },
    operations: {
      onInputChange,
      onPrimaryMediaChange,
      onPrimaryMediaEditClick,
      onPrimaryMediaChangeCancelClick,
      onBackgroundMediaChange,
      onBackgroundMediaEditClick,
      onBackgroundMediaChangeCancelClick,
      onArtSelect,
      onAddArtClick,
      onDeleteArt,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useUpdateExhibitionModal;

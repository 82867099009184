import React from "react";
import LabeledInput from "../../common/components/LabeledInput";
import Loading from "../../common/components/Loading";
import Modal from "../../common/components/Modal";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../common/styles/commonStyles";
import ReportReasonCard from "./components/ReportReasonCard";
import useReportReasonModal from "./ReportReasonModal.hook";

function ReportReasonModal({ isOpen, onRequestClose }) {
  const {
    models: {
      noteReason,
      artReason,
      noteData,
      artData,
      loading,
      updateLoading,
    },
    operations: {
      onNoteReasonChange,
      onArtReasonChange,
      onNoteReasonSubmit,
      onArtReasonSubmit,
    },
  } = useReportReasonModal();

  return (
    <Modal
      isOpen={isOpen}
      title="신고 사유 관리"
      onRequestClose={onRequestClose}
    >
      {loading && <Loading />}
      <div>
        <SectionTitle>노트 신고 사유</SectionTitle>
        {noteData?.map((reportReason) => (
          <ReportReasonCard
            key={reportReason?.id}
            reportReason={reportReason}
          />
        ))}
        <Flex marginTop={12} gap={4}>
          <LabeledInput
            placeholder="새 노트 신고 사유"
            value={noteReason}
            onChange={onNoteReasonChange}
          />
          <TextButton
            width={100}
            disabled={updateLoading}
            onClick={() => onNoteReasonSubmit()}
          >
            {updateLoading ? "추가 중" : "추가하기"}
          </TextButton>
        </Flex>
      </div>

      <div>
        <SectionTitle>작품 신고 사유</SectionTitle>
        {artData?.map((reportReason) => (
          <ReportReasonCard
            key={reportReason?.id}
            reportReason={reportReason}
          />
        ))}
        <Flex marginTop={12} gap={4}>
          <LabeledInput
            placeholder="새 작품 신고 사유"
            value={artReason}
            onChange={onArtReasonChange}
          />
          <TextButton
            width={100}
            disabled={updateLoading}
            onClick={() => onArtReasonSubmit()}
          >
            {updateLoading ? "추가 중" : "추가하기"}
          </TextButton>
        </Flex>
      </div>
    </Modal>
  );
}

export default ReportReasonModal;

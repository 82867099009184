import React from "react";
import {
  LabeledInputError,
  LabeledInputInput,
  LabeledInputLabel,
  LabeledInputWrapper,
} from "./LabeledInput.styles";

function LabeledInput({
  wrapperWidth = "100%",
  marginBottom = 12,
  label,
  error,
  inputRef,
  ...rest
}) {
  return (
    <LabeledInputWrapper width={wrapperWidth} marginBottom={marginBottom}>
      <LabeledInputLabel>{label}</LabeledInputLabel>
      <LabeledInputInput ref={inputRef} {...rest} />
      {error && <LabeledInputError>{error}</LabeledInputError>}
    </LabeledInputWrapper>
  );
}

export default LabeledInput;

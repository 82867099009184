import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  CONFIRM_CANCEL_ORDER_AS_ADMIN,
  GET_ORDER,
  UPDATE_SHIPPING_AS_ADMIN,
} from "../../../../lib/apollo/gql/orderGqls";
import { SET_SALE_DELIVERY_STATUS_AS_ADMIN } from "../../../../lib/apollo/gql/saleGqls";

const defaultShippingState = {
  status: "",
  deliveryCompany: "",
  deliveryCode: "",
};

function useOrderDetailModal(selectedOrderId) {
  const [isShippingEditMode, setIsShippingEditMode] = useState(false);
  const [editingShippingState, setEditingShippingState] =
    useState(defaultShippingState);

  const { loading, data, error } = useQuery(GET_ORDER, {
    variables: {
      orderId: selectedOrderId,
    },
  });

  const [updateShippingAsAdmin, { loading: updateShippingLoading }] =
    useMutation(UPDATE_SHIPPING_AS_ADMIN, {
      onCompleted: () => {
        setIsShippingEditMode(false);
        setEditingShippingState(defaultShippingState);
      },
    });

  const [setSaleDeliveryStatusAsAdmin] = useMutation(
    SET_SALE_DELIVERY_STATUS_AS_ADMIN
  );

  const [confirmCancelOrderAsAdmin] = useMutation(
    CONFIRM_CANCEL_ORDER_AS_ADMIN,
    {
      refetchQueries: () => [
        {
          query: GET_ORDER,
          variables: {
            orderId: selectedOrderId,
          },
        },
      ],
      onError: (err) => {
        console.log(err);
      },
    }
  );

  async function changeSaleDeliveryStatus(id, status) {
    await setSaleDeliveryStatusAsAdmin({
      variables: {
        saleId: id,
        deliveryStatus: status,
      },
    });
  }

  function toggleShippingEditMode() {
    const { shipping } = data?.getOrder;

    if (!shipping) return;

    const { status, deliveryCode, deliveryCompany } = shipping;

    setEditingShippingState({
      status,
      deliveryCode: deliveryCode || "",
      deliveryCompany: deliveryCompany || "",
    });

    setIsShippingEditMode(true);
  }

  function onShippingChange(e, key) {
    const { value } = e.target;

    setEditingShippingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onShippingSubmit() {
    const { shipping } = data?.getOrder;
    const { id, order } = shipping;
    const { status, deliveryCode, deliveryCompany } = editingShippingState;

    updateShippingAsAdmin({
      variables: {
        shippingInput: {
          id,
          orderId: order?.id,
          status,
          deliveryCode,
          deliveryCompany,
        },
      },
    });
  }

  function confirmCancel(orderId) {
    confirmCancelOrderAsAdmin({
      variables: {
        orderId,
      },
    });
  }

  return {
    models: {
      loading,
      data: data?.getOrder,
      isShippingEditMode,
      updateShippingLoading,
      editingShippingState,
    },
    operations: {
      changeSaleDeliveryStatus,
      toggleShippingEditMode,
      onShippingChange,
      onShippingSubmit,
      confirmCancel,
    },
  };
}

export default useOrderDetailModal;

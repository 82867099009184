import React from "react";
import Loading from "../common/components/Loading";
import Pagination from "../common/components/Pagination";
import { Flex, SectionTitle } from "../common/styles/commonStyles";
import QuestionCard from "./components/QuestionCard";
import useQuestionContainer from "./QuestionContainer.hook";

function QuestionContainer() {
  const {
    models: { loading, data, offset, totalPageCount },
    operations: { onPageChange },
  } = useQuestionContainer();

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
      padding={12}
    >
      <Flex marginBottom={24}>
        <SectionTitle>문의사항</SectionTitle>
      </Flex>
      {loading && <Loading />}
      {!loading && (
        <Flex
          flexWrap="wrap"
          width="100%"
          gap={4}
          alignItems="stretch"
          justifyContent="flex-start"
          marginBottom={24}
        >
          {data?.map((question) => (
            <QuestionCard key={question.id} question={question} />
          ))}
        </Flex>
      )}
      <Pagination
        forcePage={offset}
        pageCount={totalPageCount}
        pageRangeDisplayed={5}
        previousLabel="Prev"
        nextLabel="Next"
        onPageChange={onPageChange}
      />
    </Flex>
  );
}

export default QuestionContainer;

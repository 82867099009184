import { useState } from "react";

function usePlaylistsContainer() {
  const [selectedPlaylistId, setSelectedPlaylistId] = useState(null);

  function onPlaylistSelect(playlistId) {
    setSelectedPlaylistId(playlistId);
  }

  return {
    models: {
      selectedPlaylistId,
    },
    operations: {
      onPlaylistSelect,
    },
  };
}

export default usePlaylistsContainer;

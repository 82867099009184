import { gql } from "@apollo/client";

export const GET_ADMIN_STATS = gql`
  query GetAdminStats {
    getAdminStats {
      userCount
      artistCount
      writerCount
      spaceCount
      subscriptionCount
      activeSubscriptionCount
      artCount
      saleCount
      soldCount
      collectorArtCount
      essayCount
      essayClapCount
      essayClapUserCount
      docentCount
      docentClapCount
      docentClapUserCount
      interviewCount
      interviewClapCount
      interviewClapUserCount
      weeklyCount
      weeklyClapCount
      weeklyClapUserCount
    }
  }
`;

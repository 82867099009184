import React from "react";
import Loading from "../../common/components/Loading";
import { Flex } from "../../common/styles/commonStyles";
import NoticeCard from "./NoticeCard";
import useNoticeList from "./NoticeList.hook";

function NoticeList() {
  const {
    models: { data, loading },
  } = useNoticeList();

  if (loading) return <Loading />;

  console.log(data);

  return (
    <Flex
      alignItems="stretch"
      gap={12}
      flexWrap="wrap"
      justifyContent="flex-start"
      width="100%"
    >
      {data?.map((notice) => (
        <NoticeCard key={notice.id} notice={notice} />
      ))}
    </Flex>
  );
}

export default NoticeList;

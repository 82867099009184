import { useState } from "react";

function useSubscriptionListContainer() {
  const [selectedSubscriptionStatus, setSelectedSubscriptionStatus] =
    useState(null);

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);

  function onSubscriptionStatusSelect(status) {
    setSelectedSubscriptionStatus(status);
  }

  function onSubscriptionSelect(id) {
    setSelectedSubscriptionId(id);
  }

  return {
    models: {
      selectedSubscriptionStatus,
      selectedSubscriptionId,
    },
    operations: {
      onSubscriptionStatusSelect,
      onSubscriptionSelect,
    },
  };
}

export default useSubscriptionListContainer;

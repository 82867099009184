import React from "react";
import {
  Flex,
  SearchIcon,
  SearchInput,
  TextButton,
} from "../../../common/styles/commonStyles";

function WeeklyListNavBar({
  searchQuery,
  onSelectWeekly,
  onSearchChange,
  onSearchKeyDown,
  onSearch,
}) {
  return (
    <Flex
      padding={12}
      justifyContent="space-between"
      width="100%"
      backgroundColor="rgba(0,0,0,0.1)"
    >
      <Flex alignItems="center" gap={4}>
        <TextButton
          backgroundColor="#df65dd"
          onClick={() => onSelectWeekly("new")}
        >
          등록
        </TextButton>
      </Flex>
      <Flex alignItems="center" gap={2}>
        <SearchInput
          placeholder="위클리 제목, 하일라이트, 설명"
          value={searchQuery}
          onChange={onSearchChange}
          onKeyDown={onSearchKeyDown}
        />
        <TextButton
          backgroundColor="white"
          width={24}
          height={24}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={onSearch}
        >
          <SearchIcon />
        </TextButton>
      </Flex>
    </Flex>
  );
}

export default WeeklyListNavBar;

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  DELETE_WEEKLY_AS_ADMIN,
  GET_WEEKLIES,
  SEARCH_WEEKLIES,
} from "../../../../lib/apollo/gql/weeklyGqls";

const LIMIT = 10;

function useWeeklyListTable(searchedQuery) {
  const [offset, setOffset] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);

  const [
    getWeeklies,
    { data: weeklyData, loading: weeklyLoading, fetchMore: weeklyFetchMore },
  ] = useLazyQuery(GET_WEEKLIES, {
    onCompleted: ({ getWeeklies }) => {
      const { pageInfo } = getWeeklies;

      const { totalPages } = pageInfo;

      setTotalPageCount(totalPages);
      setOffset(pageInfo.offset);
    },
  });

  const [
    searchWeeklies,
    { data: searchData, loading: searchLoading, fetchMore: searchFetchMore },
  ] = useLazyQuery(SEARCH_WEEKLIES, {
    onCompleted: ({ searchWeeklies }) => {
      const { pageInfo } = searchWeeklies;

      const { totalPages } = pageInfo;

      setTotalPageCount(totalPages);
      setOffset(pageInfo.offset);
    },
  });

  const [deleteWeeklyAsAdmin] = useMutation(DELETE_WEEKLY_AS_ADMIN, {
    refetchQueries: () => [
      {
        query: GET_WEEKLIES,
        variables: {
          activeOnly: false,
          offset,
          limit: LIMIT,
        },
      },
    ],
  });

  useEffect(() => {
    if (searchedQuery) {
      searchWeeklies({
        variables: {
          query: searchedQuery,
          activeOnly: false,
          offset: 0,
          limit: LIMIT,
        },
      });
    } else {
      getWeeklies({
        variables: {
          activeOnly: false,
          offset: 0,
          limit: LIMIT,
        },
      });
    }
  }, [searchedQuery]);

  /**
   *  When page changed from pagination
   *  Fetch data for the offset changed
   */
  async function onPageChange({ selected }) {
    const newOffset = selected;
    if (!fetchingMore) {
      setFetchingMore(true);
      if (searchedQuery) {
        await searchFetchMore({
          query: searchedQuery,
          activeOnly: false,
          offset: newOffset,
          limit: LIMIT,
        });
      } else {
        await weeklyFetchMore({
          variables: {
            activeOnly: false,
            offset: newOffset,
            limit: LIMIT,
          },
        });
      }
      setOffset(newOffset);
      setFetchingMore(false);
    }
  }

  async function onDeleteClick(id) {
    await deleteWeeklyAsAdmin({
      variables: {
        weeklyId: id,
      },
    });
  }

  return {
    models: {
      loading: weeklyLoading || searchLoading || fetchingMore,
      data: searchedQuery
        ? searchData?.searchWeeklies?.edges
        : weeklyData?.getWeeklies?.edges,
      offset,
      totalPageCount,
    },
    operations: {
      onPageChange,
      onDeleteClick,
    },
  };
}

export default useWeeklyListTable;

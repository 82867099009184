import React from "react";
import {
  SELLER_DELIVERY_OPTIONS,
  SELLER_DELIVERY_OPTIONS_TEXT,
} from "../../../../utilities/constants";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../../common/styles/commonStyles";

function UpdateSaleDeliveryOption({ deliveryOption, onDeliveryOptionChange }) {
  return (
    <Flex flexDirection="column" alignItems="flex-start" flex={1} gap={8}>
      <SectionTitle>배송 옵션</SectionTitle>
      <Flex alignItems="center" gap={4}>
        {Object.keys(SELLER_DELIVERY_OPTIONS).map((option) => (
          <TextButton
            key={option}
            backgroundColor={option === deliveryOption ? "blue" : "gray"}
            onClick={() => onDeliveryOptionChange(option)}
          >
            {SELLER_DELIVERY_OPTIONS_TEXT[option]}
          </TextButton>
        ))}
      </Flex>
    </Flex>
  );
}

export default UpdateSaleDeliveryOption;

import React from "react";
import { DISCOUNT_TYPE } from "../../../../utilities/constants";
import LabeledInput from "../../../common/components/LabeledInput";
import { LabeledInputLabel } from "../../../common/components/LabeledInput.styles";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../../common/styles/commonStyles";
import useUpdateCouponType from "./UpdateCouponType.hook";

function UpdateCouponType({ selectedCouponType }) {
  const {
    models: { state },
    operations: { onChange, isSubmitDisabled, onSubmit },
  } = useUpdateCouponType(selectedCouponType);

  const {
    id,
    name,
    discount,
    discountType,
    maximumDiscountAmount,
    minimumPrice,
  } = state;

  return (
    <Flex
      flex={1}
      padding={12}
      alignItems="flex-start"
      justifyContent="flex-start"
      flexDirection="column"
      width="100%"
    >
      <SectionTitle>쿠폰 타입 추가/수정</SectionTitle>
      {selectedCouponType && (
        <LabeledInput label="ID" value={selectedCouponType?.id} disabled />
      )}
      <Flex alignItems="flex-start" width="100%" gap={12}>
        <Flex flex={1}>
          <LabeledInput
            label="이름"
            placeholder="쿠폰 타입 이름"
            value={name}
            onChange={(e) => onChange(e, "name")}
          />
        </Flex>
        <Flex flex={1} flexDirection="column" alignItems="flex-start">
          <LabeledInputLabel>할인 방법</LabeledInputLabel>
          <Flex gap={8}>
            <TextButton
              backgroundColor={
                discountType === DISCOUNT_TYPE.PERCENTAGE ? "blue" : "gray"
              }
              onClick={() =>
                onChange(
                  { target: { value: DISCOUNT_TYPE.PERCENTAGE } },
                  "discountType"
                )
              }
            >
              퍼센트
            </TextButton>
            <TextButton
              backgroundColor={
                discountType === DISCOUNT_TYPE.AMOUNT ? "blue" : "gray"
              }
              onClick={() =>
                onChange(
                  { target: { value: DISCOUNT_TYPE.AMOUNT } },
                  "discountType"
                )
              }
            >
              액수
            </TextButton>
          </Flex>
        </Flex>
      </Flex>
      <LabeledInput
        label="할인 액(타입이 퍼센트면 이 액수의 퍼센트만큼 할인, 액수면, 이 액수의 금액만큼 할인)"
        placeholder="타입이 퍼센트면 이 액수의 퍼센트만큼 할인, 액수면, 이 액수의 금액만큼 할인"
        type="number"
        value={discount}
        onChange={(e) => onChange(e, "discount")}
      />
      <Flex alignItems="flex-start" width="100%" gap={8} marginBototm={8}>
        <LabeledInput
          label="최대 할인 액수"
          placeholder="최대 할인되는 금액"
          type="number"
          value={maximumDiscountAmount}
          onChange={(e) => onChange(e, "maximumDiscountAmount")}
        />
        <LabeledInput
          label="최소 결제 금액"
          placeholder="할인이 적용되기 위한 최소 결제 금액"
          type="number"
          value={minimumPrice}
          onChange={(e) => onChange(e, "minimumPrice")}
        />
      </Flex>
      <TextButton
        backgroundColor="blue"
        disabled={isSubmitDisabled()}
        onClick={() => onSubmit()}
      >
        등록/수정
      </TextButton>
    </Flex>
  );
}

export default UpdateCouponType;

import React from "react";
import { Flex } from "../common/styles/commonStyles";
import PaymentListTable from "./components/PaymentListTable";
import PaymentNavBar from "./components/PaymentNavBar";
import usePaymentContainer from "./PaymentContainer.hook";

function PaymentContainer() {
  const {
    models: { range, unpaidOnly, data, loading, offset, totalPageCount },
    operations: { onRangeChange, toggleUnpaidOnly, onSubmit, onPageChange },
  } = usePaymentContainer();

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
    >
      <PaymentNavBar
        range={range}
        unpaidOnly={unpaidOnly}
        onRangeChange={onRangeChange}
        toggleUnpaidOnly={toggleUnpaidOnly}
        onSubmit={onSubmit}
      />
      {!loading && (
        <PaymentListTable
          data={data}
          offset={offset}
          totalPageCount={totalPageCount}
          onPageChange={onPageChange}
        />
      )}
    </Flex>
  );
}

export default PaymentContainer;

import { gql } from "@apollo/client";

export const GET_PLAYLISTS = gql`
  query GetPlaylists($activeOnly: Boolean, $first: Int, $after: String) {
    getPlaylists(activeOnly: $activeOnly, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        theme
        title
        summary
        thumbnailMedia {
          id
          uri
        }
        audio {
          id
          uri
        }
        backgroundColor
        active
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PLAYLIST = gql`
  query GetPlaylist($playlistId: ID!) {
    getPlaylist(playlistId: $playlistId) {
      id
      theme
      title
      summary
      thumbnailMedia {
        id
        uri
      }
      audio {
        id
        uri
      }
      backgroundColor
      active
      createdAt
      updatedAt
    }
  }
`;

export const GET_PLAYLIST_ITEMS = gql`
  query GetPlaylistItems($playlistId: ID!) {
    getPlaylistItems(playlistId: $playlistId) {
      id
      playlist {
        id
      }
      content {
        id
        title
      }
      art {
        id
        titleEng
        titleKor
      }
      sort
    }
  }
`;

export const UPDATE_PLAYLIST_AS_ADMIN = gql`
  mutation UpdatePlaylistAsAdmin($playlistInput: PlaylistInput!) {
    updatePlaylistAsAdmin(playlistInput: $playlistInput) {
      id
      theme
      title
      summary
      thumbnailMedia {
        id
        uri
      }
      audio {
        id
        uri
      }
      backgroundColor
      active
      createdAt
      updatedAt
    }
  }
`;

export const TOGGLE_PLAYLIST_ACTIVE_AS_ADMIN = gql`
  mutation TogglePlaylistActiveAsAdmin($playlistId: ID!, $active: Boolean!) {
    togglePlaylistActiveAsAdmin(playlistId: $playlistId, active: $active) {
      id
      active
    }
  }
`;

export const ADD_PLAYLIST_ITEMS_AS_ADMIN = gql`
  mutation AddPlaylistItemsAsAdmin($playlistItemsInput: [PlaylistItemInput]!) {
    addPlaylistItemsAsAdmin(playlistItemsInput: $playlistItemsInput) {
      id
    }
  }
`;

export const DELETE_PLAYLIST_ITEM_AS_ADMIN = gql`
  mutation DeletePlaylistItemAsAdmin($playlistId: ID!, $playlistItemId: ID!) {
    deletePlaylistItemAsAdmin(
      playlistId: $playlistId
      playlistItemId: $playlistItemId
    ) {
      id
    }
  }
`;

import { gql } from "@apollo/client";

export const GET_POLICIES = gql`
  query GetPolicies {
    getPolicies {
      id
      terms
      privacy
    }
  }
`;

export const UPDATE_POLICY_AS_ADMIN = gql`
  mutation UpdatePolicyAsAdmin($policyInput: PolicyInput!) {
    updatePolicyAsAdmin(policyInput: $policyInput) {
      id
      terms
      privacy
    }
  }
`;

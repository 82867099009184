import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "./HTMLEditor.css";
import useHTMLEditor from "./HTMLEditor.hook";

const Quill = ReactQuill.Quill;
const Font = Quill.import("formats/font");
Font.whitelist = ["NotoSerifKR", "Roboto"];

const Size = Quill.import("attributors/style/size");
Size.whitelist = [
  "8px",
  "11px",
  "12px",
  "14px",
  "16px",
  "20px",
  "24px",
  "28px",
  "30px",
  "36px",
];

Quill.register(Font, true);
Quill.register(Size, true);

function HTMLEditor({ value, height = 400, onChange }) {
  const { refs } = useHTMLEditor();

  const { editorRef } = refs;

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: Font.whitelist }],
        [{ size: Size.whitelist }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ align: [] }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
      ],
    },
  };

  return (
    <>
      <ReactQuill
        ref={editorRef}
        theme="snow"
        value={value}
        modules={modules}
        onChange={onChange}
        style={{ width: "100%", height }}
        preserveWhitespace
      />
    </>
  );
}

export default HTMLEditor;

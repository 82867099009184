import styled from "styled-components";

const THUMBNAIL_WIDTH = 200;
const THUMBNAIL_HEIGHT = 200;

export const ThumbnailWrapper = styled.div(
  ({ width = THUMBNAIL_WIDTH, height = THUMBNAIL_HEIGHT }) => ({
    position: "relative",
    width,
    height,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  })
);

export const ThumbnailImage = styled.img(() => ({
  width: "100%",
  height: "100%",
  objectFit: "contain",
}));

import React from "react";
import Loading from "../common/components/Loading";
import { Flex, SectionTitle } from "../common/styles/commonStyles";
import MainStatCard from "./components/MainStatCard";
import useMainContainer from "./MainContainer.hook";

function MainContainer() {
  const {
    models: { loading, data },
  } = useMainContainer();

  if (loading) return <Loading />;

  const {
    userCount,
    artistCount,
    writerCount,
    spaceCount,
    subscriptionCount,
    activeSubscriptionCount,
    artCount,
    saleCount,
    soldCount,
    collectorArtCount,
    essayCount,
    essayClapCount,
    essayClapUserCount,
    docentCount,
    docentClapCount,
    docentClapUserCount,
    interviewCount,
    interviewClapCount,
    interviewClapUserCount,
    weeklyCount,
    weeklyClapCount,
    weeklyClapUserCount,
  } = data;

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
      padding={20}
    >
      <SectionTitle>통계!</SectionTitle>
      <Flex
        width="100%"
        flexWrap="wrap"
        justifyContent="flex-start"
        alignItems="stretch"
        marginTop={20}
        gap={12}
      >
        <MainStatCard title="유저 수" value={userCount} />
        <MainStatCard title="아티스트 수" value={artistCount} />
        <MainStatCard title="스페이스 수" value={spaceCount} />
        <MainStatCard title="작가 수" value={writerCount} />
        <MainStatCard title="누적 구독자 수" value={subscriptionCount} />
        <MainStatCard title="활성 구독자 수" value={activeSubscriptionCount} />
        <MainStatCard title="작품 수" value={artCount} />
        <MainStatCard title="판매중 작품 수" value={saleCount} />
        <MainStatCard title="판매된 작품 수" value={soldCount} />
        <MainStatCard title="콜렉터 작품 수" value={collectorArtCount} />
        <MainStatCard title="에세이 수" value={essayCount} />
        <MainStatCard title="에세이 박수 수" value={essayClapCount} />
        <MainStatCard title="에세이 박수 계정 수" value={essayClapUserCount} />
        <MainStatCard title="도슨트 수" value={docentCount} />
        <MainStatCard title="도슨트 박수 수" value={docentClapCount} />
        <MainStatCard title="도슨트 박수 계정 수" value={docentClapUserCount} />
        <MainStatCard title="인터뷰 수" value={interviewCount} />
        <MainStatCard title="인터뷰 박수 수" value={interviewClapCount} />
        <MainStatCard
          title="인터뷰 박수 계정 수"
          value={interviewClapUserCount}
        />
        <MainStatCard title="위클리 수" value={weeklyCount} />
        <MainStatCard title="위클리 박수 수" value={weeklyClapCount} />
        <MainStatCard title="위클리 박수 계정 수" value={weeklyClapUserCount} />
      </Flex>
    </Flex>
  );
}

export default MainContainer;

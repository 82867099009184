import { gql } from "@apollo/client";
import { CONTENT_FIELD, PAGE_INFO_FIELD } from "./fragments";

export const UPDATE_CONTENT_AS_ADMIN = gql`
  ${CONTENT_FIELD}
  mutation UpdateContentAsAdmin($contentInput: ContentInput!) {
    updateContentAsAdmin(contentInput: $contentInput) {
      ...ContentField
    }
  }
`;

export const GET_CONTENTS_BY_TYPE = gql`
  ${CONTENT_FIELD}
  ${PAGE_INFO_FIELD}
  query GetContentsByType(
    $type: ContentTypes
    $activeOnly: Boolean
    $sortOption: ContentSortOptions
    $offset: Int
    $limit: Int
  ) {
    getContentsByType(
      type: $type
      activeOnly: $activeOnly
      sortOption: $sortOption
      offset: $offset
      limit: $limit
    ) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...ContentField
      }
    }
  }
`;

export const SEARCH_CONTENTS = gql`
  ${CONTENT_FIELD}
  ${PAGE_INFO_FIELD}
  query SearchContents(
    $query: String!
    $type: ContentTypes
    $activeOnly: Boolean
    $offset: Int
    $limit: Int
  ) {
    searchContents(
      query: $query
      activeOnly: $activeOnly
      type: $type
      offset: $offset
      limit: $limit
    ) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...ContentField
      }
    }
  }
`;

export const GET_CONTENT = gql`
  ${CONTENT_FIELD}
  query GetContent($contentId: ID!) {
    getContent(contentId: $contentId) {
      ...ContentField
    }
  }
`;

export const DELETE_CONTENT_AS_ADMIN = gql`
  mutation DeleteContentAsAdmin($contentId: ID!) {
    deleteContentAsAdmin(contentId: $contentId)
  }
`;

import { useMutation } from "@apollo/client";
import {
  EXPIRE_FREE_SUBSCRIPTION_TO_USER_AS_ADMIN,
  SET_FREE_SUBSCRIPTION_TO_USER_AS_ADMIN,
} from "../../../../lib/apollo/gql/subscriptionGqls";

function useUserTable() {
  const [setFreeSubscriptionToUserAsAdmin] = useMutation(
    SET_FREE_SUBSCRIPTION_TO_USER_AS_ADMIN
  );
  const [expireFreeSubscriptionToUserAsAdmin] = useMutation(
    EXPIRE_FREE_SUBSCRIPTION_TO_USER_AS_ADMIN
  );

  function onSetFreeSubscriptionClick(userId) {
    setFreeSubscriptionToUserAsAdmin({
      variables: {
        userId,
      },
    });
  }

  function onExpireFreeSubscriptionClick(subscriptionId) {
    expireFreeSubscriptionToUserAsAdmin({
      variables: {
        subscriptionId,
      },
    });
  }

  return {
    operations: {
      onSetFreeSubscriptionClick,
      onExpireFreeSubscriptionClick,
    },
  };
}

export default useUserTable;

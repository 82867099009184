import React from "react";
import {
  Flex,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TextButton,
} from "../../common/styles/commonStyles";
import useBannerTable from "./BannerTable.hook";
import Loading from "../../common/components/Loading";
import Thumbnail from "../../common/components/Thumbnail";
import moment from "moment";
import Pagination from "../../common/components/Pagination";

function BannerTable({ onBannerSelect }) {
  const {
    models: { loading, data, offset, totalPageCount },
    operations: { onPageChange },
  } = useBannerTable();

  return (
    <Flex
      flex={9}
      overflowY="auto"
      flexDirection="column"
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      padding={12}
    >
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell width="8%">ID</TableHeadCell>
            <TableHeadCell width="15%">이미지</TableHeadCell>
            <TableHeadCell width="20%">url</TableHeadCell>
            <TableHeadCell width="5%">순서</TableHeadCell>
            <TableHeadCell width="5%">화면표시</TableHeadCell>
            <TableHeadCell width="5%">구독자전용</TableHeadCell>
            <TableHeadCell width="5%">구독유치배너</TableHeadCell>
            <TableHeadCell width="8%">등록일</TableHeadCell>
            <TableHeadCell width="5%">수정</TableHeadCell>
          </TableHeadRow>
        </TableHead>

        {loading ? (
          <tbody>
            <tr>
              <td colSpan="100%">
                <Loading />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data?.map((banner) => {
              const {
                id,
                media,
                externalUrl,
                active,
                sort,
                isFirst,
                subscriberOnly,
                createdAt,
              } = banner;

              return (
                <TableRow key={id}>
                  <TableCell>{id}</TableCell>
                  <TableCell>
                    <Thumbnail width={100} height={100} media={media} />
                  </TableCell>
                  <TableCell>{externalUrl}</TableCell>
                  <TableCell>{sort}</TableCell>
                  <TableCell>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        backgroundColor: active ? "black" : "white",
                        borderRadius: 6,
                        border: "1px solid black",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        backgroundColor: subscriberOnly ? "black" : "white",
                        borderRadius: 6,
                        border: "1px solid black",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        backgroundColor: isFirst ? "black" : "white",
                        borderRadius: 6,
                        border: "1px solid black",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {moment(createdAt).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell>
                    <TextButton
                      padding={4}
                      onClick={() => onBannerSelect(banner)}
                    >
                      수정
                    </TextButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </tbody>
        )}
      </Table>
      <Pagination
        forcePage={offset}
        pageCount={totalPageCount}
        pageRangeDisplayed={5}
        previousLabel="Prev"
        nextLabel="Next"
        onPageChange={onPageChange}
      />
    </Flex>
  );
}

export default BannerTable;

import React from "react";
import { TextButton } from "../styles/commonStyles";
import { ThumbnailImage, ThumbnailWrapper } from "./Thumbnail.styles";

function Thumbnail({ media, width, height, children }) {
  return (
    <ThumbnailWrapper width={width} height={height}>
      <ThumbnailImage src={media?.uri} />
      {!!children && children}
    </ThumbnailWrapper>
  );
}

export default Thumbnail;

import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  GET_ALL_COUPON_TYPES_AS_ADMIN,
  UPDATE_COUPON_TYPE_AS_ADMIN,
} from "../../../../lib/apollo/gql/couponGqls";
import { DISCOUNT_TYPE } from "../../../../utilities/constants";

const initialState = {
  id: "",
  name: "",
  discountType: DISCOUNT_TYPE?.PERCENTAGE,
  discount: 0,
  maximumDiscountAmount: 0,
  minimumPrice: 0,
};

function useUpdateCouponType(selectedCouponType) {
  const [state, setState] = useState(initialState);

  const [updateCouponTypeAsAdmin, { loading }] = useMutation(
    UPDATE_COUPON_TYPE_AS_ADMIN,
    {
      onCompleted: () => {
        setState(initialState);
      },
      refetchQueries: () => [
        {
          query: GET_ALL_COUPON_TYPES_AS_ADMIN,
        },
      ],
    }
  );

  useEffect(() => {
    if (selectedCouponType) {
      setState(selectedCouponType);
    }
  }, [selectedCouponType]);

  function onChange(e, key) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function isSubmitDisabled() {
    return !state?.name || !state?.discount || loading;
  }

  function onSubmit() {
    const {
      id,
      name,
      discountType,
      discount,
      maximumDiscountAmount,
      minimumPrice,
    } = state;
    updateCouponTypeAsAdmin({
      variables: {
        couponTypeInput: {
          id: selectedCouponType ? selectedCouponType?.id : null,
          name,
          discountType,
          discount: Number(discount),
          maximumDiscountAmount: Number(maximumDiscountAmount),
          minimumPrice: Number(minimumPrice),
        },
      },
    });
  }

  return {
    models: {
      state,
    },
    operations: {
      onChange,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useUpdateCouponType;

import React from "react";
import { Flex } from "../../common/styles/commonStyles";
import OrderDeliveryFeeModal from "./components/OrderDeliveryFeeModal";
import OrderDetailModal from "./components/OrderDetailModal";
import OrderListNavBar from "./components/OrderListNavBar";
import OrderListTable from "./components/OrderListTable";
import useOrderListContainer from "./OrderListContainer.hook";

function OrderListContainer() {
  const {
    models: {
      activeOnly,
      selectedOrderId,
      searchQuery,
      searchedQuery,
      showDeliveryFeeModal,
    },
    operations: {
      toggleActiveOnly,
      onOrderSelect,
      onSearchChange,
      onSearchKeyDown,
      onSearch,
      toggleDeliveryFeeModal,
    },
  } = useOrderListContainer();

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        width="100%"
        height="100vh"
      >
        <OrderListNavBar
          activeOnly={activeOnly}
          searchQuery={searchQuery}
          onSearchChange={onSearchChange}
          onSearchKeyDown={onSearchKeyDown}
          onSearch={onSearch}
          toggleActiveOnly={toggleActiveOnly}
          toggleDeliveryFeeModal={toggleDeliveryFeeModal}
        />
        <OrderListTable
          activeOnly={activeOnly}
          searchedQuery={searchedQuery}
          onOrderSelect={onOrderSelect}
        />
      </Flex>
      {!!selectedOrderId && (
        <OrderDetailModal
          isOpen={!!selectedOrderId}
          selectedOrderId={selectedOrderId}
          onRequestClose={() => onOrderSelect(null)}
        />
      )}
      {showDeliveryFeeModal && (
        <OrderDeliveryFeeModal
          isOpen={showDeliveryFeeModal}
          onRequestClose={toggleDeliveryFeeModal}
        />
      )}
    </>
  );
}

export default OrderListContainer;

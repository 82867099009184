import React from "react";
import { AvatarImage, AvatarWrapper } from "./Avatar.styles";

function Avatar({ media, size, children }) {
  return (
    <AvatarWrapper>
      <AvatarImage src={media?.uri} size={size} />
      {!!children && children}
    </AvatarWrapper>
  );
}

export default Avatar;

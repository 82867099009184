import React from "react";
import { SALE_STATUS } from "../../../../utilities/constants";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../../common/styles/commonStyles";

function UpdateSaleSaleStatus({ status, onStatusChange }) {
  return (
    <Flex flex={1} flexDirection="column" alignItems="flex-start" gap={8}>
      <SectionTitle>판매 상태</SectionTitle>
      <Flex alignItems="center" gap={4}>
        <TextButton
          backgroundColor={status === SALE_STATUS.SALE ? "blue" : "gray"}
          onClick={() => onStatusChange(SALE_STATUS.SALE)}
        >
          판매
        </TextButton>
        <TextButton
          backgroundColor={
            status === SALE_STATUS.NOT_FOR_SALE ? "blue" : "gray"
          }
          onClick={() => onStatusChange(SALE_STATUS.NOT_FOR_SALE)}
        >
          비매
        </TextButton>
        <TextButton
          backgroundColor={status === SALE_STATUS.SOLDOUT ? "blue" : "gray"}
          onClick={() => onStatusChange(SALE_STATUS.SOLDOUT)}
        >
          Sold Out
        </TextButton>
      </Flex>
    </Flex>
  );
}

export default UpdateSaleSaleStatus;

import React from "react";
import { SALE_STATUS } from "../../../utilities/constants";
import LabeledInput from "../../common/components/LabeledInput";
import LabeledTextarea from "../../common/components/LabeledTextarea";
import Loading from "../../common/components/Loading";
import Modal from "../../common/components/Modal";
import Thumbnail from "../../common/components/Thumbnail";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../common/styles/commonStyles";
import UpdateSaleDeliveryOption from "./components/UpdateSaleDeliveryOption";
import UpdateSaleSaleStatus from "./components/UpdateSaleSaleStatus";
import UpdateSaleSearchBuyer from "./components/UpdateSaleSearchBuyer";
import useUpdateSaleModal from "./UpdateSaleModal.hook";

function UpdateSaleModal({ isOpen, selectedSaleId, onRequestClose }) {
  const {
    models: { loading, state, data },
    operations: {
      onTextChange,
      onBuyerChange,
      onStatusChange,
      onDeliveryOptionChange,
      onWarrantyChange,
      onSubmit,
    },
  } = useUpdateSaleModal(selectedSaleId, onRequestClose);

  const {
    price,
    note,
    hasWarranty,
    status,
    deliveryOption,
    bankName,
    accountName,
    accountNumber,
    saleMedia,
    buyer,
    buyerState,
  } = state;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title="판매 작품 관리"
    >
      {loading && <Loading />}
      {!loading && (
        <Flex flexDirection="column" width="100%" alignItems="flex-start">
          <Flex
            alignItems="flex-start"
            justifyContent="space-between"
            width="100%"
          >
            <Flex
              flex={1}
              flexDirection="column"
              width="100%"
              alignItems="flex-start"
              gap={8}
            >
              <SectionTitle>작품</SectionTitle>
              <Flex>
                <Thumbnail
                  media={data?.art?.primaryMedia}
                  width={200}
                  height={200}
                />
                <div>{data?.art?.titleKor}</div>
              </Flex>
            </Flex>

            <Flex
              flex={1}
              flexDirection="column"
              width="100%"
              alignItems="flex-start"
              gap={8}
            >
              <SectionTitle>판매자</SectionTitle>
              <Flex>
                <img
                  src={data?.seller?.avatar?.uri}
                  style={{
                    width: 200,
                    height: 200,
                    borderRadius: 100,
                    objectFit: "cover",
                  }}
                />
                <div>{data?.seller?.name}</div>
              </Flex>
            </Flex>
            {status !== SALE_STATUS.NOT_FOR_SALE && (
              <Flex
                flex={1}
                flexDirection="column"
                width="100%"
                alignItems="flex-start"
                gap={8}
              >
                <SectionTitle>구매자</SectionTitle>
                <UpdateSaleSearchBuyer
                  buyerState={buyerState}
                  buyer={buyer}
                  onBuyerChange={onBuyerChange}
                />
              </Flex>
            )}
          </Flex>
          <Flex width="100%" alignItems="flex-start" gap={8} marginTop={12}>
            <UpdateSaleSaleStatus
              status={status}
              onStatusChange={onStatusChange}
            />
          </Flex>
          {status !== SALE_STATUS.NOT_FOR_SALE && (
            <>
              <Flex width="100%" alignItems="flex-start" gap={8} marginTop={12}>
                <UpdateSaleDeliveryOption
                  deliveryOption={deliveryOption}
                  onDeliveryOptionChange={onDeliveryOptionChange}
                />
                <Flex flex={1}>
                  <LabeledInput
                    label="보증서 유무"
                    type="checkbox"
                    checked={hasWarranty}
                    onChange={onWarrantyChange}
                  />
                </Flex>
                <Flex flex={1}>
                  <LabeledInput
                    label="가격"
                    type="number"
                    value={price}
                    onChange={(e) => onTextChange(e, "price")}
                  />
                </Flex>
              </Flex>

              <Flex width="100%" alignItems="flex-start" gap={8} marginTop={12}>
                <Flex flex={1}>
                  <LabeledTextarea
                    label="판매 노트"
                    value={note}
                    onChange={(e) => onTextChange(e, "note")}
                  />
                </Flex>
              </Flex>

              <Flex width="100%" alignItems="flex-start" gap={8} marginTop={12}>
                <Flex flex={1}>
                  <LabeledInput
                    label="판매자 계좌명"
                    value={accountName}
                    onChange={(e) => onTextChange(e, "accountName")}
                  />
                </Flex>
                <Flex flex={1}>
                  <LabeledInput
                    label="은행명"
                    value={bankName}
                    onChange={(e) => onTextChange(e, "bankName")}
                  />
                </Flex>
                <Flex flex={1}>
                  <LabeledInput
                    label="판매자 계좌번호"
                    value={accountNumber}
                    onChange={(e) => onTextChange(e, "accountNumber")}
                  />
                </Flex>
              </Flex>
            </>
          )}
          <TextButton
            style={{ marginTop: 24, backgroundColor: "blue" }}
            onClick={() => onSubmit()}
          >
            수정
          </TextButton>
        </Flex>
      )}
    </Modal>
  );
}

export default UpdateSaleModal;

import { gql } from "@apollo/client";
import {
  PAGE_INFO_FIELD,
  REPORT_FIELD,
  REPORT_REASON_FIELD,
} from "./fragments";

export const GET_REPORTS_AS_ADMIN = gql`
  ${REPORT_FIELD}
  ${PAGE_INFO_FIELD}
  query GetReportsAsAdmin($type: ReportType!, $offset: Int, $limit: Int) {
    getReportsAsAdmin(type: $type, offset: $offset, limit: $limit) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...ReportField
      }
    }
  }
`;

export const GET_REPORT_REASONS = gql`
  ${REPORT_REASON_FIELD}
  query GetReportReasons($type: ReportType!) {
    getReportReasons(type: $type) {
      ...ReportReasonField
    }
  }
`;

export const UPDATE_REPORT_REASON_AS_ADMIN = gql`
  ${REPORT_REASON_FIELD}
  mutation UpdateReportReasonAsAdmin($reportReasonInput: ReportReasonInput!) {
    updateReportReasonAsAdmin(reportReasonInput: $reportReasonInput) {
      ...ReportReasonField
    }
  }
`;

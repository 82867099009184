import React from "react";
import { SALE_TYPES } from "../../../../utilities/constants";
import {
  Flex,
  SearchIcon,
  SearchInput,
  TextButton,
} from "../../../common/styles/commonStyles";

function SaleListNavBar({
  selectedSaleType,
  searchQuery,
  onSearchChange,
  onSearchKeyDown,
  onSearch,
  onSaleTypeCick,
}) {
  return (
    <Flex
      padding={12}
      justifyContent="space-between"
      width="100%"
      backgroundColor="rgba(0,0,0,0.1)"
    >
      <Flex gap={4}>
        {Object.keys(SALE_TYPES).map((key) => {
          const active = selectedSaleType === key;
          return (
            <TextButton
              key={key}
              fontSize={12}
              backgroundColor={active ? "black" : "white"}
              color={active ? "white" : "black"}
              onClick={() => onSaleTypeCick(key)}
            >
              {key}
            </TextButton>
          );
        })}
      </Flex>
      <Flex alignItems="center" gap={2}>
        <SearchInput
          placeholder="판매 ID"
          value={searchQuery}
          onChange={onSearchChange}
          onKeyDown={onSearchKeyDown}
        />
        <TextButton
          backgroundColor="white"
          width={24}
          height={24}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={onSearch}
        >
          <SearchIcon />
        </TextButton>
      </Flex>
    </Flex>
  );
}

export default SaleListNavBar;

import React from "react";
import Modal from "../../common/components/Modal";
import useUpdateBannerModal from "./UpdateBannerModal.hook";
import { Flex, TextButton } from "../../common/styles/commonStyles";
import LabeledInput from "../../common/components/LabeledInput";
import Thumbnail from "../../common/components/Thumbnail";

function UpdateBannerModal({ isOpen, selectedBanner, onRequestClose }) {
  const isNew = selectedBanner === "new";

  const {
    refs: { mediaInputRef },
    models: { state, loading },
    operations: {
      onInputChange,
      onMediaChange,
      onMediaChangeCancelClick,
      toggleActive,
      toggleSubscriberOnly,
      toggleIsFirst,
      isSubmitDisabled,
      onSubmit,
    },
  } = useUpdateBannerModal(selectedBanner, onRequestClose);

  const {
    id,
    externalUrl,
    mediaState,
    media,
    active,
    subscriberOnly,
    isFirst,
    sort,
  } = state;

  return (
    <Modal
      isOpen={isOpen}
      title={isNew ? "배너 등록" : "배너 수정"}
      onRequestClose={onRequestClose}
    >
      {!isNew && (
        <Flex>
          <LabeledInput label="ID" value={id} disabled />
        </Flex>
      )}

      <LabeledInput
        inputRef={mediaInputRef}
        label="이미지"
        type="file"
        accept="image/*"
        style={{
          visibility: media || mediaState ? "hidden" : "visible",
        }}
        onChange={onMediaChange}
      />
      {media && !mediaState && (
        <Flex alignItems="center" justifyContent="flex-start" gap={4}>
          <Thumbnail media={media}>
            <TextButton onClick={() => mediaInputRef?.current?.click()}>
              수정
            </TextButton>
          </Thumbnail>
        </Flex>
      )}
      {mediaState && (
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          gap={4}
          marginBottom={12}
        >
          <Thumbnail media={{ uri: URL.createObjectURL(mediaState) }}>
            <Flex gap={4}>
              <TextButton onClick={() => mediaInputRef?.current?.click()}>
                파일변경
              </TextButton>
              {media && (
                <TextButton
                  backgroundColor="red"
                  onClick={onMediaChangeCancelClick}
                >
                  수정 취소
                </TextButton>
              )}
            </Flex>
          </Thumbnail>
        </Flex>
      )}

      <LabeledInput
        label="URL"
        placeholder="https://bgaworks.com"
        value={externalUrl}
        onChange={(e) => onInputChange(e, "externalUrl")}
      />

      <LabeledInput
        label="순서"
        type="number"
        placeholder="안겹치게"
        value={sort}
        onChange={(e) => onInputChange(e, "sort")}
      />

      <LabeledInput
        label="화면 표시여부"
        type="checkbox"
        checked={active}
        onChange={(e) => toggleActive(e)}
      />

      <LabeledInput
        label="구독자 전용 여부"
        type="checkbox"
        checked={subscriberOnly}
        onChange={(e) => toggleSubscriberOnly(e)}
      />

      <LabeledInput
        label="구독 유치 배너 여부"
        type="checkbox"
        checked={isFirst}
        onChange={(e) => toggleIsFirst(e)}
      />

      <Flex marginTop={80}>
        <TextButton
          width={300}
          onClick={async () => await onSubmit()}
          disabled={isSubmitDisabled()}
        >
          {loading ? "등록중" : "등록하기"}
        </TextButton>
      </Flex>
    </Modal>
  );
}

export default UpdateBannerModal;

import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useReducer, useRef } from "react";

import imageCompression from "browser-image-compression";

import {
  GET_MARKETING,
  GET_MARKETINGS,
  UPDATE_MARKETING_AS_ADMIN,
} from "../../../lib/apollo/gql/marketingGqls";
import { COMPRESSION_OPTION } from "../../../utilities/constants";

export const UPDATE_MARKETING_ACTION_TYPES = {
  HANDLE_CATEGORY: "HANDLE_CATEGORY",
  HANDLE_TITLE: "HANDLE_TITLE",
  HANDLE_HIGHLIGHT: "HANDLE_HIGHLIGHT",
  HANDLE_BODY: "HANDLE_BODY",
  HANDLE_PRIMARY_MEDIA: "HANDLE_PRIMARY_MEDIA",

  SET_ALL: "SET_ALL",
};

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_MARKETING_ACTION_TYPES.HANDLE_CATEGORY: {
      return {
        ...state,
        category: action.payload,
      };
    }

    case UPDATE_MARKETING_ACTION_TYPES.HANDLE_TITLE: {
      return {
        ...state,
        title: action.payload,
      };
    }

    case UPDATE_MARKETING_ACTION_TYPES.HANDLE_HIGHLIGHT: {
      return {
        ...state,
        highlight: action.payload,
      };
    }

    case UPDATE_MARKETING_ACTION_TYPES.HANDLE_BODY: {
      return {
        ...state,
        body: action.payload,
      };
    }

    case UPDATE_MARKETING_ACTION_TYPES.HANDLE_PRIMARY_MEDIA: {
      return {
        ...state,
        primaryMediaState: action.payload,
      };
    }

    case UPDATE_MARKETING_ACTION_TYPES.SET_ALL: {
      return {
        ...state,
        ...action.payload,
      };
    }
  }
}

const initialState = {
  id: "",
  title: "",
  category: "",
  highlight: "",
  body: "",
  primaryMediaState: null,
  primaryMedia: null,
};

function useUpdateMarketingContainer(selectedMarketingId, onRequestClose) {
  const primaryMediaRef = useRef(null);

  const [state, dispatch] = useReducer(reducer, initialState);

  const [updateMarketingAsAdmin, { loading: updateLoading }] = useMutation(
    UPDATE_MARKETING_AS_ADMIN,
    {
      onCompleted: () => {
        onRequestClose();
      },
      refetchQueries: () => [
        {
          query: GET_MARKETINGS,
          variables: {
            offset: 0,
            limit: 10,
          },
        },
      ],
    }
  );

  const [getMarketing, { loading: marketingLoading }] = useLazyQuery(
    GET_MARKETING,
    {
      onCompleted: ({ getMarketing }) => {
        const { id, title, body, highlight, category, primaryMedia } =
          getMarketing;

        const payload = {
          id,
          title,
          body,
          highlight,
          category,
          primaryMedia,
        };

        dispatch({
          type: UPDATE_MARKETING_ACTION_TYPES.SET_ALL,
          payload,
        });
      },
    }
  );

  useEffect(() => {
    if (selectedMarketingId !== "new") {
      getMarketing({
        variables: {
          marketingId: selectedMarketingId,
        },
      });
    }
  }, [selectedMarketingId]);

  /**
   * Handle input values like title, highlight, body, category
   * @param {object} e
   * @param {string} type
   */
  function onInputChange(e, type) {
    const { value } = e.target;

    dispatch({
      type,
      payload: value,
    });
  }

  /**
   * Handle media input
   */
  function onMediaChange(e) {
    const { files } = e.target;

    const file = files[0];

    dispatch({
      type: UPDATE_MARKETING_ACTION_TYPES.HANDLE_PRIMARY_MEDIA,
      payload: file,
    });
  }

  function onMediaEditClick() {
    primaryMediaRef?.current?.click();
  }

  function onMediaChangeCancelClick() {
    dispatch({
      type: UPDATE_MARKETING_ACTION_TYPES.HANDLE_PRIMARY_MEDIA,
      payload: null,
    });
  }

  /**
   * Handle marketing body change
   * @param {string} value
   */
  function onBodyChange(value) {
    dispatch({
      type: UPDATE_MARKETING_ACTION_TYPES.HANDLE_BODY,
      payload: value,
    });
  }

  function isSubmitDisabled() {
    const {
      id,
      category,
      title,
      highlight,
      body,
      primaryMedia,
      primaryMediaState,
    } = state;

    const isCategoryDisabled = !category;
    const isTitleDisabled = !title;
    const isHighlightDisabled = !highlight;
    const isBodyDisabled = !body;
    const isPrimaryMediaDisabled = !primaryMedia && !primaryMediaState;

    return (
      isCategoryDisabled ||
      isTitleDisabled ||
      isHighlightDisabled ||
      isBodyDisabled ||
      isPrimaryMediaDisabled ||
      updateLoading
    );
  }

  async function onSubmit() {
    const { title, body, category, highlight, primaryMediaState } = state;

    let primaryMediaInput = null;

    if (primaryMediaState) {
      await imageCompression(primaryMediaState, COMPRESSION_OPTION).then(
        (file) => {
          primaryMediaInput = { file };
        }
      );
    }

    const marketingInput = {
      id: selectedMarketingId === "new" ? null : selectedMarketingId,
      title,
      body,
      highlight,
      category,
      primaryMediaInput,
    };

    await updateMarketingAsAdmin({
      variables: {
        marketingInput,
      },
    });
  }

  return {
    refs: {
      primaryMediaRef,
    },
    models: {
      state,
      updateLoading,
      marketingLoading,
    },
    operations: {
      onMediaChange,
      onMediaEditClick,
      onMediaChangeCancelClick,
      onInputChange,
      onBodyChange,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useUpdateMarketingContainer;

import { useLazyQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { useEffect, useReducer, useRef } from "react";

import imageCompression from "browser-image-compression";

import {
  GET_WEEKLIES,
  GET_WEEKLY,
  UPDATE_WEEKLY_AS_ADMIN,
} from "../../../lib/apollo/gql/weeklyGqls";
import { COMPRESSION_OPTION, LIMIT } from "../../../utilities/constants";

export const UPDATE_WEEKLY_MODAL_ACTION_TYPES = {
  HANDLE_WRITER: "HANDLE_WRITER",

  HANDLE_PRIMARY_MEDIA: "HANDLE_PRIMARY_MEDIA",

  HANDLE_TITLE: "HANDLE_TITLE",
  HANDLE_HIGHLIGHT: "HANDLE_HIGHLIGHT",
  HANDLE_DESCRIPTION: "HANDLE_DESCRIPTION",
  HANDLE_VOLUME: "HANDLE_VOLUME",

  HANDLE_BACKGROUND_COLOR: "HANDLE_BACKGROUND_COLOR",
  HANDLE_FONT_COLOR: "HANDLE_FONT_COLOR",
  HANDLE_RELEASE_DATE: "HANDLE_RELEASE_DATE",
  HANDLE_RELEASE_TIME: "HANDLE_RELEASE_TIME",
  HANDLE_CONTENTS: "HANDLE_CONTENTS",

  SET_ALL: "SET_ALL",
};

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_WRITER: {
      return {
        ...state,
        writer: action.payload,
      };
    }

    case UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_PRIMARY_MEDIA: {
      return {
        ...state,
        primaryMediaState: action.payload,
      };
    }

    case UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_TITLE: {
      return {
        ...state,
        title: action.payload,
      };
    }

    case UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_HIGHLIGHT: {
      return {
        ...state,
        highlight: action.payload,
      };
    }

    case UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_VOLUME: {
      return {
        ...state,
        volume: action.payload,
      };
    }

    case UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_DESCRIPTION: {
      return {
        ...state,
        description: action.payload,
      };
    }

    case UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_BACKGROUND_COLOR: {
      return {
        ...state,
        backgroundColor: action.payload,
      };
    }

    case UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_FONT_COLOR: {
      return {
        ...state,
        fontColor: action.payload,
      };
    }

    case UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_RELEASE_DATE: {
      return {
        ...state,
        releaseDate: action.payload,
      };
    }

    case UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_RELEASE_TIME: {
      return { ...state, releaseTime: action.payload };
    }

    case UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_CONTENTS: {
      return {
        ...state,
        contents: action.payload,
      };
    }

    case UPDATE_WEEKLY_MODAL_ACTION_TYPES.SET_ALL: {
      return {
        ...state,
        ...action.payload,
      };
    }
  }
}

const initialState = {
  id: "",
  writer: null,
  primaryMedia: null,
  primaryMediaState: null,
  title: "",
  highlight: "",
  description: "",
  backgroundColor: "",
  fontColor: "",
  volume: 0,
  releaseDate: moment().format("YYYY-MM-DD"),
  releaseTime: moment().format("HH:mm"),
  contents: [
    {
      content: null,
      releaseDate: moment().format("YYYY-MM-DD"),
      releaseTime: moment().format("HH:mm"),
    },
  ],
};

function useUpdateWeeklyModal(selectedWeeklyId, onRequestClose) {
  const primaryMediaRef = useRef(null);

  const [state, dispatch] = useReducer(reducer, initialState);

  const [updateWeeklyAsAdmin, { loading: updateLoading }] = useMutation(
    UPDATE_WEEKLY_AS_ADMIN,
    {
      onCompleted: (data) => {
        onRequestClose();
      },

      refetchQueries: () => [
        {
          query: GET_WEEKLIES,
          variables: {
            activeOnly: false,
            offset: 0,
            limit: LIMIT,
          },
        },
      ],
    }
  );

  const [getWeekly, { loading: weeklyLoading }] = useLazyQuery(GET_WEEKLY, {
    fetchPolicy: "network-only",
    onCompleted: ({ getWeekly }) => {
      if (selectedWeeklyId !== state?.id) {
        const {
          id,
          writer,
          primaryMedia,
          title,
          highlight,
          description,
          fontColor,
          backgroundColor,
          releaseAt,
          contents,
        } = getWeekly;

        const contentsPayload = [];

        contents
          ?.sort((a, b) => {
            if (moment(a.releaseAt).isBefore(b.releaseAt)) return -1;
            return 1;
          })
          .forEach((item) => {
            const { id, title, releaseAt } = item;
            contentsPayload.push({
              content: {
                value: id,
                label: title,
              },
              releaseDate: moment(releaseAt).format("YYYY-MM-DD"),
              releaseTime: moment(releaseAt).format("HH:mm"),
            });
          });

        const payload = {
          id,
          writer: { value: writer.id, label: writer?.user?.name },
          primaryMedia,
          title,
          highlight,
          description,
          fontColor,
          backgroundColor,
          releaseDate: moment(releaseAt).format("YYYY-MM-DD"),
          releaseTime: moment(releaseAt).format("HH:mm"),
          contents: contentsPayload,
        };

        dispatch({
          type: UPDATE_WEEKLY_MODAL_ACTION_TYPES.SET_ALL,
          payload,
        });
      }
    },
  });

  useEffect(() => {
    if (selectedWeeklyId && selectedWeeklyId !== "new") {
      getWeekly({
        variables: {
          weeklyId: selectedWeeklyId,
        },
      });
    }
  }, [selectedWeeklyId]);

  /**
   * Handle media input
   */
  function onMediaChange(e) {
    const { files } = e.target;

    const file = files[0];

    dispatch({
      type: UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_PRIMARY_MEDIA,
      payload: file,
    });
  }

  function onMediaEditClick() {
    primaryMediaRef?.current?.click();
  }

  function onMediaChangeCancelClick() {
    dispatch({
      type: UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_PRIMARY_MEDIA,
      payload: null,
    });
  }

  /**
   * Handle input values like title, highlight, description,
   * background color, font color, release date, and release time
   * @param {object} e
   * @param {string} type
   */
  function onInputChange(e, type) {
    const { value } = e.target;

    dispatch({
      type,
      payload: value,
    });
  }

  /**
   * Handle writer
   * @param {object} value
   */
  function onWriterSelect(value) {
    dispatch({
      type: UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_WRITER,
      payload: value,
    });
  }

  function onContentSelect(value, index, type) {
    let editedContents = [...state.contents];
    editedContents[index] = { ...editedContents[index], [type]: value };

    dispatch({
      type: UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_CONTENTS,
      payload: editedContents,
    });
  }

  /**
   * When add content button is clicked,
   * add a new set of content
   */
  function onAddContentClick() {
    const editedContents = [
      ...state.contents,
      {
        content: null,
        releaseDate: moment().format("YYYY-MM-DD"),
        releaseTime: moment().format("HH:mm"),
      },
    ];
    dispatch({
      type: UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_CONTENTS,
      payload: editedContents,
    });
  }

  function onDeleteContentClick(selectedIndex) {
    const editedContents = [...state.contents].filter(
      (content, index) => index !== selectedIndex
    );

    dispatch({
      type: UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_CONTENTS,
      payload: editedContents,
    });
  }

  function isSubmitDisabled() {
    const {
      writer,
      contents,
      primaryMedia,
      primaryMediaState,
      title,
      description,
      highlight,
      releaseDate,
      releaseTime,
      backgroundColor,
      fontColor,
    } = state;

    const isWriterDisabled = !writer;
    const isTitleDisabled = !title;
    const isHighlightDisabled = !highlight;
    const isDescriptionDisabled = !description;
    const isReleaseAtDisabled = !releaseDate || !releaseTime;
    const isBackgroundColorDisabled = !backgroundColor;
    const isFontColorDisabled = !fontColor;
    const isPrimaryMediaDisabled = !(primaryMedia || primaryMediaState);
    let isContentsDisabled = false;

    contents.forEach(({ content }) => {
      if (!content) {
        isContentsDisabled = true;
      }
    });

    return (
      isWriterDisabled ||
      isTitleDisabled ||
      isHighlightDisabled ||
      isDescriptionDisabled ||
      isReleaseAtDisabled ||
      isBackgroundColorDisabled ||
      isFontColorDisabled ||
      isPrimaryMediaDisabled ||
      isContentsDisabled ||
      updateLoading
    );
  }

  async function onSubmit() {
    const {
      writer,
      contents,
      primaryMediaState,
      title,
      description,
      highlight,
      releaseDate,
      releaseTime,
      backgroundColor,
      volume,
      fontColor,
    } = state;

    let primaryMediaInput = null;

    if (primaryMediaState) {
      await imageCompression(primaryMediaState, COMPRESSION_OPTION).then(
        (file) => {
          primaryMediaInput = { file };
        }
      );
    }

    const contentsInput = [];

    contents?.forEach((item) => {
      const { content, releaseDate, releaseTime } = item;
      const contentId = content.value;
      const releaseAt = moment(releaseDate + "T" + releaseTime).toISOString();
      contentsInput.push({ contentId, releaseAt });
    });

    const weeklyInput = {
      id: selectedWeeklyId !== "new" ? selectedWeeklyId : null,
      writerId: writer?.value,
      primaryMediaInput,
      title,
      description,
      highlight,
      backgroundColor,
      volume: Number(volume),
      fontColor,
      releaseAt: moment(releaseDate + "T" + releaseTime).toISOString(),
      contentsInput,
    };

    await updateWeeklyAsAdmin({
      variables: {
        weeklyInput,
      },
    });
  }

  return {
    refs: {
      primaryMediaRef,
    },
    models: {
      state,
      updateLoading,
      weeklyLoading,
    },
    operations: {
      onMediaChange,
      onMediaEditClick,
      onMediaChangeCancelClick,
      onInputChange,
      onWriterSelect,
      onContentSelect,
      onAddContentClick,
      onDeleteContentClick,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useUpdateWeeklyModal;

import React from "react";
import {
  Flex,
  SearchIcon,
  SearchInput,
  TextButton,
} from "../../../common/styles/commonStyles";

function OrderListNavBar({
  activeOnly,
  searchQuery,
  onSearchChange,
  onSearchKeyDown,
  onSearch,
  toggleActiveOnly,
  toggleDeliveryFeeModal,
}) {
  return (
    <Flex
      padding={12}
      justifyContent="space-between"
      width="100%"
      backgroundColor="rgba(0,0,0,0.1)"
    >
      <Flex alignItems="center" gap={4}>
        <TextButton
          backgroundColor={!activeOnly ? "blue" : "gray"}
          onClick={() => toggleActiveOnly(false)}
        >
          전체보기
        </TextButton>
        <TextButton
          backgroundColor={activeOnly ? "blue" : "gray"}
          onClick={() => toggleActiveOnly(true)}
        >
          진행중만보기
        </TextButton>
        <TextButton
          backgroundColor={"black"}
          onClick={() => window.location.reload()}
        >
          새로고침
        </TextButton>
        <TextButton backgroundColor={"black"} onClick={toggleDeliveryFeeModal}>
          배송비 관리
        </TextButton>
      </Flex>

      <Flex alignItems="center" gap={2}>
        <SearchInput
          placeholder="판매 ID"
          value={searchQuery}
          onChange={onSearchChange}
          onKeyDown={onSearchKeyDown}
        />
        <TextButton
          backgroundColor="white"
          width={24}
          height={24}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={onSearch}
        >
          <SearchIcon />
        </TextButton>
      </Flex>
    </Flex>
  );
}

export default OrderListNavBar;

import moment from "moment";
import React from "react";
import {
  SALE_DELIVERY_STATUS,
  SALE_DELIVERY_STATUS_TEXT,
  SALE_STATUS,
  SELLER_DELIVERY_OPTIONS_TEXT,
} from "../../../../utilities/constants";
import Loading from "../../../common/components/Loading";
import Pagination from "../../../common/components/Pagination";
import Thumbnail from "../../../common/components/Thumbnail";
import {
  Flex,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TextButton,
} from "../../../common/styles/commonStyles";
import { numberWithCommas } from "../../../common/utils/numberWithCommas";

function SaleListTable({
  data,
  loading,
  showPagination = true,
  offset,
  totalPageCount,
  onPageChange,
  changeSaleDeliveryStatus,
  onSaleSelect,
}) {
  return (
    <Flex
      flex={9}
      overflowY="auto"
      flexDirection="column"
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      padding={12}
    >
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell width="8%">ID</TableHeadCell>
            <TableHeadCell width="5%">세일상태</TableHeadCell>
            <TableHeadCell width="10%">작품 이미지</TableHeadCell>
            <TableHeadCell width="10%">작품명</TableHeadCell>
            <TableHeadCell width="15%">가격</TableHeadCell>
            <TableHeadCell width="10%">수수료</TableHeadCell>
            <TableHeadCell width="10%">정산금액</TableHeadCell>
            <TableHeadCell width="10%">배송옵션</TableHeadCell>
            <TableHeadCell width="10%">입고현황</TableHeadCell>
            <TableHeadCell width="5%">조회수</TableHeadCell>
            <TableHeadCell width="8%">게시일</TableHeadCell>
            <TableHeadCell width="8%">관리</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan="100%">
                <Loading />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data?.map((sale) => {
              if (!sale)
                return (
                  <tr>
                    <td colSpan="100%">판매작품을 찾을 수 없습니다</td>
                  </tr>
                );

              const {
                id,
                art,
                price,
                deliveryOption,
                createdAt,
                status,
                deliveryStatus,
                serviceFee,
                returnAmount,
              } = sale;

              const { primaryMedia, titleKor, titleEng, viewCount, claps } =
                art;
              return (
                <TableRow key={id}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{status}</TableCell>
                  <TableCell>
                    <Thumbnail width={100} height={100} media={primaryMedia} />
                  </TableCell>
                  <TableCell>
                    {titleKor}/{titleEng}
                  </TableCell>
                  <TableCell>{price && numberWithCommas(price)}</TableCell>
                  <TableCell>
                    {serviceFee && numberWithCommas(serviceFee)}
                  </TableCell>
                  <TableCell>
                    {returnAmount && numberWithCommas(returnAmount)}
                  </TableCell>
                  <TableCell>
                    {SELLER_DELIVERY_OPTIONS_TEXT[deliveryOption]}
                  </TableCell>
                  <TableCell>
                    {deliveryStatus && (
                      <select
                        defaultValue={deliveryStatus}
                        onChange={async (e) => {
                          await changeSaleDeliveryStatus(id, e.target?.value);
                        }}
                      >
                        {Object.keys(SALE_DELIVERY_STATUS).map((status) => (
                          <option value={status} key={status}>
                            {SALE_DELIVERY_STATUS_TEXT[status]}
                          </option>
                        ))}
                      </select>
                    )}
                  </TableCell>
                  <TableCell>{viewCount}</TableCell>
                  <TableCell>
                    {moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                  <TableCell>
                    <TextButton
                      disabled={
                        !(
                          status === SALE_STATUS.NOT_FOR_SALE ||
                          status === SALE_STATUS.SALE
                        )
                      }
                      onClick={() => onSaleSelect(id)}
                    >
                      관리
                    </TextButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </tbody>
        )}
      </Table>
      {showPagination && (
        <Pagination
          forcePage={offset}
          pageCount={totalPageCount}
          pageRangeDisplayed={5}
          previousLabel="Prev"
          nextLabel="Next"
          onPageChange={onPageChange}
        />
      )}
    </Flex>
  );
}

export default SaleListTable;

import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  GET_DELIVERY_FEE,
  UPDATE_DELIVERY_FEE_AS_ADMIN,
} from "../../../../lib/apollo/gql/orderGqls";

function useOrderDeliveryFeeModal() {
  const [fee, setFee] = useState(0);

  const { loading } = useQuery(GET_DELIVERY_FEE, {
    onCompleted: ({ getDeliveryFee }) => {
      setFee(getDeliveryFee?.fee || 0);
    },
  });

  const [updateDeliveryFeeAsAdmin, { loading: updateLoading }] = useMutation(
    UPDATE_DELIVERY_FEE_AS_ADMIN,
    {
      refetchQueries: () => [
        {
          query: GET_DELIVERY_FEE,
        },
      ],
    }
  );

  function onChange(e) {
    const { value } = e.target;

    setFee(value);
  }

  function onSubmit() {
    updateDeliveryFeeAsAdmin({
      variables: {
        fee,
      },
    });
  }

  return {
    models: {
      loading,
      fee,
      updateLoading,
    },
    operations: {
      onChange,
      onSubmit,
    },
  };
}

export default useOrderDeliveryFeeModal;

import { useQuery } from "@apollo/client";
import { GET_ALL_NOTICES_AS_ADMIN } from "../../../lib/apollo/gql/noticeGqls";

function useNoticeList() {
  const { data, loading, error } = useQuery(GET_ALL_NOTICES_AS_ADMIN);

  return {
    models: {
      data: data?.getAllNoticesAsAdmin,
      loading,
    },
  };
}

export default useNoticeList;

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useReducer, useRef } from "react";

import imageCompression from "browser-image-compression";

import {
  CHECK_ARTIST_REGISTER_ENABLE_AS_ADMIN,
  CHECK_SPACE_REGISTER_ENABLE_AS_ADMIN,
  CREATE_USER_PROFILE_AS_ADMIN,
  GET_USERS_BY_USER_TYPE_AS_ADMIN,
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE_AS_ADMIN,
} from "../../../lib/apollo/gql/userGqls";
import {
  COMPRESSION_OPTION,
  LIMIT,
  USER_TYPES_PARAMS,
} from "../../../utilities/constants";
import { firebaseSignUp } from "../../../lib/firebase";

export const UPDATE_USER_ACTION_TYPES = {
  HANDLE_EMAIL: "HANDLE_EMAIL",
  HANDLE_NAME: "HANDLE_NAME",
  HANDLE_SUMMARY: "HANDLE_SUMMARY",
  HANDLE_NAME_KOR: "HANDLE_NAME_KOR",
  HANDLE_NAME_ENG: "HANDLE_NAME_ENG",

  HANDLE_AVATAR: "HANDLE_AVATAR",

  ADD_WRITER_PROFILE: "ADD_WRITER_PROFILE",
  REMOVE_WRITER_PROFILE: "REMOVE_WRITER_PROFILE",
  HANDLE_WRITER_INTRODUCTION: "HANDLE_WRITER_INTRODUCTION",
  HANDLE_WRITER_JOB: "HANDLE_WRITER_JOB",
  HANDLE_WRITER_ACTIVE: "HANDLE_WRITER_ACTIVE",

  ADD_ARTIST_PROFILE: "ADD_ARTIST_PROFILE",
  REMOVE_ARTIST_PROFILE: "REMOVE_ARTIST_PROFILE",
  HANDLE_ARTIST_INTRODUCTION: "HANDLE_ARTIST_INTRODUCTION",
  HANDLE_ARTIST_ACTIVE: "HANDLE_ARTIST_ACTIVE",

  ADD_SPACE_PROFILE: "ADD_SPACE_PROFILE",
  REMOVE_SPACE_PROFILE: "REMOVE_SPACE_PROFILE",
  HANDLE_SPACE_INTRODUCTION: "HANDLE_SPACE_INTRODUCTION",
  HANDLE_SPACE_ACTIVE: "HANDLE_SPACE_ACTIVE",

  HANDLE_IS_ARTIST_REGISTER_ENABLE: "HANDLE_IS_ARTIST_REGISTER_ENABLE",
  HANDLE_IS_SPACE_REGISTER_ENABLE: "HANDLE_IS_SPACE_REGISTER_ENABLE",

  SET_ALL: "SET_ALL",
};

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_USER_ACTION_TYPES.HANDLE_EMAIL: {
      return {
        ...state,
        email: action.payload,
      };
    }

    case UPDATE_USER_ACTION_TYPES.HANDLE_NAME: {
      return {
        ...state,
        name: action.payload,
      };
    }

    case UPDATE_USER_ACTION_TYPES.HANDLE_SUMMARY: {
      return {
        ...state,
        summary: action.payload,
      };
    }

    case UPDATE_USER_ACTION_TYPES.HANDLE_NAME_KOR: {
      return {
        ...state,
        nameKor: action.payload,
      };
    }

    case UPDATE_USER_ACTION_TYPES.HANDLE_AVATAR: {
      return {
        ...state,
        avatarState: action.payload,
      };
    }

    case UPDATE_USER_ACTION_TYPES.HANDLE_NAME_ENG: {
      return {
        ...state,
        nameEng: action.payload,
      };
    }

    case UPDATE_USER_ACTION_TYPES.ADD_WRITER_PROFILE: {
      return {
        ...state,
        writer: {
          introduction: "",
          job: "",
          active: true,
        },
      };
    }

    case UPDATE_USER_ACTION_TYPES.REMOVE_WRITER_PROFILE: {
      return {
        ...state,
        writer: null,
      };
    }

    case UPDATE_USER_ACTION_TYPES.HANDLE_WRITER_INTRODUCTION: {
      return {
        ...state,
        writer: {
          ...state.writer,
          introduction: action.payload,
        },
      };
    }

    case UPDATE_USER_ACTION_TYPES.HANDLE_WRITER_JOB: {
      return {
        ...state,
        writer: {
          ...state.writer,
          job: action.payload,
        },
      };
    }

    case UPDATE_USER_ACTION_TYPES.HANDLE_WRITER_ACTIVE: {
      return {
        ...state,
        writer: {
          ...state.writer,
          active: action.payload,
        },
      };
    }

    case UPDATE_USER_ACTION_TYPES.ADD_ARTIST_PROFILE: {
      return {
        ...state,
        artist: {
          introduction: "",
          active: true,
        },
      };
    }

    case UPDATE_USER_ACTION_TYPES.REMOVE_ARTIST_PROFILE: {
      return {
        ...state,
        artist: null,
      };
    }

    case UPDATE_USER_ACTION_TYPES.HANDLE_ARTIST_INTRODUCTION: {
      return {
        ...state,
        artist: {
          ...state.artist,
          introduction: action.payload,
        },
      };
    }

    case UPDATE_USER_ACTION_TYPES.HANDLE_ARTIST_ACTIVE: {
      return {
        ...state,
        artist: {
          ...state.artist,
          active: action.payload,
        },
      };
    }

    case UPDATE_USER_ACTION_TYPES.HANDLE_IS_ARTIST_REGISTER_ENABLE: {
      return {
        ...state,
        isArtistRegisterEnable: action.payload,
      };
    }

    case UPDATE_USER_ACTION_TYPES.ADD_SPACE_PROFILE: {
      return {
        ...state,
        space: {
          introduction: "",
          active: true,
        },
      };
    }

    case UPDATE_USER_ACTION_TYPES.REMOVE_SPACE_PROFILE: {
      return {
        ...state,
        space: null,
      };
    }

    case UPDATE_USER_ACTION_TYPES.HANDLE_SPACE_INTRODUCTION: {
      return {
        ...state,
        space: {
          ...state.space,
          introduction: action.payload,
        },
      };
    }

    case UPDATE_USER_ACTION_TYPES.HANDLE_SPACE_ACTIVE: {
      return {
        ...state,
        space: {
          ...state.space,
          active: action.payload,
        },
      };
    }

    case UPDATE_USER_ACTION_TYPES.HANDLE_IS_SPACE_REGISTER_ENABLE: {
      return {
        ...state,
        isSpaceRegisterEnable: action.payload,
      };
    }

    case UPDATE_USER_ACTION_TYPES.SET_ALL: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

const initialState = {
  id: "",
  email: "",
  name: "",
  summary: "",
  nameKor: "",
  nameEng: "",
  avatar: null,
  avatarState: null,
  writer: null,
  artist: null,
  space: null,
  isRegisteredArtist: false,
  isRegisteredWriter: false,
  isRegisteredSpace: false,
  isArtistRegisterEnable: true,
  isSpaceRegisterEnable: true,
};

function useUpdateUserModal(selectedUserId, onRequestClose) {
  const avatarInputRef = useRef(null);

  const [state, dispatch] = useReducer(reducer, initialState);

  const [createUserProfileAsAdmin, { loading: createLoading }] = useMutation(
    CREATE_USER_PROFILE_AS_ADMIN,
    {
      onCompleted: () => {
        onRequestClose();
      },
      refetchQueries: () =>
        Object.values(USER_TYPES_PARAMS).map((value) => ({
          query: GET_USERS_BY_USER_TYPE_AS_ADMIN,
          variables: {
            type: value,
            offset: 0,
            limit: LIMIT,
          },
        })),
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const [updateUserProfileAsAdmin, { loading: updateLoading }] = useMutation(
    UPDATE_USER_PROFILE_AS_ADMIN,
    {
      onCompleted: () => {
        onRequestClose();
      },

      onError: (err) => {
        console.log(err);
      },
    }
  );

  const [checkArtistRegisterEnableAsAdmin] = useLazyQuery(
    CHECK_ARTIST_REGISTER_ENABLE_AS_ADMIN,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ checkArtistRegisterEnableAsAdmin }) => {
        dispatch({
          type: UPDATE_USER_ACTION_TYPES.HANDLE_IS_ARTIST_REGISTER_ENABLE,
          payload: checkArtistRegisterEnableAsAdmin,
        });
      },
    }
  );

  const [checkSpaceRegisterEnableAsAdmin] = useLazyQuery(
    CHECK_SPACE_REGISTER_ENABLE_AS_ADMIN,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ checkSpaceRegisterEnableAsAdmin }) => {
        dispatch({
          type: UPDATE_USER_ACTION_TYPES.HANDLE_IS_SPACE_REGISTER_ENABLE,
          payload: checkSpaceRegisterEnableAsAdmin,
        });
      },
    }
  );

  const [getUserProfile, { data, loading, error }] = useLazyQuery(
    GET_USER_PROFILE,
    {
      onCompleted: ({ getUserProfile }) => {
        const {
          id,
          email,
          name,
          summary,
          nameKor,
          nameEng,
          writer,
          artist,
          space,
          avatar,
        } = getUserProfile;

        dispatch({
          type: UPDATE_USER_ACTION_TYPES.SET_ALL,
          payload: {
            id,
            name,
            email,
            avatar,
            summary: summary || "",
            nameKor: nameKor || "",
            nameEng: nameEng || "",
            writer: writer
              ? {
                  introduction: writer?.introduction || "",
                  job: writer?.job || "",
                  active: writer?.active,
                }
              : null,
            artist: artist
              ? {
                  introduction: artist?.introduction || "",
                  active: artist?.active,
                }
              : null,
            space: space
              ? {
                  introduction: space?.introduction || "",
                  active: space?.active,
                }
              : null,
            isRegisteredArtist: artist ? true : false,
            isRegisteredSpace: space ? true : false,
            isRegisteredWriter: writer ? true : false,
          },
        });
      },
    }
  );

  useEffect(() => {
    if (selectedUserId && selectedUserId !== "new") {
      getUserProfile({
        variables: {
          id: selectedUserId,
        },
      });

      checkArtistRegisterEnableAsAdmin({
        variables: {
          userId: selectedUserId,
        },
      });

      checkSpaceRegisterEnableAsAdmin({
        variables: {
          userId: selectedUserId,
        },
      });
    }
  }, [selectedUserId]);

  /**
   * Input value handler
   */
  function onChange(e, type) {
    const { value } = e.target;

    dispatch({ type, payload: value });
  }

  function onAvatarChange(e) {
    const { files } = e.target;
    const file = files[0];

    dispatch({
      type: UPDATE_USER_ACTION_TYPES.HANDLE_AVATAR,
      payload: file,
    });
  }

  function onEditAvatarClick() {
    avatarInputRef?.current?.click();
  }

  function onAvatarChangeCancelClick() {
    dispatch({
      type: UPDATE_USER_ACTION_TYPES.HANDLE_AVATAR,
      payload: null,
    });

    if (avatarInputRef?.current) {
      avatarInputRef.current.value = null;
    }
  }

  function onRegisterClick(type) {
    dispatch({ type });
  }

  /**
   * When submit button is clicked,
   * update user profile
   */
  async function onSubmit() {
    const {
      id,
      name,
      email,
      summary,
      nameKor,
      nameEng,
      artist,
      writer,
      space,
      avatarState,
    } = state;

    const userInput = {
      email,
      name,
      summary,
      nameKor,
      nameEng,
    };

    if (avatarState) {
      const compressedFile = await imageCompression(
        avatarState,
        COMPRESSION_OPTION
      );

      userInput.avatarInput = { file: compressedFile };
    }

    const writerInput = writer;

    const artistInput = artist;

    const spaceInput = space;

    if (selectedUserId === "new") {
      createUserProfileAsAdmin({
        variables: {
          userInput,
          writerInput,
          artistInput,
          spaceInput,
        },
      });
    } else {
      updateUserProfileAsAdmin({
        variables: {
          userId: selectedUserId,
          userInput,
          writerInput,
          artistInput,
          spaceInput,
        },
      });
    }
  }

  return {
    refs: {
      avatarInputRef,
    },
    models: { loading, updateLoading, state, createLoading },
    operations: {
      onChange,
      onRegisterClick,
      onAvatarChange,
      onEditAvatarClick,
      onAvatarChangeCancelClick,
      onSubmit,
    },
  };
}

export default useUpdateUserModal;

import React from "react";
import HTMLEditor from "../../common/components/HTMLEditor";
import LabeledInput from "../../common/components/LabeledInput";
import Loading from "../../common/components/Loading";
import Modal from "../../common/components/Modal";
import SearchArtSelector from "../../common/components/SearchArtSelector";
import SearchUserSelector from "../../common/components/SearchUserSelector";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../common/styles/commonStyles";
import ContentTypeSelector from "./components/ContentTypeSelector";
import useUpdateContentModal, {
  UPDATE_CONTENT_MODAL_ACTION_TYPES,
} from "./UpdateContentModal.hook";

function UpdateContentModal({ isOpen, selectedContentId, onRequestClose }) {
  const isNew = selectedContentId === "new";

  const { models, operations } = useUpdateContentModal(
    selectedContentId,
    onRequestClose
  );

  const { state, updateLoading, contentLoading } = models;

  const {
    onInputChange,
    onContentTypeChange,
    onWriterSelect,
    onArtistSelect,
    onArtSelect,
    onBodyChange,
    isSubmitDisabled,
    onSubmit,
  } = operations;

  const { id, contentType, writer, artist, art, title, highlight, body } =
    state;

  return (
    <Modal
      isOpen={isOpen}
      title={isNew ? "컨텐츠 등록" : "컨텐츠 수정"}
      onRequestClose={onRequestClose}
    >
      {contentLoading && <Loading />}
      {!contentLoading && (
        <>
          <Flex justifyContent="space-between" gap={12}>
            {!isNew && (
              <Flex flex={1}>
                <LabeledInput label="ID" value={selectedContentId} disabled />
              </Flex>
            )}
          </Flex>
          <ContentTypeSelector
            contentType={contentType}
            onContentTypeChange={onContentTypeChange}
          />
          <SearchUserSelector
            userType="WRITER"
            title="작가 선택"
            placeholder="저자(작가)"
            value={writer}
            onSelectorChange={onWriterSelect}
          />
          {contentType === "INTERVIEW" && (
            <SearchUserSelector
              userType="ARTIST"
              title="아티스트 선택"
              placeholder="아티스트"
              value={artist}
              onSelectorChange={onArtistSelect}
            />
          )}
          <SearchArtSelector value={art} onSelectorChange={onArtSelect} />
          <LabeledInput
            label="컨텐츠 제목"
            placeholder="컨텐츠 제목"
            value={title}
            onChange={(e) =>
              onInputChange(e, UPDATE_CONTENT_MODAL_ACTION_TYPES.HANDLE_TITLE)
            }
          />
          <LabeledInput
            label="컨텐츠 하일라이트"
            placeholder="컨텐츠 하일라이트"
            value={highlight}
            onChange={(e) =>
              onInputChange(
                e,
                UPDATE_CONTENT_MODAL_ACTION_TYPES.HANDLE_HIGHLIGHT
              )
            }
          />
          <Flex width="100%" flexDirection="column" alignItems="flex-start">
            <SectionTitle>컨텐츠 본문</SectionTitle>
            <HTMLEditor value={body} onChange={onBodyChange} />
          </Flex>
          <Flex marginTop={80}>
            <TextButton
              width={300}
              onClick={async () => await onSubmit()}
              disabled={isSubmitDisabled()}
            >
              {updateLoading ? "등록중" : "등록하기"}
            </TextButton>
          </Flex>
        </>
      )}
    </Modal>
  );
}

export default UpdateContentModal;

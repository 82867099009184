import React from "react";
import { Flex } from "../../common/styles/commonStyles";
import UpdateArtHistoryModal from "../updateArtHistory/UpdateArtHistoryModal";
import useArtHistoryListContainer from "./ArtHistoryListContainer.hook";
import ArtHistoryListNavBar from "./components/ArtHistoryListNavBar";
import ArtHistoryTable from "./components/ArtHistoryTable";

function ArtHistoryListContainer() {
  const { models, operations } = useArtHistoryListContainer();

  const { selectedArtHistoryId, searchQuery, searchedQuery } = models;

  const { onSelectArtHistory, onSearchChange, onSearchKeyDown, onSearch } =
    operations;

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
    >
      <ArtHistoryListNavBar
        searchQuery={searchQuery}
        onSearchChange={onSearchChange}
        onSearchKeyDown={onSearchKeyDown}
        onSearch={onSearch}
        onSelectArtHistory={onSelectArtHistory}
      />

      <ArtHistoryTable
        searchedQuery={searchedQuery}
        onSelectArtHistory={onSelectArtHistory}
      />

      {!!selectedArtHistoryId && (
        <UpdateArtHistoryModal
          isOpen={!!selectedArtHistoryId}
          selectedArtHistoryId={selectedArtHistoryId}
          onRequestClose={() => onSelectArtHistory(null)}
        />
      )}
    </Flex>
  );
}

export default ArtHistoryListContainer;

import { useMutation, useQuery } from "@apollo/client";
import {
  CANCEL_SUBSCRIPTION_AS_ADMIN,
  GET_SUBSCRIPTION,
} from "../../../../lib/apollo/gql/subscriptionGqls";

function useSubscriptionDetailModal(selectedSubscriptionId) {
  const { data, loading, error } = useQuery(GET_SUBSCRIPTION, {
    variables: {
      subscriptionId: selectedSubscriptionId,
    },
  });

  const [cancelSubscriptionAsAdmin] = useMutation(CANCEL_SUBSCRIPTION_AS_ADMIN);

  function onCancelClick(subscriptionId) {
    cancelSubscriptionAsAdmin({
      variables: {
        subscriptionId,
      },
    });
  }

  return {
    models: {
      data: data?.getSubscription,
      loading,
    },
    operations: {
      onCancelClick,
    },
  };
}

export default useSubscriptionDetailModal;

import React from "react";
import AsyncSelector from "react-select/async";
import { Flex, SectionTitle } from "../styles/commonStyles";
import useSearchUserSelector from "./SearchUserSelector.hook";

function SearchUserSelector({
  userType,
  title,
  placeholder,
  value,
  onSelectorChange,
}) {
  const { operations } = useSearchUserSelector(userType);

  const { loadOptions } = operations;

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      gap={8}
      width="100%"
      marginBottom={12}
    >
      <SectionTitle>{title}</SectionTitle>
      <Flex width="100%" justifyContent="flex-start" gap={8}>
        <AsyncSelector
          placeholder={placeholder}
          loadOptions={loadOptions}
          value={value}
          onChange={(value) => onSelectorChange(value)}
          styles={{
            container: (style) => ({ ...style, flex: 1 }),
          }}
        />
      </Flex>
    </Flex>
  );
}

export default SearchUserSelector;

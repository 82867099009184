import React from "react";
import { CONTENT_TYPES } from "../../../../utilities/constants";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../../common/styles/commonStyles";

function ContentTypeSelector({ contentType, onContentTypeChange }) {
  return (
    <>
      <SectionTitle>컨텐츠 타입</SectionTitle>
      <Flex justifyContent="flex-start" gap={4} marginTop={8} marginBottom={12}>
        {Object.keys(CONTENT_TYPES).map((type) => {
          const isSelected = contentType === type;
          return (
            type !== "ALL" && (
              <TextButton
                key={type}
                fontSize={14}
                backgroundColor={isSelected ? "blue" : "white"}
                color={isSelected ? "white" : "black"}
                border="1px solid rgba(0,0,0,0.2)"
                onClick={() => onContentTypeChange(type)}
              >
                {CONTENT_TYPES[type]}
              </TextButton>
            )
          );
        })}
      </Flex>
    </>
  );
}

export default ContentTypeSelector;

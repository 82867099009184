import { gql } from "@apollo/client";
import { COUPON_FIELD, COUPON_TYPE_FIELD } from "./fragments";

export const UPDATE_COUPON_TYPE_AS_ADMIN = gql`
  ${COUPON_TYPE_FIELD}
  mutation UpdateCouponTypeAsAdmin($couponTypeInput: CouponTypeInput!) {
    updateCouponTypeAsAdmin(couponTypeInput: $couponTypeInput) {
      ...CouponTypeField
    }
  }
`;

export const UPDATE_COUPON_AS_ADMIN = gql`
  ${COUPON_FIELD}
  mutation UpdateCouponAsAdmin($couponInput: CouponInput!) {
    updateCouponAsAdmin(couponInput: $couponInput) {
      ...CouponField
    }
  }
`;

export const GET_ALL_COUPON_TYPES_AS_ADMIN = gql`
  ${COUPON_TYPE_FIELD}
  query GetAllCouponTypesAsAdmin {
    getAllCouponTypesAsAdmin {
      ...CouponTypeField
    }
  }
`;

export const GET_ALL_COUPONS_AS_ADMIN = gql`
  ${COUPON_FIELD}
  query GetAllCouponsAsAdmin {
    getAllCouponsAsAdmin {
      ...CouponField
    }
  }
`;

import { gql } from "@apollo/client";
import { MARKETING_FIELD, PAGE_INFO_FIELD } from "./fragments";

export const UPDATE_MARKETING_AS_ADMIN = gql`
  ${MARKETING_FIELD}
  mutation UpdateMarketingAsAdmin($marketingInput: MarketingInput!) {
    updateMarketingAsAdmin(marketingInput: $marketingInput) {
      ...MarketingField
    }
  }
`;

export const DELETE_MARKETING_AS_ADMIN = gql`
  mutation DeleteMarketingAsAdmin($marketingId: ID!) {
    deleteMarketingAsAdmin(marketingId: $marketingId)
  }
`;

export const GET_MARKETING = gql`
  ${MARKETING_FIELD}
  query GetMarketing($marketingId: ID!) {
    getMarketing(marketingId: $marketingId) {
      ...MarketingField
    }
  }
`;

export const GET_MARKETINGS = gql`
  ${PAGE_INFO_FIELD}
  ${MARKETING_FIELD}
  query GetMarketings($offset: Int, $limit: Int) {
    getMarketings(offset: $offset, limit: $limit) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...MarketingField
      }
    }
  }
`;

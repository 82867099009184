import { useLazyQuery, useQuery } from "@apollo/client";
import { useState } from "react";
import { SEARCH_CONTENTS } from "../../../../lib/apollo/gql/contentGqls";

function useWeeklyContentSelector() {
  const [searchContents] = useLazyQuery(SEARCH_CONTENTS);

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(async () => {
        const { data } = await searchContents({
          variables: {
            query: inputValue,
            type: null,
            activeOnly: false,
            offset: 0,
            limit: 100,
          },
        });

        const result = data?.searchContents?.edges;

        const options = result?.map((content) => ({
          value: content?.id,
          label: content?.title,
        }));

        resolve(options);
      }, 1);
    });

  return {
    operations: {
      loadOptions,
    },
  };
}

export default useWeeklyContentSelector;

import React from "react";
import { Flex, TextButton } from "../../../common/styles/commonStyles";

function MarketingListNavBar({ onMarketingSelect }) {
  return (
    <Flex
      padding={12}
      justifyContent="space-between"
      width="100%"
      backgroundColor="rgba(0,0,0,0.1)"
    >
      <TextButton
        backgroundColor="#df65dd"
        onClick={() => onMarketingSelect("new")}
      >
        등록
      </TextButton>
    </Flex>
  );
}

export default MarketingListNavBar;

import React from "react";
import { Flex } from "../../common/styles/commonStyles";
import UpdateArtModal from "../updateArt/UpdateArtModal";
import useArtsListContainer from "./ArtsListContainer.hook";
import ArtsListNavBar from "./components/ArtsListNavBar";
import ArtsListTable from "./components/ArtsListTable";

function ArtsListContainer() {
  const { models, operations } = useArtsListContainer();

  const { selectedArtId, searchQuery, searchedQuery } = models;

  const { onSelectArt, onSearchChange, onSearchKeyDown, onSearch } = operations;

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        width="100%"
        height="100vh"
      >
        <ArtsListNavBar
          searchQuery={searchQuery}
          onSearchChange={onSearchChange}
          onSearchKeyDown={onSearchKeyDown}
          onSearch={onSearch}
          onSelectArt={onSelectArt}
        />
        <ArtsListTable
          searchedQuery={searchedQuery}
          onSelectArt={onSelectArt}
        />
      </Flex>
      {!!selectedArtId && (
        <UpdateArtModal
          isOpen={!!selectedArtId}
          selectedArtId={selectedArtId}
          onRequestClose={() => onSelectArt(null)}
        />
      )}
    </>
  );
}

export default ArtsListContainer;

export const USER_TYPES = {
  ALL: "전체",
  ARTIST: "아티스트",
  WRITER: "작가",
  COLLECTOR: "콜렉터",
  SPACE: "스페이스",
};

export const USER_TYPES_PARAMS = {
  전체: "ALL",
  아티스트: "ARTIST",
  작가: "WRITER",
  콜렉터: "COLLECTOR",
  스페이스: "SPACE",
};

export const CONTENT_TYPES = {
  ALL: "전체",
  DOCENT: "DOCENT",
  INTERVIEW: "INTERVIEW",
  ESSAY: "ESSAY",
  ARTIST_VOICE: "ARTIST_VOICE",
};

export const COMPRESSION_OPTION = {
  maxSizeMB: 0.3,
};

export const SELLER_DELIVERY_OPTIONS = {
  UNDELIVERABLE: "UNDELIVERABLE",
  DELIVERABLE: "DELIVERABLE",
};

export const SELLER_DELIVERY_OPTIONS_TEXT = {
  UNDELIVERABLE: "택배 불가",
  DELIVERABLE: "택배 가능",
};

export const SALE_STATUS = {
  SALE: "SALE",
  NOT_FOR_SALE: "NOT_FOR_SALE",
  TRANSFERRING: "TRANSFERRING",
  SOLDOUT: "SOLDOUT",
};

export const SALE_STATUS_TEXT = {
  SALE: "판매",
  NOT_FOR_SALE: "비매",
};

export const SALE_TYPES = {
  STORE: "STORE",
  RESELL: "RESELL",
};

export const LIMIT = 20;

export const SUBSCRIPTION_STATUS = {
  ACTIVE: "ACTIVE",
  CANCEL_REQUESTED: "CANCEL_REQUESTED",
  CANCELLED: "CANCELLED",
  PAUSED: "PAUSED",
  EXPIRED: "EXPIRED",
};

export const DISCOUNT_TYPE = {
  PERCENTAGE: "PERCENTAGE",
  AMOUNT: "AMOUNT",
};

export const SALE_DELIVERY_STATUS = {
  PENDING_IN_HOUSE: "PENDING_IN_HOUSE",
  IN_HOUSE: "IN_HOUSE",
  PACKAGED: "PACKAGED",
  PENDING_PICKUP: "PENDING_PICKUP",
  PENDING_DELIVERY: "PENDING_DELIVERY",
  PENDING_CONFIRMATION: "PENDING_CONFIRMATION",
  DELIVERED: "DELIVERED",
};

export const SALE_DELIVERY_STATUS_TEXT = {
  PENDING_IN_HOUSE: "입고대기",
  IN_HOUSE: "입고완료",
  PACKAGED: "검수 및 패키징 완료",
  PENDING_PICKUP: "픽업 대기중",
  PENDING_DELIVERY: "배송 대기중",
  PENDING_CONFIRMATION: "구매학정 대기중",
  DELIVERED: "인도완료",
};

export const ORDER_STATUS = {
  PENDING: "PENDING",
  PAID: "PAID",
  CANCELLED: "CANCELLED",
  CONFIRMED: "CONFIRMED",
  CANCEL_REQUESTED: "CANCEL_REQUESTED",
};

export const ORDER_STATUS_TEXT = {
  PENDING: "입금대기중",
  PAID: "결제됨",
  CANCELLED: "취소됨",
  CONFIRMED: "구매확정",
  CANCEL_REQUESTED: "취소요청",
};

export const ORDER_DELIVERY_OPTIONS = {
  PICKUP: "PICKUP",
  DELIVERY: "DELIVERY",
  DIRECT_DELIVERY: "DIRECT_DELIVERY",
};

export const ORDER_DELIVERY_OPTIONS_TEXT = {
  PICKUP: "픽업",
  DELIVERY: "택배",
  DIRECT_DELIVERY: "운송차 배송",
};

export const SHIPPING_STATUS = {
  PENDING: "PENDING",
  PENDING_PICKUP: "PENDING_PICKUP",
  PENDING_DELIVERY: "PENDING_DELIVERY",
  ON_DELIVERY: "ON_DELIVERY",
  DELIVERED: "DELIVERED",
  CANCELLED: "CANCELLED",
};

export const SHIPPING_STATUS_TEXT = {
  PENDING: "없음",
  PENDING_PICKUP: "픽업대기중",
  PENDING_DELIVERY: "배송대기중",
  ON_DELIVERY: "배송중",
  DELIVERED: "배송완료",
  CANCELLED: "취소됨",
};

export const POLICY_TYPE = {
  TERMS: "TERMS",
  PRIVACY: "PRIVACY",
};

export const REPORT_TYPE = {
  NOTE: "NOTE",
  ART: "ART",
};

import { useMutation } from "@apollo/client";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  GET_ALL_COUPONS_AS_ADMIN,
  UPDATE_COUPON_AS_ADMIN,
} from "../../../../lib/apollo/gql/couponGqls";

const defaultState = {
  id: "",
  couponTypeId: "",
  remains: 0,
  expiresAt: moment().format("YYYY-MM-DD"),
};

function useUpdateCoupon(selectedCoupon) {
  const [state, setState] = useState(defaultState);

  const [updateCouponAsAdmin, { loading }] = useMutation(
    UPDATE_COUPON_AS_ADMIN,
    {
      onCompleted: () => {
        setState(defaultState);
      },
      refetchQueries: () => [
        {
          query: GET_ALL_COUPONS_AS_ADMIN,
        },
      ],
    }
  );

  useEffect(() => {
    if (selectedCoupon) {
      setState({
        id: selectedCoupon?.id,
        couponTypeId: selectedCoupon?.couponType?.id,
        remains: selectedCoupon?.remains,
        expiresAt: moment(selectedCoupon?.expiresAt).format("YYYY-MM-DD"),
      });
    }
  }, [selectedCoupon]);

  function onChange(e, type) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [type]: value,
    }));
  }

  function isSubmitDisabled() {
    return !state?.id || !state?.couponTypeId || loading;
  }

  function onSubmit() {
    updateCouponAsAdmin({
      variables: {
        couponInput: {
          id: selectedCoupon ? selectedCoupon?.id : state.id,
          couponTypeId: state.couponTypeId,
          remains: Number(state.remains),
          expiresAt: moment(state.expiresAt).toISOString(),
        },
      },
    });
  }

  return {
    models: {
      state,
    },
    operations: {
      onChange,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useUpdateCoupon;

import { useState } from "react";

function useOrderListContainer() {
  const [activeOnly, setActiveOnly] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [showDeliveryFeeModal, setShowDeliveryFeeModal] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchedQuery, setSearchedQuery] = useState("");

  function toggleActiveOnly(value) {
    setActiveOnly(value);
  }

  function onOrderSelect(id) {
    setSelectedOrderId(id);
  }

  function onSearchChange(e) {
    const { value } = e.target;

    setSearchQuery(value);
    if (value.length === 0 && searchedQuery) {
      setSearchedQuery("");
    }
  }

  function onSearchKeyDown(e) {
    const { key } = e;

    if (key === "Enter") {
      onSearch();
    }
  }

  function onSearch() {
    if (searchQuery !== searchedQuery) {
      setSearchedQuery(searchQuery);
    }
  }

  function toggleDeliveryFeeModal() {
    setShowDeliveryFeeModal(!showDeliveryFeeModal);
  }

  return {
    models: {
      activeOnly,
      selectedOrderId,
      searchQuery,
      searchedQuery,
      showDeliveryFeeModal,
    },
    operations: {
      toggleActiveOnly,
      onOrderSelect,
      onSearchChange,
      onSearchKeyDown,
      onSearch,
      toggleDeliveryFeeModal,
    },
  };
}

export default useOrderListContainer;

import { useLazyQuery, useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_ARTS, SEARCH_ARTS } from "../../../lib/apollo/gql/artGqls";

function useSearchArtSelector(oursOnly) {
  const [searchArts] = useLazyQuery(SEARCH_ARTS);

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(async () => {
        const { data } = await searchArts({
          variables: {
            query: inputValue,
            offset: 0,
            limit: 20,
          },
        });

        const result = data?.searchArts?.edges;

        const options = oursOnly
          ? result
              ?.filter((item) => !!item.artist)
              .map((art) => ({
                value: art?.id,
                label: `${art?.titleKor}(${art?.titleEng})`,
              }))
          : result?.map((art) => ({
              value: art?.id,
              label: `${art?.titleKor}(${art?.titleEng})`,
            }));

        resolve(options);
      }, 1);
    });

  return {
    operations: {
      loadOptions,
    },
  };
}

export default useSearchArtSelector;

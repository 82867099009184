import React from "react";
import Loading from "../../../common/components/Loading";
import Pagination from "../../../common/components/Pagination";
import { Flex } from "../../../common/styles/commonStyles";
import useArtReportList from "./ArtReportList.hook";
import ReportCard from "./ReportCard";

function ArtReportList() {
  const {
    models: { loading, data, offset, totalPageCount },
    operations: { onPageChange },
  } = useArtReportList();

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <Flex
          flexWrap="wrap"
          alignItems="stretch"
          gap={4}
          width="100%"
          justifyContent="flex-start"
        >
          {data?.map((report) => (
            <ReportCard key={report?.id} report={report} />
          ))}
        </Flex>
      )}

      <Pagination
        forcePage={offset}
        pageCount={totalPageCount}
        pageRangeDisplayed={5}
        previousLabel="Prev"
        nextLabel="Next"
        onPageChange={onPageChange}
      />
    </>
  );
}

export default ArtReportList;

import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  DELETE_CONTENT_AS_ADMIN,
  GET_CONTENTS_BY_TYPE,
  SEARCH_CONTENTS,
} from "../../../../lib/apollo/gql/contentGqls";
import { CONTENT_TYPES } from "../../../../utilities/constants";

const LIMIT = 10;

function useContentListTable(selectedContentType, searchedQuery) {
  const [offset, setOffset] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);

  const [
    getContentsByType,
    {
      data: contentsData,
      loading: contentsLoading,
      fetchMore: contentsFetchMore,
    },
  ] = useLazyQuery(GET_CONTENTS_BY_TYPE, {
    onCompleted: ({ getContentsByType }) => {
      const { pageInfo } = getContentsByType;

      const { totalPages } = pageInfo;
      setTotalPageCount(totalPages);
      setOffset(pageInfo.offset);
    },
  });

  const [
    searchContents,
    { data: searchData, loading: searchLoading, fetchMore: searchFetchMore },
  ] = useLazyQuery(SEARCH_CONTENTS, {
    onCompleted: ({ searchContents }) => {
      const { pageInfo } = searchContents;

      const { totalPages } = pageInfo;

      setTotalPageCount(totalPages);
      setOffset(pageInfo.offset);
    },
  });

  const [deleteContentAsAdmin] = useMutation(DELETE_CONTENT_AS_ADMIN, {
    refetchQueries: () => [
      {
        query: GET_CONTENTS_BY_TYPE,
        variables: {
          type:
            selectedContentType === CONTENT_TYPES.ALL
              ? null
              : selectedContentType,
          offset: 0,
          limit: 10,
        },
      },
    ],
  });

  useEffect(() => {
    if (searchedQuery) {
      searchContents({
        variables: {
          query: searchedQuery,
          type:
            selectedContentType === CONTENT_TYPES.ALL
              ? null
              : selectedContentType,
          activeOnly: false,
          offset: 0,
          limit: 10,
        },
      });
    } else {
      getContentsByType({
        variables: {
          type:
            selectedContentType === CONTENT_TYPES.ALL
              ? null
              : selectedContentType,
          activeOnly: false,
          sortOption: "CREATED",
          offset: 0,
          limit: 10,
        },
      });
    }
  }, [selectedContentType, searchedQuery]);

  /**
   *  When page changed from pagination
   *  Fetch data for the offset changed
   */
  async function onPageChange({ selected }) {
    const newOffset = selected;
    if (!fetchingMore) {
      setFetchingMore(true);
      if (searchedQuery) {
        await searchFetchMore({
          variables: {
            query: searchedQuery,
            type:
              selectedContentType === CONTENT_TYPES.ALL
                ? null
                : selectedContentType,
            offset: newOffset,
            limit: LIMIT,
          },
        });
      } else {
        await contentsFetchMore({
          variables: {
            type:
              selectedContentType === CONTENT_TYPES.ALL
                ? null
                : selectedContentType,
            offset: newOffset,
            limit: LIMIT,
          },
        });
      }
      setOffset(newOffset);
      setFetchingMore(false);
    }
  }

  async function onDeleteClick(id) {
    await deleteContentAsAdmin({
      variables: {
        contentId: id,
      },
    });
  }

  return {
    models: {
      data: searchedQuery
        ? searchData?.searchContents?.edges
        : contentsData?.getContentsByType?.edges,
      loading: contentsLoading || searchLoading || fetchingMore,
      offset,
      totalPageCount,
    },
    operations: {
      onPageChange,
      onDeleteClick,
    },
  };
}

export default useContentListTable;

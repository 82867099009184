import React from "react";
import { Flex } from "../common/styles/commonStyles";
import useBannerContainer from "./BannerContainer.hook";
import BannerNavBar from "./components/BannerNavBar";
import BannerTable from "./components/BannerTable";
import UpdateBannerModal from "./components/UpdateBannerModal";

function BannerContainer() {
  const {
    models: { selectedBanner },
    operations: { onBannerSelect },
  } = useBannerContainer();

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
    >
      <BannerNavBar onBannerSelect={onBannerSelect} />
      <BannerTable onBannerSelect={onBannerSelect} />

      {selectedBanner && (
        <UpdateBannerModal
          selectedBanner={selectedBanner}
          isOpen={!!selectedBanner}
          onRequestClose={() => onBannerSelect(null)}
        />
      )}
    </Flex>
  );
}

export default BannerContainer;

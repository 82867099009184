import moment from "moment";
import React from "react";
import Loading from "../../../common/components/Loading";
import Pagination from "../../../common/components/Pagination";
import Thumbnail from "../../../common/components/Thumbnail";
import {
  Flex,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TextButton,
} from "../../../common/styles/commonStyles";
import copyText from "../../../common/utils/copyText";
import useMarketingListTable from "./MarketingListTable.hook";

function MarketingListTable({ onMarketingSelect }) {
  const {
    models: { loading, data, offset, totalPageCount },
    operations: { onPageChange, onDeleteClick },
  } = useMarketingListTable();

  return (
    <Flex
      flex={9}
      overflowY="auto"
      flexDirection="column"
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      padding={12}
    >
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell width="8%">ID</TableHeadCell>
            <TableHeadCell width="15%">대표 이미지</TableHeadCell>
            <TableHeadCell width="10%">카테고리</TableHeadCell>
            <TableHeadCell width="10%">타이틀</TableHeadCell>
            <TableHeadCell width="15%">하일라이트</TableHeadCell>
            <TableHeadCell width="5%">조회수</TableHeadCell>
            <TableHeadCell width="8%">등록일</TableHeadCell>
            <TableHeadCell width="5%">수정</TableHeadCell>
            <TableHeadCell width="5%">삭제</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan="100%">
                <Loading />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data?.map((marketing) => {
              const {
                id,
                category,
                title,
                highlight,
                viewCount,
                primaryMedia,
                createdAt,
              } = marketing;

              return (
                <TableRow key={id}>
                  <TableCell onClick={async () => await copyText(id)}>
                    {id}
                  </TableCell>
                  <TableCell>
                    <Thumbnail width={100} height={100} media={primaryMedia} />
                  </TableCell>
                  <TableCell>{category}</TableCell>
                  <TableCell>{title}</TableCell>
                  <TableCell>{highlight}</TableCell>
                  <TableCell>{viewCount}</TableCell>
                  <TableCell>
                    {moment(createdAt).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell>
                    <TextButton
                      padding={4}
                      onClick={() => onMarketingSelect(id)}
                    >
                      수정
                    </TextButton>
                  </TableCell>
                  <TableCell>
                    <TextButton
                      padding={4}
                      backgroundColor="red"
                      onClick={async () => await onDeleteClick(id)}
                    >
                      삭제
                    </TextButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </tbody>
        )}
      </Table>
      <Pagination
        forcePage={offset}
        pageCount={totalPageCount}
        pageRangeDisplayed={5}
        previousLabel="Prev"
        nextLabel="Next"
        onPageChange={onPageChange}
      />
    </Flex>
  );
}

export default MarketingListTable;

import React from "react";
import { LabeledInputLabel, LabeledInputWrapper } from "./LabeledInput.styles";
import { LabeledTextareaTextarea } from "./LabeledTextarea.styles";

function LabeledTextarea({
  wrapperWidth = "100%",
  marginBottom = 12,
  label,
  ...rest
}) {
  return (
    <LabeledInputWrapper width={wrapperWidth} marginBottom={marginBottom}>
      <LabeledInputLabel>{label}</LabeledInputLabel>
      <LabeledTextareaTextarea {...rest} />
    </LabeledInputWrapper>
  );
}

export default LabeledTextarea;

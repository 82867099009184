import React from "react";
import { Flex } from "../../modules/common/styles/commonStyles";
import NavigationContainer from "../../modules/navigation/NavigationContainer";

function Layout({ children }) {
  return (
    <Flex
      width="100vw"
      height="100vh"
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Flex
        flex={1}
        maxWidth={250}
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        height="100%"
      >
        <NavigationContainer />
      </Flex>
      <Flex
        flex={5}
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {children}
      </Flex>
    </Flex>
  );
}

export default Layout;

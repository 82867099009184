import { useQuery } from "@apollo/client";
import { useState } from "react";
import {
  GET_ALL_ORDERS_AS_ADMIN,
  GET_ORDER_BY_ID_AS_ADMIN,
} from "../../../../lib/apollo/gql/orderGqls";
import { LIMIT } from "../../../../utilities/constants";

function useOrderListTable(activeOnly, searchedQuery) {
  const [offset, setOffset] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, error, fetchMore } = useQuery(
    !searchedQuery ? GET_ALL_ORDERS_AS_ADMIN : GET_ORDER_BY_ID_AS_ADMIN,
    {
      variables: !searchedQuery
        ? {
            offset,
            limit: LIMIT,
            activeOnly,
          }
        : {
            orderId: searchedQuery,
          },
      onCompleted: !searchedQuery
        ? ({ getAllOrdersAsAdmin }) => {
            const { pageInfo } = getAllOrdersAsAdmin;

            const { totalPages } = pageInfo;

            setTotalPageCount(totalPages);
            setOffset(pageInfo.offset);
          }
        : () => {
            setTotalPageCount(0);
            setOffset(0);
          },
    }
  );

  async function onPageChange({ selected }) {
    const newOffset = selected;
    if (!fetchingMore) {
      setFetchingMore(true);
      await fetchMore({
        variables: {
          offset: newOffset,
          limit: LIMIT,
          activeOnly,
        },
      });
      setOffset(newOffset);
      setFetchingMore(false);
    }
  }

  console.log(data, searchedQuery);

  return {
    models: {
      data: !searchedQuery
        ? data?.getAllOrdersAsAdmin?.edges
        : [data?.getOrderByIdAsAdmin],
      loading,
      offset,
      totalPageCount,
    },
    operations: {
      onPageChange,
    },
  };
}

export default useOrderListTable;

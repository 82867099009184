import React from "react";
import Avatar from "../../common/components/Avatar";
import LabeledInput from "../../common/components/LabeledInput";
import LabeledTextarea from "../../common/components/LabeledTextarea";
import Loading from "../../common/components/Loading";
import Modal from "../../common/components/Modal";
import { Flex, TextButton } from "../../common/styles/commonStyles";
import useUpdateUserModal, {
  UPDATE_USER_ACTION_TYPES,
} from "./UpdateUserModal.hook";
import { UpdateUserModalSectionTitle } from "./UpdateUserModal.styles";

function UpdateUserModal({ isOpen, selectedUserId, onRequestClose }) {
  const isNew = selectedUserId === "new";

  const { refs, models, operations } = useUpdateUserModal(
    selectedUserId,
    onRequestClose
  );

  const { avatarInputRef } = refs;

  const { loading, updateLoading, state, createLoading } = models;

  const {
    onChange,
    onRegisterClick,
    onAvatarChange,
    onEditAvatarClick,
    onAvatarChangeCancelClick,
    onSubmit,
  } = operations;

  const {
    id,
    email,
    name,
    summary,
    nameKor,
    nameEng,
    artist,
    writer,
    space,
    avatar,
    avatarState,
    isRegisteredArtist,
    isRegisteredWriter,
    isArtistRegisterEnable,
    isSpaceRegisterEnable,
  } = state;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={isNew ? "유저 등록" : "유저 정보 수정"}
    >
      {loading && <Loading />}
      {!loading && (
        <>
          <Flex justifyContent="space-between" gap={4} marginBottom={12}>
            {!isNew && (
              <Flex flex={1}>
                <LabeledInput label="ID" value={id} disabled={true} />
              </Flex>
            )}
            <Flex flex={1}>
              <LabeledInput
                label="이메일"
                value={email}
                onChange={(e) =>
                  onChange(e, UPDATE_USER_ACTION_TYPES.HANDLE_EMAIL)
                }
                disabled={!isNew}
              />
            </Flex>
            <Flex flex={1}>
              <LabeledInput
                label="이름"
                value={name}
                onChange={(e) =>
                  onChange(e, UPDATE_USER_ACTION_TYPES.HANDLE_NAME)
                }
              />
            </Flex>
          </Flex>
          <Flex alignItems="flex-start" flexDirection="column">
            <LabeledInput
              inputRef={avatarInputRef}
              label="프로필 사진"
              type="file"
              accept="image/*"
              style={{
                visibility: avatar || avatarState ? "hidden" : "visible",
              }}
              onChange={onAvatarChange}
            />
            {avatar && !avatarState && (
              <Avatar size={200} media={avatar}>
                <TextButton onClick={onEditAvatarClick}>수정</TextButton>
              </Avatar>
            )}
            {avatarState && (
              <Avatar
                size={200}
                media={{ uri: URL.createObjectURL(avatarState) }}
              >
                <Flex gap={4}>
                  <TextButton onClick={onEditAvatarClick}>수정</TextButton>
                  <TextButton
                    backgroundColor="red"
                    onClick={onAvatarChangeCancelClick}
                  >
                    수정 취소
                  </TextButton>
                </Flex>
              </Avatar>
            )}
          </Flex>
          <Flex marginBottom={12}>
            <LabeledTextarea
              label="summary"
              value={summary}
              onChange={(e) =>
                onChange(e, UPDATE_USER_ACTION_TYPES.HANDLE_SUMMARY)
              }
            />
          </Flex>
          {(!!artist || !!writer || !!space) && (
            <Flex justifyContent="space-between" gap={4} marginBottom={12}>
              <Flex flex={1}>
                <LabeledInput
                  label="한글이름"
                  value={nameKor}
                  onChange={(e) =>
                    onChange(e, UPDATE_USER_ACTION_TYPES.HANDLE_NAME_KOR)
                  }
                />
              </Flex>
              <Flex flex={1}>
                <LabeledInput
                  label="영문이름"
                  value={nameEng}
                  onChange={(e) =>
                    onChange(e, UPDATE_USER_ACTION_TYPES.HANDLE_NAME_ENG)
                  }
                />
              </Flex>
            </Flex>
          )}

          {/* WRITER_FIELD */}
          <Flex gap={16} marginBottom={12} justifyContent="flex-start">
            <UpdateUserModalSectionTitle>작가계정</UpdateUserModalSectionTitle>

            {!isRegisteredWriter && (
              <TextButton
                onClick={() =>
                  onRegisterClick(
                    !writer
                      ? UPDATE_USER_ACTION_TYPES.ADD_WRITER_PROFILE
                      : UPDATE_USER_ACTION_TYPES.REMOVE_WRITER_PROFILE
                  )
                }
              >
                {!writer ? "등록" : "제거"}
              </TextButton>
            )}
          </Flex>
          {!!writer && (
            <>
              <Flex marginBottom={8}>
                <LabeledInput
                  label="직업"
                  placeholder="작가 직업"
                  value={writer?.job}
                  onChange={(e) =>
                    onChange(e, UPDATE_USER_ACTION_TYPES.HANDLE_WRITER_JOB)
                  }
                />
              </Flex>
              <Flex marginBottom={12}>
                <LabeledTextarea
                  label="소개"
                  placeholder="작가 소개"
                  value={writer?.introduction}
                  onChange={(e) =>
                    onChange(
                      e,
                      UPDATE_USER_ACTION_TYPES.HANDLE_WRITER_INTRODUCTION
                    )
                  }
                />
              </Flex>
            </>
          )}

          {/* ARTIST_FIELD */}
          <Flex gap={16} marginBottom={12} justifyContent="flex-start">
            <UpdateUserModalSectionTitle>
              아티스트 계정 {!isArtistRegisterEnable && "등록 불가"}
            </UpdateUserModalSectionTitle>

            {!isRegisteredArtist && isArtistRegisterEnable && (
              <TextButton
                onClick={() =>
                  onRegisterClick(
                    !artist
                      ? UPDATE_USER_ACTION_TYPES.ADD_ARTIST_PROFILE
                      : UPDATE_USER_ACTION_TYPES.REMOVE_ARTIST_PROFILE
                  )
                }
              >
                {!artist ? "등록" : "제거"}
              </TextButton>
            )}
          </Flex>
          {!!artist && (
            <>
              <Flex marginBottom={40}>
                <LabeledTextarea
                  label="소개"
                  placeholder="아티스트 소개"
                  value={artist?.introduction}
                  onChange={(e) =>
                    onChange(
                      e,
                      UPDATE_USER_ACTION_TYPES.HANDLE_ARTIST_INTRODUCTION
                    )
                  }
                />
              </Flex>
            </>
          )}

          {/* SPACE */}
          <Flex gap={16} marginBottom={12} justifyContent="flex-start">
            <UpdateUserModalSectionTitle>
              스페이스 계정 {!isSpaceRegisterEnable && "등록 불가"}
            </UpdateUserModalSectionTitle>

            {!isRegisteredArtist && isSpaceRegisterEnable && (
              <TextButton
                onClick={() =>
                  onRegisterClick(
                    !space
                      ? UPDATE_USER_ACTION_TYPES.ADD_SPACE_PROFILE
                      : UPDATE_USER_ACTION_TYPES.REMOVE_SPACE_PROFILE
                  )
                }
              >
                {!space ? "등록" : "제거"}
              </TextButton>
            )}
          </Flex>
          {!!space && (
            <>
              <Flex marginBottom={40}>
                <LabeledTextarea
                  label="소개"
                  placeholder="스페이스 소개"
                  value={space?.introduction}
                  onChange={(e) =>
                    onChange(
                      e,
                      UPDATE_USER_ACTION_TYPES.HANDLE_SPACE_INTRODUCTION
                    )
                  }
                />
              </Flex>
            </>
          )}
          <Flex>
            <TextButton
              backgroundColor="blue"
              onClick={onSubmit}
              disabled={createLoading || updateLoading}
            >
              {selectedUserId === "new"
                ? createLoading
                  ? "등록 중"
                  : "등록하기"
                : updateLoading
                ? "수정 중"
                : "수정하기"}
            </TextButton>
          </Flex>
        </>
      )}
    </Modal>
  );
}

export default UpdateUserModal;

import { gql } from "@apollo/client";

export const MEDIA_FIELD = gql`
  fragment MediaField on Media {
    id
    name
    uri
    size
    type
    createdAt
    thumbnailUri
  }
`;

export const WRITER_FIELD = gql`
  fragment WriterField on Writer {
    id
    job
    user {
      id
      name
      email
      nameKor
      nameEng
    }
    introduction
    active
    createdAt
    updatedAt
  }
`;

export const ARTIST_FIELD = gql`
  fragment ArtistField on Artist {
    id
    user {
      id
      name
      email
      nameKor
      nameEng
    }
    active
    introduction
    createdAt
    updatedAt
  }
`;

export const SPACE_FIELD = gql`
  fragment SpaceField on Space {
    id
    user {
      id
      name
      email
      nameKor
      nameEng
    }
    active
    introduction
    createdAt
    updatedAt
  }
`;

export const USER_FIELD = gql`
  ${WRITER_FIELD}
  ${ARTIST_FIELD}
  ${SPACE_FIELD}
  ${MEDIA_FIELD}
  fragment UserField on User {
    id
    email
    name
    summary
    avatar {
      ...MediaField
    }
    nameKor
    nameEng
    writer {
      ...WriterField
    }
    artist {
      ...ArtistField
    }
    space {
      ...SpaceField
    }
    isSubscriber
    subscriptions {
      id
      subscriptionPlan {
        id
        name
        trialDays
        monthlyPrice
        duration
        totalPrice
        finalPrice
        isHot
        active
        createdAt
        updatedAt
      }
      from
      to
      nextPayAt
      status
      productId
      createdAt
      updatedAt
    }
    collection {
      id
      artsCount
      viewCount
      claps
    }
    isCollector
    requestSaleCount

    createdAt
    updatedAt
  }
`;

export const PAGE_INFO_FIELD = gql`
  fragment PageInfoField on OffsetPageInfo {
    totalPages
    totalCounts
    offset
    limit
  }
`;

export const COLOR_FIELD = gql`
  fragment ColorField on Color {
    id
  }
`;

export const SEARCH_MATERIAL_FIELD = gql`
  fragment SearchMaterialField on SearchMaterial {
    id
    material
    order
    active
  }
`;

export const SEARCH_CATEGORY_FIELD = gql`
  fragment SearchCategoryField on SearchCategory {
    id
    category
    order
    active
  }
`;

export const BANK_ACCOUNT_FIELD = gql`
  fragment BankAccountField on BankAccount {
    id
    accountName
    bankName
    accountNumber
  }
`;

export const COUPON_TYPE_FIELD = gql`
  fragment CouponTypeField on CouponType {
    id
    name
    discountType
    discount
    maximumDiscountAmount
    minimumPrice
    createdAt
  }
`;

export const COUPON_FIELD = gql`
  ${COUPON_TYPE_FIELD}
  fragment CouponField on Coupon {
    id
    couponType {
      ...CouponTypeField
    }
    remains
    expiresAt
    createdAt
  }
`;

export const SALE_FIELD = gql`
  ${USER_FIELD}
  ${MEDIA_FIELD}
  ${BANK_ACCOUNT_FIELD}
  fragment SaleField on Sale {
    id
    type
    art {
      id
      titleKor
      titleEng
      category
      primaryMedia {
        ...MediaField
      }
      viewCount
      claps
    }
    seller {
      ...UserField
    }
    buyer {
      ...UserField
    }
    price
    note
    deliveryOption
    deliveryStatus
    hasWarranty
    status
    saleMedia {
      ...MediaField
    }
    bankAccount {
      ...BankAccountField
    }
    serviceFee
    returnAmount
    createdAt
    updatedAt
  }
`;

export const SHIPPING_FIELD = gql`
  fragment ShippingField on Shipping {
    id
    order {
      id
    }
    status
    deliveryCode
    deliveryCompany
    name
    phone
    address
    createdAt
    updatedAt
  }
`;

export const ORDER_FIELD = gql`
  ${USER_FIELD}
  ${COUPON_FIELD}
  ${SALE_FIELD}
  ${SHIPPING_FIELD}
  fragment OrderField on Order {
    id
    user {
      ...UserField
    }
    coupon {
      ...CouponField
    }
    point
    status
    initialAmount
    finalAmount
    payMethod
    paidAt
    cancelledAt
    cancelReason
    deliveryOption
    saleItems {
      ...SaleField
    }
    shipping {
      ...ShippingField
    }
    createdAt
    updatedAt
  }
`;

export const ART_FIELD = gql`
  ${ARTIST_FIELD}
  ${SPACE_FIELD}
  ${SEARCH_MATERIAL_FIELD}
  ${SEARCH_CATEGORY_FIELD}
  ${MEDIA_FIELD}
  ${USER_FIELD}
  ${SALE_FIELD}
  fragment ArtField on Art {
    id
    type
    category
    artist {
      ...ArtistField
    }
    space {
      ...SpaceField
    }
    artistNameKor
    artistNameEng
    titleKor
    titleEng
    materialKor
    materialEng
    width
    height
    depth
    year
    tag
    primaryMedia {
      ...MediaField
    }
    media {
      ...MediaField
    }
    audios {
      ...MediaField
    }
    edition
    owner {
      ...UserField
    }
    searchColors {
      id
    }
    searchMaterials {
      ...SearchMaterialField
    }
    searchCategory {
      ...SearchCategoryField
    }
    artistNote
    collectorNote
    viewCount
    claps
    sales {
      ...SaleField
    }
    createdAt
    updatedAt
    deletedAt
  }
`;

export const CONTENT_FIELD = gql`
  ${WRITER_FIELD}
  ${ARTIST_FIELD}
  ${ART_FIELD}
  fragment ContentField on Content {
    id
    type
    writer {
      ...WriterField
    }
    artist {
      ...ArtistField
    }
    art {
      ...ArtField
    }
    title
    body
    highlight
    releaseAt
    viewCount
    claps
    createdAt
    updatedAt
  }
`;

export const ART_HISTORY_FIELD = gql`
  ${WRITER_FIELD}
  ${ART_FIELD}
  ${MEDIA_FIELD}
  fragment ArtHistoryField on ArtHistory {
    id
    art {
      ...ArtField
    }
    writer {
      ...WriterField
    }
    theme
    paringMedia {
      ...MediaField
    }
    title
    highlight
    body
    order
    viewCount
    claps
    createdAt
    updatedAt
  }
`;

export const WEEKLY_FIELD = gql`
  ${WRITER_FIELD}
  ${MEDIA_FIELD}
  ${CONTENT_FIELD}
  fragment WeeklyField on Weekly {
    id
    writer {
      ...WriterField
    }
    primaryMedia {
      ...MediaField
    }
    contents {
      ...ContentField
    }
    title
    description
    highlight
    backgroundColor
    fontColor
    viewCount
    volume
    claps
    releaseAt
    createdAt
    updatedAt
  }
`;

export const EXHIBITION_FIELD = gql`
  ${MEDIA_FIELD}
  ${ARTIST_FIELD}
  ${ART_FIELD}
  fragment ExhibitionField on Exhibition {
    id
    title
    introduction
    from
    to
    director
    host
    sponsor
    primaryMedia {
      ...MediaField
    }
    backgroundMedia {
      ...MediaField
    }
    fontColor
    arts {
      ...ArtField
    }
    artists {
      ...ArtistField
    }
    claps
    viewCount
    createdAt
    updatedAt
  }
`;

export const ADMIN_PHRASE_FIELD = gql`
  fragment AdminPhraseField on AdminPhrase {
    id
    editorial
    docent
    interview
    essay
    weekly
    artHistory
    notes
  }
`;

export const FOOTER_INFORMATION_FIELD = gql`
  fragment FooterInformationField on FooterInformation {
    id
    copyrightMain
    copyright
    email
    ceoName
    address
    registrationNumber
    escroRegistration
    phone
    workingHour
    instagramUrl
    subscriberMessage
    subscriberMessageUrl
  }
`;

export const MARKETING_FIELD = gql`
  ${MEDIA_FIELD}
  fragment MarketingField on Marketing {
    id
    primaryMedia {
      ...MediaField
    }
    title
    category
    highlight
    body
    viewCount
    createdAt
    updatedAt
  }
`;

export const NOTICE_FIELD = gql`
  ${MARKETING_FIELD}
  fragment NoticeField on Notice {
    id
    marketing {
      ...MarketingField
    }
    active
    createdAt
    updatedAt
  }
`;

export const BILLING_FIELD = gql`
  ${USER_FIELD}
  fragment BillingField on Billing {
    id
    user {
      ...UserField
    }
    cardName
    cardNumber
    pgProvider
    customerUid
    createdAt
    updatedAt
  }
`;

export const SUBSCRIPTION_PLAN_FIELD = gql`
  fragment SubscriptionPlanField on SubscriptionPlan {
    id
    name
    trialDays
    monthlyPrice
    duration
    totalPrice
    finalPrice
    isHot
    active
    createdAt
    updatedAt
  }
`;

export const SUBSCRIPTION_PAYMENT_FIELD = gql`
  ${USER_FIELD}
  ${SUBSCRIPTION_PLAN_FIELD}
  ${BILLING_FIELD}
  fragment SubscriptionPaymentField on SubscriptionPayment {
    id
    user {
      ...UserField
    }
    subscriptionPlan {
      ...SubscriptionPlanField
    }
    billing {
      ...BillingField
    }
    merchantUid
    amount
    status
    createdAt
    updatedAt
  }
`;

export const SUBSCRIPTION_FIELD = gql`
  ${USER_FIELD}
  ${SUBSCRIPTION_PLAN_FIELD}
  ${SUBSCRIPTION_PAYMENT_FIELD}
  fragment SubscriptionField on Subscription {
    id
    user {
      ...UserField
    }
    subscriptionPlan {
      ...SubscriptionPlanField
    }
    from
    to
    nextPayAt
    status
    productId
    subscriptionPayments {
      ...SubscriptionPaymentField
    }
    createdAt
    updatedAt
  }
`;

export const PAYMENT_FIELD = gql`
  ${SALE_FIELD}
  ${ORDER_FIELD}
  fragment PaymentField on Payment {
    id
    sale {
      ...SaleField
    }
    order {
      ...OrderField
    }
    paid
    price
    serviceFee
    amount
    paidAt
    createdAt
    updatedAt
  }
`;

export const COLLECTION_FIELD = gql`
  ${MEDIA_FIELD}
  ${USER_FIELD}
  ${ART_FIELD}
  fragment CollectionField on Collection {
    id
    backgroundColor
    backgroundMedia {
      ...MediaField
    }
    fontColor
    viewCount
    claps
    clapped
    user {
      ...UserField
    }
    arts {
      ...ArtField
    }
    noteCount
  }
`;

export const NOTE_FIELD = gql`
  ${ART_FIELD}
  ${USER_FIELD}
  ${CONTENT_FIELD}
  ${WEEKLY_FIELD}
  ${ART_HISTORY_FIELD}
  ${COLLECTION_FIELD}
  ${EXHIBITION_FIELD}
  fragment NoteField on Note {
    id
    sender {
      ...UserField
    }
    art {
      ...ArtField
    }
    content {
      ...ContentField
    }
    weekly {
      ...WeeklyField
    }
    collection {
      ...CollectionField
    }
    exhibition {
      ...ExhibitionField
    }
    artHistory {
      ...ArtHistoryField
    }
    replies {
      id
      sender {
        ...UserField
      }
      parentNote {
        id
        body
      }
      body
      claps
      clapped
      createdAt
      updatedAt
    }
    parentNote {
      id
      body
    }
    receiver {
      ...UserField
    }
    body
    claps
    clapped
    createdAt
    updatedAt
  }
`;

export const REPORT_REASON_FIELD = gql`
  fragment ReportReasonField on ReportReason {
    id
    reason
    type
  }
`;

export const REPORT_FIELD = gql`
  ${REPORT_REASON_FIELD}
  ${USER_FIELD}
  ${NOTE_FIELD}
  fragment ReportField on Report {
    id
    user {
      ...UserField
    }
    reportReason {
      ...ReportReasonField
    }
    note {
      ...NoteField
    }
    art {
      id
      titleKor
      titleEng
      artist {
        id
        user {
          id
          name
        }
      }
    }
    reason
    createdAt
  }
`;

export const QUESTION_FIELD = gql`
  ${USER_FIELD}
  fragment QuestionField on Question {
    id
    user {
      ...UserField
    }
    title
    email
    question
    createdAt
  }
`;

import moment from "moment";
import React from "react";
import Loading from "../../../common/components/Loading";
import {
  Flex,
  SectionTitle,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TextButton,
} from "../../../common/styles/commonStyles";
import useCouponList from "./CouponList.hook";

function CouponList({ onCouponSelect }) {
  const {
    models: { data, loading },
  } = useCouponList();

  if (loading) return <Loading />;

  return (
    <Flex
      flex={1.5}
      borderBottom="1px solid black"
      width="100%"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      padding={12}
      overflowY="auto"
    >
      <SectionTitle>쿠폰</SectionTitle>
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell width="10%">이름</TableHeadCell>
            <TableHeadCell width="10%">쿠폰타입</TableHeadCell>
            <TableHeadCell width="10%">남은수량</TableHeadCell>
            <TableHeadCell width="10%">만료일</TableHeadCell>
            <TableHeadCell width="8%">수정</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <tbody>
          {data?.map((coupon) => {
            const { id, couponType, remains, expiresAt } = coupon;

            return (
              <TableRow key={id}>
                <TableCell>{id}</TableCell>
                <TableCell>
                  {couponType?.id}
                  <br />
                  <strong>{couponType?.name}</strong>
                </TableCell>
                <TableCell>{remains}</TableCell>
                <TableCell>{moment(expiresAt).format("YYYY-MM-DD")}</TableCell>
                <TableCell>
                  <TextButton onClick={() => onCouponSelect(coupon)}>
                    수정
                  </TextButton>
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </Flex>
  );
}

export default CouponList;

import React from "react";
import { Flex } from "../../common/styles/commonStyles";
import CouponTypeList from "./components/CouponTypeList";
import UpdateCouponType from "./components/UpdateCouponType";
import useCouponTypeContainer from "./CouponTypeContainer.hook";

function CouponTypeContainer() {
  const {
    models: { selectedCouponType },
    operations: { onCouponTypeSelect },
  } = useCouponTypeContainer();

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      flex={1}
      height="100vh"
      borderRight="1px solid black"
    >
      <CouponTypeList onCouponTypeSelect={onCouponTypeSelect} />
      <UpdateCouponType selectedCouponType={selectedCouponType} />
    </Flex>
  );
}

export default CouponTypeContainer;

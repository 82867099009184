import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  DELETE_ART,
  GET_ARTS,
  SEARCH_ARTS,
} from "../../../../lib/apollo/gql/artGqls";

const LIMIT = 10;

function useArtsListTable(searchedQuery) {
  const [offset, setOffset] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);

  const [
    getArts,
    { data: artsData, loading: artsLoading, fetchMore: artsFetchMore },
  ] = useLazyQuery(GET_ARTS, {
    fetchPolicy: "network-only",
    onCompleted: ({ getArts }) => {
      const { pageInfo } = getArts;

      const { totalPages } = pageInfo;

      setTotalPageCount(totalPages);
      setOffset(pageInfo.offset);
    },
  });

  const [
    searchArts,
    { data: searchData, loading: searchLoading, fetchMore: searchFetchMore },
  ] = useLazyQuery(SEARCH_ARTS, {
    fetchPolicy: "network-only",
    onCompleted: ({ searchArts }) => {
      const { pageInfo } = searchArts;

      const { totalPages } = pageInfo;

      setTotalPageCount(totalPages);
      setOffset(pageInfo.offset);
    },
  });

  const [deleteArt] = useMutation(DELETE_ART, {
    refetchQueries: () => [
      {
        query: GET_ARTS,
        variables: {
          offset: 0,
          limit: LIMIT,
        },
      },
      {
        query: SEARCH_ARTS,
        variables: {
          query: searchedQuery,
          offset: 0,
          limit: LIMIT,
        },
      },
    ],
  });

  useEffect(() => {
    if (searchedQuery) {
      searchArts({
        variables: {
          query: searchedQuery,
          offset: 0,
          limit: 10,
        },
      });
    } else {
      getArts({
        variables: {
          offset: 0,
          limit: 10,
        },
      });
    }
  }, [searchedQuery]);

  async function onDeleteClick(id) {
    const confirm = window.confirm("정말 삭제하시겠습니까?");
    if (confirm) {
      await deleteArt({
        variables: {
          artId: id,
        },
      });
    }
  }

  /**
   *  When page changed from pagination
   *  Fetch data for the offset changed
   */
  async function onPageChange({ selected }) {
    const newOffset = selected;
    if (!fetchingMore) {
      setFetchingMore(true);
      if (searchedQuery) {
        await searchFetchMore({
          variables: {
            query: searchedQuery,
            offset: newOffset,
            limit: LIMIT,
          },
        });
      } else {
        await artsFetchMore({
          variables: {
            offset: newOffset,
            limit: LIMIT,
          },
        });
      }
      setOffset(newOffset);
      setFetchingMore(false);
    }
  }

  return {
    models: {
      data: searchedQuery
        ? searchData?.searchArts?.edges
        : artsData?.getArts?.edges,
      loading: artsLoading || searchLoading || fetchingMore,
      offset,
      totalPageCount,
    },
    operations: {
      onDeleteClick,
      onPageChange,
    },
  };
}

export default useArtsListTable;

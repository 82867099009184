import React from "react";
import LabeledInput from "../../common/components/LabeledInput";
import LabeledTextarea from "../../common/components/LabeledTextarea";
import Loading from "../../common/components/Loading";
import Modal from "../../common/components/Modal";
import SearchArtSelector from "../../common/components/SearchArtSelector";
import Thumbnail from "../../common/components/Thumbnail";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../common/styles/commonStyles";
import useUpdateExhibitionModal, {
  UPDATE_EXHIBITION_MODAL_ACTION_TYPES,
} from "./UpdateExhibitionModal.hook";

function UpdateExhibitionModal({
  isOpen,
  selectedExhibitionId,
  onRequestClose,
}) {
  const isNew = selectedExhibitionId === "new";

  const { refs, models, operations } = useUpdateExhibitionModal(
    selectedExhibitionId,
    onRequestClose
  );

  const { primaryMediaRef, backgroundMediaRef } = refs;

  const { state, updateLoading, exhibitionLoading } = models;

  const {
    onInputChange,
    onPrimaryMediaChange,
    onPrimaryMediaEditClick,
    onPrimaryMediaChangeCancelClick,
    onBackgroundMediaChange,
    onBackgroundMediaEditClick,
    onBackgroundMediaChangeCancelClick,
    onArtSelect,
    onAddArtClick,
    onDeleteArt,
    isSubmitDisabled,
    onSubmit,
  } = operations;

  const {
    id,
    title,
    introduction,
    from,
    to,
    director,
    host,
    sponsor,
    fontColor,
    primaryMediaState,
    primaryMedia,
    backgroundMediaState,
    backgroundMedia,
    arts,
    artists,
  } = state;

  return (
    <Modal
      isOpen={isOpen}
      title={isNew ? "전시 등록" : "전시 수정"}
      onRequestClose={onRequestClose}
    >
      {exhibitionLoading && <Loading />}
      {!exhibitionLoading && (
        <>
          <Flex justifyContent="space-between" gap={12}>
            {!isNew && (
              <Flex flex={1}>
                <LabeledInput
                  label="ID"
                  value={selectedExhibitionId}
                  disabled
                />
              </Flex>
            )}
          </Flex>

          <LabeledInput
            inputRef={primaryMediaRef}
            label="대표 이미지"
            type="file"
            accept="image/*"
            style={{
              visibility:
                primaryMedia || primaryMediaState ? "hidden" : "visible",
            }}
            onChange={onPrimaryMediaChange}
          />
          {primaryMedia && !primaryMediaState && (
            <Flex alignItems="center" justifyContent="flex-start" gap={4}>
              <Thumbnail media={primaryMedia}>
                <TextButton onClick={onPrimaryMediaEditClick}>수정</TextButton>
              </Thumbnail>
            </Flex>
          )}
          {primaryMediaState && (
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              gap={4}
              marginBottom={12}
            >
              <Thumbnail
                media={{ uri: URL.createObjectURL(primaryMediaState) }}
              >
                <Flex gap={4}>
                  <TextButton onClick={onPrimaryMediaEditClick}>
                    파일변경
                  </TextButton>
                  {primaryMedia && (
                    <TextButton
                      backgroundColor="red"
                      onClick={onPrimaryMediaChangeCancelClick}
                    >
                      수정 취소
                    </TextButton>
                  )}
                </Flex>
              </Thumbnail>
            </Flex>
          )}

          <LabeledInput
            inputRef={backgroundMediaRef}
            label="전시 배경 이미지"
            type="file"
            accept="image/*"
            style={{
              visibility:
                backgroundMedia || backgroundMediaState ? "hidden" : "visible",
            }}
            onChange={onBackgroundMediaChange}
          />
          {backgroundMedia && !backgroundMediaState && (
            <Flex alignItems="center" justifyContent="flex-start" gap={4}>
              <Thumbnail media={backgroundMedia}>
                <TextButton onClick={onBackgroundMediaEditClick}>
                  수정
                </TextButton>
              </Thumbnail>
            </Flex>
          )}
          {backgroundMediaState && (
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              gap={4}
              marginBottom={12}
            >
              <Thumbnail
                media={{ uri: URL.createObjectURL(backgroundMediaState) }}
              >
                <Flex gap={4}>
                  <TextButton onClick={onBackgroundMediaEditClick}>
                    파일변경
                  </TextButton>
                  {backgroundMedia && (
                    <TextButton
                      backgroundColor="red"
                      onClick={onBackgroundMediaChangeCancelClick}
                    >
                      수정 취소
                    </TextButton>
                  )}
                </Flex>
              </Thumbnail>
            </Flex>
          )}

          <LabeledInput
            label="전시 제목"
            placeholder="전시 제목"
            value={title}
            onChange={(e) =>
              onInputChange(
                e,
                UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_TITLE
              )
            }
          />
          <LabeledTextarea
            label="전시 소개"
            placeholder="전시 소개"
            value={introduction}
            onChange={(e) =>
              onInputChange(
                e,
                UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_INTRODUCTION
              )
            }
          />
          <Flex
            flexDirection="column"
            width="100%"
            gap={4}
            alignItems="flex-start"
          >
            <SectionTitle>전시 기간</SectionTitle>
            <Flex alignItems="center" width="100%" gap={8}>
              <Flex flex={1}>
                <LabeledInput
                  label="FROM"
                  type="date"
                  value={from}
                  onChange={(e) =>
                    onInputChange(
                      e,
                      UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_FROM
                    )
                  }
                />
              </Flex>
              <Flex flex={1}>
                <LabeledInput
                  label="TO"
                  type="date"
                  value={to}
                  min={from}
                  onChange={(e) =>
                    onInputChange(
                      e,
                      UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_TO
                    )
                  }
                />
              </Flex>
            </Flex>
          </Flex>

          <LabeledInput
            label="기획(director)"
            placeholder="기획(director)"
            value={director}
            onChange={(e) =>
              onInputChange(
                e,
                UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_DIRECTOR
              )
            }
          />

          <LabeledInput
            label="주관(host)"
            placeholder="주관(host)"
            value={host}
            onChange={(e) =>
              onInputChange(e, UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_HOST)
            }
          />

          <LabeledInput
            label="후원(sponsor)"
            placeholder="후원(sponsor)"
            value={sponsor}
            onChange={(e) =>
              onInputChange(
                e,
                UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_SPONSOR
              )
            }
          />

          <LabeledInput
            label="글자 색상(HEX, RGB, RGBA)"
            placeholder="글자 색상"
            value={fontColor}
            onChange={(e) =>
              onInputChange(
                e,
                UPDATE_EXHIBITION_MODAL_ACTION_TYPES.HANDLE_FONT_COLOR
              )
            }
          />

          <>
            {arts?.map((art, index) => (
              <Flex
                key={index}
                flexDirection="column"
                width="100%"
                alignItems="flex-start"
                marginBottom={8}
              >
                <Flex gap={8} width="100%">
                  <SearchArtSelector
                    oursOnly={true}
                    value={art}
                    onSelectorChange={(value) => onArtSelect(value, index)}
                  />
                  <TextButton
                    width={80}
                    backgroundColor="red"
                    marginTop={10}
                    onClick={() => onDeleteArt(index)}
                  >
                    삭제
                  </TextButton>
                </Flex>
                {artists[index] && (
                  <SectionTitle>
                    작품 아티스트 : {artists[index]?.user?.name}
                  </SectionTitle>
                )}
              </Flex>
            ))}
            <TextButton width={100} onClick={onAddArtClick}>
              작품 추가
            </TextButton>
          </>

          <Flex marginTop={80}>
            <TextButton
              width={300}
              onClick={async () => await onSubmit()}
              disabled={isSubmitDisabled()}
            >
              {updateLoading ? "등록중" : "등록하기"}
            </TextButton>
          </Flex>
        </>
      )}
    </Modal>
  );
}

export default UpdateExhibitionModal;

import { useMutation } from "@apollo/client";
import { useState } from "react";
import {
  GET_REPORT_REASONS,
  UPDATE_REPORT_REASON_AS_ADMIN,
} from "../../../../lib/apollo/gql/report";

function useReportReasonCard(reportReason) {
  const [editMode, setEditMode] = useState(false);
  const [reason, setReason] = useState(reportReason?.reason);

  const [updateReportReasonAsAdmin, { loading: updateLoading }] = useMutation(
    UPDATE_REPORT_REASON_AS_ADMIN,
    {
      onCompleted: ({ updateReportReasonAsAdmin }) => {
        setReason(updateReportReasonAsAdmin?.reason);
        setEditMode(false);
      },
    }
  );

  function toggleEditMode() {
    setEditMode(!editMode);
  }

  function onChange(e) {
    const { value } = e.target;

    setReason(value);
  }

  function onSubmit() {
    updateReportReasonAsAdmin({
      variables: {
        reportReasonInput: {
          id: reportReason?.id,
          reason,
          type: reportReason?.type,
        },
      },
    });
  }

  return {
    models: {
      editMode,
      reason,
      updateLoading,
    },
    operations: {
      toggleEditMode,
      onChange,
      onSubmit,
    },
  };
}

export default useReportReasonCard;

import React from "react";
import LabeledInput from "../../common/components/LabeledInput";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../common/styles/commonStyles";
import useSubscriptionPlanCard from "./SubscriptionPlanCard.hook";

function SubscriptionPlanCard({ plan }) {
  const {
    models: { state },
    operations: { onChange, isSubmitDisabled, onSubmit },
  } = useSubscriptionPlanCard(plan);

  const {
    id,
    name,
    trialDays,
    monthlyPrice,
    duration,
    isHot,
    active,
    totalPrice,
    finalPrice,
  } = state;

  return (
    <Flex
      flexBasis="32%"
      borderRadius={6}
      padding={12}
      border="1px solid black"
      flexDirection="column"
      alignItems="flex-start"
      gap={4}
    >
      <SectionTitle>{plan ? "수정" : "등록"}</SectionTitle>
      {id && <LabeledInput label="ID" disabled={true} value={id} />}
      <LabeledInput
        label="플랜명"
        placeholder="예)6개월 정기구독권"
        value={name}
        onChange={(e) => onChange(e, "name")}
      />
      <Flex width="100%" gap={4}>
        <LabeledInput
          label="무료기간"
          placeholder="예)7일 무료면 7"
          value={trialDays}
          onChange={(e) => onChange(e, "trialDays")}
        />
      </Flex>
      <Flex width="100%" gap={4}>
        <LabeledInput
          label="결제 주기"
          placeholder="예)6개월 구독 상품이면 6"
          value={duration}
          onChange={(e) => onChange(e, "duration")}
        />
        <LabeledInput
          label="월 가격"
          placeholder="월 가격 표기금액"
          value={monthlyPrice}
          onChange={(e) => onChange(e, "monthlyPrice")}
        />
      </Flex>

      <Flex width="100%" gap={4}>
        <LabeledInput
          label="총 가격"
          placeholder="예)할인 전 가격 표기 금액 "
          value={totalPrice}
          onChange={(e) => onChange(e, "totalPrice")}
        />
        <LabeledInput
          label="할인된 가격"
          placeholder="할인된 최종 가격"
          value={finalPrice}
          onChange={(e) => onChange(e, "finalPrice")}
        />
      </Flex>

      <Flex width="100%" gap={4}>
        <LabeledInput
          type="checkbox"
          label="핫태그 여부"
          checked={isHot}
          onChange={(e) => onChange(e, "isHot")}
        />
        <LabeledInput
          type="checkbox"
          label="활성 여부"
          checked={active}
          onChange={(e) => onChange(e, "active")}
        />
      </Flex>
      <TextButton
        disabled={isSubmitDisabled()}
        onClick={() => onSubmit()}
        backgroundColor="blue"
      >
        {plan ? "수정" : "등록"}
      </TextButton>
    </Flex>
  );
}

export default SubscriptionPlanCard;

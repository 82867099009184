import { gql } from "@apollo/client";
import { SUBSCRIPTION_PLAN_FIELD } from "./fragments";

export const UPDATE_SUBSCRIPTION_PLAN_AS_ADMIN = gql`
  ${SUBSCRIPTION_PLAN_FIELD}
  mutation UpdateSubscriptionPlanAsAdmin(
    $subscriptionPlanInput: SubscriptionPlanInput!
  ) {
    updateSubscriptionPlanAsAdmin(
      subscriptionPlanInput: $subscriptionPlanInput
    ) {
      ...SubscriptionPlanField
    }
  }
`;

export const GET_SUBSCRIPTION_PLANS = gql`
  ${SUBSCRIPTION_PLAN_FIELD}
  query GetSubscriptionPlans($activeOnly: Boolean) {
    getSubscriptionPlans(activeOnly: $activeOnly) {
      ...SubscriptionPlanField
    }
  }
`;

import React from "react";
import LabeledInput from "../../common/components/LabeledInput";
import Loading from "../../common/components/Loading";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../common/styles/commonStyles";
import useAdminPhraseContainer from "./AdminPhraseContainer.hook";

const TITLE = {
  editorial: "에디토리얼 페이지 문구",
  docent: "도슨트 페이지 문구",
  interview: "인터뷰 페이지 문구",
  essay: "에세이 페이지 문구",
  weekly: "위클리 페이지 문구",
  artHistory: "아트 히스토리 페이지 문구",
  notes: "노트 페이지 문구",
};

function AdminPhraseContainer() {
  const { models, operations } = useAdminPhraseContainer();

  const { state, loading, updateLoading } = models;

  const { onInputChange, onSubmit } = operations;

  if (loading) return <Loading />;

  const { editorial, docent, interview, essay, weekly, artHistory, notes } =
    state;

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
      padding={20}
    >
      <SectionTitle>각 페이지의 문구를 수정합니다</SectionTitle>
      <Flex
        flexWrap="wrap"
        gap={8}
        width="100%"
        justifyContent="space-between"
        marginTop={24}
      >
        {Object.keys(state).map((key) => (
          <Flex flexBasis="46%" key={key}>
            <LabeledInput
              label={TITLE[key]}
              placeholder={TITLE[key]}
              value={state[key]}
              onChange={(e) => onInputChange(e, `${key}`)}
            />
          </Flex>
        ))}
      </Flex>
      <TextButton onClick={onSubmit} disabled={updateLoading}>
        {updateLoading ? "수정 중" : "수정하기"}
      </TextButton>
    </Flex>
  );
}

export default AdminPhraseContainer;

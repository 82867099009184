import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import useRouteContainer from "./RouteContainer.hook";
import ROUTE_NAMES from "./utils/routeNames";

function RouteContainer() {
  useRouteContainer();
  return (
    <Routes>
      {Object.keys(ROUTE_NAMES).map((routeKey) => {
        const { path, element } = ROUTE_NAMES[routeKey];
        return routeKey !== "SIGN_IN" ? (
          <Route key={path} path={path} element={<Layout>{element}</Layout>} />
        ) : (
          <Route key={path} path={path} element={element} />
        );
      })}
    </Routes>
  );
}

export default RouteContainer;

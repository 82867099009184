import { useState } from "react";

function useWeeklyListContainer() {
  const [selectedWeeklyId, setSelectedWeeklyId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchedQuery, setSearchedQuery] = useState("");

  function onSelectWeekly(id) {
    setSelectedWeeklyId(id);
  }

  function onSearchChange(e) {
    const { value } = e.target;

    setSearchQuery(value);
    if (value.length === 0 && searchedQuery) {
      setSearchedQuery("");
    }
  }

  function onSearchKeyDown(e) {
    const { key } = e;

    if (key === "Enter") {
      onSearch();
    }
  }

  function onSearch() {
    if (searchQuery !== searchedQuery) {
      setSearchedQuery(searchQuery);
    }
  }

  return {
    models: {
      selectedWeeklyId,
      searchQuery,
      searchedQuery,
    },
    operations: {
      onSelectWeekly,
      onSearchChange,
      onSearchKeyDown,
      onSearch,
    },
  };
}

export default useWeeklyListContainer;

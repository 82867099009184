import React from "react";
import AsyncSelector from "react-select/async";
import { Flex, SectionTitle } from "../styles/commonStyles";
import useSearchArtSelector from "./SearchArtSelector.hook";

function SearchArtSelector({ value, oursOnly = false, onSelectorChange }) {
  const { operations } = useSearchArtSelector(oursOnly);

  const { loadOptions } = operations;

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      gap={8}
      width="100%"
      marginBottom={12}
    >
      <SectionTitle>작품</SectionTitle>
      <Flex width="100%" justifyContent="flex-start" gap={8}>
        <AsyncSelector
          placeholder="작품명, 재료, 작가명"
          loadOptions={loadOptions}
          value={value}
          onChange={(value) => {
            onSelectorChange(value);
          }}
          styles={{
            container: (style) => ({ ...style, flex: 1 }),
          }}
        />
      </Flex>
    </Flex>
  );
}

export default SearchArtSelector;

import { useMutation, useQuery } from "@apollo/client";
import {
  GET_PLAYLISTS,
  TOGGLE_PLAYLIST_ACTIVE_AS_ADMIN,
} from "../../../../lib/apollo/gql/playlistGqls";
import { useInView } from "react-intersection-observer";
import { useState } from "react";

function usePlaylistsTable() {
  const { ref: fetchMoreRef } = useInView({
    threshold: 0.3,
    onChange: onEndReached,
  });

  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, error, fetchMore } = useQuery(GET_PLAYLISTS, {
    variables: {
      activeOnly: false,
      first: 10,
    },
  });

  const [togglePlaylistActiveAsAdmin] = useMutation(
    TOGGLE_PLAYLIST_ACTIVE_AS_ADMIN
  );

  function onEndReached(inView) {
    if (inView) {
      const { hasNextPage, endCursor } = data?.getPlaylists?.pageInfo;

      if (!fetchingMore && hasNextPage) {
        setFetchingMore(true);

        fetchMore({
          variables: {
            activeOnly: false,
            first: 10,
            after: endCursor,
          },
        }).finally(() => {
          setFetchingMore(false);
        });
      }
    }
  }

  function onActiveClick(playlistId, active) {
    togglePlaylistActiveAsAdmin({
      variables: {
        playlistId,
        active: !active,
      },
    });
  }

  return {
    ref: {
      fetchMoreRef,
    },
    models: {
      loading,
      data: data?.getPlaylists?.edges,
    },
    operations: {
      onActiveClick,
    },
  };
}

export default usePlaylistsTable;

import React from "react";
import LabeledInput from "../../common/components/LabeledInput";
import { Flex, TextButton } from "../../common/styles/commonStyles";

function UpdateNotice({
  state,
  isUpdate = true,
  onChange,
  onSubmit,
  isSubmitDisabled,
}) {
  const { id, marketingId, active } = state;

  return (
    <Flex flexDirection="column" flex={1} gap={8}>
      {isUpdate && (
        <LabeledInput
          label="ID"
          placeholder="notice id"
          disabled
          value={id}
          onChange={(e) => onChange(e, "id")}
        />
      )}

      <LabeledInput
        label="marketing id"
        placeholder="marketing id"
        value={marketingId}
        onChange={(e) => onChange(e, "marketingId")}
      />

      <LabeledInput
        label="활성"
        type="checkbox"
        checked={active}
        onChange={(e) => onChange(e, "active")}
      />

      <TextButton disabled={isSubmitDisabled()} onClick={onSubmit}>
        등록
      </TextButton>
    </Flex>
  );
}

export default UpdateNotice;

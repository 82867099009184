import React from "react";
import AsyncSelector from "react-select/async";

import LabeledInput from "../../../common/components/LabeledInput";

import { Flex, TextButton } from "../../../common/styles/commonStyles";
import useWeeklyContentSelector from "./WeeklyContentSelector.hook";

function WeeklyContentSelector({
  content,
  onSelectorChange,
  onReleaseDateChange,
  onReleaseTimeChange,
  onDeleteContentClick,
}) {
  const { operations } = useWeeklyContentSelector();

  const { loadOptions } = operations;

  const value = content?.content;

  return (
    <Flex alignItems="center" gap={8} width="100%">
      <AsyncSelector
        placeholder="컨텐츠 제목, 하일라이트, 바디"
        value={value}
        loadOptions={loadOptions}
        onChange={(value) => onSelectorChange(value)}
        styles={{
          container: (style) => ({ ...style, flex: 1 }),
        }}
      />
      <Flex flex={1} alignItems="center" gap={4}>
        <Flex flex={1}>
          <LabeledInput
            type="date"
            label="공개 날짜"
            value={content.releaseDate}
            onChange={(e) => {
              const { value } = e.target;
              onReleaseDateChange(value);
            }}
          />
        </Flex>
        <Flex flex={1}>
          <LabeledInput
            type="time"
            label="공개 시간"
            value={content.releaseTime}
            onChange={(e) => {
              const { value } = e.target;
              onReleaseTimeChange(value);
            }}
          />
        </Flex>
      </Flex>
      <TextButton backgroundColor="red" onClick={onDeleteContentClick}>
        Delete
      </TextButton>
    </Flex>
  );
}

export default WeeklyContentSelector;

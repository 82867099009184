import React from "react";
import { Flex } from "../common/styles/commonStyles";
import AddNotice from "./components/AddNotice";
import NoticeList from "./components/NoticeList";
import UpdateNotice from "./components/UpdateNotice";

function NoticeContainer() {
  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
      padding={24}
      gap={24}
    >
      <AddNotice />
      <NoticeList />
    </Flex>
  );
}

export default NoticeContainer;

import moment from "moment";
import React from "react";
import {
  ORDER_DELIVERY_OPTIONS,
  ORDER_DELIVERY_OPTIONS_TEXT,
  ORDER_STATUS_TEXT,
} from "../../../../utilities/constants";
import Loading from "../../../common/components/Loading";
import Pagination from "../../../common/components/Pagination";
import Thumbnail from "../../../common/components/Thumbnail";
import {
  Flex,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TextButton,
} from "../../../common/styles/commonStyles";
import useOrderListTable from "./OrderListTable.hook";

function OrderListTable({ activeOnly, searchedQuery, onOrderSelect }) {
  const {
    models: { data, loading, offset, totalPageCount },
    operations: { onPageChange },
  } = useOrderListTable(activeOnly, searchedQuery);

  return (
    <Flex
      flex={9}
      overflowY="auto"
      flexDirection="column"
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      padding={12}
    >
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell width="8%">ID</TableHeadCell>
            <TableHeadCell width="8%">구매자</TableHeadCell>
            <TableHeadCell width="8%">작품</TableHeadCell>
            <TableHeadCell width="8%">주문상태</TableHeadCell>
            <TableHeadCell width="8%">결제금액</TableHeadCell>
            <TableHeadCell width="8%">할인금액</TableHeadCell>
            <TableHeadCell width="8%">쿠폰사용유무</TableHeadCell>
            <TableHeadCell width="8%">결제수단</TableHeadCell>
            <TableHeadCell width="8%">결제시각</TableHeadCell>
            <TableHeadCell width="8%">배송옵션</TableHeadCell>
            <TableHeadCell width="8%">배송주소</TableHeadCell>
            <TableHeadCell width="8%">연락처</TableHeadCell>
            <TableHeadCell width="8%">배송상태</TableHeadCell>
            <TableHeadCell width="8%">주문시각</TableHeadCell>
            <TableHeadCell width="8%">상세보기</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan="100%">
                <Loading />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data?.map((order) => {
              if (!order)
                return (
                  <tr>
                    <td colSpan="100%">주문을 찾을 수 없습니다</td>
                  </tr>
                );

              const {
                id,
                user,
                coupon,
                point,
                status,
                initialAmount,
                finalAmount,
                payMethod,
                paidAt,
                cancelledAt,
                cancelReason,
                deliveryOption,
                saleItems,
                shipping,
                createdAt,
              } = order;

              return (
                <TableRow key={id}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{user?.name}</TableCell>
                  <TableCell>
                    {saleItems?.map((item) => {
                      const { art } = item;
                      const { primaryMedia, titleKor } = art;
                      return (
                        <div key={item?.id}>
                          <Thumbnail width={200} media={primaryMedia} />
                          <div>{titleKor}</div>
                        </div>
                      );
                    })}
                  </TableCell>
                  <TableCell>{ORDER_STATUS_TEXT[status]}</TableCell>
                  <TableCell>{finalAmount}</TableCell>
                  <TableCell>{initialAmount - finalAmount}</TableCell>
                  <TableCell>{coupon ? coupon?.id : "N"}</TableCell>
                  <TableCell>{payMethod}</TableCell>
                  <TableCell>
                    {moment(paidAt).format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                  <TableCell>
                    {ORDER_DELIVERY_OPTIONS_TEXT[deliveryOption]}
                  </TableCell>
                  <TableCell>{shipping?.address || "-"}</TableCell>
                  <TableCell>{shipping?.phone || "-"}</TableCell>

                  <TableCell>{shipping?.status}</TableCell>
                  <TableCell>
                    {moment.utc(createdAt).format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                  <TableCell>
                    <TextButton onClick={() => onOrderSelect(id)}>
                      선택
                    </TextButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </tbody>
        )}
      </Table>
      <Pagination
        forcePage={offset}
        pageCount={totalPageCount}
        pageRangeDisplayed={5}
        previousLabel="Prev"
        nextLabel="Next"
        onPageChange={onPageChange}
      />
    </Flex>
  );
}

export default OrderListTable;

import React from "react";
import Modal from "react-modal";
import RouteContainer from "./routes/RoutesContainer";

function App() {
  return <RouteContainer />;
}

export default App;

Modal.setAppElement("#root");

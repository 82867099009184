import React from "react";
import { NavLink } from "react-router-dom";
import ROUTE_NAMES from "../../routes/utils/routeNames";
import {
  NavigationContainerWrapper,
  NavigationMenuWrapper,
  NavigationMenuDivider,
  NavigationMenuSection,
  NavigationMenuTitle,
  NavigationMenuAccount,
} from "./NavigationContainer.styles";
import { useFirebaseContext } from "../common/providers/FirebaseProvider";
import { Flex, TextButton } from "../common/styles/commonStyles";
import { firebaseSignOut } from "../../lib/firebase";
import { SignInLogoImage } from "../signIn/SignInContainer.styles";

const NAV_MENU = [
  { title: "유저", paths: [ROUTE_NAMES.USER] },
  { title: "작품", paths: [ROUTE_NAMES.ARTS] },
  {
    title: "에디토리얼",
    paths: [ROUTE_NAMES.CONTENTS, ROUTE_NAMES.ART_HISTORY, ROUTE_NAMES.WEEKLY],
  },
  { title: "플레이리스트", paths: [ROUTE_NAMES.PLAYLIST] },
  {
    title: "전시",
    paths: [ROUTE_NAMES.EXHIBITION],
  },
  {
    title: "구독",
    paths: [ROUTE_NAMES.SUBSCRIPTION, ROUTE_NAMES.SUBSCRIPTION_PLAN],
  },
  {
    title: "마케팅",
    paths: [ROUTE_NAMES.MARKETING, ROUTE_NAMES.NOTICE, ROUTE_NAMES.BANNER],
  },

  {
    title: "관리",
    paths: [
      ROUTE_NAMES.SALE,
      ROUTE_NAMES.ORDER,
      ROUTE_NAMES.PAYMENT,
      ROUTE_NAMES.PROMOTION_CODE,
      ROUTE_NAMES.REPORT,
      ROUTE_NAMES.QUESTION,
      ROUTE_NAMES.ADMIN_PHRASE,
      ROUTE_NAMES.FOOTER_INFORMATION,
      ROUTE_NAMES.POLICY,
      ROUTE_NAMES.FAQ,
    ],
  },
];

function NavigationContainer() {
  const user = useFirebaseContext();
  return (
    <NavigationContainerWrapper>
      <NavLink to={ROUTE_NAMES.MAIN.path} style={{ width: "100%" }}>
        <Flex
          height={100}
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <SignInLogoImage />
        </Flex>
      </NavLink>
      {NAV_MENU.map((menu) => (
        <NavigationMenuWrapper key={menu.title}>
          <NavigationMenuSection>
            <NavigationMenuTitle>{menu.title}</NavigationMenuTitle>
            <Flex
              flexDirection="column"
              width="100%"
              alignItems="flex-start"
              gap={8}
            >
              {menu.paths.map((path) => (
                <Flex key={path.path} width="100%">
                  <NavLink
                    to={path.path}
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                  >
                    {path.title}
                  </NavLink>
                </Flex>
              ))}
            </Flex>
          </NavigationMenuSection>
          <NavigationMenuDivider />
        </NavigationMenuWrapper>
      ))}
      <NavigationMenuAccount>
        <div>Account</div>
        <div>
          <strong>{user.email}</strong>
        </div>
        <TextButton fontSize={12} onClick={async () => await firebaseSignOut()}>
          Sign out
        </TextButton>
      </NavigationMenuAccount>
    </NavigationContainerWrapper>
  );
}

export default NavigationContainer;

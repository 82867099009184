import React from "react";
import LabeledInput from "../../common/components/LabeledInput";
import Loading from "../../common/components/Loading";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../common/styles/commonStyles";
import useFooterInformationContainer from "./FooterInformationContainer.hook";

const TITLE = {
  copyrightMain: "메인 카피라이트",
  copyright: "푸터 최하단 카피라이트",
  email: "푸터 하단 이메일",
  ceoName: "대표 이름",
  address: "주소",
  registrationNumber: "사업자 등록번호",
  escroRegistration: "통신판매업 등록번호",
  phone: "전화번호",
  workingHour: "운영시간",
  instagramUrl: "인스타그램 주소 (ex. https://www.instagram.com/account)",
  subscriberMessage: "구독자 바텀바 메세지",
  subscriberMessageUrl: "구독자 바텀바 url",
};

function FooterInformationContainer() {
  const { models, operations } = useFooterInformationContainer();

  const { state, loading, updateLoading } = models;

  const { onInputChange, onSubmit } = operations;

  if (loading) return <Loading />;

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
      padding={20}
    >
      <SectionTitle>풋터 정보를 수정합니다</SectionTitle>
      <Flex
        flexWrap="wrap"
        gap={8}
        width="100%"
        justifyContent="space-between"
        marginTop={24}
      >
        {Object.keys(state).map((key) => (
          <Flex flexBasis="46%" key={key}>
            <LabeledInput
              label={TITLE[key]}
              placeholder={TITLE[key]}
              value={state[key]}
              onChange={(e) => onInputChange(e, `${key}`)}
            />
          </Flex>
        ))}
      </Flex>
      <TextButton onClick={onSubmit} disabled={updateLoading}>
        {updateLoading ? "수정 중" : "수정하기"}
      </TextButton>
    </Flex>
  );
}

export default FooterInformationContainer;

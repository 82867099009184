import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  DELETE_EXHIBITION_AS_ADMIN,
  GET_EXHIBITIONS_AS_ADMIN,
} from "../../../../lib/apollo/gql/exhibitionGqls";

const LIMIT = 10;

function useExhibitionListTable() {
  const [offset, setOffset] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, fetchMore } = useQuery(GET_EXHIBITIONS_AS_ADMIN, {
    onCompleted: ({ getExhibitionsAsAdmin }) => {
      const { pageInfo } = getExhibitionsAsAdmin;

      const { totalPages } = pageInfo;

      setTotalPageCount(totalPages);
      setOffset(pageInfo.offset);
    },
  });

  const [deleteExhibitionAsAdmin] = useMutation(DELETE_EXHIBITION_AS_ADMIN, {
    refetchQueries: () => [
      {
        query: GET_EXHIBITIONS_AS_ADMIN,
        variables: {
          offset: offset,
          limit: LIMIT,
        },
      },
    ],
  });

  /**
   *  When page changed from pagination
   *  Fetch data for the offset changed
   */
  async function onPageChange({ selected }) {
    const newOffset = selected;
    if (!fetchingMore) {
      setFetchingMore(true);
      fetchMore({
        offset: newOffset,
        limit: LIMIT,
      });
      setOffset(newOffset);
      setFetchingMore(false);
    }
  }

  async function onDeleteClick(id) {
    await deleteExhibitionAsAdmin({
      variables: {
        exhibitionId: id,
      },
    });
  }

  return {
    models: {
      data: data?.getExhibitionsAsAdmin?.edges,
      loading,
      offset,
      totalPageCount,
    },
    operations: {
      onPageChange,
      onDeleteClick,
    },
  };
}

export default useExhibitionListTable;

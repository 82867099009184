import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_SUBSCRIPTIONS_AS_ADMIN } from "../../../../lib/apollo/gql/subscriptionGqls";
import { LIMIT } from "../../../../utilities/constants";

function useSubscriptionListTable(selectedSubscriptionStatus) {
  const [offset, setOffset] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, error, fetchMore } = useQuery(
    GET_SUBSCRIPTIONS_AS_ADMIN,
    {
      variables: {
        status: selectedSubscriptionStatus,
        offset,
        limit: LIMIT,
      },
      onCompleted: ({ getSubscriptionsAsAdmin }) => {
        const { pageInfo } = getSubscriptionsAsAdmin;

        const { totalPages } = pageInfo;

        setTotalPageCount(totalPages);
        setOffset(pageInfo.offset);
      },
    }
  );

  /**
   *  When page changed from pagination
   *  Fetch data for the offset changed
   */
  async function onPageChange({ selected }) {
    const newOffset = selected;
    if (!fetchingMore) {
      setFetchingMore(true);

      await fetchMore({
        variables: {
          status: selectedSubscriptionStatus,
          offset: newOffset,
          limit: LIMIT,
        },
      });

      setOffset(newOffset);
      setFetchingMore(false);
    }
  }

  return {
    models: {
      loading: loading || fetchingMore,
      data: data?.getSubscriptionsAsAdmin?.edges,
      offset,
      totalPageCount,
    },
    operations: {
      onPageChange,
    },
  };
}

export default useSubscriptionListTable;

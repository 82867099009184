import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_REPORTS_AS_ADMIN } from "../../../../lib/apollo/gql/report";
import { LIMIT, REPORT_TYPE } from "../../../../utilities/constants";

function useNoteReportList() {
  const [offset, setOffset] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, fetchMore } = useQuery(GET_REPORTS_AS_ADMIN, {
    fetchPolicy: "network-only",
    variables: {
      type: REPORT_TYPE.NOTE,
      offset: 0,
      limit: LIMIT,
    },
    onCompleted: ({ getReportsAsAdmin }) => {
      const { pageInfo } = getReportsAsAdmin;

      const { totalPages } = pageInfo;

      setTotalPageCount(totalPages);
      setOffset(pageInfo.offset);
    },
  });

  async function onPageChange({ selected }) {
    const newOffset = selected;
    if (!fetchingMore) {
      setFetchingMore(true);
      await fetchMore({
        variables: {
          offset: newOffset,
          limit: LIMIT,
        },
      });
      setOffset(newOffset);
      setFetchingMore(false);
    }
  }

  return {
    models: {
      loading,
      data: data?.getReportsAsAdmin?.edges,
      offset,
      totalPageCount,
    },
    operations: {
      onPageChange,
    },
  };
}

export default useNoteReportList;

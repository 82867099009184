import { useState } from "react";

function useExhibitionListContainer() {
  const [selectedExhibitionId, setSelectedExhibitionId] = useState(null);

  function onSelectExhibition(id) {
    setSelectedExhibitionId(id);
  }

  return {
    models: {
      selectedExhibitionId,
    },
    operations: {
      onSelectExhibition,
    },
  };
}

export default useExhibitionListContainer;

import { gql } from "@apollo/client";
import { PAGE_INFO_FIELD, SALE_FIELD } from "./fragments";

export const GET_SALE_ITEMS = gql`
  ${SALE_FIELD}
  ${PAGE_INFO_FIELD}
  query GetSaleItems(
    $saleType: SaleTypes!
    $artCategory: ArtCategories
    $onSaleOnly: Boolean
    $offset: Int
    $limit: Int
  ) {
    getSaleItems(
      saleType: $saleType
      artCategory: $artCategory
      onSaleOnly: $onSaleOnly
      offset: $offset
      limit: $limit
    ) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...SaleField
      }
    }
  }
`;

export const SET_SALE_DELIVERY_STATUS_AS_ADMIN = gql`
  ${SALE_FIELD}
  mutation SetSaleDeliveryStatusAsAdmin(
    $saleId: ID!
    $deliveryStatus: SaleDeliveryStatus!
  ) {
    setSaleDeliveryStatusAsAdmin(
      saleId: $saleId
      deliveryStatus: $deliveryStatus
    ) {
      ...SaleField
    }
  }
`;

export const GET_SALE_BY_ID_AS_ADMIN = gql`
  ${SALE_FIELD}
  query GetSaleByIdAsAdmin($saleId: ID!) {
    getSaleByIdAsAdmin(saleId: $saleId) {
      ...SaleField
    }
  }
`;

export const UPDATE_SALE_AS_ADMIN = gql`
  ${SALE_FIELD}
  mutation UpdateSaleAsAdmin($saleInput: SaleInput!) {
    updateSaleAsAdmin(saleInput: $saleInput) {
      ...SaleField
    }
  }
`;

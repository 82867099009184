import { useState } from "react";
import { SALE_TYPES } from "../../../utilities/constants";

function useSaleListContainer() {
  const [selectedSaleType, setSeledtedSaleType] = useState(SALE_TYPES.STORE);
  const [selectedSaleId, setSeledtedSaleId] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchedQuery, setSearchedQuery] = useState("");

  function onSaleTypeCick(type) {
    setSeledtedSaleType(type);
  }

  function onSearchChange(e) {
    const { value } = e.target;

    setSearchQuery(value);
    if (value.length === 0 && searchedQuery) {
      setSearchedQuery("");
    }
  }

  function onSearchKeyDown(e) {
    const { key } = e;

    if (key === "Enter") {
      onSearch();
    }
  }

  function onSearch() {
    if (searchQuery !== searchedQuery) {
      setSearchedQuery(searchQuery);
    }
  }

  function onSaleSelect(saleId) {
    setSeledtedSaleId(saleId);
  }

  return {
    models: {
      selectedSaleType,
      searchQuery,
      searchedQuery,
      selectedSaleId,
    },
    operations: {
      onSaleTypeCick,
      onSearchChange,
      onSearchKeyDown,
      onSearch,
      onSaleSelect,
    },
  };
}

export default useSaleListContainer;

import { useQuery } from "@apollo/client";
import { GET_ALL_COUPON_TYPES_AS_ADMIN } from "../../../../lib/apollo/gql/couponGqls";

function useCouponTypeList() {
  const { data, loading } = useQuery(GET_ALL_COUPON_TYPES_AS_ADMIN);

  return {
    models: {
      loading,
      data: data?.getAllCouponTypesAsAdmin,
    },
  };
}

export default useCouponTypeList;

/**
 * Utility function that returns an object with keyArgs, read, and merge.
 * It can handle intial fetch, refetch, and pagination that has structure of {pageInfo, edges}
 * @param {*} keyArgs This array indicates which arguments are key arguments that are used to calculate the field's value. Specifying this array can help reduce the amount of duplicate data in your cache.
 */
function customOffsetLimitPagination(keyArgs, read, merge) {
  return {
    // Needs to define any key argument related to this type/query
    keyArgs: keyArgs || [],

    // Handles read interaction
    read:
      read ||
      function (data) {
        return data;
      },

    // Handles incoming data
    merge:
      merge ||
      function (existing, incoming, { args }) {
        // console.log({ existing, incoming, args });

        if (!existing) {
          // First fetch or refetch
          return incoming;
        }

        // Data received from relay pagination
        return {
          ...existing,
          pageInfo: incoming.pageInfo,
          edges: incoming.edges,
        };
      },
  };
}

export default customOffsetLimitPagination;

import React from "react";
import LabeledInput from "../../common/components/LabeledInput";
import Modal from "../../common/components/Modal";
import SearchUserSelector from "../../common/components/SearchUserSelector";
import Thumbnail from "../../common/components/Thumbnail";
import LabeledTextarea from "../../common/components/LabeledTextarea";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../common/styles/commonStyles";
import WeeklyContentSelector from "./components/WeeklyContentSelector";
import useUpdateWeeklyModal, {
  UPDATE_WEEKLY_MODAL_ACTION_TYPES,
} from "./UpdateWeeklyModal.hook";
import Loading from "../../common/components/Loading";

function UpdateWeeklyModal({ isOpen, selectedWeeklyId, onRequestClose }) {
  const isNew = selectedWeeklyId === "new";

  const { refs, models, operations } = useUpdateWeeklyModal(
    selectedWeeklyId,
    onRequestClose
  );

  const { primaryMediaRef } = refs;

  const { state, updateLoading, weeklyLoading } = models;

  const {
    onMediaChange,
    onMediaEditClick,
    onMediaChangeCancelClick,
    onInputChange,
    onWriterSelect,
    onContentSelect,
    onAddContentClick,
    onDeleteContentClick,
    isSubmitDisabled,
    onSubmit,
  } = operations;

  const {
    id,
    writer,
    primaryMediaState,
    primaryMedia,
    title,
    highlight,
    description,
    backgroundColor,
    fontColor,
    volume,
    releaseDate,
    releaseTime,
    contents,
  } = state;

  return (
    <Modal
      isOpen={isOpen}
      title={isNew ? "위클리 등록" : "위클리 수정"}
      onRequestClose={onRequestClose}
    >
      {weeklyLoading && <Loading />}
      {!weeklyLoading && (
        <>
          <Flex justifyContent="space-between" gap={12}>
            {!isNew && (
              <Flex flex={1}>
                <LabeledInput label="ID" value={selectedWeeklyId} disabled />
              </Flex>
            )}
          </Flex>

          <SearchUserSelector
            userType="WRITER"
            title="작가 선택"
            placeholder="저자(작가)"
            value={writer}
            onSelectorChange={onWriterSelect}
          />
          <LabeledInput
            inputRef={primaryMediaRef}
            label="대표 이미지"
            type="file"
            accept="image/*"
            style={{
              visibility:
                primaryMedia || primaryMediaState ? "hidden" : "visible",
            }}
            onChange={onMediaChange}
          />
          {primaryMedia && !primaryMediaState && (
            <Flex alignItems="center" justifyContent="flex-start" gap={4}>
              <Thumbnail media={primaryMedia}>
                <TextButton onClick={onMediaEditClick}>수정</TextButton>
              </Thumbnail>
            </Flex>
          )}
          {primaryMediaState && (
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              gap={4}
              marginBottom={12}
            >
              <Thumbnail
                media={{ uri: URL.createObjectURL(primaryMediaState) }}
              >
                <Flex gap={4}>
                  <TextButton onClick={onMediaEditClick}>파일변경</TextButton>
                  {primaryMedia && (
                    <TextButton
                      backgroundColor="red"
                      onClick={onMediaChangeCancelClick}
                    >
                      수정 취소
                    </TextButton>
                  )}
                </Flex>
              </Thumbnail>
            </Flex>
          )}

          <LabeledInput
            label="위클리 제목"
            placeholder="위클리 제목"
            value={title}
            onChange={(e) =>
              onInputChange(e, UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_TITLE)
            }
          />
          <LabeledInput
            label="Volume"
            placeholder="Volume"
            type="number"
            value={volume}
            onChange={(e) =>
              onInputChange(e, UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_VOLUME)
            }
          />
          <LabeledInput
            label="위클리 하일라이트"
            placeholder="위클리 하일라이트"
            value={highlight}
            onChange={(e) =>
              onInputChange(
                e,
                UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_HIGHLIGHT
              )
            }
          />
          <LabeledTextarea
            label="위클리 디스크립션"
            placeholder="위클리 디스크립션"
            value={description}
            onChange={(e) =>
              onInputChange(
                e,
                UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_DESCRIPTION
              )
            }
          />
          <Flex alignItems="center">
            <Flex width={25} height={25} backgroundColor={backgroundColor} />
            <LabeledInput
              label="배경 색상"
              placeholder="#FFFFFF or rgb(0,0,0) or rgba(0,0,0,0.5)"
              value={backgroundColor}
              onChange={(e) =>
                onInputChange(
                  e,
                  UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_BACKGROUND_COLOR
                )
              }
            />
          </Flex>
          <Flex alignItems="center">
            <Flex width={25} height={25} backgroundColor={fontColor} />
            <LabeledInput
              label="폰트 색상"
              placeholder="#FFFFFF or rgb(0,0,0) or rgba(0,0,0,0.5)"
              value={fontColor}
              onChange={(e) =>
                onInputChange(
                  e,
                  UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_FONT_COLOR
                )
              }
            />
          </Flex>
          <Flex alignItems="stretch" gap={8} marginBottom={12}>
            <Flex flex={1}>
              <LabeledInput
                type="date"
                label="공개 날짜"
                value={releaseDate}
                onChange={(e) =>
                  onInputChange(
                    e,
                    UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_RELEASE_DATE
                  )
                }
              />
            </Flex>
            <Flex flex={1}>
              <LabeledInput
                type="time"
                label="공개 시간"
                value={releaseTime}
                onChange={(e) =>
                  onInputChange(
                    e,
                    UPDATE_WEEKLY_MODAL_ACTION_TYPES.HANDLE_RELEASE_TIME
                  )
                }
              />
            </Flex>
          </Flex>
          <Flex
            width="100%"
            flexDirection="column"
            gap={4}
            alignItems="flex-start"
          >
            <SectionTitle>컨텐츠 선택</SectionTitle>
            {contents?.map((content, index) => (
              <WeeklyContentSelector
                key={index}
                content={content}
                onSelectorChange={(value) =>
                  onContentSelect(value, index, "content")
                }
                onReleaseDateChange={(value) =>
                  onContentSelect(value, index, "releaseDate")
                }
                onReleaseTimeChange={(value) =>
                  onContentSelect(value, index, "releaseTime")
                }
                onDeleteContentClick={() => onDeleteContentClick(index)}
              />
            ))}
            <TextButton
              margin="0px auto"
              backgroundColor="blue"
              onClick={onAddContentClick}
            >
              컨텐츠 추가
            </TextButton>
          </Flex>
          <Flex marginTop={80}>
            <TextButton
              width={300}
              onClick={async () => await onSubmit()}
              disabled={isSubmitDisabled()}
            >
              {updateLoading ? "등록중" : "등록하기"}
            </TextButton>
          </Flex>
        </>
      )}
    </Modal>
  );
}

export default UpdateWeeklyModal;

import { useLazyQuery, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  GET_USERS_BY_USER_TYPE_AS_ADMIN,
  SEARCH_USER,
} from "../../../../lib/apollo/gql/userGqls";
import { USER_TYPES } from "../../../../utilities/constants";

function useArtSearchArtist(isNew, artist) {
  const [isNotOurArtist, setIsNotOurArtist] = useState(
    isNew ? false : artist?.value ? false : true
  );

  const [searchUser] = useLazyQuery(SEARCH_USER);

  function toggleIsNotOurArtist() {
    setIsNotOurArtist(!isNotOurArtist);
  }

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(async () => {
        const { data } = await searchUser({
          variables: {
            query: inputValue,
            type: isNotOurArtist ? "ALL" : "ARTIST",
            offset: 0,
            limit: 20,
          },
        });

        const result = data?.searchUser?.edges;

        const options = result?.map((user) => ({
          value: user?.id,
          label: `${user?.name}(${user?.email || ""})`,
        }));

        resolve(options);
      }, 1);
    });

  return {
    models: {
      isNotOurArtist,
    },
    operations: {
      toggleIsNotOurArtist,
      loadOptions,
    },
  };
}

export default useArtSearchArtist;

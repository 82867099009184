import React from "react";
import { Flex, SectionTitle } from "../../../common/styles/commonStyles";
import copyText from "../../../common/utils/copyText";

function ReportCard({ report }) {
  const { note, user, reportReason, reason } = report;
  return (
    <Flex
      flexDirection="column"
      gap={4}
      padding={8}
      flexBasis="25%"
      border="1px solid black"
      borderRadius={4}
    >
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        gap={8}
        marginBottom={12}
      >
        <SectionTitle>신고자</SectionTitle>
        <div>User Id </div>
        <div> {user?.id}</div>
        <div>이름 </div>
        <div> {user?.name}</div>
        <div>이메일 </div>
        <div> {user?.email}</div>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        gap={8}
        marginBottom={12}
      >
        <SectionTitle>신고이유</SectionTitle>
        <div>{reportReason?.reason || reason}</div>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        gap={8}
        marginBottom={12}
      >
        <SectionTitle>신고한 노트</SectionTitle>
        <div>Note Id </div>
        <div> {note?.id}</div>
        <div>노트 내용 </div>
        <div> {note?.body}</div>
      </Flex>
    </Flex>
  );
}

export default ReportCard;

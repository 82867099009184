import { useMutation } from "@apollo/client";
import { useState } from "react";
import {
  DELETE_NOTICE_AS_ADMIN,
  GET_ALL_NOTICES_AS_ADMIN,
  UPDATE_NOTICE_AS_ADMIN,
} from "../../../lib/apollo/gql/noticeGqls";

function useNoticeCard(notice) {
  console.log(notice);

  const [showInputs, setShowInputs] = useState(false);
  const [state, setState] = useState({
    id: notice?.id,
    marketingId: notice?.marketing?.id,
    active: notice?.active,
  });

  const [updateNoticeAsAdmin, { loading }] = useMutation(
    UPDATE_NOTICE_AS_ADMIN,
    {
      onCompleted: () => {
        setShowInputs(false);
      },
    }
  );

  const [deleteNoticeAsAdmin] = useMutation(DELETE_NOTICE_AS_ADMIN, {
    refetchQueries: () => [
      {
        query: GET_ALL_NOTICES_AS_ADMIN,
      },
    ],
  });

  function toggleShowInputs() {
    setShowInputs(!showInputs);
  }

  function onChange(e, key) {
    const { value } = e.target;
    const { checked } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: key === "active" ? checked : value,
    }));
  }

  function isSubmitDisabled() {
    return !state.marketingId || loading;
  }

  function onSubmit() {
    updateNoticeAsAdmin({
      variables: {
        noticeInput: {
          id: state.id,
          marketingId: state.marketingId,
          active: state.active,
        },
      },
    });
  }

  function onDeleteClick() {
    const confirm = window.confirm("정말로 삭제하시겠습니까?");

    if (confirm) {
      deleteNoticeAsAdmin({
        variables: {
          noticeId: state.id,
        },
      });
    }
  }

  return {
    models: {
      state,
      showInputs,
    },
    operations: {
      toggleShowInputs,
      onChange,
      onSubmit,
      isSubmitDisabled,
      onDeleteClick,
    },
  };
}

export default useNoticeCard;

import { useMutation } from "@apollo/client";
import { useEffect, useRef } from "react";
import imageCompression from "browser-image-compression";

import { UPLOAD_PUBLIC_MEDIA_AS_ADMIN } from "../../../lib/apollo/gql/commonGqls";
import { COMPRESSION_OPTION } from "../../../utilities/constants";

function useHTMLEditor() {
  const editorRef = useRef();

  const [uploadPublicMediaAsAdmin] = useMutation(UPLOAD_PUBLIC_MEDIA_AS_ADMIN, {
    onCompleted: ({ uploadPublicMediaAsAdmin }) => {
      const range = editorRef?.current.getEditor().getSelection();
      console.log(uploadPublicMediaAsAdmin);

      editorRef?.current
        .getEditor()
        .insertEmbed(range.index, "image", uploadPublicMediaAsAdmin);
    },
  });

  useEffect(() => {
    function imageUploader() {
      const input = document.createElement("input");

      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();

      input.onchange = async () => {
        const file = input.files[0];

        const compressedFile = await imageCompression(file, COMPRESSION_OPTION);

        await uploadPublicMediaAsAdmin({
          variables: {
            mediaInput: {
              file: compressedFile,
            },
          },
        });
      };
    }

    if (editorRef?.current) {
      const toolbar = editorRef?.current.getEditor().getModule("toolbar");
      toolbar.addHandler("image", imageUploader);
    }
  }, [editorRef?.current]);

  return {
    refs: {
      editorRef,
    },
  };
}

export default useHTMLEditor;

import React from "react";
import Select from "react-select";
import Loading from "../../../common/components/Loading";
import { Flex, SectionTitle } from "../../../common/styles/commonStyles";
import useArtTypeSelector from "./ArtTypeSelector.hook";

/**
 * Art type selector using react-select
 * react-select props
 * https://react-select.com/home
 */
function ArtTypeSelector({ type, onSelectorChange }) {
  const { models } = useArtTypeSelector();

  const { loading, data } = models;

  if (loading) return <Loading />;

  const options = data?.map((type) => ({
    value: type,
    label: type,
  }));

  return (
    <Flex flexDirection="column" alignItems="flex-start" gap={8} width="100%">
      <SectionTitle>작품 타입</SectionTitle>
      <Select
        placeholder="작품 타입"
        options={options}
        value={type}
        onChange={(value) => onSelectorChange(value)}
        styles={{
          container: (style) => ({ ...style, width: "100%" }),
        }}
      />
    </Flex>
  );
}

export default ArtTypeSelector;

import { useQuery } from "@apollo/client";
import { GET_SUBSCRIPTION_PLANS } from "../../lib/apollo/gql/subscriptionPlanGqls";

function useSubscriptionPlanContainer() {
  const { data, loading, error } = useQuery(GET_SUBSCRIPTION_PLANS, {
    variables: {
      activeOnly: false,
    },
  });

  return {
    models: {
      data: data?.getSubscriptionPlans,
      loading,
    },
  };
}

export default useSubscriptionPlanContainer;

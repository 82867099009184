import moment from "moment";
import React from "react";
import Avatar from "../../../common/components/Avatar";
import Loading from "../../../common/components/Loading";
import Pagination from "../../../common/components/Pagination";
import {
  Flex,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TextButton,
} from "../../../common/styles/commonStyles";
import useUserTable from "./UserTable.hook";

function UserTable({
  data,
  loading,
  offset,
  totalPageCount,
  onPageChange,
  onUserSelect,
}) {
  const {
    operations: { onSetFreeSubscriptionClick, onExpireFreeSubscriptionClick },
  } = useUserTable();

  return (
    <Flex
      flex={9}
      overflowY="auto"
      flexDirection="column"
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      padding={12}
    >
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell width="4%">ID</TableHeadCell>
            <TableHeadCell width="8%">Avatar</TableHeadCell>
            <TableHeadCell width="8%">Email</TableHeadCell>
            <TableHeadCell width="10%">Name</TableHeadCell>
            <TableHeadCell width="10%">Summary</TableHeadCell>
            <TableHeadCell width="8%">가입일자</TableHeadCell>
            <TableHeadCell width="5%">Artist</TableHeadCell>
            <TableHeadCell width="5%">Writer</TableHeadCell>
            <TableHeadCell width="5%">Space</TableHeadCell>
            <TableHeadCell width="5%">Collector</TableHeadCell>
            <TableHeadCell width="5%">구독상태</TableHeadCell>
            <TableHeadCell width="5%">무료구독권</TableHeadCell>
            <TableHeadCell width="5%">작품수</TableHeadCell>
            <TableHeadCell width="5%">콜렉션 조회수</TableHeadCell>
            <TableHeadCell width="5%">콜렉션 박수수</TableHeadCell>
            <TableHeadCell width="5%">콜렉션 방명록수</TableHeadCell>
            <TableHeadCell width="5%">콜렉션 구매문의수</TableHeadCell>
            <TableHeadCell width="5%">MANAGE</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan="100%">
                <Loading />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data?.map((user) => {
              const {
                id,
                name,
                email,
                summary,
                avatar,
                createdAt,
                writer,
                artist,
                space,
                isCollector,
                isSubscriber,
                subscriptions,
                collection,
                requestSaleCount,
              } = user;

              const currentSubscription = subscriptions?.[0];

              const isFreeSubscription =
                isSubscriber &&
                !currentSubscription?.subscriptionPlan &&
                !currentSubscription?.productId;

              const subscriber = isSubscriber || artist || writer;
              const freeSubscriptionAvailable =
                !artist && !writer && !isSubscriber;

              return (
                <TableRow key={id}>
                  <TableCell>{id}</TableCell>
                  <TableCell>
                    {avatar ? <Avatar media={avatar} /> : "미등록"}
                  </TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>{summary}</TableCell>
                  <TableCell>
                    {moment(createdAt).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell>{artist ? "O" : "N"}</TableCell>
                  <TableCell>{writer ? "O" : "N"}</TableCell>
                  <TableCell>{space ? "O" : "N"}</TableCell>
                  <TableCell>{isCollector ? "O" : "N"}</TableCell>
                  <TableCell>{subscriber ? "O" : "N"}</TableCell>
                  <TableCell>
                    {freeSubscriptionAvailable ? (
                      <TextButton
                        onClick={() => onSetFreeSubscriptionClick(id)}
                      >
                        부여
                      </TextButton>
                    ) : isFreeSubscription ? (
                      <TextButton
                        onClick={() =>
                          onExpireFreeSubscriptionClick(subscriptions?.[0]?.id)
                        }
                      >
                        해지
                      </TextButton>
                    ) : !(artist || writer) ? (
                      "구독중"
                    ) : (
                      "부여 불가"
                    )}
                  </TableCell>
                  <TableCell>{collection?.artsCount || 0}</TableCell>
                  <TableCell>{collection?.viewCount || 0}</TableCell>
                  <TableCell>{collection?.claps || 0}</TableCell>
                  <TableCell>{collection?.noteCount || 0}</TableCell>
                  <TableCell>{requestSaleCount || 0}</TableCell>
                  <TableCell>
                    <TextButton padding={4} onClick={() => onUserSelect(id)}>
                      관리
                    </TextButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </tbody>
        )}
      </Table>
      <Pagination
        forcePage={offset}
        pageCount={totalPageCount}
        pageRangeDisplayed={5}
        previousLabel="Prev"
        nextLabel="Next"
        onPageChange={onPageChange}
      />
    </Flex>
  );
}

export default UserTable;

import React from "react";
import Thumbnail from "../../common/components/Thumbnail";
import { Flex, TextButton } from "../../common/styles/commonStyles";
import useNoticeCard from "./NoticeCard.hook";
import UpdateNotice from "./UpdateNotice";

function NoticeCard({ notice }) {
  const { marketing, active, createdAt, updatedAt } = notice;

  const { primaryMedia, title } = marketing;

  const {
    models: { state, showInputs },
    operations: {
      toggleShowInputs,
      onChange,
      onSubmit,
      isSubmitDisabled,
      onDeleteClick,
    },
  } = useNoticeCard(notice);

  return (
    <Flex
      flexBasis="32%"
      borderRadius={6}
      padding={12}
      border="1px solid black"
      flexDirection="column"
    >
      <Thumbnail width="100%" media={primaryMedia} />
      <Flex flexDirection="column" gap={4} width="100%" marginTop={8}>
        <div>제목</div>
        <strong>{title}</strong>
      </Flex>
      <Flex flexDirection="column" gap={4} width="100%" marginTop={8}>
        <div>Active</div>
        <div
          style={{
            width: 20,
            height: 20,
            backgroundColor: active ? "black" : "white",
            borderRadius: 6,
            border: "1px solid black",
          }}
        />
      </Flex>
      <Flex gap={4}>
        <TextButton backgroundColor="blue" onClick={toggleShowInputs}>
          수정
        </TextButton>
        <TextButton backgroundColor="red" onClick={onDeleteClick}>
          삭제
        </TextButton>
      </Flex>

      {showInputs && (
        <UpdateNotice
          state={state}
          isUpdate
          onChange={onChange}
          isSubmitDisabled={isSubmitDisabled}
          onSubmit={onSubmit}
        />
      )}
    </Flex>
  );
}

export default NoticeCard;

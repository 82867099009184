import { useQuery } from "@apollo/client";
import { GET_ALL_COUPONS_AS_ADMIN } from "../../../../lib/apollo/gql/couponGqls";

function useCouponList() {
  const { data, loading } = useQuery(GET_ALL_COUPONS_AS_ADMIN);

  return {
    models: {
      data: data?.getAllCouponsAsAdmin,
      loading,
    },
  };
}

export default useCouponList;

import React from "react";
import { Flex } from "../../common/styles/commonStyles";
import UpdateExhibitionModal from "../updateExhibition/UpdateExhibitionModal";
import ExhibitionListNavBar from "./components/ExhibitionListNavBar";
import ExhibitionListTable from "./components/ExhibitionListTable";
import useExhibitionListContainer from "./ExhibitionListContainer.hook";

function ExhibitionListContainer() {
  const { models, operations } = useExhibitionListContainer();

  const { selectedExhibitionId } = models;

  const { onSelectExhibition } = operations;

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
    >
      <ExhibitionListNavBar onSelectExhibition={onSelectExhibition} />
      <ExhibitionListTable onSelectExhibition={onSelectExhibition} />

      {!!selectedExhibitionId && (
        <UpdateExhibitionModal
          selectedExhibitionId={selectedExhibitionId}
          isOpen={!!selectedExhibitionId}
          onRequestClose={() => onSelectExhibition(null)}
        />
      )}
    </Flex>
  );
}

export default ExhibitionListContainer;

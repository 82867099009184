import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFirebaseContext } from "../modules/common/providers/FirebaseProvider";
import ROUTE_NAMES from "./utils/routeNames";

function useRouteContainer() {
  const navigate = useNavigate();
  const user = useFirebaseContext();

  useEffect(() => {
    if (!user) {
      navigate(ROUTE_NAMES.SIGN_IN.path, { replace: true });
    }
  }, [user]);
}

export default useRouteContainer;

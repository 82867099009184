import moment from "moment";
import React from "react";
import Loading from "../../../common/components/Loading";
import Pagination from "../../../common/components/Pagination";
import Thumbnail from "../../../common/components/Thumbnail";
import {
  Flex,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TextButton,
} from "../../../common/styles/commonStyles";
import useArtHistoryTable from "./ArtHistoryTable.hook";

function ArtHistoryTable({ searchedQuery, onSelectArtHistory }) {
  const { models, operations } = useArtHistoryTable(searchedQuery);

  const { data, loading, offset, totalPageCount } = models;

  const { onPageChange, onDeleteClick } = operations;
  return (
    <Flex
      flex={9}
      overflowY="auto"
      flexDirection="column"
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      padding={12}
    >
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell width="8%">ID</TableHeadCell>
            <TableHeadCell width="15%">작품</TableHeadCell>
            <TableHeadCell width="15%">비교 이미지</TableHeadCell>
            <TableHeadCell width="8%">작가</TableHeadCell>
            <TableHeadCell width="10%">타이틀</TableHeadCell>
            <TableHeadCell width="15%">하일라이트</TableHeadCell>
            <TableHeadCell width="5%">박수</TableHeadCell>
            <TableHeadCell width="5%">조회수</TableHeadCell>
            <TableHeadCell width="8%">등록일</TableHeadCell>
            <TableHeadCell width="5%">수정</TableHeadCell>
            <TableHeadCell width="5%">삭제</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan="100%">
                <Loading />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data?.map((artHistory) => {
              const {
                id,
                writer,
                art,
                paringMedia,
                title,
                highlight,
                createdAt,
                viewCount,
                claps,
              } = artHistory;

              return (
                <TableRow key={id}>
                  <TableCell>{id}</TableCell>
                  <TableCell>
                    <Thumbnail
                      width={100}
                      height={100}
                      media={art.primaryMedia}
                    />
                    <div>{art.titleKor}</div>
                  </TableCell>
                  <TableCell>
                    {paringMedia ? (
                      <Thumbnail width={100} height={100} media={paringMedia} />
                    ) : (
                      "이미지 없음"
                    )}
                  </TableCell>
                  <TableCell>{writer.user.name}</TableCell>
                  <TableCell>{title}</TableCell>
                  <TableCell>{highlight}</TableCell>
                  <TableCell>{claps}</TableCell>
                  <TableCell>{viewCount}</TableCell>
                  <TableCell>
                    {moment(createdAt).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell>
                    <TextButton
                      padding={4}
                      onClick={() => onSelectArtHistory(id)}
                    >
                      수정
                    </TextButton>
                  </TableCell>
                  <TableCell>
                    <TextButton
                      padding={4}
                      backgroundColor="red"
                      onClick={async () => await onDeleteClick(id)}
                    >
                      삭제
                    </TextButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </tbody>
        )}
      </Table>
      <Pagination
        forcePage={offset}
        pageCount={totalPageCount}
        pageRangeDisplayed={5}
        previousLabel="Prev"
        nextLabel="Next"
        onPageChange={onPageChange}
      />
    </Flex>
  );
}

export default ArtHistoryTable;

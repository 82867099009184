import { useMutation } from "@apollo/client";
import { useState } from "react";
import {
  GET_SUBSCRIPTION_PLANS,
  UPDATE_SUBSCRIPTION_PLAN_AS_ADMIN,
} from "../../../lib/apollo/gql/subscriptionPlanGqls";

const initialState = {
  id: null,
  name: "",
  trialDays: "",
  monthlyPrice: "",
  duration: "",
  totalPrice: "",
  finalPrice: "",
  isHot: false,
  active: true,
};

function useSubscriptionPlanCard(plan) {
  const [state, setState] = useState({
    id: plan?.id || null,
    name: plan?.name || "",
    trialDays: plan?.trialDays !== null ? plan?.trialDays?.toString() : "",
    monthlyPrice:
      plan?.monthlyPrice !== null ? plan?.monthlyPrice?.toString() : "",
    duration: plan?.duration !== null ? plan?.duration?.toString() : "",
    totalPrice: plan?.totalPrice !== null ? plan?.totalPrice?.toString() : "",
    finalPrice: plan?.finalPrice !== null ? plan?.finalPrice?.toString() : "",
    isHot:
      plan?.isHot === null || plan?.isHot === undefined ? false : plan?.isHot,
    active:
      plan?.active === null || plan?.active === undefined
        ? false
        : plan?.active,
  });

  const [updateSubscriptionPlanAsAdmin, { loading }] = useMutation(
    UPDATE_SUBSCRIPTION_PLAN_AS_ADMIN,
    {
      onCompleted: () => {
        if (!plan) {
          setState(initialState);
        }
      },
      refetchQueries: () => [
        {
          query: GET_SUBSCRIPTION_PLANS,
          variables: {
            activeOnly: false,
          },
        },
      ],
    }
  );

  function onChange(e, type) {
    const { value } = e.target;
    const { checked } = e.target;

    setState((prev) => ({
      ...prev,
      [type]: type === "isHot" || type === "active" ? checked : value,
    }));
  }

  function isSubmitDisabled() {
    const {
      name,
      trialDays,
      monthlyPrice,
      duration,
      totalPrice,
      isHot,
      active,
      finalPrice,
    } = state;

    return (
      !name ||
      !(trialDays?.length > 0) ||
      !(monthlyPrice?.length > 0) ||
      !(duration?.length > 0) ||
      !(totalPrice?.length > 0) ||
      !(finalPrice?.length > 0) ||
      loading
    );
  }

  function onSubmit() {
    const {
      id,
      name,
      trialDays,
      monthlyPrice,
      duration,
      isHot,
      active,
      totalPrice,
      finalPrice,
    } = state;

    updateSubscriptionPlanAsAdmin({
      variables: {
        subscriptionPlanInput: {
          id,
          name,
          trialDays: Number(trialDays),
          monthlyPrice: Number(monthlyPrice),
          duration: Number(duration),
          finalPrice: Number(finalPrice),
          totalPrice: Number(totalPrice),
          isHot: isHot || false,
          active,
        },
      },
    });
  }

  return {
    models: {
      state,
    },
    operations: {
      onChange,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useSubscriptionPlanCard;

import { onAuthStateChanged } from "@firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { auth, firebaseSignOut } from "../../../lib/firebase";
import ROUTE_NAMES from "../../../routes/utils/routeNames";

const FirebaseContext = createContext(null);

function FirebaseProvider({ children }) {
  const navigate = useNavigate();
  const isSignIn = useMatch(ROUTE_NAMES.SIGN_IN.path);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const isAdmin = (await user.getIdTokenResult()).claims?.admin;

        if (!isAdmin) {
          alert("Only admin can access this page");
          await firebaseSignOut();
        } else {
          setUser(user);

          if (isSignIn) {
            navigate(ROUTE_NAMES.MAIN.path, { replace: true });
          }
        }
      } else {
        setUser(null);
        navigate(ROUTE_NAMES.SIGN_IN.path, { replace: true });
      }

      setLoading(false);
    });
  }, [isSignIn]);

  if (loading) return null;

  return (
    <FirebaseContext.Provider value={user}>{children}</FirebaseContext.Provider>
  );
}

export default FirebaseProvider;

export function useFirebaseContext() {
  return useContext(FirebaseContext);
}

import React from "react";
import LabeledInput from "../common/components/LabeledInput";
import { Flex, TextButton } from "../common/styles/commonStyles";
import useSignInContainer from "./SignInContainer.hook";
import {
  SignInContainerWrapper,
  SignInLogoImage,
} from "./SignInContainer.styles";

function SignInContainer() {
  const { models, operations } = useSignInContainer();

  const { state } = models;

  const { onChange, onPasswordKeyDown, onSubmit } = operations;

  const { email, password } = state;

  return (
    <SignInContainerWrapper>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="50%"
        padding={16}
        border="1px solid rgba(0,0,0,0.2)"
        borderRadius={6}
      >
        <Flex flex={1} backgroundColor="black" padding={12}>
          <SignInLogoImage />
        </Flex>
        <Flex
          flex={1}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          borderLeft="1px solid rgba(0,0,0,0.2)"
          paddingLeft={12}
        >
          <LabeledInput
            label="EMAIL"
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => onChange(e, "email")}
          />
          <LabeledInput
            label="PASSWORD"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => onChange(e, "password")}
            onKeyDown={onPasswordKeyDown}
          />
          <TextButton width="100%" onClick={onSubmit}>
            SIGN IN
          </TextButton>
        </Flex>
      </Flex>
    </SignInContainerWrapper>
  );
}

export default SignInContainer;

import { gql } from "@apollo/client";

export const GET_FAQS = gql`
  query GetFAQs {
    getFAQs {
      id
      question
      answer
      sort
    }
  }
`;

export const UPDATE_FAQ_AS_ADMIN = gql`
  mutation UpdateFAQAsAdmin($faqInput: FAQInput!) {
    updateFAQAsAdmin(faqInput: $faqInput) {
      id
      question
      answer
      sort
    }
  }
`;

import { gql } from "@apollo/client";

export const GET_BANNERS_AS_ADMIN = gql`
  query GetBannersAsAdmin($offset: Int, $limit: Int) {
    getBannersAsAdmin(offset: $offset, limit: $limit) {
      pageInfo {
        totalPages
        totalCounts
        offset
        limit
      }
      edges {
        id
        media {
          id
          uri
        }
        active
        externalUrl
        subscriberOnly
        sort
        isFirst
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_BANNERS_AS_ADMIN = gql`
  mutation UpdateBannerAsAdmin($bannerInput: BannerInput) {
    updateBannerAsAdmin(bannerInput: $bannerInput) {
      id
      media {
        id
        uri
      }
      active
      externalUrl
      subscriberOnly
      sort
      isFirst
      createdAt
      updatedAt
    }
  }
`;

import React from "react";
import AsyncSelector from "react-select/async";
import useArtSearchArtist from "../../../arts/updateArt/components/ArtSearchArtist.hook";

import { Flex, TextButton } from "../../../common/styles/commonStyles";

function UpdateSaleSearchBuyer({ buyer, buyerState, onBuyerChange }) {
  const {
    operations: { loadOptions },
  } = useArtSearchArtist();

  return (
    <Flex width="100%" justifyContent="flex-start" gap={8}>
      {buyer?.avatar && (
        <img
          src={buyer?.avatar?.uri}
          style={{
            width: 200,
            height: 200,
            borderRadius: 100,
            objectFit: "cover",
          }}
        />
      )}
      <AsyncSelector
        placeholder="구매자 선택"
        value={buyerState}
        loadOptions={loadOptions}
        onChange={onBuyerChange}
        styles={{
          container: (style) => ({ ...style, flex: 1 }),
        }}
      />
    </Flex>
  );
}

export default UpdateSaleSearchBuyer;

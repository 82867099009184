import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  GET_SALE_BY_ID_AS_ADMIN,
  GET_SALE_ITEMS,
  SET_SALE_DELIVERY_STATUS_AS_ADMIN,
} from "../../../../lib/apollo/gql/saleGqls";

const LIMIT = 10;

function useSaleList(selectedSaleType, searchedQuery) {
  const [offset, setOffset] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, error, fetchMore } = useQuery(
    !searchedQuery ? GET_SALE_ITEMS : GET_SALE_BY_ID_AS_ADMIN,
    {
      variables: !searchedQuery
        ? {
            saleType: selectedSaleType,
            onSaleOnly: false,
            offset: offset,
            limit: LIMIT,
          }
        : {
            saleId: searchedQuery,
          },
      onCompleted: !searchedQuery
        ? ({ getSaleItems }) => {
            const { pageInfo } = getSaleItems;

            const { totalPages } = pageInfo;

            setTotalPageCount(totalPages);
            setOffset(pageInfo.offset);
          }
        : () => {
            setTotalPageCount(0);
            setOffset(0);
          },
    }
  );

  const [setSaleDeliveryStatusAsAdmin] = useMutation(
    SET_SALE_DELIVERY_STATUS_AS_ADMIN
  );

  /**
   *  When page changed from pagination
   *  Fetch data for the offset changed
   */
  async function onPageChange({ selected }) {
    const newOffset = selected;
    if (!fetchingMore) {
      setFetchingMore(true);

      await fetchMore({
        variables: {
          onSaleOnly: false,
          offset: newOffset,
          limit: LIMIT,
        },
      });

      setOffset(newOffset);
      setFetchingMore(false);
    }
  }

  async function changeSaleDeliveryStatus(id, status) {
    await setSaleDeliveryStatusAsAdmin({
      variables: {
        saleId: id,
        deliveryStatus: status,
      },
    });
  }

  return {
    models: {
      data: !searchedQuery
        ? data?.getSaleItems?.edges
        : [data?.getSaleByIdAsAdmin],
      loading: loading || fetchingMore,
      offset,
      totalPageCount,
    },
    operations: {
      onPageChange,
      changeSaleDeliveryStatus,
    },
  };
}

export default useSaleList;

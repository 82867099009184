import React from "react";
import { SALE_STATUS, USER_TYPES } from "../../../utilities/constants";
import LabeledInput from "../../common/components/LabeledInput";
import LabeledTextarea from "../../common/components/LabeledTextarea";
import Loading from "../../common/components/Loading";
import Modal from "../../common/components/Modal";
import Thumbnail from "../../common/components/Thumbnail";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../common/styles/commonStyles";
import ArtCategorySelector from "./components/ArtCategorySelector";
import ArtColorSelector from "./components/ArtColorSelector";
import ArtSaleBankAccount from "./components/ArtSaleBankAccount";
import ArtSaleDeliveryOptionSelector from "./components/ArtSaleDeliveryOptionSelector";
import ArtSaleWarrantySelector from "./components/ArtSaleHasWarrantySelector";
import ArtSaleStatusSelector from "./components/ArtSaleStatusSelector";
import ArtSearchMaterialSelector from "./components/ArtSeachMaterialSelector";
import ArtSearchArtist from "./components/ArtSearchArtist";
import ArtSearchCategorySelector from "./components/ArtSearchCategorySelector";
import ArtTypeSelector from "./components/ArtTypeSelector";
import useUpdateArtModal, {
  UPDATE_ART_MODAL_ACTION_TYPES,
} from "./UpdateArtModal.hook";
import ArtSearchSpace from "./components/ArtSearchSpace";

function UpdateArtModal({ isOpen, selectedArtId, onRequestClose }) {
  const isNew = selectedArtId === "new";

  const { refs, models, operations } = useUpdateArtModal(
    selectedArtId,
    onRequestClose
  );

  const { primaryMediaRef, mediaRef, audioRef } = refs;

  const { state, loading, getArtLoading } = models;

  const {
    onChange,
    onSelectorChange,
    onPrimaryMediaChange,
    onMediaChange,
    onAudioChange,
    onSearchColorChange,
    onSearchMaterialChange,
    onSearchCategoryChange,
    onSubmit,
    isSubmitDisabled,
    onPrimaryMediaEditClick,
    onPrimaryMediaChangeClick,
    onAddMediaClick,
    onMediaDeleteClick,
    onUpdateMediaClick,
    onEditAudioClick,
    onAudioChangeClick,
  } = operations;

  const {
    type,
    category,
    artist,
    artistNameKor,
    artistNameEng,
    space,
    titleKor,
    titleEng,
    materialKor,
    materialEng,
    width,
    height,
    depth,
    year,
    tag,
    edition,
    owner,
    searchColorIds,
    searchMaterialIds,
    searchCategoryId,
    artistNote,
    primaryMedia,
    media,
    audios,
    primaryMediaState,
    mediaState,
    audiosState,
  } = state;

  return (
    <Modal
      isOpen={isOpen}
      title={isNew ? "작품 등록" : "작품 수정"}
      onRequestClose={onRequestClose}
    >
      {getArtLoading && <Loading />}
      {!getArtLoading && (
        <>
          <Flex justifyContent="space-between" gap={12}>
            {!isNew && (
              <Flex flex={1}>
                <LabeledInput label="ID" value={selectedArtId} disabled />
              </Flex>
            )}
          </Flex>
          <Flex justifyContent="flex-start" gap={12} marginBottom={12}>
            <Flex flex={1}>
              <ArtTypeSelector
                type={type}
                onSelectorChange={(value) =>
                  onSelectorChange(
                    value,
                    UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_TYPE
                  )
                }
              />
            </Flex>
            <Flex flex={1}>
              <ArtCategorySelector
                category={category}
                onSelectorChange={(value) =>
                  onSelectorChange(
                    value,
                    UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_CATEGORY
                  )
                }
              />
            </Flex>
          </Flex>
          <Flex marginBottom={12}>
            <Flex flexDirection="column" width="100%" alignItems="flex-start">
              <LabeledInput
                inputRef={primaryMediaRef}
                label="대표 이미지"
                type="file"
                accept="image/*"
                style={{
                  visibility:
                    primaryMedia || primaryMediaState ? "hidden" : "visible",
                }}
                onChange={onPrimaryMediaChange}
              />
              {primaryMedia && !primaryMediaState && (
                <Flex alignItems="center" justifyContent="flex-start" gap={4}>
                  <Thumbnail media={primaryMedia}>
                    <TextButton onClick={onPrimaryMediaEditClick}>
                      수정
                    </TextButton>
                  </Thumbnail>
                </Flex>
              )}
              {primaryMediaState && (
                <Flex alignItems="center" justifyContent="flex-start" gap={4}>
                  <Thumbnail
                    media={{ uri: URL.createObjectURL(primaryMediaState) }}
                  >
                    <Flex gap={4}>
                      <TextButton onClick={onPrimaryMediaEditClick}>
                        파일변경
                      </TextButton>
                      <TextButton
                        backgroundColor="blue"
                        onClick={onPrimaryMediaChangeClick}
                      >
                        수정완료
                      </TextButton>
                    </Flex>
                  </Thumbnail>
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex marginBottom={24}>
            <Flex flexDirection="column" width="100%" alignItems="flex-start">
              <LabeledInput
                inputRef={mediaRef}
                label="추가 이미지"
                type="file"
                accept="image/*"
                multiple
                onChange={onMediaChange}
                style={{
                  visibility: !isNew ? "hidden" : "visible",
                }}
              />
              {!isNew && (
                <TextButton onClick={onAddMediaClick}>
                  추가이미지 선택/변경하기
                </TextButton>
              )}
              {media?.length > 0 && (
                <Flex alignItems="center" justifyContent="flex-start" gap={4}>
                  {media?.map((media) => (
                    <Thumbnail key={media.id} media={media}>
                      <TextButton
                        backgroundColor="red"
                        onClick={() => onMediaDeleteClick(media.id)}
                      >
                        삭제
                      </TextButton>
                    </Thumbnail>
                  ))}
                </Flex>
              )}

              {mediaState?.length > 0 && (
                <Flex alignItems="center" justifyContent="flex-start" gap={4}>
                  {mediaState?.map((media) => (
                    <Thumbnail
                      key={media.name}
                      media={{ uri: URL.createObjectURL(media) }}
                    />
                  ))}
                </Flex>
              )}
              {!isNew && mediaState?.length > 0 && (
                <TextButton
                  backgroundColor="blue"
                  onClick={() => onUpdateMediaClick()}
                >
                  추가 이미지 등록하기
                </TextButton>
              )}
            </Flex>
          </Flex>
          <Flex marginBottom={8} justifyContent="flex-start">
            <SectionTitle>작품정보</SectionTitle>
          </Flex>
          <Flex marginBottom={8}>
            <Flex flex={1}>
              <ArtSearchArtist
                isNew={isNew}
                artist={artist}
                owner={owner}
                artistNameKor={artistNameKor}
                artistNameEng={artistNameEng}
                onSelectorChange={(value, userType) =>
                  onSelectorChange(
                    value,
                    userType === USER_TYPES.ARTIST
                      ? UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_ARTIST_ID
                      : UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_OWNER_ID
                  )
                }
                onNameKorChange={(e) =>
                  onChange(
                    e,
                    UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_ARTIST_NAME_KOR
                  )
                }
                onNameEngChange={(e) =>
                  onChange(
                    e,
                    UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_ARTIST_NAME_ENG
                  )
                }
              />
            </Flex>
          </Flex>
          <Flex marginBottom={8}>
            <Flex flex={1}>
              <ArtSearchSpace
                space={space}
                onSelectorChange={(value, userType) =>
                  onSelectorChange(
                    value,
                    UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_SPACE_ID
                  )
                }
              />
            </Flex>
          </Flex>
          <Flex marginBottom={8} justifyContent="space-between" gap={4}>
            <Flex flex={1}>
              <LabeledInput
                label="작품명(한글)"
                placeholder="작품명(한글)"
                value={titleKor}
                onChange={(e) =>
                  onChange(e, UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_TITLE_KOR)
                }
              />
            </Flex>
            <Flex flex={1}>
              <LabeledInput
                label="작품명(영문)"
                placeholder="작품명(영문)"
                value={titleEng}
                onChange={(e) =>
                  onChange(e, UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_TITLE_ENG)
                }
              />
            </Flex>
          </Flex>
          <Flex marginBottom={8} justifyContent="space-between" gap={4}>
            <Flex flex={1}>
              <LabeledInput
                label="재료명(한글)"
                placeholder="재료명(한글)"
                value={materialKor}
                onChange={(e) =>
                  onChange(e, UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_MATERIAL_KOR)
                }
              />
            </Flex>
            <Flex flex={1}>
              <LabeledInput
                label="재료명(영문)"
                placeholder="재료명(영문)"
                value={materialEng}
                onChange={(e) =>
                  onChange(e, UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_MATERIAL_ENG)
                }
              />
            </Flex>
          </Flex>
          <Flex marginBottom={8} justifyContent="space-between" gap={4}>
            <Flex flex={1}>
              <LabeledInput
                label="가로길이(cm)"
                placeholder="가로길이(cm)"
                value={width}
                onChange={(e) =>
                  onChange(e, UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_WIDTH)
                }
              />
            </Flex>
            <Flex flex={1}>
              <LabeledInput
                label="세로길이(cm)"
                placeholder="세로길이(cm)"
                value={height}
                onChange={(e) =>
                  onChange(e, UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_HEIGHT)
                }
              />
            </Flex>
            <Flex flex={1}>
              <LabeledInput
                label="깊이(cm)"
                placeholder="깊이(cm)"
                value={depth}
                onChange={(e) =>
                  onChange(e, UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_DEPTH)
                }
              />
            </Flex>
          </Flex>
          <Flex marginBottom={8} justifyContent="space-between" gap={4}>
            <Flex flex={1}>
              <LabeledInput
                label="제작연도"
                placeholder="제작연도"
                value={year}
                onChange={(e) =>
                  onChange(e, UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_YEAR)
                }
              />
            </Flex>
            <Flex flex={1}>
              <LabeledInput
                label="태그"
                placeholder="예)INDEX#24"
                value={tag}
                onChange={(e) =>
                  onChange(e, UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_TAG)
                }
              />
            </Flex>
            <Flex flex={1}>
              <LabeledInput
                label="에디션"
                placeholder="에디션"
                value={edition}
                onChange={(e) =>
                  onChange(e, UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_EDITION)
                }
              />
            </Flex>
          </Flex>
          <Flex marginBottom={12} justifyContent="space-between" gap={4}>
            <LabeledTextarea
              label="작가노트"
              placeholder="작가노트"
              value={artistNote}
              onChange={(e) =>
                onChange(e, UPDATE_ART_MODAL_ACTION_TYPES.HANDLE_ARTIST_NOTE)
              }
            />
          </Flex>
          <Flex marginBottom={12}>
            <Flex flexDirection="column" width="100%" alignItems="flex-start">
              <LabeledInput
                inputRef={audioRef}
                label="오디오"
                type="file"
                accept="audio/*"
                multiple
                onChange={onAudioChange}
                style={{ visibility: !isNew ? "hidden" : "visible" }}
              />
              {!isNew && (
                <TextButton onClick={onEditAudioClick}>
                  오디오파일 변경 하기
                </TextButton>
              )}
              {audios?.length > 0 && !(audiosState?.length > 0) && (
                <Flex
                  alignItems="center"
                  justifyContent="flex-start"
                  gap={4}
                  width="100%"
                  height={100}
                >
                  {audios?.map((audio) => (
                    <audio
                      key={audio.id}
                      controls
                      preload="auto"
                      controlsList="nodownload"
                    >
                      <source src={audio?.uri} />
                    </audio>
                  ))}
                </Flex>
              )}

              {audiosState?.length > 0 && (
                <Flex
                  alignItems="center"
                  justifyContent="flex-start"
                  gap={4}
                  width="100%"
                  height={100}
                >
                  {audiosState?.map((audio) => (
                    <audio
                      controls
                      preload="auto"
                      key={audio.name}
                      controlsList="nodownload"
                    >
                      <source src={URL.createObjectURL(audio)} />
                    </audio>
                  ))}
                </Flex>
              )}
              {!isNew && audiosState?.length > 0 && (
                <TextButton
                  backgroundColor="blue"
                  onClick={() => onAudioChangeClick()}
                >
                  변경된 오디오 등록하기
                </TextButton>
              )}
            </Flex>
          </Flex>
          <ArtColorSelector
            searchColorIds={searchColorIds}
            onSearchColorChange={onSearchColorChange}
          />
          <ArtSearchMaterialSelector
            searchMaterialIds={searchMaterialIds}
            onSearchMaterialChange={onSearchMaterialChange}
          />
          <ArtSearchCategorySelector
            searchCategoryId={searchCategoryId}
            onSearchCategoryChange={onSearchCategoryChange}
          />

          <Flex marginTop={24}>
            <TextButton
              width={300}
              onClick={async () => await onSubmit()}
              disabled={isSubmitDisabled()}
            >
              {loading ? "등록중" : "등록하기"}
            </TextButton>
          </Flex>
        </>
      )}
    </Modal>
  );
}

export default UpdateArtModal;

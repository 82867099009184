import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  GET_POLICIES,
  UPDATE_POLICY_AS_ADMIN,
} from "../../lib/apollo/gql/policyGqls";
import { POLICY_TYPE } from "../../utilities/constants";

function usePolicyContainer() {
  const [selectedPolicy, setSelectedPolicy] = useState(POLICY_TYPE.TERMS);
  const [html, setHtml] = useState("");

  const { data, loading } = useQuery(GET_POLICIES, {
    onCompleted: ({ getPolicies }) => {
      const { terms, privacy } = getPolicies;

      if (selectedPolicy === POLICY_TYPE.TERMS) {
        setHtml(terms);
      } else {
        setHtml(privacy);
      }
    },
  });

  const [updatePolicyAsAdmin] = useMutation(UPDATE_POLICY_AS_ADMIN);

  useEffect(() => {
    if (data?.getPolicies) {
      const { terms, privacy } = data?.getPolicies;

      if (selectedPolicy === POLICY_TYPE.TERMS) {
        setHtml(terms);
      } else {
        setHtml(privacy);
      }
    }
  }, [data, selectedPolicy]);

  function onPolicyTypeSelect(type) {
    setSelectedPolicy(type);
  }

  /**
   * Handle content body change
   * @param {string} value
   */
  function onBodyChange(value) {
    setHtml(value);
  }

  function onSubmit() {
    const policyInput =
      selectedPolicy === POLICY_TYPE.TERMS
        ? {
            terms: html,
          }
        : {
            privacy: html,
          };

    updatePolicyAsAdmin({
      variables: {
        policyInput,
      },
    });
  }

  return {
    models: {
      selectedPolicy,
      html,
      loading,
    },
    operations: {
      onPolicyTypeSelect,
      onBodyChange,
      onSubmit,
    },
  };
}

export default usePolicyContainer;

import React from "react";
import AsyncSelector from "react-select/async";

import { USER_TYPES } from "../../../../utilities/constants";
import { Flex, SectionTitle } from "../../../common/styles/commonStyles";
import useArtSearchArtist from "./ArtSearchSpace.hook";

function ArtSearchSpace({ space, onSelectorChange }) {
  const { operations } = useArtSearchArtist();

  const { loadOptions } = operations;

  return (
    <Flex flexDirection="column" alignItems="flex-start" gap={8} width="100%">
      <SectionTitle>스페이스</SectionTitle>
      <Flex width="100%" justifyContent="flex-start" gap={8}>
        <AsyncSelector
          placeholder="스페이스"
          value={space}
          loadOptions={loadOptions}
          onChange={(value) => onSelectorChange(value, USER_TYPES.SPACE)}
          styles={{
            container: (style) => ({ ...style, flex: 1 }),
          }}
        />
      </Flex>
    </Flex>
  );
}

export default ArtSearchSpace;

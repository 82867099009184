import React from "react";
import { Flex, TextButton } from "../../../common/styles/commonStyles";

function ExhibitionListNavBar({ onSelectExhibition }) {
  return (
    <Flex
      padding={12}
      justifyContent="space-between"
      width="100%"
      backgroundColor="rgba(0,0,0,0.1)"
    >
      <Flex gap={4}>
        <TextButton
          fontSize={14}
          backgroundColor="blue"
          onClick={() => onSelectExhibition("new")}
        >
          새 전시 등록
        </TextButton>
      </Flex>
    </Flex>
  );
}

export default ExhibitionListNavBar;

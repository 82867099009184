import { gql } from "@apollo/client";
import { ORDER_FIELD, PAGE_INFO_FIELD, SHIPPING_FIELD } from "./fragments";

export const GET_ALL_ORDERS_AS_ADMIN = gql`
  ${PAGE_INFO_FIELD}
  ${ORDER_FIELD}
  query GetAllOrdersAsAdmin($activeOnly: Boolean, $offset: Int, $limit: Int) {
    getAllOrdersAsAdmin(
      activeOnly: $activeOnly
      offset: $offset
      limit: $limit
    ) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...OrderField
      }
    }
  }
`;

export const GET_ORDER = gql`
  ${ORDER_FIELD}
  query GetOrder($orderId: ID!) {
    getOrder(orderId: $orderId) {
      ...OrderField
    }
  }
`;

export const UPDATE_SHIPPING_AS_ADMIN = gql`
  ${SHIPPING_FIELD}
  mutation UpdateShippingAsAdmin($shippingInput: ShippingInput!) {
    updateShippingAsAdmin(shippingInput: $shippingInput) {
      ...ShippingField
    }
  }
`;

export const CONFIRM_CANCEL_ORDER_AS_ADMIN = gql`
  ${ORDER_FIELD}
  mutation ConfirmCancelOrderAsAdmin($orderId: ID!) {
    confirmCancelOrderAsAdmin(orderId: $orderId) {
      ...OrderField
    }
  }
`;

export const GET_ORDER_BY_ID_AS_ADMIN = gql`
  ${ORDER_FIELD}
  query GetOrderByIdAsAdmin($orderId: ID!) {
    getOrderByIdAsAdmin(orderId: $orderId) {
      ...OrderField
    }
  }
`;

export const GET_DELIVERY_FEE = gql`
  query GetDeliveryFee {
    getDeliveryFee {
      fee
    }
  }
`;

export const UPDATE_DELIVERY_FEE_AS_ADMIN = gql`
  mutation UpdateDeliveryFeeAsAdmin($fee: String!) {
    updateDeliveryFeeAsAdmin(fee: $fee) {
      fee
    }
  }
`;

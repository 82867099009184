import { useQuery } from "@apollo/client";
import { GET_ART_CATEGORIES } from "../../../../lib/apollo/gql/artGqls";

function useArtCategorySelector() {
  const { data, loading } = useQuery(GET_ART_CATEGORIES);

  return {
    models: {
      loading,
      data: data?.getArtCategories,
    },
  };
}

export default useArtCategorySelector;

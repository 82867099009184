import React from "react";
import Loading from "../../../common/components/Loading";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../../common/styles/commonStyles";
import useArtSearchMaterialSelector from "./ArtSearchMaterialSelector.hook";

function ArtSearchMaterialSelector({
  searchMaterialIds,
  onSearchMaterialChange,
}) {
  const { models } = useArtSearchMaterialSelector();

  const { data, loading } = models;

  if (loading) return <Loading />;

  return (
    <>
      <SectionTitle>검색 재료(복수 선택)</SectionTitle>
      <Flex
        flexWrap="wrap"
        gap={4}
        justifyContent="flex-start"
        marginTop={8}
        marginBottom={12}
      >
        {data?.map((material) => {
          const isSelected = searchMaterialIds.includes(material.id);
          return (
            <TextButton
              key={material.id}
              backgroundColor={isSelected ? "blue" : "white"}
              color={isSelected ? "white" : "black"}
              onClick={() => onSearchMaterialChange(material.id)}
            >
              {material.material}
            </TextButton>
          );
        })}
      </Flex>
    </>
  );
}

export default ArtSearchMaterialSelector;

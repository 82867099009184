import React from "react";
import {
  Flex,
  SearchIcon,
  SearchInput,
  TextButton,
} from "../../../common/styles/commonStyles";
import { USER_TYPES } from "../../../../utilities/constants";

function UserListNavBar({
  selectedUserType,
  searchQuery,
  onUserTypeClick,
  onSearchChange,
  onSearchKeyDown,
  onSearch,
  onUserSelect,
}) {
  return (
    <Flex
      padding={12}
      justifyContent="space-between"
      width="100%"
      backgroundColor="rgba(0,0,0,0.1)"
    >
      <Flex gap={4}>
        {Object.keys(USER_TYPES).map((type) => {
          const active = selectedUserType === USER_TYPES[type];
          return (
            <TextButton
              key={type}
              fontSize={12}
              backgroundColor={active ? "black" : "white"}
              color={active ? "white" : "black"}
              onClick={() => onUserTypeClick(USER_TYPES[type])}
            >
              {USER_TYPES[type]}
            </TextButton>
          );
        })}
        <TextButton
          fontSize={12}
          backgroundColor="blue"
          color="white"
          onClick={() => onUserSelect("new")}
        >
          등록
        </TextButton>
      </Flex>
      <Flex alignItems="center" gap={2}>
        <SearchInput
          placeholder="이메일, 이름, 영문이름, 한글이름"
          value={searchQuery}
          onChange={onSearchChange}
          onKeyDown={onSearchKeyDown}
        />
        <TextButton
          backgroundColor="white"
          width={24}
          height={24}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={onSearch}
        >
          <SearchIcon />
        </TextButton>
      </Flex>
    </Flex>
  );
}

export default UserListNavBar;

import React from "react";
import useSaleList from "./SaleList.hook";
import SaleListTable from "./SaleListTable";

function SaleList({ selectedSaleType, searchedQuery, onSaleSelect }) {
  const { models, operations } = useSaleList(selectedSaleType, searchedQuery);

  const { data, loading, offset, totalPageCount } = models;

  const { onPageChange, changeSaleDeliveryStatus } = operations;

  if (loading) return <div />;

  return (
    <SaleListTable
      data={data}
      loading={loading}
      offset={offset}
      totalPageCount={totalPageCount}
      onPageChange={onPageChange}
      changeSaleDeliveryStatus={changeSaleDeliveryStatus}
      onSaleSelect={onSaleSelect}
    />
  );
}

export default SaleList;

import React from "react";
import { Flex } from "../../common/styles/commonStyles";
import PlaylistsNavBar from "./components/PlaylistsNavBar";
import usePlaylistsContainer from "./PlaylistsContainer.hook";
import UpdatePlaylistContainer from "../updatePlaylist/UpdatePlaylistContainer";
import PlaylistsTable from "./components/PlaylistsTable";

function PlaylistsContainer() {
  const {
    models: { selectedPlaylistId },
    operations: { onPlaylistSelect },
  } = usePlaylistsContainer();

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
    >
      <PlaylistsNavBar onPlaylistSelect={onPlaylistSelect} />

      <PlaylistsTable onPlaylistSelect={onPlaylistSelect} />

      {!!selectedPlaylistId && (
        <UpdatePlaylistContainer
          isOpen={!!selectedPlaylistId}
          selectedPlaylistId={selectedPlaylistId}
          onRequestClose={() => onPlaylistSelect(null)}
        />
      )}
    </Flex>
  );
}

export default PlaylistsContainer;

import { useQuery } from "@apollo/client";
import { GET_ADMIN_STATS } from "../../lib/apollo/gql/stat";

function useMainContainer() {
  const { data, loading, error } = useQuery(GET_ADMIN_STATS);

  return {
    models: {
      loading,
      data: data?.getAdminStats,
    },
  };
}

export default useMainContainer;

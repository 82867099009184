import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  GET_ADMIN_PHRASE,
  UPDATE_ADMIN_PHRASE_AS_ADMIN,
} from "../../../lib/apollo/gql/manageGqls";

function useAdminPhraseContainer() {
  const [state, setState] = useState({
    editorial: "",
    docent: "",
    interview: "",
    essay: "",
    weekly: "",
    artHistory: "",
    notes: "",
  });

  const { data, loading, error } = useQuery(GET_ADMIN_PHRASE, {
    onCompleted: ({ getAdminPhrase }) => {
      const { editorial, docent, interview, essay, weekly, artHistory, notes } =
        getAdminPhrase;

      setState({
        editorial: editorial || "",
        docent: docent || "",
        interview: interview || "",
        essay: essay || "",
        weekly: weekly || "",
        artHistory: artHistory || "",
        notes: notes || "",
      });
    },
  });

  const [updateAdminPhraseAsAdmin, { loading: updateLoading }] = useMutation(
    UPDATE_ADMIN_PHRASE_AS_ADMIN
  );

  function onInputChange(e, key) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onSubmit() {
    updateAdminPhraseAsAdmin({
      variables: {
        adminPhraseInput: {
          ...state,
        },
      },
    });
  }

  return {
    models: {
      loading,
      updateLoading,
      state,
    },
    operations: {
      onInputChange,
      onSubmit,
    },
  };
}

export default useAdminPhraseContainer;

import { gql } from "@apollo/client";
import { PAGE_INFO_FIELD, WEEKLY_FIELD } from "./fragments";

export const UPDATE_WEEKLY_AS_ADMIN = gql`
  ${WEEKLY_FIELD}
  mutation UpdateWeeklyAsAdmin($weeklyInput: WeeklyInput!) {
    updateWeeklyAsAdmin(weeklyInput: $weeklyInput) {
      ...WeeklyField
    }
  }
`;

export const GET_WEEKLIES = gql`
  ${WEEKLY_FIELD}
  ${PAGE_INFO_FIELD}
  query GetWeeklies($offset: Int, $limit: Int, $activeOnly: Boolean) {
    getWeeklies(offset: $offset, limit: $limit, activeOnly: $activeOnly) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...WeeklyField
      }
    }
  }
`;

export const SEARCH_WEEKLIES = gql`
  ${WEEKLY_FIELD}
  ${PAGE_INFO_FIELD}
  query SearchWeeklies($query: String!, $offset: Int, $limit: Int) {
    searchWeeklies(query: $query, offset: $offset, limit: $limit) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...WeeklyField
      }
    }
  }
`;

export const DELETE_WEEKLY_AS_ADMIN = gql`
  mutation DeleteWeeklyAsAdmin($weeklyId: ID!) {
    deleteWeeklyAsAdmin(weeklyId: $weeklyId)
  }
`;

export const GET_WEEKLY = gql`
  ${WEEKLY_FIELD}
  query GetWeekly($weeklyId: ID!) {
    getWeekly(weeklyId: $weeklyId) {
      ...WeeklyField
    }
  }
`;

import styled from "styled-components";
import { Flex } from "../common/styles/commonStyles";

const ACCOUNT_DIV_HEIGHT = 100;

export const NavigationContainerWrapper = styled(Flex)(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
  overflowY: "auto",
  backgroundColor: "#4e73df",

  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  gap: 12,
}));

export const NavigationMenuWrapper = styled.div(() => ({
  width: "100%",
}));

export const NavigationMenuSection = styled.div(() => ({
  padding: "8px 12px",
  borderRadius: 6,
  color: "white",
}));

export const NavigationMenuTitle = styled.div(() => ({
  fontSize: 12,
  marginBottom: 8,
}));

export const NavigationMenuDivider = styled.div(() => ({
  width: "100%",
  height: 0.5,
  backgroundColor: "rgba(255,255,255,0.5)",
  marginTop: 12,
}));

export const NavigationMenuAccount = styled.div(() => ({
  width: "100%",
  padding: 8,
  backgroundColor: "rgba(0,0,0,0.2)",
  color: "white",
  display: "flex",
  flexDirection: "column",
  gap: 4,
}));

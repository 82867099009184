import React from "react";
import { Flex } from "../../common/styles/commonStyles";
import copyText from "../../common/utils/copyText";
import moment from "moment";

function QuestionCard({ question }) {
  const { id, user, title, email, createdAt } = question;

  return (
    <Flex
      flexBasis="33%"
      flexDirection="column"
      border="1px solid black"
      borderRadius={4}
      gap={8}
      alignItems="flex-start"
    >
      <div>{user?.name || "비회원"}</div>
      <div onClick={async () => await copyText(email)}>이메일 : {email}</div>
      <div>제목 : {title}</div>
      <div>문의 내용</div>
      <div>{question?.question}</div>
      <div style={{ marginTop: 8, fontSize: 14, color: "grey" }}>
        {moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}
      </div>
    </Flex>
  );
}

export default QuestionCard;

import React from "react";
import HTMLEditor from "../../common/components/HTMLEditor";
import LabeledInput from "../../common/components/LabeledInput";
import Loading from "../../common/components/Loading";
import Modal from "../../common/components/Modal";
import SearchArtSelector from "../../common/components/SearchArtSelector";
import SearchUserSelector from "../../common/components/SearchUserSelector";
import Thumbnail from "../../common/components/Thumbnail";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../common/styles/commonStyles";
import useUpdateArtHistoryModal, {
  UPDATE_ART_HISTORY_MODAL_ACTION_TYPES,
} from "./UpdateArtHistoryModal.hook";

function UpdateArtHistoryModal({
  isOpen,
  selectedArtHistoryId,
  onRequestClose,
}) {
  const isNew = selectedArtHistoryId === "new";

  const { refs, models, operations } = useUpdateArtHistoryModal(
    selectedArtHistoryId,
    onRequestClose
  );

  const { paringMediaRef } = refs;

  const { state, updateLoading, orderLoading, artHistoryLoading } = models;

  const {
    onMediaChange,
    onMediaEditClick,
    onMediaChangeCancelClick,
    onInputChange,
    onWriterSelect,
    onArtSelect,
    onBodyChange,
    isSubmitDisabled,
    onSubmit,
  } = operations;

  const {
    id,
    theme,
    art,
    writer,
    order,
    paringMediaState,
    paringMedia,
    title,
    highlight,
    body,
  } = state;

  return (
    <Modal
      isOpen={isOpen}
      title={isNew ? "미술사 등록" : "미술사 수정"}
      onRequestClose={onRequestClose}
    >
      {artHistoryLoading && <Loading />}
      {!artHistoryLoading && (
        <>
          <Flex justifyContent="space-between" gap={12}>
            {!isNew && (
              <Flex flex={1}>
                <LabeledInput
                  label="ID"
                  value={selectedArtHistoryId}
                  disabled
                />
              </Flex>
            )}
          </Flex>
          <SearchArtSelector value={art} onSelectorChange={onArtSelect} />

          <SearchUserSelector
            userType="WRITER"
            title="작가 선택"
            placeholder="저자(작가)"
            value={writer}
            onSelectorChange={onWriterSelect}
          />

          <LabeledInput
            inputRef={paringMediaRef}
            label="비교 이미지"
            type="file"
            accept="image/*"
            style={{
              visibility:
                paringMedia || paringMediaState ? "hidden" : "visible",
            }}
            onChange={onMediaChange}
          />
          {paringMedia && !paringMediaState && (
            <Flex alignItems="center" justifyContent="flex-start" gap={4}>
              <Thumbnail media={paringMedia}>
                <TextButton onClick={onMediaEditClick}>수정</TextButton>
              </Thumbnail>
            </Flex>
          )}
          {paringMediaState && (
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              gap={4}
              marginBottom={12}
            >
              <Thumbnail media={{ uri: URL.createObjectURL(paringMediaState) }}>
                <Flex gap={4}>
                  <TextButton onClick={onMediaEditClick}>파일변경</TextButton>
                  {paringMedia && (
                    <TextButton
                      backgroundColor="red"
                      onClick={onMediaChangeCancelClick}
                    >
                      수정 취소
                    </TextButton>
                  )}
                </Flex>
              </Thumbnail>
            </Flex>
          )}
          {!orderLoading && (
            <LabeledInput
              label="순서"
              placeholder="순서"
              type="number"
              value={order}
              onChange={(e) =>
                onInputChange(
                  e,
                  UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_ORDER
                )
              }
            />
          )}
          <LabeledInput
            label="미술사조"
            placeholder="미술 사조"
            value={theme}
            onChange={(e) =>
              onInputChange(
                e,
                UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_THEME
              )
            }
          />
          <LabeledInput
            label="컨텐츠 제목"
            placeholder="컨텐츠 제목"
            value={title}
            onChange={(e) =>
              onInputChange(
                e,
                UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_TITLE
              )
            }
          />
          <LabeledInput
            label="컨텐츠 하일라이트"
            placeholder="컨텐츠 하일라이트"
            value={highlight}
            onChange={(e) =>
              onInputChange(
                e,
                UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_HIGHLIGHT
              )
            }
          />
          <Flex width="100%" flexDirection="column" alignItems="flex-start">
            <SectionTitle>컨텐츠 본문</SectionTitle>
            <HTMLEditor value={body} onChange={onBodyChange} />
          </Flex>
          <Flex marginTop={80}>
            <TextButton
              width={300}
              onClick={async () => await onSubmit()}
              disabled={isSubmitDisabled()}
            >
              {updateLoading ? "등록중" : "등록하기"}
            </TextButton>
          </Flex>
        </>
      )}
    </Modal>
  );
}

export default UpdateArtHistoryModal;

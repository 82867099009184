import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  DELETE_MARKETING_AS_ADMIN,
  GET_MARKETINGS,
} from "../../../../lib/apollo/gql/marketingGqls";

const LIMIT = 10;

function useMarketingListTable() {
  const [offset, setOffset] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);

  const [deleteMarketingAsAdmin] = useMutation(DELETE_MARKETING_AS_ADMIN, {
    refetchQueries: () => [
      {
        query: GET_MARKETINGS,
        variables: {
          offset,
          limit: LIMIT,
        },
      },
    ],
  });

  const { data, loading, error, fetchMore } = useQuery(GET_MARKETINGS, {
    variables: {
      offset,
      limit: LIMIT,
    },
    onCompleted: ({ getMarketings }) => {
      const { pageInfo } = getMarketings;

      const { totalPages } = pageInfo;

      setTotalPageCount(totalPages);
      setOffset(pageInfo.offset);
    },
  });

  /**
   *  When page changed from pagination
   *  Fetch data for the offset changed
   */
  async function onPageChange({ selected }) {
    const newOffset = selected;
    if (!fetchingMore) {
      setFetchingMore(true);
      fetchMore({
        variables: {
          offset: newOffset,
          limit: LIMIT,
        },
      });
      setOffset(newOffset);
      setFetchingMore(false);
    }
  }

  async function onDeleteClick(id) {
    await deleteMarketingAsAdmin({
      variables: {
        marketingId: id,
      },
    });
  }

  return {
    models: {
      loading,
      data: data?.getMarketings?.edges,
      offset,
      totalPageCount,
    },
    operations: {
      onPageChange,
      onDeleteClick,
    },
  };
}

export default useMarketingListTable;

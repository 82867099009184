import React from "react";
import LabeledInput from "../../../common/components/LabeledInput";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../../common/styles/commonStyles";
import useUpdateCoupon from "./UpdateCoupon.hook";

function UpdateCoupon({ selectedCoupon }) {
  const {
    models: { state },
    operations: { onChange, isSubmitDisabled, onSubmit },
  } = useUpdateCoupon(selectedCoupon);

  const { id, remains, couponTypeId, expiresAt } = state;

  return (
    <Flex
      flex={1}
      padding={12}
      alignItems="flex-start"
      justifyContent="flex-start"
      flexDirection="column"
      width="100%"
    >
      <SectionTitle>쿠폰 추가/수정</SectionTitle>
      {selectedCoupon && (
        <LabeledInput label="ID" value={selectedCoupon?.id} disabled />
      )}
      <Flex
        flexDirection="column"
        width="100%"
        gap={8}
        alignItems="flex-start"
        marginBottom={12}
      >
        <LabeledInput
          label="쿠폰 이름(ONLY ENGLISH)"
          placeholder="ex) 20DISCOUNT"
          value={id}
          onChange={(e) => onChange(e, "id")}
        />
        <LabeledInput
          label="쿠폰 타입 ID"
          placeholder="쿠폰 타입 ID"
          value={couponTypeId}
          onChange={(e) => onChange(e, "couponTypeId")}
        />
        <LabeledInput
          label="발행갯수"
          placeholder="발행갯수"
          type="number"
          value={remains}
          onChange={(e) => onChange(e, "remains")}
        />
        <LabeledInput
          label="만료일"
          type="date"
          value={expiresAt}
          onChange={(e) => onChange(e, "expiresAt")}
        />
      </Flex>
      <TextButton
        backgroundColor="blue"
        disabled={isSubmitDisabled()}
        onClick={() => onSubmit()}
      >
        등록/수정
      </TextButton>
    </Flex>
  );
}

export default UpdateCoupon;

import { useMutation } from "@apollo/client";
import { useState } from "react";
import {
  GET_ALL_NOTICES_AS_ADMIN,
  UPDATE_NOTICE_AS_ADMIN,
} from "../../../lib/apollo/gql/noticeGqls";

function useAddNotice() {
  const [showInputs, setShowInputs] = useState(false);
  const [state, setState] = useState({
    marketingId: "",
    active: true,
  });

  const [updateNoticeAsAdmin, { loading }] = useMutation(
    UPDATE_NOTICE_AS_ADMIN,
    {
      onCompleted: () => {
        setShowInputs(false);
      },
      refetchQueries: () => [
        {
          query: GET_ALL_NOTICES_AS_ADMIN,
        },
      ],
    }
  );

  function toggleShowInputs() {
    setShowInputs(!showInputs);
  }

  function onChange(e, key) {
    const { value } = e.target;
    const { checked } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: key === "active" ? checked : value,
    }));
  }

  function isSubmitDisabled() {
    return !state.marketingId || loading;
  }

  function onSubmit() {
    updateNoticeAsAdmin({
      variables: {
        noticeInput: {
          marketingId: state.marketingId,
          active: state.active,
        },
      },
    });
  }

  return {
    models: {
      state,
      showInputs,
    },
    operations: {
      toggleShowInputs,
      onChange,
      onSubmit,
      isSubmitDisabled,
    },
  };
}

export default useAddNotice;

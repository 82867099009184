import { useQuery } from "@apollo/client";
import { GET_FAQS } from "../../lib/apollo/gql/faqGqls";

function useFAQContainer() {
  const { data, loading } = useQuery(GET_FAQS);

  return {
    models: {
      data: data?.getFAQs,
      loading,
    },
  };
}

export default useFAQContainer;

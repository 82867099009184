import React from "react";
import { Flex } from "../../common/styles/commonStyles";
import SubscriptionDetailModal from "./components/SubscriptionDetailModal";
import SubscriptionListNavBar from "./components/SubscriptionListNavBar";
import SubscriptionListTable from "./components/SubscriptionListTable";
import useSubscriptionListContainer from "./SubscriptionListContainer.hook";

function SubscriptionListContainer() {
  const {
    models: { selectedSubscriptionStatus, selectedSubscriptionId },
    operations: { onSubscriptionStatusSelect, onSubscriptionSelect },
  } = useSubscriptionListContainer();

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
    >
      <SubscriptionListNavBar
        selectedSubscriptionStatus={selectedSubscriptionStatus}
        onSubscriptionStatusSelect={onSubscriptionStatusSelect}
      />
      <SubscriptionListTable
        selectedSubscriptionStatus={selectedSubscriptionStatus}
        onSubscriptionSelect={onSubscriptionSelect}
      />

      {!!selectedSubscriptionId && (
        <SubscriptionDetailModal
          isOpen={!!selectedSubscriptionId}
          selectedSubscriptionId={selectedSubscriptionId}
          onRequestClose={() => onSubscriptionSelect(null)}
        />
      )}
    </Flex>
  );
}

export default SubscriptionListContainer;

import { gql } from "@apollo/client";
import { NOTICE_FIELD } from "./fragments";

export const UPDATE_NOTICE_AS_ADMIN = gql`
  ${NOTICE_FIELD}
  mutation UpdateNoticeAsAdmin($noticeInput: NoticeInput!) {
    updateNoticeAsAdmin(noticeInput: $noticeInput) {
      ...NoticeField
    }
  }
`;

export const DELETE_NOTICE_AS_ADMIN = gql`
  mutation DeleteNoticeAsAdmin($noticeId: ID!) {
    deleteNoticeAsAdmin(noticeId: $noticeId)
  }
`;

export const GET_ALL_NOTICES_AS_ADMIN = gql`
  ${NOTICE_FIELD}
  query GetAllNoticesAsAdmin {
    getAllNoticesAsAdmin {
      ...NoticeField
    }
  }
`;

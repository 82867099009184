import React from "react";
import { Flex } from "../styles/commonStyles";
import loadingIcon from "../../../assets/icons/loading.gif";

function Loading() {
  return (
    <Flex width="100%" padding={40}>
      <img src={loadingIcon} />
    </Flex>
  );
}

export default Loading;

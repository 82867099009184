import React from "react";
import { Flex } from "../../common/styles/commonStyles";
import UpdateSaleModal from "../updateSale/UpdateSaleModal";
import SaleList from "./components/SaleList";
import SaleListNavBar from "./components/SaleListNavBar";
import useSaleListContainer from "./SaleListContainer.hook";

function SaleListContainer() {
  const {
    models: { selectedSaleType, searchQuery, searchedQuery, selectedSaleId },
    operations: {
      onSaleTypeCick,
      onSearchChange,
      onSearchKeyDown,
      onSearch,
      onSaleSelect,
    },
  } = useSaleListContainer();

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
    >
      <SaleListNavBar
        selectedSaleType={selectedSaleType}
        searchQuery={searchQuery}
        onSearchChange={onSearchChange}
        onSearchKeyDown={onSearchKeyDown}
        onSearch={onSearch}
        onSaleTypeCick={onSaleTypeCick}
      />
      <SaleList
        selectedSaleType={selectedSaleType}
        searchedQuery={searchedQuery}
        onSaleSelect={onSaleSelect}
      />

      {!!selectedSaleId && (
        <UpdateSaleModal
          isOpen={!!selectedSaleId}
          onRequestClose={() => onSaleSelect(null)}
          selectedSaleId={selectedSaleId}
        />
      )}
    </Flex>
  );
}

export default SaleListContainer;

import { useState } from "react";
import { USER_TYPES } from "../../../utilities/constants";

function useUserListContainer() {
  const [selectedUserType, setSelectedUserType] = useState(USER_TYPES.ALL);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchedQuery, setSearchedQuery] = useState("");

  function onUserTypeClick(type) {
    if (type !== selectedUserType) {
      setSelectedUserType(type);
    }
  }

  function onSearchChange(e) {
    const { value } = e.target;

    setSearchQuery(value);
    if (value.length === 0 && searchedQuery) {
      setSearchedQuery("");
    }
  }

  function onSearchKeyDown(e) {
    const { key } = e;

    if (key === "Enter") {
      onSearch();
    }
  }

  function onSearch() {
    if (searchQuery !== searchedQuery) {
      setSearchedQuery(searchQuery);
    }
  }

  function onUserSelect(id) {
    setSelectedUserId(id);
  }

  return {
    models: {
      selectedUserType,
      searchQuery,
      searchedQuery,
      selectedUserId,
    },
    operations: {
      onUserTypeClick,
      onSearchChange,
      onSearchKeyDown,
      onSearch,
      onUserSelect,
    },
  };
}

export default useUserListContainer;

import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  GET_FOOTER_INFORMATION,
  UPDATE_FOOTER_INFORMATION_AS_ADMIN,
} from "../../../lib/apollo/gql/manageGqls";

function useFooterInformationContainer() {
  const [state, setState] = useState({
    copyrightMain: "",
    copyright: "",
    email: "",
    ceoName: "",
    address: "",
    registrationNumber: "",
    escroRegistration: "",
    phone: "",
    workingHour: "",
    instagramUrl: "",
    subscriberMessage: "",
    subscriberMessageUrl: "",
  });

  const { data, loading } = useQuery(GET_FOOTER_INFORMATION, {
    onCompleted: ({ getFooterInformation }) => {
      const {
        copyrightMain,
        copyright,
        email,
        ceoName,
        address,
        registrationNumber,
        escroRegistration,
        phone,
        workingHour,
        instagramUrl,
        subscriberMessage,
        subscriberMessageUrl,
      } = getFooterInformation;

      setState({
        copyrightMain: copyrightMain || "",
        copyright: copyright || "",
        email: email || "",
        ceoName: ceoName || "",
        address: address || "",
        registrationNumber: registrationNumber || "",
        escroRegistration: escroRegistration || "",
        phone: phone || "",
        workingHour: workingHour || "",
        instagramUrl: instagramUrl || "",
        subscriberMessage: subscriberMessage || "",
        subscriberMessageUrl: subscriberMessageUrl || "",
      });
    },
  });

  const [updateFooterInformationAsAdmin, { loading: updateLoading }] =
    useMutation(UPDATE_FOOTER_INFORMATION_AS_ADMIN);

  function onInputChange(e, key) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onSubmit() {
    updateFooterInformationAsAdmin({
      variables: {
        footerInformationInput: {
          ...state,
        },
      },
    });
  }

  return {
    models: {
      state,
      loading,
      updateLoading,
    },
    operations: {
      onInputChange,
      onSubmit,
    },
  };
}

export default useFooterInformationContainer;

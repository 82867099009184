import React from "react";
import { POLICY_TYPE } from "../../utilities/constants";
import HTMLEditor from "../common/components/HTMLEditor";
import { Flex, SectionTitle, TextButton } from "../common/styles/commonStyles";
import usePolicyContainer from "./PolicyContainer.hook";

function PolicyContainer() {
  const {
    models: { selectedPolicy, html, loading },
    operations: { onPolicyTypeSelect, onBodyChange, onSubmit },
  } = usePolicyContainer();

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
    >
      <Flex
        width="100%"
        padding={12}
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        gap={4}
        marginBottom={20}
      >
        <TextButton
          backgroundColor={
            selectedPolicy === POLICY_TYPE.TERMS ? "blue" : "gray"
          }
          onClick={() => onPolicyTypeSelect(POLICY_TYPE.TERMS)}
        >
          이용약관
        </TextButton>
        <TextButton
          backgroundColor={
            selectedPolicy === POLICY_TYPE.PRIVACY ? "blue" : "gray"
          }
          onClick={() => onPolicyTypeSelect(POLICY_TYPE.PRIVACY)}
        >
          개인정보처리방침
        </TextButton>
      </Flex>

      <Flex
        width="100%"
        flexDirection="column"
        alignItems="flex-start"
        marginBottom={120}
      >
        <HTMLEditor height={600} value={html} onChange={onBodyChange} />
      </Flex>
      <TextButton onClick={() => onSubmit()}>수정</TextButton>
    </Flex>
  );
}

export default PolicyContainer;

import React from "react";
import { Flex } from "../../common/styles/commonStyles";
import CouponList from "./components/CouponList";
import UpdateCoupon from "./components/UpdateCoupon";
import useCouponContainer from "./CouponContainer.hook";

function CouponContainer() {
  const {
    models: { selectedCoupon },
    operations: { onCouponSelect },
  } = useCouponContainer();

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      flex={1}
      height="100vh"
    >
      <CouponList onCouponSelect={onCouponSelect} />
      <UpdateCoupon selectedCoupon={selectedCoupon} />
    </Flex>
  );
}

export default CouponContainer;

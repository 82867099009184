import React from "react";
import LabeledInput from "../../common/components/LabeledInput";
import LabeledTextarea from "../../common/components/LabeledTextarea";
import { Flex, TextButton } from "../../common/styles/commonStyles";
import useFAQCard from "./FAQCard.hook";

function FAQCard({ faq }) {
  const {
    models: { state, loading },
    operations: { onChange, onSubmit },
  } = useFAQCard(faq);

  const { question, answer, sort } = state;

  const isNew = faq?.id === "new";

  return (
    <Flex
      flexDirection="column"
      flexBasis="48%"
      border="1px solid black"
      borderRadius={4}
      padding={12}
      gap={4}
    >
      {!isNew && <LabeledInput label="ID" value={faq?.id} disabled />}
      <LabeledInput
        label="질문"
        placeholder="질문"
        value={question}
        onChange={(e) => onChange(e, "question")}
      />
      <LabeledTextarea
        label="답변"
        placeholder="답변"
        value={answer}
        onChange={(e) => onChange(e, "answer")}
      />
      <LabeledInput
        type="number"
        label="순서"
        value={sort}
        onChange={(e) => onChange(e, "sort")}
      />
      <TextButton onClick={() => onSubmit()}>
        {isNew
          ? loading
            ? "등록 중"
            : "등록하기"
          : loading
          ? "수정 중"
          : "수정하기"}
      </TextButton>
    </Flex>
  );
}

export default FAQCard;

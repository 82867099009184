import React from "react";
import { PaginationDiv } from "./Pagination.styles";

/**
 * Check out the doc for props
 * https://github.com/AdeleD/react-paginate
 */
function Pagination({ ...rest }) {
  return <PaginationDiv {...rest} />;
}

export default Pagination;

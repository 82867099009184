import { useMutation } from "@apollo/client";
import { useState } from "react";
import { GET_FAQS, UPDATE_FAQ_AS_ADMIN } from "../../../lib/apollo/gql/faqGqls";

const defaultState = {
  id: "",
  question: "",
  answer: "",
  sort: 0,
};

function useFAQCard(faq) {
  const [state, setState] = useState(
    faq?.id === "new"
      ? defaultState
      : {
          id: faq?.id,
          question: faq?.question,
          answer: faq?.answer,
          sort: faq?.sort,
        }
  );

  const [updateFAQAsAdmin, { loading }] = useMutation(UPDATE_FAQ_AS_ADMIN, {
    refetchQueries: () => (faq?.id === "new" ? [{ query: GET_FAQS }] : []),
    onCompleted: () => {
      if (faq?.id === "new") {
        setState(defaultState);
      }
    },
  });

  function onChange(e, key) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onSubmit() {
    const { question, answer, sort } = state;
    const faqInput = {
      id: faq?.id === "new" ? null : faq?.id,
      question,
      answer,
      sort: Number(sort),
    };

    updateFAQAsAdmin({
      variables: {
        faqInput,
      },
    });
  }

  return {
    models: {
      state,
      loading,
    },
    operations: {
      onChange,
      onSubmit,
    },
  };
}

export default useFAQCard;

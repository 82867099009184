import React from "react";
import usePlaylistsTable from "./PlaylistsTable.hook";
import {
  Flex,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TextButton,
} from "../../../common/styles/commonStyles";
import Loading from "../../../common/components/Loading";
import Thumbnail from "../../../common/components/Thumbnail";
import moment from "moment";
import copyText from "../../../common/utils/copyText";

function PlaylistsTable({ onPlaylistSelect }) {
  const {
    ref: { fetchMoreRef },
    models: { loading, data },
    operations: { onActiveClick },
  } = usePlaylistsTable();

  return (
    <Flex
      flex={9}
      overflowY="auto"
      flexDirection="column"
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      padding={12}
    >
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell width="8%">ID</TableHeadCell>
            <TableHeadCell width="15%">대표 이미지</TableHeadCell>
            <TableHeadCell width="5%">오디오 유무</TableHeadCell>
            <TableHeadCell width="5%">배경색</TableHeadCell>
            <TableHeadCell width="10%">테마</TableHeadCell>
            <TableHeadCell width="10%">타이틀</TableHeadCell>
            <TableHeadCell width="15%">설명</TableHeadCell>
            <TableHeadCell width="8%">등록일</TableHeadCell>
            <TableHeadCell width="5%">활성여부</TableHeadCell>
            <TableHeadCell width="5%">수정</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan="100%">
                <Loading />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data?.map((playlist) => {
              const {
                id,
                theme,
                title,
                summary,
                thumbnailMedia,
                audio,
                backgroundColor,
                active,
                createdAt,
              } = playlist;

              return (
                <TableRow key={id}>
                  <TableCell onClick={async () => await copyText(id)}>
                    {id}
                  </TableCell>
                  <TableCell>
                    <Thumbnail
                      width={100}
                      height={100}
                      media={thumbnailMedia}
                    />
                  </TableCell>
                  <TableCell>{audio ? "O" : "X"}</TableCell>
                  <TableCell>
                    <div
                      style={{ backgroundColor: backgroundColor || "black" }}
                    >
                      {backgroundColor || "X"}
                    </div>
                  </TableCell>

                  <TableCell>{theme}</TableCell>
                  <TableCell>{title}</TableCell>
                  <TableCell>{summary}</TableCell>
                  <TableCell>
                    {moment(createdAt).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell>
                    <TextButton
                      padding={4}
                      backgroundColor="red"
                      onClick={() => onActiveClick(id, active)}
                    >
                      {active ? "비활성" : "활성"}
                    </TextButton>
                  </TableCell>
                  <TableCell>
                    <TextButton
                      padding={4}
                      onClick={() => onPlaylistSelect(id)}
                    >
                      수정
                    </TextButton>
                  </TableCell>
                </TableRow>
              );
            })}
            <tr>
              <td colSpan="100%" ref={fetchMoreRef}></td>
            </tr>
          </tbody>
        )}
      </Table>
    </Flex>
  );
}

export default PlaylistsTable;

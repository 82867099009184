import moment from "moment";
import React from "react";
import Pagination from "../../common/components/Pagination";
import Thumbnail from "../../common/components/Thumbnail";
import {
  Flex,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from "../../common/styles/commonStyles";
import copyText from "../../common/utils/copyText";
import usePaymentListTable from "./PaymentListTable.hook";

function PaymentListTable({ data, offset, totalPageCount, onPageChange }) {
  const {
    operations: { onPaidChange },
  } = usePaymentListTable();

  if (!data?.length > 0)
    return (
      <Flex justifyContent="center" width="100%" paddingTop={24}>
        데이터가 없습니다
      </Flex>
    );

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
    >
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell width="8%">ID</TableHeadCell>
            <TableHeadCell width="8%">판매ID</TableHeadCell>
            <TableHeadCell>작품</TableHeadCell>
            <TableHeadCell width="8%">주문ID</TableHeadCell>
            <TableHeadCell width="8%">구매확정 시각</TableHeadCell>
            <TableHeadCell>판매가</TableHeadCell>
            <TableHeadCell>수수료</TableHeadCell>
            <TableHeadCell>지급금액</TableHeadCell>
            <TableHeadCell width="10%">계좌번호</TableHeadCell>
            <TableHeadCell>지불시각</TableHeadCell>
            <TableHeadCell>지불여부</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <tbody>
          {data?.map((payment) => {
            const {
              id,
              sale,
              order,
              paid,
              price,
              serviceFee,
              amount,
              paidAt,
              createdAt,
            } = payment;

            const bankAccountText =
              `${sale?.bankAccount?.accountName || "예금주명 없음"}` +
              " | " +
              `${sale?.bankAccount?.bankName || "은행명 없음"}` +
              " | " +
              `${sale?.bankAccount?.accountNumber || "계좌번호 없음"}`;
            return (
              <TableRow key={id}>
                <TableCell>{id}</TableCell>
                <TableCell onClick={async () => await copyText(sale?.id)}>
                  {sale?.id}
                </TableCell>
                <TableCell>
                  <Flex flexDirection="column" gap={8}>
                    <Thumbnail width={200} media={sale?.art?.primaryMedia} />
                    <div>{sale?.art?.titleKor}</div>
                  </Flex>
                </TableCell>
                <TableCell onClick={async () => await copyText(order?.id)}>
                  {order?.id}
                </TableCell>
                <TableCell>
                  {moment.utc(createdAt).format("YYYY-MM-DD HH:mm:ss")}
                </TableCell>
                <TableCell>{price}</TableCell>
                <TableCell>{serviceFee}</TableCell>
                <TableCell>
                  <strong>{amount}</strong>
                </TableCell>
                <TableCell>{bankAccountText}</TableCell>
                <TableCell>
                  {paidAt
                    ? moment(paidAt).format("YYYY-MM-DD HH:mm:ss")
                    : "미지급"}
                </TableCell>
                <TableCell>
                  <input
                    type="checkbox"
                    checked={paid}
                    onChange={(e) => onPaidChange(e, id)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        forcePage={offset}
        pageCount={totalPageCount}
        pageRangeDisplayed={5}
        previousLabel="Prev"
        nextLabel="Next"
        onPageChange={onPageChange}
      />
    </Flex>
  );
}

export default PaymentListTable;

import { useLazyQuery, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  GET_USERS_BY_USER_TYPE_AS_ADMIN,
  SEARCH_USER,
} from "../../../lib/apollo/gql/userGqls";

function useSearchUserSelector(userType) {
  const [searchUser] = useLazyQuery(SEARCH_USER);

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(async () => {
        const { data } = await searchUser({
          variables: {
            query: inputValue,
            type: userType,
            offset: 0,
            limit: 20,
          },
        });

        const result = data?.searchUser?.edges;

        const options = result?.map((user) => ({
          value: user?.id,
          label: `${user?.name}(${user?.email || ""})`,
        }));

        resolve(options);
      }, 1);
    });

  return {
    operations: {
      loadOptions,
    },
  };
}

export default useSearchUserSelector;

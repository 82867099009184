import React from "react";
import { Flex } from "../../common/styles/commonStyles";
import UpdateUserModal from "../updateUser/UpdateUserModal";
import SearchUserList from "./components/SearchUserList";
import UserList from "./components/UserList";
import UserListNavBar from "./components/UserListNavBar";
import UserTable from "./components/UserTable";
import useUserListContainer from "./UserListContainer.hook";

function UserListContainer() {
  const { models, operations } = useUserListContainer();

  const { selectedUserType, searchQuery, searchedQuery, selectedUserId } =
    models;

  const {
    onUserTypeClick,
    onSearchChange,
    onSearchKeyDown,
    onSearch,
    onUserSelect,
  } = operations;

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
    >
      <UserListNavBar
        selectedUserType={selectedUserType}
        searchQuery={searchQuery}
        onSearchChange={onSearchChange}
        onSearchKeyDown={onSearchKeyDown}
        onSearch={onSearch}
        onUserTypeClick={onUserTypeClick}
        onUserSelect={onUserSelect}
      />
      {!searchedQuery ? (
        <UserList
          selectedUserType={selectedUserType}
          onUserSelect={onUserSelect}
        />
      ) : (
        <SearchUserList
          searchedQuery={searchedQuery}
          selectedUserType={selectedUserType}
          onUserSelect={onUserSelect}
        />
      )}

      {!!selectedUserId && (
        <UpdateUserModal
          isOpen={!!selectedUserId}
          selectedUserId={selectedUserId}
          onRequestClose={() => onUserSelect(null)}
        />
      )}
    </Flex>
  );
}

export default UserListContainer;

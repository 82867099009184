import React from "react";
import {
  Flex,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TextButton,
} from "../../../common/styles/commonStyles";
import Loading from "../../../common/components/Loading";
import Thumbnail from "../../../common/components/Thumbnail";
import useArtsListTable from "./ArtsListTable.hook";
import moment from "moment";
import Pagination from "../../../common/components/Pagination";

function ArtsListTable({ searchedQuery, onSelectArt }) {
  const { models, operations } = useArtsListTable(searchedQuery);

  const { loading, data, totalPageCount, offset } = models;

  const { onDeleteClick, onPageChange } = operations;

  return (
    <Flex
      flex={9}
      overflowY="auto"
      flexDirection="column"
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      padding={12}
    >
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell width="8%">ID</TableHeadCell>
            <TableHeadCell width="15%">이미지</TableHeadCell>
            <TableHeadCell width="8%">작가</TableHeadCell>
            <TableHeadCell width="8%">스페이스</TableHeadCell>
            <TableHeadCell width="8%">콜렉션</TableHeadCell>
            <TableHeadCell width="15%">작품 이름</TableHeadCell>
            <TableHeadCell width="15%">재료</TableHeadCell>
            <TableHeadCell width="5%">오디오 유/무</TableHeadCell>
            <TableHeadCell width="5%">년도</TableHeadCell>
            <TableHeadCell width="5%">조회수</TableHeadCell>
            <TableHeadCell width="7%">등록일</TableHeadCell>
            <TableHeadCell width="5%">수정</TableHeadCell>
            <TableHeadCell width="5%">삭제</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan="100%">
                <Loading />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data?.map((art) => {
              const {
                id,
                primaryMedia,
                artist,
                space,
                artistNameKor,
                titleKor,
                materialKor,
                audios,
                year,
                viewCount,
                createdAt,
                owner,
                deletedAt,
              } = art;

              return (
                <TableRow key={art.id}>
                  <TableCell>{id}</TableCell>
                  <TableCell>
                    <Thumbnail width={100} height={100} media={primaryMedia} />
                  </TableCell>
                  <TableCell>
                    {artist ? artist?.user?.name : artistNameKor}
                  </TableCell>
                  <TableCell>{space?.user?.name || "X"}</TableCell>
                  <TableCell>{owner?.name}</TableCell>
                  <TableCell>{titleKor}</TableCell>
                  <TableCell>{materialKor}</TableCell>
                  <TableCell>{audios?.length > 0 ? "유" : "무"}</TableCell>
                  <TableCell>{year}</TableCell>
                  <TableCell>{viewCount}</TableCell>
                  <TableCell>
                    {moment(createdAt).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell>
                    <TextButton padding={4} onClick={() => onSelectArt(id)}>
                      수정
                    </TextButton>
                  </TableCell>
                  <TableCell>
                    <TextButton
                      padding={4}
                      backgroundColor="red"
                      disabled={!!deletedAt}
                      onClick={async () => await onDeleteClick(id)}
                    >
                      {deletedAt ? "삭제됨" : "삭제"}
                    </TextButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </tbody>
        )}
      </Table>
      <Pagination
        forcePage={offset}
        pageCount={totalPageCount}
        pageRangeDisplayed={5}
        previousLabel="Prev"
        nextLabel="Next"
        onPageChange={onPageChange}
      />
    </Flex>
  );
}

export default ArtsListTable;

import { gql } from "@apollo/client";
import { PAGE_INFO_FIELD, SUBSCRIPTION_FIELD, USER_FIELD } from "./fragments";

export const GET_SUBSCRIPTIONS_AS_ADMIN = gql`
  ${PAGE_INFO_FIELD}
  ${SUBSCRIPTION_FIELD}
  query GetSubscriptionsAsAdmin(
    $status: SubscriptionStatus
    $offset: Int
    $limit: Int
  ) {
    getSubscriptionsAsAdmin(status: $status, offset: $offset, limit: $limit) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...SubscriptionField
      }
    }
  }
`;

export const GET_SUBSCRIPTION = gql`
  ${SUBSCRIPTION_FIELD}
  query GetSubscription($subscriptionId: ID!) {
    getSubscription(subscriptionId: $subscriptionId) {
      ...SubscriptionField
    }
  }
`;

export const SET_FREE_SUBSCRIPTION_TO_USER_AS_ADMIN = gql`
  ${USER_FIELD}
  mutation SetFreeSubscriptionToUserAsAdmin($userId: ID!) {
    setFreeSubscriptionToUserAsAdmin(userId: $userId) {
      ...UserField
    }
  }
`;

export const EXPIRE_FREE_SUBSCRIPTION_TO_USER_AS_ADMIN = gql`
  ${USER_FIELD}
  mutation ExpireFreeSubscriptionToUserAsAdmin($subscriptionId: ID!) {
    expireFreeSubscriptionToUserAsAdmin(subscriptionId: $subscriptionId) {
      ...UserField
    }
  }
`;

export const CANCEL_SUBSCRIPTION_AS_ADMIN = gql`
  ${SUBSCRIPTION_FIELD}
  mutation CancelSubscriptionAsAdmin($subscriptionId: ID!) {
    cancelSubscriptionAsAdmin(subscriptionId: $subscriptionId) {
      ...SubscriptionField
    }
  }
`;

import { useMutation } from "@apollo/client";
import { useRef, useState } from "react";
import imageCompression from "browser-image-compression";

import {
  GET_BANNERS_AS_ADMIN,
  UPDATE_BANNERS_AS_ADMIN,
} from "../../../lib/apollo/gql/bannerGqls";
import { COMPRESSION_OPTION } from "../../../utilities/constants";

function useUpdateBannerModal(banner, onRequestClose) {
  const isNew = banner === "new";

  const mediaInputRef = useRef();

  const [state, setState] = useState({
    id: isNew ? "" : banner?.id,
    media: isNew ? null : banner?.media,
    mediaState: null,
    externalUrl: isNew ? "" : banner?.externalUrl,
    active: isNew ? true : banner?.active,
    subscriberOnly: isNew ? false : banner?.subscriberOnly,
    sort: isNew ? 0 : banner?.sort,
    isFirst: isNew ? false : banner?.isFirst,
  });

  const [updateBannerAsAdmin, { loading }] = useMutation(
    UPDATE_BANNERS_AS_ADMIN,
    {
      onCompleted: () => {
        onRequestClose();
      },
      refetchQueries: () => [
        {
          query: GET_BANNERS_AS_ADMIN,
          variables: {
            offset: 0,
            limit: 10,
          },
        },
      ],
    }
  );

  function onInputChange(e, type) {
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      [type]: value,
    }));
  }

  function onMediaChange(e) {
    const { files } = e.target;
    const file = files[0];
    setState((prev) => ({
      ...prev,
      mediaState: file,
    }));
  }

  function onMediaChangeCancelClick() {
    setState((prev) => ({
      ...prev,
      mediaState: null,
    }));
  }

  function toggleActive(e) {
    const { checked } = e.target;

    setState((prev) => ({
      ...prev,
      active: checked,
    }));
  }

  function toggleSubscriberOnly(e) {
    const { checked } = e.target;

    setState((prev) => ({
      ...prev,
      subscriberOnly: checked,
    }));
  }

  function toggleIsFirst(e) {
    const { checked } = e.target;

    setState((prev) => ({
      ...prev,
      isFirst: checked,
    }));
  }

  function isSubmitDisabled() {
    const { id, externalUrl, mediaState, active } = state;

    const isMediaDisabled = isNew && !mediaState;
    const isUrlDisabled = !externalUrl;

    return isMediaDisabled || isUrlDisabled || loading;
  }

  async function onSubmit() {
    const {
      id,
      externalUrl,
      mediaState,
      active,
      subscriberOnly,
      sort,
      isFirst,
    } = state;

    let mediaInput;

    if (mediaState) {
      await imageCompression(mediaState, COMPRESSION_OPTION).then((file) => {
        mediaInput = { file };
      });
    }

    updateBannerAsAdmin({
      variables: {
        bannerInput: {
          id,
          externalUrl,
          active,
          mediaInput,
          subscriberOnly,
          sort: Number(sort),
          isFirst,
        },
      },
    });
  }

  return {
    refs: {
      mediaInputRef,
    },
    models: {
      state,
      loading,
    },
    operations: {
      onInputChange,
      onMediaChange,
      onMediaChangeCancelClick,
      toggleActive,
      toggleSubscriberOnly,
      toggleIsFirst,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useUpdateBannerModal;

import { useQuery } from "@apollo/client";
import { GET_ART_TYPES } from "../../../../lib/apollo/gql/artGqls";

function useArtTypeSelector() {
  const { data, loading, error } = useQuery(GET_ART_TYPES);

  return {
    models: {
      loading,
      data: data?.getArtTypes,
    },
  };
}

export default useArtTypeSelector;

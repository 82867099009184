import { useQuery } from "@apollo/client";
import { GET_SEARCH_CATEGORIES } from "../../../../lib/apollo/gql/artGqls";

function useArtSearchCategorySelector() {
  const { data, loading } = useQuery(GET_SEARCH_CATEGORIES);

  return {
    models: {
      data: data?.getSearchCategories,
      loading,
    },
  };
}

export default useArtSearchCategorySelector;

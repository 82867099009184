import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useReducer, useRef } from "react";
import imageCompression from "browser-image-compression";

import {
  GET_ART_HISTORIES,
  GET_ART_HISTORIES_LENGTH,
  GET_ART_HISTORY,
  UPDATE_ART_HISTORY_AS_ADMIN,
} from "../../../lib/apollo/gql/artHistoryGqls";
import { COMPRESSION_OPTION } from "../../../utilities/constants";

export const UPDATE_ART_HISTORY_MODAL_ACTION_TYPES = {
  HANDLE_ART: "HANDLE_ART",

  HANDLE_WRITER: "HANDLE_WRITER",

  HANDLE_PARING_MEDIA: "HANDLE_PARING_MEDIA",

  HANDLE_ORDER: "HANDLE_ORDER",

  HANDLE_THEME: "HANDLE_THEME",

  HANDLE_TITLE: "HANDLE_TITLE",
  HANDLE_HIGHLIGHT: "HANDLE_HIGHLIGHT",
  HANDLE_BODY: "HANDLE_BODY",

  SET_ALL: "SET_ALL",
};

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_ART: {
      return {
        ...state,
        art: action.payload,
      };
    }

    case UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_WRITER: {
      return {
        ...state,
        writer: action.payload,
      };
    }

    case UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_PARING_MEDIA: {
      return {
        ...state,
        paringMediaState: action.payload,
      };
    }

    case UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_ORDER: {
      return {
        ...state,
        order: action.payload,
      };
    }

    case UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_THEME: {
      return {
        ...state,
        theme: action.payload,
      };
    }

    case UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_TITLE: {
      return {
        ...state,
        title: action.payload,
      };
    }

    case UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_HIGHLIGHT: {
      return {
        ...state,
        highlight: action.payload,
      };
    }

    case UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_BODY: {
      return {
        ...state,
        body: action.payload,
      };
    }

    case UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.SET_ALL: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

const initialState = {
  id: "",
  art: null,
  writer: null,
  paringMedia: null,
  paringMediaState: null,
  order: 1,
  theme: "",
  title: "",
  highlight: "",
  body: "",
};

function useUpdateArtHistoryModal(selectedArtHistoryId, onRequestClose) {
  const paringMediaRef = useRef(null);

  const [state, dispatch] = useReducer(reducer, initialState);

  const [getArtHistoriesLength, { loading: orderLoading }] = useLazyQuery(
    GET_ART_HISTORIES_LENGTH,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ getArtHistoriesLength }) => {
        dispatch({
          type: UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_ORDER,
          payload: getArtHistoriesLength + 1,
        });
      },
    }
  );

  const [updateArtHistoryAsAdmin, { loading: updateLoading }] = useMutation(
    UPDATE_ART_HISTORY_AS_ADMIN,
    {
      onCompleted: () => {
        onRequestClose();
      },
      refetchQueries: () => [
        {
          query: GET_ART_HISTORIES,
          variables: {
            offset: 0,
            limit: 10,
          },
        },
      ],
    }
  );

  const [getArtHistory, { loading: artHistoryLoading }] = useLazyQuery(
    GET_ART_HISTORY,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ getArtHistory }) => {
        const {
          id,
          art,
          writer,
          paringMedia,
          order,
          theme,
          title,
          highlight,
          body,
        } = getArtHistory;

        const payload = {
          id,
          art: { value: art.id, label: `${art?.titleKor}(${art?.titleEng})` },
          writer: {
            value: writer?.id,
            label: `${writer?.user?.name}(${writer?.user?.email || ""})`,
          },
          paringMedia,
          order,
          theme,
          title,
          highlight,
          body,
        };

        dispatch({
          type: UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.SET_ALL,
          payload,
        });
      },
    }
  );

  useEffect(() => {
    if (selectedArtHistoryId && selectedArtHistoryId !== "new") {
      getArtHistory({
        variables: {
          artHistoryId: selectedArtHistoryId,
        },
      });
    } else {
      getArtHistoriesLength();
    }
  }, [selectedArtHistoryId]);

  /**
   * Handle media input
   */
  function onMediaChange(e) {
    const { files } = e.target;

    const file = files[0];

    dispatch({
      type: UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_PARING_MEDIA,
      payload: file,
    });
  }

  function onMediaEditClick() {
    paringMediaRef?.current?.click();
  }

  function onMediaChangeCancelClick() {
    dispatch({
      type: UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_PARING_MEDIA,
      payload: null,
    });
  }

  /**
   * Handle input values like title and highlight
   * @param {object} e
   * @param {string} type
   */
  function onInputChange(e, type) {
    const { value } = e.target;

    dispatch({
      type,
      payload: value,
    });
  }

  /**
   * Handle writer
   * @param {object} value
   */
  function onWriterSelect(value) {
    dispatch({
      type: UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_WRITER,
      payload: value,
    });
  }

  /**
   * Handle art
   * @param {object} value
   */
  function onArtSelect(value) {
    dispatch({
      type: UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_ART,
      payload: value,
    });
  }

  /**
   * Handle content body change
   * @param {string} value
   */
  function onBodyChange(value) {
    dispatch({
      type: UPDATE_ART_HISTORY_MODAL_ACTION_TYPES.HANDLE_BODY,
      payload: value,
    });
  }

  function isSubmitDisabled() {
    const { art, writer, theme, title, body, highlight } = state;
    const isWriterDisabled = !writer;
    const isArtDisabled = !art;
    const isThemeDisabled = !theme;
    const isTitleDisabled = !title;
    const isHighlightDisabled = !highlight;
    const isBodyDisabled = !body;

    return (
      isWriterDisabled ||
      isArtDisabled ||
      isThemeDisabled ||
      isTitleDisabled ||
      isHighlightDisabled ||
      isBodyDisabled ||
      updateLoading
    );
  }

  async function onSubmit() {
    const {
      art,
      writer,
      theme,
      title,
      body,
      highlight,
      paringMediaState,
      order,
    } = state;

    let paringMediaInput = null;

    if (paringMediaState) {
      await imageCompression(paringMediaState, COMPRESSION_OPTION).then(
        (file) => {
          paringMediaInput = { file };
        }
      );
    }

    const artHistoryInput = {
      id: selectedArtHistoryId === "new" ? null : selectedArtHistoryId,
      artId: art?.value,
      writerId: writer?.value,
      theme,
      title,
      body,
      highlight,
      order: Number(order),
      paringMediaInput,
    };

    updateArtHistoryAsAdmin({
      variables: {
        artHistoryInput,
      },
    });
  }

  return {
    refs: {
      paringMediaRef,
    },
    models: {
      state,
      updateLoading,
      orderLoading,
      artHistoryLoading,
    },
    operations: {
      onMediaChange,
      onMediaEditClick,
      onMediaChangeCancelClick,
      onInputChange,
      onWriterSelect,
      onArtSelect,
      onBodyChange,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useUpdateArtHistoryModal;

import { useState } from "react";

function useCouponTypeContainer() {
  const [selectedCouponType, setSelectedCouponType] = useState(null);

  function onCouponTypeSelect(coupontype) {
    setSelectedCouponType(coupontype);
  }

  return {
    models: {
      selectedCouponType,
    },
    operations: {
      onCouponTypeSelect,
    },
  };
}

export default useCouponTypeContainer;

import { async } from "@firebase/util";
import { useState } from "react";
import { firebaseSignIn } from "../../lib/firebase";

function useSignInContainer() {
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  /**
   * Handle input value from email and password
   * @param {keyboard Event} e
   * @param {string} key
   */
  function onChange(e, key) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  /**
   * When user press enter on password input,
   * it runs onSubmit function to try sign in
   * @param {Keyboard Event} e
   */
  async function onPasswordKeyDown(e) {
    const { key } = e;

    if (key === "Enter") {
      await onSubmit();
    }
  }

  /**
   * Sign in
   */
  async function onSubmit() {
    try {
      await firebaseSignIn(state.email, state.password);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  }

  return {
    models: {
      state,
    },
    operations: {
      onChange,
      onPasswordKeyDown,
      onSubmit,
    },
  };
}

export default useSignInContainer;

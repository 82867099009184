import React from "react";
import Loading from "../common/components/Loading";
import { Flex, SectionTitle } from "../common/styles/commonStyles";
import FAQCard from "./components/FAQCard";
import useFAQContainer from "./FAQContainer.hook";

function FAQContainer() {
  const {
    models: { loading, data },
  } = useFAQContainer();

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
      padding={12}
    >
      <SectionTitle>FAQ</SectionTitle>
      {loading && <Loading />}
      {!loading && (
        <Flex
          flexDirection="row"
          flexWrap="wrap"
          width="100%"
          gap={4}
          alignItems="stretch"
          justifyContent="flex-start"
          marginTop={20}
        >
          {[{ id: "new" }, ...data].map((faq) => (
            <FAQCard key={faq?.id} faq={faq} />
          ))}
        </Flex>
      )}
    </Flex>
  );
}

export default FAQContainer;

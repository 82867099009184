import { useQuery } from "@apollo/client";
import { useState } from "react";
import { SEARCH_USER } from "../../../../lib/apollo/gql/userGqls";
import { LIMIT, USER_TYPES_PARAMS } from "../../../../utilities/constants";

function useSearchUserList(selectedUserType, searchedQuery) {
  const [offset, setOffset] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, fetchMore } = useQuery(SEARCH_USER, {
    variables: {
      query: searchedQuery,
      type: USER_TYPES_PARAMS[selectedUserType],
      offset,
      limit: LIMIT,
    },
    onCompleted: ({ searchUser }) => {
      const { pageInfo } = searchUser;

      const { totalPages } = pageInfo;

      setTotalPageCount(totalPages);
      setOffset(pageInfo.offset);
    },
  });

  /**
   *  When page changed from pagination
   *  Fetch data for the offset changed
   */
  async function onPageChange({ selected }) {
    const newOffset = selected;
    if (!fetchingMore) {
      setFetchingMore(true);

      await fetchMore({
        variables: {
          type: USER_TYPES_PARAMS[selectedUserType],
          offset: newOffset,
          limit: LIMIT,
        },
      });

      setOffset(newOffset);
      setFetchingMore(false);
    }
  }

  return {
    models: {
      loading: loading || fetchingMore,
      data: data?.searchUser?.edges,
      offset,
      totalPageCount,
    },
    operations: {
      onPageChange,
    },
  };
}

export default useSearchUserList;

import { from, HttpLink } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

import authLink from "./authLink";
import config from "../../../utilities/config";

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const uploadLink = createUploadLink({
  uri: `https://${config.API_URL}/graphql`,
  // uri: "http://api-dev.iptime.org:8000/graphql",
});

export default from([retryLink, errorLink, authLink, uploadLink]);

import styled from "styled-components";
import closeIcon from "../../../assets/icons/close.svg";

const CLOSE_ICON_SIZE = 20;

export const ModalCloseButton = styled.div(() => ({
  position: "absolute",
  right: "1%",
  top: "1%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 8,
  cursor: "pointer",
}));

export const ModalCloseIcon = styled.img.attrs(() => ({
  src: closeIcon,
}))(() => ({
  width: CLOSE_ICON_SIZE,
  height: CLOSE_ICON_SIZE,
}));

export const ModalTitle = styled.div(() => ({
  fontSize: 16,
  marginBottom: 16,
  fontWeight: "bold",
}));

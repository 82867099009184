import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { useState } from "react";
import { GET_PAYMENTS_AS_ADMIN } from "../../lib/apollo/gql/paymentGqls";
import { LIMIT } from "../../utilities/constants";

function usePaymentContainer() {
  const [range, setRange] = useState({
    from: moment().subtract(1, "month").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });
  const [unpaidOnly, setUnpaidOnly] = useState(false);

  const [offset, setOffset] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);

  const [getPaymentsAsAdmin, { data, loading, fetchMore }] = useLazyQuery(
    GET_PAYMENTS_AS_ADMIN,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ getPaymentsAsAdmin }) => {
        const { pageInfo } = getPaymentsAsAdmin;

        const { totalPages } = pageInfo;

        setTotalPageCount(totalPages);
        setOffset(pageInfo.offset);
      },
    }
  );

  function onRangeChange(e, key) {
    const { value } = e.target;

    setRange((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function toggleUnpaidOnly(e) {
    const { checked } = e.target;

    setUnpaidOnly(checked);
  }

  function onSubmit() {
    getPaymentsAsAdmin({
      variables: {
        from: moment(range?.from).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        to: moment(range?.to).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        unpaidOnly,
        offset,
        limit: LIMIT,
      },
    });
  }

  async function onPageChange({ selected }) {
    const newOffset = selected;
    if (!fetchingMore) {
      setFetchingMore(true);
      await fetchMore({
        variables: {
          from: moment(range?.from)
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
          to: moment(range?.to).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
          unpaidOnly,
          offset: newOffset,
          limit: LIMIT,
        },
      });
      setOffset(newOffset);
      setFetchingMore(false);
    }
  }

  return {
    models: {
      range,
      unpaidOnly,
      data: data?.getPaymentsAsAdmin?.edges,
      loading: loading || fetchingMore,
      offset,
      totalPageCount,
    },
    operations: {
      onRangeChange,
      toggleUnpaidOnly,
      onSubmit,
      onPageChange,
    },
  };
}

export default usePaymentContainer;

import { useState } from "react";

function useArtHistoryListContainer() {
  const [selectedArtHistoryId, setSelectedArtHistoryId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchedQuery, setSearchedQuery] = useState("");

  function onSelectArtHistory(id) {
    setSelectedArtHistoryId(id);
  }

  function onSearchChange(e) {
    const { value } = e.target;

    setSearchQuery(value);
    if (value.length === 0 && searchedQuery) {
      setSearchedQuery("");
    }
  }

  function onSearchKeyDown(e) {
    const { key } = e;

    if (key === "Enter") {
      onSearch();
    }
  }

  function onSearch() {
    if (searchQuery !== searchedQuery) {
      setSearchedQuery(searchQuery);
    }
  }

  return {
    models: {
      selectedArtHistoryId,
      searchQuery,
      searchedQuery,
    },
    operations: {
      onSelectArtHistory,
      onSearchChange,
      onSearchKeyDown,
      onSearch,
    },
  };
}

export default useArtHistoryListContainer;

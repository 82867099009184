import React from "react";
import Loading from "../common/components/Loading";
import { Flex } from "../common/styles/commonStyles";
import SubscriptionPlanCard from "./components/SubscriptionPlanCard";
import useSubscriptionPlanContainer from "./SubscriptionPlanContainer.hook";

function SubscriptionPlanContainer() {
  const {
    models: { data, loading },
  } = useSubscriptionPlanContainer();

  if (loading) return <Loading />;

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      height="100vh"
      padding={24}
      gap={24}
    >
      <Flex
        alignItems="stretch"
        gap={12}
        flexWrap="wrap"
        justifyContent="flex-start"
        width="100%"
      >
        <SubscriptionPlanCard />
        {data?.map((plan) => (
          <SubscriptionPlanCard key={plan.id} plan={plan} />
        ))}
      </Flex>
    </Flex>
  );
}

export default SubscriptionPlanContainer;

import styled from "styled-components";

import logoImage from "../../assets/images/logo.png";

export const SignInContainerWrapper = styled.div(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const SignInLogoImage = styled.img.attrs(() => ({
  src: logoImage,
}))(() => ({
  width: "100%",
  height: "100%",
  objectFit: "contain",
}));

import React from "react";
import LabeledInput from "../../../common/components/LabeledInput";
import Modal from "../../../common/components/Modal";
import { TextButton } from "../../../common/styles/commonStyles";
import useOrderDeliveryFeeModal from "./OrderDeliveryFeeModal.hook";

function OrderDeliveryFeeModal({ isOpen, onRequestClose }) {
  const {
    models: { loading, fee, updateLoading },
    operations: { onChange, onSubmit },
  } = useOrderDeliveryFeeModal();

  return (
    <Modal isOpen={isOpen} title="배송비 관리" onRequestClose={onRequestClose}>
      <LabeledInput
        label="배송비"
        type="number"
        value={fee}
        onChange={onChange}
      />
      <TextButton disabled={updateLoading} onClick={onSubmit}>
        수정
      </TextButton>
    </Modal>
  );
}

export default OrderDeliveryFeeModal;

import moment from "moment";
import React from "react";
import { SUBSCRIPTION_STATUS } from "../../../../utilities/constants";
import Loading from "../../../common/components/Loading";
import Modal from "../../../common/components/Modal";
import {
  Flex,
  SectionTitle,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TextButton,
} from "../../../common/styles/commonStyles";
import useSubscriptionDetailModal from "./SubscriptionDetailModal.hook";

function SubscriptionDetailModal({
  isOpen,
  selectedSubscriptionId,
  onRequestClose,
}) {
  const {
    models: { loading, data },
    operations: { onCancelClick },
  } = useSubscriptionDetailModal(selectedSubscriptionId);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title="구독 상세보기"
    >
      {loading && <Loading />}
      {!loading && (
        <>
          <Flex flexDirection="column" width="100%">
            <SectionTitle>구독 정보</SectionTitle>
            <Table>
              <TableHead>
                <TableHeadRow>
                  <TableHeadCell width="4%">ID</TableHeadCell>
                  <TableHeadCell width="5%">User Name</TableHeadCell>
                  <TableHeadCell width="8%">User Email</TableHeadCell>
                  <TableHeadCell width="8%">Plan</TableHeadCell>
                  <TableHeadCell width="5%">시작일</TableHeadCell>
                  <TableHeadCell width="5%">종료일</TableHeadCell>
                  <TableHeadCell width="5%">다음 결제일</TableHeadCell>
                  <TableHeadCell width="8%">상태</TableHeadCell>
                  <TableHeadCell width="8%">취소하기</TableHeadCell>
                </TableHeadRow>
              </TableHead>
              <tbody>
                <TableRow>
                  <TableCell>{data?.id}</TableCell>
                  <TableCell>{data?.user?.name}</TableCell>
                  <TableCell>{data?.user?.email}</TableCell>
                  <TableCell>
                    {data?.subscriptionPlan?.name || "무료"}
                  </TableCell>
                  <TableCell>
                    {moment(data?.from).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell>
                    {data?.newTo
                      ? moment(data?.newTo).format("YYYY-MM-DD")
                      : "월정액"}
                  </TableCell>
                  <TableCell>
                    {data?.nextPayAt
                      ? moment(data?.nextPayAt).format("YYYY-MM-DD")
                      : "없음"}
                  </TableCell>
                  <TableCell>{data?.status}</TableCell>
                  <TableCell>
                    {!!data?.subscriptionPlan?.name &&
                    data?.status === SUBSCRIPTION_STATUS.ACTIVE ? (
                      <TextButton
                        width={100}
                        backgroundColor="red"
                        onClick={() => onCancelClick(data?.id)}
                      >
                        취소
                      </TextButton>
                    ) : (
                      "X"
                    )}
                  </TableCell>
                </TableRow>
              </tbody>
            </Table>
          </Flex>

          <Flex flexDirection="column" marginTop={12} width="100%">
            <SectionTitle>결제 내역</SectionTitle>
            {data?.subscriptionPayments?.length > 0 ? (
              <Table>
                <TableHead>
                  <TableHeadRow>
                    <TableHeadCell width="4%">ID</TableHeadCell>
                    <TableHeadCell width="5%">플랜 이름</TableHeadCell>
                    <TableHeadCell width="8%">플랜 월가격</TableHeadCell>
                    <TableHeadCell width="8%">결제카드사</TableHeadCell>
                    <TableHeadCell width="5%">결제카드번호</TableHeadCell>
                    <TableHeadCell width="5%">금액</TableHeadCell>
                    <TableHeadCell width="5%">결제 상태</TableHeadCell>
                    <TableHeadCell width="8%">결제일</TableHeadCell>
                  </TableHeadRow>
                </TableHead>
                <tbody>
                  {data?.subscriptionPayments?.map((payment) => {
                    const {
                      id,
                      user,
                      subscriptionPlan,
                      billing,
                      amount,
                      status,
                      createdAt,
                    } = payment;

                    return (
                      <TableRow key={id}>
                        <TableCell>{id}</TableCell>
                        <TableCell>{subscriptionPlan?.name}</TableCell>
                        <TableCell>{subscriptionPlan?.monthlyPrice}</TableCell>
                        <TableCell>{billing?.cardName}</TableCell>
                        <TableCell>{billing?.cardNumber}</TableCell>
                        <TableCell>{amount}</TableCell>
                        <TableCell>{status}</TableCell>
                        <TableCell>
                          {moment(createdAt).format("YYYY-MM-DD HH:mm")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div>결제 내역 없음</div>
            )}
          </Flex>
        </>
      )}
    </Modal>
  );
}

export default SubscriptionDetailModal;

import styled from "styled-components";

const AVATAR_SIZE = 50;

export const AvatarWrapper = styled.div(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export const AvatarImage = styled.img(({ size = AVATAR_SIZE }) => ({
  width: size,
  height: size,
  borderRadius: size / 2,
  objectFit: "cover",
  marginBottom: 4,
}));

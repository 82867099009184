import { gql } from "@apollo/client";
import { PAGE_INFO_FIELD, PAYMENT_FIELD } from "./fragments";

export const GET_PAYMENTS_AS_ADMIN = gql`
  ${PAGE_INFO_FIELD}
  ${PAYMENT_FIELD}
  query GetPaymentsAsAdmin(
    $from: DateTime!
    $to: DateTime!
    $unpaidOnly: Boolean
    $offset: Int
    $limit: Int
  ) {
    getPaymentsAsAdmin(
      from: $from
      to: $to
      unpaidOnly: $unpaidOnly
      offset: $offset
      limit: $limit
    ) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...PaymentField
      }
    }
  }
`;

export const TOGGLE_PAYMENT_AS_ADMIN = gql`
  ${PAYMENT_FIELD}
  mutation TogglePaymentAsAdmin($paymentId: ID!, $paid: Boolean!) {
    togglePaymentAsAdmin(paymentId: $paymentId, paid: $paid) {
      ...PaymentField
    }
  }
`;

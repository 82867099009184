import { gql } from "@apollo/client";
import { EXHIBITION_FIELD, PAGE_INFO_FIELD } from "./fragments";

export const UPDATE_EXHIBITION_AS_ADMIN = gql`
  ${EXHIBITION_FIELD}
  mutation UpdateExhibitionAsAdmin($exhibitionInput: ExhibitionInput!) {
    updateExhibitionAsAdmin(exhibitionInput: $exhibitionInput) {
      ...ExhibitionField
    }
  }
`;

export const GET_EXHIBITIONS_AS_ADMIN = gql`
  ${EXHIBITION_FIELD}
  ${PAGE_INFO_FIELD}
  query GetExhibitionsAsAdmin($offset: Int, $limit: Int) {
    getExhibitionsAsAdmin(offset: $offset, limit: $limit) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        ...ExhibitionField
      }
    }
  }
`;

export const GET_EXHIBITION = gql`
  ${EXHIBITION_FIELD}
  query GetExhibition($exhibitionId: ID!) {
    getExhibition(exhibitionId: $exhibitionId) {
      ...ExhibitionField
    }
  }
`;

export const DELETE_EXHIBITION_AS_ADMIN = gql`
  mutation DeleteExhibitionAsAdmin($exhibitionId: ID!) {
    deleteExhibitionAsAdmin(exhibitionId: $exhibitionId)
  }
`;

import React from "react";
import LabeledInput from "../../common/components/LabeledInput";
import { Flex, TextButton } from "../../common/styles/commonStyles";

function PaymentNavBar({
  range,
  unpaidOnly,
  onRangeChange,
  toggleUnpaidOnly,
  onSubmit,
}) {
  const { from, to } = range;

  return (
    <Flex
      padding={12}
      justifyContent="space-between"
      width="100%"
      backgroundColor="rgba(0,0,0,0.1)"
    >
      <Flex alignItems="center" gap={4}>
        <LabeledInput
          label="FROM"
          type="date"
          value={from}
          onChange={(e) => onRangeChange(e, "from")}
        />
        <LabeledInput
          label="TO"
          type="date"
          value={to}
          onChange={(e) => onRangeChange(e, "to")}
        />
        <LabeledInput
          type="checkbox"
          label="미지급만보기"
          checked={unpaidOnly}
          onChange={toggleUnpaidOnly}
        />
        <TextButton width={150} onClick={() => onSubmit()}>
          검색
        </TextButton>
      </Flex>
    </Flex>
  );
}

export default PaymentNavBar;

import Loading from "../../../common/components/Loading";
import {
  Flex,
  SectionTitle,
  TextButton,
} from "../../../common/styles/commonStyles";
import useArtSearchCategorySelector from "./ArtSearchCategorySelector.hook";

function ArtSearchCategorySelector({
  searchCategoryId,
  onSearchCategoryChange,
}) {
  const { models } = useArtSearchCategorySelector();

  const { loading, data } = models;

  if (loading) return <Loading />;

  return (
    <>
      <SectionTitle>검색 카테고리</SectionTitle>
      <Flex
        flexWrap="wrap"
        justifyContent="flex-start"
        marginTop={8}
        marginBottom={12}
        gap={4}
      >
        {data?.map((category) => {
          const isSelected = searchCategoryId === category.id;
          return (
            <TextButton
              key={category.id}
              backgroundColor={isSelected ? "blue" : "white"}
              color={isSelected ? "white" : "black"}
              onClick={() => onSearchCategoryChange(category.id)}
            >
              {category.category}
            </TextButton>
          );
        })}
      </Flex>
    </>
  );
}

export default ArtSearchCategorySelector;

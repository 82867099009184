import styled from "styled-components";
import ReactPaginate from "react-paginate";

export const PaginationDiv = styled(ReactPaginate).attrs({
  activeClassName: "active",
})(() => ({
  margin: "40px auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  listStyleType: "none",
  padding: "8px 12px",
  gap: 8,

  "li a": {
    borderRadius: 4,
    padding: 4,
    border: "1px solid gray",
    cursor: "pointer",
  },

  "li.previous a, li.next a, li.break a": {
    borderColor: "transparent",
  },

  "li.active a": {
    backgroundColor: "#0366d6",
    borderColor: "transparent",
    color: "white",
  },

  "li.disabled a": {
    color: "grey",
  },

  "li.disable, li.disabled a": {
    cursor: "default",
  },
}));
